export const HEADER_USER_STATUS = 'HEADER_USER_STATUS'
export const HEADER_CART_COUNT = 'HEADER_CART_COUNT'
export const HEADER_CART_DETAILS = 'HEADER_CART_DETAILS'
export const HEADER_SAYT_DATA = 'HEADER_SAYT_DATA'
export const HEADER_USER_NAME = 'HEADER_USER_NAME'
export const HEADER_SEARCH_TERMS = 'HEADER_SEARCH_TERMS'
export const HEADER_ITEM_RECORDS = 'HEADER_ITEM_RECORDS'
export const HEADER_INNER_MENU_OPEN = 'HEADER_INNER_MENU_OPEN'
export const HEADER_DROPDOWN = 'HEADER_DROPDOWN'
export const SET_SHOW_OVERLAY = 'SET_SHOW_OVERLAY'
export const TRIGGER_CLOSE_DROPDOWN = 'TRIGGER_CLOSE_DROPDOWN'