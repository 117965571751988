/**
 * Created by adam.smestad on 06/07/21.
 */

export const FAVORITE_ADD = 'FAVORITE_ADD'
export const FAVORITE_ADD_CLEAR = 'FAVORITE_ADD_CLEAR'
export const FAV_SORT = 'FAV_SORT'
export const FAVORITE_UPDATE = 'FAVORITE_UPDATE'
export const FAVORITE_UPDATE_SORT = 'FAVORITE_UPDATE_SORT'
export const MA_LOADING = 'MA_LOADING'
export const MA_LOADING_COMPLETE = 'MA_LOADING_COMPLETE'
export const FAVORITE_DELETE = 'FAVORITE_DELETE'
export const SET_FAV_SEARCH = 'SET_FAV_SEARCH'
