import React, { useEffect, useState } from 'react'
import { HeartNoFill } from '../../_svgs/HeartNoFill/HeartNoFill'
import { PersonWithCircle } from '../../_svgs/PersonWithCircle/PersonWithCircle'
import styled, { css } from 'styled-components'
import { Cart } from '../../_svgs/Cart/Cart'
import { QuestionWithCircle } from '../../_svgs/QuestionWithCircle/QuestionWithCircle'
import { HeaderMenuDropdown } from '../HeaderMenuDropdown/HeaderMenuDropdown'
import { SET_SHOW_OVERLAY, TRIGGER_CLOSE_DROPDOWN } from '../../../constants/headerConstants'
import { useDispatch, useSelector } from 'react-redux'
import { headerInfo } from '../../../actions/headerActions'
import { useMediaQuery } from 'react-responsive'
import { sharedSetInstanceAction } from '../../../actions/sharedActions'

export const HeaderMenuButtons = ({
  cartCount = 0
}) => {
  const dispatch = useDispatch()
  const [menuType, setMenuType] = useState(0)
  const isLoggedIn = useSelector(state => state.header?.userStatus ? state.header.userStatus : false)
  const triggerClose = useSelector(state => state.header?.triggerCloseDropdown ? state.header.triggerCloseDropdown : false)
  const isOpen = useSelector(state => state.header?.showOverlay ? state.header.showOverlay : false)
  const [previousFocus, setPreviousFocus] = useState()
  const [pendingFocus, setPendingFocus] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [pendingType, setPendingType] = useState(0)
  const isDesktop = useMediaQuery({ minWidth: 1280 })

  const triggerDropdown = (e, menuIndex, eventType) => {
    if (menuType === menuIndex && eventType !== 'hover') {
      closeHandler()
    } else if (eventType === 'click' || isOpen) {
      setMenuType(menuIndex)
      setPreviousFocus(e.target)
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'hidden'
      }
      if (pendingType === 2) {
        dispatch(headerInfo())
      }
      if (eventType === 'click') {
        dispatch({type: SET_SHOW_OVERLAY, payload: true})
      }
    } else {
      setPendingType(menuIndex)
      setPendingFocus(e.target)
      setIsHovered(true)
    }
  }

  const mouseLeaveHandler = () => {
    setIsHovered(false)
    closeHandler()
  }

  const closeHandler = () => {
    setMenuType(0)
    setIsHovered(false)
    if (previousFocus) {
      previousFocus.focus()
    }
    dispatch({ type: SET_SHOW_OVERLAY, payload: false })
    if (typeof window.location !== 'undefined') {
      document.body.style.overflow = 'auto'
    }
  }

  const onTimeout = () => {
    setMenuType(pendingType)
    setPreviousFocus(pendingFocus)
    if (pendingType === 2) {
      dispatch(headerInfo())
    }
    dispatch({ type: SET_SHOW_OVERLAY, payload: true })
    if (typeof window.location !== 'undefined') {
      document.body.style.overflow = 'hidden'
    }
  }

  useEffect(() => {
    const timer = isHovered && setTimeout(onTimeout, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [isHovered, pendingType])

  useEffect(() => {
    if (triggerClose) {
      closeHandler()
      dispatch({ type: TRIGGER_CLOSE_DROPDOWN, payload: false })
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'auto'
      }
    }
  }, [triggerClose])

  const loginSliderHandler = () => {
    dispatch(sharedSetInstanceAction({
      name: 'slideInLogin',
      data: {}
    }))
  }

  return (
    <HeaderMenuButtonsContainerDiv>
      <HeaderMenuButtonsDiv
        onMouseLeave={mouseLeaveHandler}
      >
        <ReactHeaderA
          aria-label='favorites'
          href='/myaccount/favorites.html'
          data-testid='menu-favorites-anchor'
          data-tracking='header|favorites-heart'
          onMouseEnter={closeHandler}
        >
          <HeartNoFill/>
        </ReactHeaderA>
        {isLoggedIn ?
          <ReactHeaderBtn
            aria-label='my account'
            data-testid='myAccountBtn'
            onMouseEnter={(e) => {triggerDropdown(e, 1, 'hover')}}
            className={menuType === 1 ? 'dropdown-active' : ''}
            onClick={(e) => isLoggedIn ? triggerDropdown(e,1, 'click') : loginSliderHandler()}
          >
            <PersonWithCircle/>
          </ReactHeaderBtn>
          :
          <ReactHeaderBtn
            aria-label='login'
            data-testid='menu-account-anchor'
            data-tracking='header|sign-in-register'
            onClick={loginSliderHandler}
          >
            <PersonWithCircle/>
          </ReactHeaderBtn>
        }
        <ReactHeaderA
          href='/cart'
          data-tracking='header|cart'
          data-testid='menu-cart-anchor'
          styles={css`position: relative; margin: 0 0.7rem; height: 3.5rem; width: 3.5rem`}
          onMouseEnter={closeHandler}
        >
          <Cart/>
          <CartCountSpan
            className='cart__count bypassInterstitialPage'
            styles={cartCount > 99 ? css`
              font-size: 1.2rem;
              height: 2.5rem;
              width: 2.5rem;
              top: -0.7rem;
            ` : ''}
          >
            {cartCount > 99 ? '99+' : cartCount}
          </CartCountSpan>
        </ReactHeaderA>
        <ReactHeaderBtn
          data-testid='helpBtn'
          onMouseEnter={isDesktop ? (e) => {triggerDropdown(e, 3, 'hover')} : () => {}}
          onClick={(e) => {triggerDropdown(e, 3, 'click')}}
          className={menuType === 3 ? 'dropdown-active' : ''}
        >
          <QuestionWithCircle/>
        </ReactHeaderBtn>
        {menuType !== 0 &&
          <HeaderMenuDropdown cartCount={cartCount} closeHandler={closeHandler} menuType={menuType}/>
        }
      </HeaderMenuButtonsDiv>
    </HeaderMenuButtonsContainerDiv>
  )
}

const HeaderMenuButtonsContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  order: 2;
  width: 25%;
  position: relative;
  @media (min-width: 1280px) {
    height: 7rem;
  }
  @media (max-width: 1279px) {
    display: none;
  }
`

const ReactHeaderBtn = styled.button`
  background-color: ${props => props.theme.white};
  margin: 0 1.2rem;
  padding: 0rem;
  border: none;
  width: 3rem;
  height: 3rem;
  transition: none;
  min-width: auto !important;
  &:hover, &.dropdown-active {
    border-radius: 2rem;
    transition: none;
    @media (min-width: 1280px) {
      background-color: ${props => props.theme.primary};
      path {
        fill: ${props => props.theme.white};
      }
      circle {
        fill: ${props => props.theme.primary};
      }
    }
  }
  ${props => props.styles};
`

const CartCountSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8rem;
  width: 1.8rem;
  top: -0.3rem;
  left: 2.4rem;
  font-size: 1.4rem;
  color: ${props => props.theme.black};
  line-height: 1.6rem;
  background-color: ${props => props.theme.buttercupYellow};
  border: 1px solid ${props => props.theme.primary};
  border-radius: 3rem;
  position: absolute;
  ${props => props.styles};
`

const ReactHeaderA = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.2rem;
  padding: 0;
  border: none;
  width: 3rem;
  height: 3rem;
  transition: none;
  text-decoration: none;
  &:hover {
    border-radius: 2rem;
    transition: none;
    text-decoration: none;
    @media (min-width: 1280px) {
      background-color: ${props => props.theme.primary};
      path {
        fill: ${props => props.theme.white};
      }
      circle {
        fill: ${props => props.theme.primary};
      }
    }
  }
  ${props => props.styles};
`

const HeaderMenuButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
