import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const FacebookIcon = props => {
  return (
    <Svg x='0px' y='0px' viewBox='0 0 288 288' className={`${props.classes}`} >
      <title>Facebook Icon</title>
      <Polygon fill='#2F711C' points='127,186 127,186 127,186 ' />
      <Path fill='#2F711C' d='M143,4.3C66.1,4.3,3.8,66.6,3.8,143.5S66.1,282.8,143,282.8s139.3-62.3,139.3-139.3S219.9,4.3,143,4.3z M191.1,73.9h-19c-14.9,0-17.8,7.1-17.8,17.5v22.9h35.5l-4.6,35.9h-30.9v92.1h-37.1v-92.1h-31v-35.9h31V87.8 c0-30.7,18.8-47.4,46.2-47.4c13.2,0,24.4,1,27.7,1.4V73.9z' />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
 &.footer-icon{
  width: 45px;
  margin: 1rem 1.2rem;
  height: 45px;
  @media(min-width: 768px) and (max-width: 990px){
   width: 25px;
   height: 25px;
   margin: 0.5rem
  }
  @media (min-width: 991px) and (max-width: 1365px ){
   width: 40px;
   height: 40px;
   margin: 0.5rem;
  }
 }
`

const Polygon = styled.polygon`
  fill: '#2F711C';
`
const Path = styled.path`
  fill: '#2F711C'
`
