import styled, { css } from 'styled-components'

export const AriaHidden = styled.div`
  ${props => props.isAriaHidden ? `
    max-height: 0;
    margin: 0 !important;
    overflow: hidden;
    visibility: hidden;
    ${props => props.displayNone && css`
      display: none;
    `}
  ` : `
    max-height: 9000px;
    transition: max-height 2s;
    height: auto;
    visibility: visible;
  `}
`
