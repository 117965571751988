import {
  CART_GET_PRODUCT_DATA,
  CART_GET_ORDER_TOTALS,
  CART_GET_PROMO_INFO,
  GET_RECOMMENDED_ITEMS,
  SHOPPING_CART_DATA_COMPLETE,
  CART_GET_EASYREFILL,
  SET_DISPLAY_ECHO_BANNER
} from '../constants/shoppingCartConstants'
import { updatePromoCodeMessages } from '../actions/cartMessageActions'
import { GET_PROMO_DESCRIPTION } from '../constants/promoDescConstants'
import axios from 'axios'
import { LOADING, LOADING_COMPLETE } from '../constants/appConstants'
import { CART_GET_CART_COUNT } from '../constants/cartCountConstants'
import setOgSettings from '../actions/setOrderGrooveSettingsAction'
import { CART_INFO_SAVE } from '../constants/cartConstants'
import { HEADER_CART_COUNT } from '../constants/headerConstants'
import { formUpdateAction } from './formActions'
import { utagLink } from '../tealium'
import { CLEAR_MESSAGES } from '../constants/messagesConstants'

export function updateCartData (data, dispatch) {
  dispatch({ type: LOADING_COMPLETE })
  dispatch({ type: CART_GET_ORDER_TOTALS, payload: data.shoppingBag })
  dispatch({ type: CART_GET_CART_COUNT, payload: data.cartCount })
  dispatch({ type: CART_GET_PRODUCT_DATA, payload: data.sortedItemList })
  dispatch({ type: CART_GET_PROMO_INFO, payload: data.promoCode })
  dispatch({ type: GET_RECOMMENDED_ITEMS, payload: data.wrAdobeRecords })
  dispatch({ type: GET_PROMO_DESCRIPTION, payload: data.promodesc })
  dispatch({ type: CART_GET_EASYREFILL, payload: data.easyRefill })
}

function performAfterCartUpdate (data, formData, dispatch, additionalCode) {
  window._shoprunner_com.cart.srSubTotal = data.shoppingBag.totalRoundedCost
  window._shoprunner_com.isMOVSatisfied = data.shoppingBag.totalRoundedCost >= 50

  if (formData.action === 'promocode') {
    if (data.hasOwnProperty('messages')) {
      updatePromoCodeMessages(data, dispatch)
    } else {
      dispatch({ type: SET_DISPLAY_ECHO_BANNER, payload: false })
      utagLink({
        'tealium_event': 'cart_update_promo',
        'promo_code': data.promoCode.promoCode.trim()
      })
    }
  } else if (formData.action === 'add') {
    let storedItems = data.sortedItemList
    for (let sili = 0; sili < storedItems.length; sili++) {
      dispatch(formUpdateAction({
        form: 'cartQuantityForm' + storedItems[sili].productPartNumber,
        name: 'qty',
        value: storedItems[sili].quantity
      }))
    }
  }
    updateCartData(data, dispatch)
    setOgSettings(data)

  if (additionalCode) {
    additionalCode()
  }

  dispatch({
    type: CART_INFO_SAVE,
    data: {
      numCartItems: data.cartCount,
      orderSubTotal: '$' + parseFloat(data.shoppingBag.totalDiscountPrice).toFixed(2)
    } })

  dispatch({
    type: SHOPPING_CART_DATA_COMPLETE,
    payload: formData
  })

  axios.get(`${process.env.NODE_ENV === 'development' ? 'https://localhost' : window.location.origin}/def/header.cart.count.html`)
    .then((response) => {
      // console.log('RESPONSE', response)
      const cart = document.querySelector('.cart-menu')
      cart.querySelector('.cart__count').remove()
      cart.insertAdjacentHTML('afterBegin', response.data)
    })
}

export function updateCartFormAction (formData, additionalCode) {
  return (dispatch) => {
    dispatch({ type: LOADING })
    const encodeForm = (data) => {
      return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
    }
    axios.post(`${process.env.NODE_ENV === 'development' ? 'https://localhost' : window.location.origin}/cart`,
      encodeForm(formData)
    )
      .then(function (response) {
        if (!response.data.hasOwnProperty('messages')) {
          dispatch({ type: CLEAR_MESSAGES })
        }
        performAfterCartUpdate(response.data, formData, dispatch, additionalCode)
      })
      .catch(function (error) {
        dispatch({ type: LOADING_COMPLETE })
        if (error) {
          //
        }
      })
  }
}

export function cleanMyCartAction () {
  return (dispatch) => {
    axios.post(`${process.env.NODE_ENV === 'development' ? 'https://localhost' : window.location.origin}/cart`,
      'action=emptyCart'
    ).then((response) => {
      window._shoprunner_com.cart.srSubTotal = response.data.shoppingBag.totalRoundedCost
      window._shoprunner_com.isMOVSatisfied = response.data.shoppingBag.totalRoundedCost >= 50
      updateCartData(response.data, dispatch)
      setOgSettings(response.data)
      dispatch({ type: HEADER_CART_COUNT, payload: 0 })
      dispatch({ type: SHOPPING_CART_DATA_COMPLETE })
    })
  }
}

export function updateCartSuccessAction (data, formData) {
  return (dispatch) => {
    performAfterCartUpdate(data, formData.shoppingCartCompleteFormData, dispatch)
  }
}
