import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export const FreeShippingProgressBar = ({
  subtotal,
  threshold
}) => {
  const [quantifyAmount, setQuantifyAmount] = useState(-1)
  const [percentCalc, setPercentCalc] = useState('')
  const [thresholdMeet, setThresholdMeet] = useState(false)
  const tempThreshold = typeof threshold !== 'number' ? parseFloat(threshold)*100 : threshold*100
  const tempSubtotal = typeof subtotal !== 'number' ? parseFloat(subtotal)*100 : subtotal*100

  useEffect(() => {
    if (tempThreshold < tempSubtotal) {
      setThresholdMeet(true)
      setQuantifyAmount(-1)
      setPercentCalc('100')
    } else {
      setThresholdMeet(false)
      setQuantifyAmount((tempThreshold - tempSubtotal) / 100)
      setPercentCalc(((tempSubtotal / tempThreshold) * 100).toFixed(2))
    }
  }, [subtotal])

  return (
    <FreeShippingProgressContainer>
      <FreeShippingProgressBarTextDiv>
      {!thresholdMeet ?
        <>Add ${quantifyAmount.toFixed(2)} to qualify for FREE SHIPPING!</>
        :
        <>You qualify for FREE SHIPPING!</>
      }
      </FreeShippingProgressBarTextDiv>
      <FreeShippingProgressBarContainer>
        <FreeShippingProgressBarDarkGrey widthPercent={`${percentCalc}%`}/>
      </FreeShippingProgressBarContainer>
    </FreeShippingProgressContainer>
  )
}

const FreeShippingProgressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const FreeShippingProgressBarTextDiv = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  font-size: 1.8rem;
  font-weight: 600;
  color: ${props => props.theme.treeGreen};
`

const FreeShippingProgressBarContainer = styled.div`
  display: flex;
  height: 1.8rem;
  width: 100%;
  position: relative;
  background-color: ${props => props.theme.mediumGrey};
  border-radius: 0.8rem;
  margin-top: 0.8rem;
  ${props => props.styles};
`

const FreeShippingProgressBarDarkGrey = styled.div`
  display: flex;
  width: ${props => props.widthPercent};
  height: 1.8rem;
  position: absolute;
  background-color: ${props => props.theme.darkGrey};
  border-radius: 0.8rem;
`