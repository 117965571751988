import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

export const TabbedContainer = ({
  tabs,
  tabsDataTarget,
  views,
  defaultTab = 0,
  showTabs = true,
  isTabsLocatedOnTop = true,
  showActiveTab = true,
  rightSection,
  onTabClicked = (tab) => {}
}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab)
  useEffect(() => {
    setCurrentTab(defaultTab)
  }, [defaultTab])

  const clickHandler = (index, tab) => (e) => {
    setCurrentTab(index)
    onTabClicked(tab)
  }

  const tabButtons = tabs.map((tab, index) => (
    <TabButton key={`tab${index}`} autofocus data-target={tabsDataTarget ? tabsDataTarget[index] : ''} className={`${index === currentTab ? `${showActiveTab ? '' : 'none '}button-darkestOrange-alt` : 'button-darkestOrange'}`} onClick={clickHandler(index, tab)}>{tab}</TabButton>
  ))

  return (
    <section>
      {showTabs && isTabsLocatedOnTop &&
      <TabButtonContainer>
        {tabButtons}
      </TabButtonContainer>
      }
      <TabbedContainerView>
        {views.map((view, index) => (
          <Key key={`view${index}`} className={index === currentTab ? '' : 'none'}>{view}</Key>
        ))}
        <Container>

          {rightSection &&
            <TabbedSpan>{rightSection}</TabbedSpan>
          }
        </Container>
      </TabbedContainerView>
      {showTabs && !isTabsLocatedOnTop &&
      <TabButtonContainer>
        {tabButtons}
      </TabButtonContainer>
      }
    </section>
  )
}

const Container = styled.div`
    margin: -1rem auto 4rem;
    width: 30rem;
    @media (max-width: 766px) {
     margin: 0 auto 4rem;
    }
`
const Style = css`
  margin: 0 auto;
  display: block;
`
const TabbedContainerView = styled.div`
    ${Style};
    &.none {
      display: none;
    }
`

const TabButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`
const Key = styled.div`
    ${Style};
    padding: 0 0.8rem;
    &.none {
      display: none;
    }
`
const TabButton = styled.button`
  margin: 0 0.25rem;
  border-radius: 0.8rem;
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 1rem;
  min-height: 4rem;
  &.button-darkestOrange {
    background-color: ${props => props.theme.darkestOrange};
    color: ${props => props.theme.white};
    border: none;
  }
  &.button-darkestOrange-alt{
    border: 2px solid ${props => props.theme.darkestOrange};
    color: ${props => props.theme.darkestOrange};
    background-color: ${props => props.theme.white};
  }
  &.none {
   display: none;
  }
`
const TabbedSpan = styled.span`
    ${Style};
`
