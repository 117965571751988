import React from 'react'
import styled, { css, keyframes } from 'styled-components'

export const LoadAnimation = ({
  width = '50%',
  styles,
  superBounce = true,
  everyCircleStyles
}) => {
  return (
    <LoadAnimationContainer
      loadWidth={width}
      styles={styles}
    >
      <Circle superBounce={superBounce} styles={everyCircleStyles} />
      <Circle superBounce={superBounce} styles={css`animation-delay: .2s;${everyCircleStyles};`} />
      <Circle superBounce={superBounce} styles={css`animation-delay: .6s;${everyCircleStyles};`} />
      <Circle superBounce={superBounce} styles={css`animation-delay: .9s;${everyCircleStyles};`} />
      <Circle superBounce={superBounce} styles={css`animation-delay: 1.1s;${everyCircleStyles};`} />
    </LoadAnimationContainer>
  )
}
const LoadAnimationContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 22rem;
  height: 3rem;
  width: ${props => props.loadWidth};
  ${props => props.styles};
`

const bounce = keyframes`
  0% {
    transform: translate3d(0, 0, 0)
  }
  
  16.5% {
    transform: translate3d(0, -10px, 0)
  }
  
  33% {
    transform: translate3d(0, 5px, 0)
  }
  
  49% {
    transform: translate3d(0, 0, 0)
  }
  
  100% {
    transform: translate3d(0, 0px, 0)
  }
`
const superBounce = keyframes`
  0% {
    transform: translate3d(0, 0, 0)
  }
  
  16.5% {
    transform: translate3d(0, -20px, 0)
  }
  
  33% {
    transform: translate3d(0, 10px, 0)
  }
  
  49% {
    transform: translate3d(0, 0, 0)
  }
  
  100% {
    transform: translate3d(0, 0px, 0)
  }
`

const Circle = styled.div`
  background-color: #2F711C;
  width: 12%;
  padding-bottom: 12%;
  margin: 0 4%;
  border-radius: 50%;
  animation: ${props => props.superBounce ? superBounce : bounce} 1.4s ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  ${props => props.styles};
`
