import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const PersonWithCircle = ({
  width = '25',
  height = '24',
  fill = '#2F711C'
}) => {
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 25 24'>
      <title>Account Sign In/Register</title>
      <Path
        d='M12.8 0C6.17605 0 0.800049 5.376 0.800049 12C0.800049 18.624 6.17605 24 12.8 24C19.424 24 24.8 18.624
        24.8 12C24.8 5.376 19.424 0 12.8 0ZM6.88405 19.536C7.40005 18.456 10.544 17.4 12.8 17.4C15.056 17.4 18.212
        18.456 18.716 19.536C17.084 20.832 15.032 21.6 12.8 21.6C10.568 21.6 8.51605 20.832 6.88405 19.536ZM20.4321
        17.796C18.7161 15.708 14.552 15 12.8 15C11.048 15 6.88405 15.708 5.16805 17.796C3.94405 16.188 3.20005 14.184
        3.20005 12C3.20005 6.708 7.50805 2.4 12.8 2.4C18.092 2.4 22.4 6.708 22.4 12C22.4 14.184 21.6561 16.188 20.4321
        17.796ZM12.8 4.8C10.472 4.8 8.60005 6.672 8.60005 9C8.60005 11.328 10.472 13.2 12.8 13.2C15.128 13.2 17 11.328 17
        9C17 6.672 15.128 4.8 12.8 4.8ZM12.8 10.8C11.804 10.8 11 9.996 11 9C11 8.004 11.804 7.2 12.8 7.2C13.796 7.2 14.6
        8.004 14.6 9C14.6 9.996 13.796 10.8 12.8 10.8Z'
        fill={fill}
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  fill: none;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`

const Path = styled.path`
  fill: ${props => props.fill};
`
