import { useCioAutocomplete } from '@constructor-io/constructorio-ui-autocomplete'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

export const useConstructor = (submitHandler) => {
  const isClient = typeof window.location !== 'undefined'
  const {
    sections,
    setQuery
  } = isClient ? useCioAutocomplete({
    cioJsClientOptions: {
      serviceUrl: window.constructorHost,
      userId: window.cnstrcUserId
    },
    onSubmit: submitHandler,
    apiKey: window.constructorApiKey
  }) : {
    sections: [],
    setQuery: () => {}
  }
  const dispatch = useDispatch()
  const prevSectionsRef = useRef()
  // state prevents retention of callback argument forwarding
  // i.e. callbackViaRef(payload) works, callbackViaSetState(payload) does not
  const callback = useRef()

  const searchConstructor = (query, cb) => {
    setQuery(query)
    callback.current = cb
  }

  useEffect(() => {
    if (!sections.length) return
    if (JSON.stringify(sections) === JSON.stringify(prevSectionsRef.current)) return
    const searchTerms = sections.find(section => section.identifier === 'Search Suggestions').data
    const payload = {
      result: {
        searchTerms: searchTerms.length ? searchTerms : null,
        itemRecords: sections.find(section => section.identifier === 'Products').data.map(item => ({
          productPartNumber: item.data.id,
          statusUnavailable: false,
          longURL: 'p/' + item.data.url,
          productVendor: item.data.facets.find(facet => facet.name === 'brand')?.values?.[0],
          productName: item.value,
          productDetails: item.data.facets.find(facet => facet.name === 'potent')?.values?.[0]
        })).slice(0, 4) // waiting on constructor for how to use their hook to limit this
      }
    }
    dispatch(callback.current(payload))
    prevSectionsRef.current = sections

  }, [sections])

  return {
    sections,
    searchConstructor
  }
}
