import React from 'react'
import styled from 'styled-components'
import { StandardLink } from '../../_styled/Link'
import { ReviewRating } from '../../ReviewRating/ReviewRating'
import { CTAButton } from '../../_inputs/Button/Button'
import { trackingStrip } from "../../../tealium"

export const FeaturedProduct = ({
  product,
  category
}) => {
  return (
    <ContainerDiv className='header_featured-shop-now'>
      <ProductImg src={`https://media.swansonvitamins.com/images/items/250/${product.productPartNumber}.jpg`} alt={product.productName} />
      <InfoUl>
        <Li className={`${category && product ? `cTest_header-${category.replace(/[^A-Z]/g, '')}-${product.productName.replace(/[^A-Z]/g, '')}-link` : ''}`}>
          <ProductA data-tracking={`header|${trackingStrip(category)}|featured-product|${trackingStrip(product.productName)}|name`} href={`/${product.longURL}`}>{product.productName}</ProductA>
        </Li>
        <ReviewLi>
          <ReviewRating
            trackingData={`header|${trackingStrip(category)}|featured-product|${trackingStrip(product.productName)}|reviews`}
            productUrl={product.longURL}
            rating={product.rating}
            ratingCount={product.numReviews}
            trackingClass={`header_featured-product ${category && product ? `cTest_header-${category.replace(/[^A-Z]/g, '')}-${product.productName.replace(/[^A-Z]/g, '')}-review` : ''}`}
            productName={product.productName}
            category={category}
          />
        </ReviewLi>
        <Li>
          <ProductA data-tracking={`header|${trackingStrip(category)}|featured-product|${trackingStrip(product.productName)}|shop-now`} href={`/${product.longURL}`}>
            <CTAButton className={`${category && product ? `cTest_header-${category.replace(/[^A-Z]/g, '')}-${product.productName.replace(/[^A-Z]/g, '')}-btn` : ''}`}>shop now</CTAButton>
          </ProductA>
        </Li>
      </InfoUl>
    </ContainerDiv>
  )
}

const ProductA = styled(StandardLink)`
  text-align: left;
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
  }
`

const ContainerDiv = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
`

const Li = styled.li`
  text-align: left;
`

const ReviewLi = styled(Li)`
  padding: 1rem 0;
`

const InfoUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ProductImg = styled.img`
  width: 100px;
  min-width: 100px;
  height: 100px;
`
