import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FocusTrap } from '../FocusTrap/FocusTrap'
import { LightBoxCentered } from '../_styled/LightBoxOptions'
import { Overlay } from '../Overlay/Overlay'
import { Button } from '../_inputs/Button/Button'

export const LightBox = ({
  productTitle = '',
  show = false,
  closeHandler = () => {},
  classes = '',
  styles = LightBoxCentered,
  xStyles,
  container = 'LightBox__default-width',
  children,
  closeXMinWidth = 'auto',
  childBeforeX = false,
  closeHandlerAfter = false
}) => {
  useEffect(() => {
    if (show) {
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'hidden'
      }
    } else {
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'auto'
      }
    }
  }, [show])

  const lightBoxCloseHandler = (e) => {
    if (e.target.classList.contains('LightBox--close')) {
      if (closeHandler && !closeHandlerAfter) {
        closeHandler()
      }
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'auto'
      }
      if (closeHandler && closeHandlerAfter) {
        closeHandler()
      }
    }
  }

  const escHandler = (e) => {
    if (e.which === 27) {
      if (closeHandler) {
        closeHandler()
      }

      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'auto'
      }
    }
  }

  return (
    <FocusTrap show={show}>
      <Overlay
        isVisible={show}
        fromTop={0}
        fromBottom='0'
        data-testid='LightBox__overlay'
      >
        <OverlayScrollContainer data-testid='LightBox__scroll' className={`${show ? 'Lightbox--active' : ''} LightBox--close`} onClick={lightBoxCloseHandler}>
          <ContentContainer styles={styles} role='dialog'>
            {childBeforeX &&
              <>{children}</>
            }
            {productTitle
              ? <TitleCloseHeaderDiv>
                <ProductTitleDiv>
                  {productTitle}
                </ProductTitleDiv>
                <X className='LightBox--close'
                  data-testid='LightBox__x'
                  aria-label='Close'
                  onKeyUp={escHandler}
                  onClick={lightBoxCloseHandler}
                  minWidth={closeXMinWidth}
                  styles={xStyles}
                >
                  <span onClick={lightBoxCloseHandler} className='LightBox--close LightBox--close-x'>&times;</span>
                </X>
              </TitleCloseHeaderDiv>
              : <X className='LightBox--close'
                data-testid='LightBox__x'
                aria-label='Close'
                onKeyUp={escHandler}
                onClick={lightBoxCloseHandler}
                minWidth={closeXMinWidth}
                styles={xStyles}
              >
                <span onClick={lightBoxCloseHandler} className='LightBox--close LightBox--close-x'>&times;</span>
              </X>
            }
            {!childBeforeX &&
              <>{children}</>
            }
          </ContentContainer>
        </OverlayScrollContainer>
      </Overlay>
    </FocusTrap>
  )
}

const X = styled(Button)`
  font-size: 2.8rem;
  background-color: transparent;
  color: ${props => props.theme.black};
  z-index: 90000000020;
  line-height: 1.3rem;
  position: absolute;
  right: 1rem;
  top: 0;
  cursor: pointer;
  border: none;
  ${props => props.styles};
  &:focus {
    border: 1px solid ${props => props.theme.black};
    box-shadow: none;
  }
`

const ContentContainer = styled.div`
  width: 30.4rem;
  padding: 4.0rem 1rem 1rem;
  background: ${props => props.theme.white};
  box-shadow: 0 0.1rem 0.3rem ${props => props.theme.mediumGrey};
  margin: 4rem auto 0;
  position: relative;
  ${props => props.styles ? props.styles : ''};
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}){
    width: 80%;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}){
   width: 100%;
  }
`

const OverlayScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

const ProductTitleDiv = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`

const TitleCloseHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`
