/**
 * Created by taylor.houston on 11/13/2018.
 */

import { CART_ADD, CART_INFO_SAVE, CART_NOTIFY_HIDE } from '../constants/cartConstants'
import { CART_GET_EASYREFILL, CART_GET_ORDER_TOTALS, CART_GET_PRODUCT_DATA } from '../constants/shoppingCartConstants'
const initialState = {
  recentAdd: false,
  sortedItemList: window?.initialState?.sortedItemList,
  shoppingBag: window?.initialState?.shoppingBag
}

const cartReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case CART_ADD: {
      return { ...reduxState,
        item: action.item,
        recentAdd: true
      }
    }

    case CART_NOTIFY_HIDE: {
      return { ...reduxState,
        recentAdd: false
      }
    }

    case CART_INFO_SAVE: {
      return { ...reduxState, cartData: action.data }
    }

    case CART_GET_PRODUCT_DATA:
      return { ...reduxState, sortedItemList: action.payload }
    case CART_GET_ORDER_TOTALS:
      return { ...reduxState, shoppingBag: action.payload }
    case CART_GET_EASYREFILL:
      return { ...reduxState, easyRefill: action.payload }

    default:
      return reduxState
  }
}

export default cartReducer
