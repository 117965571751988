import styled from 'styled-components'

export const Button = styled.button`
  height: 4rem;
  display: inline-flex;
  color: ${props => props.theme.white};
  font-size: 1.4rem;
  font-family: inherit;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  font-weight: 400;
  border-radius: 5%;
  padding: .2rem .8rem;
  touch-action: manipulation;
  cursor: pointer;
  border: .2rem solid;
  background-image: none;
  white-space: nowrap;
  transition: background-color .5s;
  @media(min-width: ${props => props.theme.breakpoints.sm}){
    min-width: ${props => props.minWidth || '12rem'};
  }
  @media print {
    display: none;
  }
  ${props => props.styles};
`

export const CTAButton = styled(Button)`
  background-color: ${props => props.theme.darkestOrange};
  border: ${props => props.theme.darkestOrange};
  border-radius: 0.8rem;
  &:hover {
    background-color: ${props => props.theme.darkestOrange};
    color: ${props => props.theme.white};
  }
  &:active {
    background-color: ${props => props.theme.darkestOrange};
    color: ${props => props.theme.white};
  }
  ${props => props.styles};
`

export const CTAButtonAlt = styled(Button)`
  background-color: ${props => props.theme.white};
  border: .1rem solid ${props => props.theme.darkestOrange};
  color: ${props => props.theme.darkestOrange};
  border-radius: 0.8rem;
`

export const SecondaryButton = styled(Button)`
  background-color: ${props => props.theme.white};
  border: .1rem solid ${props => props.theme.darkGrey};
  color: ${props => props.theme.darkGrey};
  border-radius: 0.8rem;
  &:active {
    background-color: ${props => props.theme.white};
    border-color: ${props => props.theme.darkGrey};
  }
  ${props => props.styles};
`

export const SecondaryButtonAlt = styled(Button)`
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.primary};
  &:active {
    color: ${props => props.theme.darkestGreen};
  }
`

export const TransparentButton = styled(Button)`
  background-color: transparent;
  border-color: ${props => props.theme.white};
  min-width: auto !important;
  ${props => props.styles};
`

export const ButtonThatLookLikeLink = styled.button`
  background-color: transparent;
  cursor: pointer;
  height: 4rem;
  color: ${props => props.theme.primary};
  text-decoration: underline;
  border: none;
  ${props => props.styles};
`

export const GrayButton = styled(Button)`
  background-color: ${props => props.theme.darkGrey};
  border-color: ${props => props.theme.darkGrey};
`

export const DisabledButton = styled(GrayButton)`
  cursor: not-allowed;
  pointer-events: none;
`

export const InlineCTAButton = styled(CTAButton)`
  display: inline-block;
  ${props => props.styles};
`

export const ButtonThatLookLikeLink2 = styled.button`
  padding: 0 0 5px 5px;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.theme.primary};
  text-decoration: underline;
  border: none;
`

export const ButtonThatLookLikeFooterLink = styled.button`
  display: inline-block;
  margin-right: 0.5rem;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0px;
  color: ${props => props.theme.black};
  font-size: 1.4rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 1.5rem;
  }
`
