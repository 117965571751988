import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const FeedbackIcon = ({
  height = `1.6rem`,
  width = `1.6rem`,
  fill = '#00833D'
}) => {
  return (
    <SvgAttr xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width={width} height={height}>
      <title>Feedback Icon</title>
        <Path
          d='M1.95605 5.66667H9.28939V7H1.95605V5.66667ZM1.95605 4.33333H9.28939V3H1.95605V4.33333ZM1.95605
            9.66667H6.62272V8.33333H1.95605V9.66667ZM11.9627 7.58L12.4361 7.10667C12.6961 6.84667 13.1161 6.84667 13.3761
            7.10667L13.8494 7.58C14.1094 7.84 14.1094 8.26 13.8494 8.52L13.3761 8.99333L11.9627 7.58ZM11.4894 8.05333L7.95605
            11.5867V13H9.36939L12.9027 9.46667L11.4894 8.05333Z'
          fill={fill}
        />
    </SvgAttr>
  )
}


const Path = styled.path`
  fill: ${props => props.theme.primary}
`
