export const ACCORDION_SHIPPING_BUTTON = 'ACCORDION_SHIPPING_BUTTON'
export const ACCORDION_SHIPPING = 'ACCORDION_SHIPPING'
export const ACCORDION_SHIPPING_METHOD = 'ACCORDION_SHIPPING_METHOD'
export const ACCORDION_PAYMENT_BUTTON = 'ACCORDION_PAYMENT_BUTTON'
export const ACCORDION_BILLING_ADDRESS = 'ACCORDION_BILLING_ADDRESS'
export const ACCORDION_PAYMENT = 'ACCORDION_PAYMENT'
export const ACCORDION_PAYMENT_SUMMARY = 'ACCORDION_PAYMENT_SUMMARY'
export const ACCORDION_PLACE_ORDER_BUTTON = 'ACCORDION_PLACE_ORDER_BUTTON'
export const ACCORDION_PLACE_ORDER = 'ACCORDION_PLACE_ORDER'
export const ACCORDION_SHIPPING_SUMMARY = 'ACCORDION_SHIPPING_SUMMARY'
export const ACCORDION_UPDATE_VIEW = 'ACCORDION_UPDATE_VIEW'
