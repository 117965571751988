/* eslint-disable no-unused-vars */
import { locationChange } from '../actions/routerActions'
import { updateSearchDataAction } from '../actions/updateSearchDataAction'

export function locationListener (history, store) {
  store.dispatch(locationChange({
    pathname: history.location.pathname,
    search: history.location.search,
    hash: history.location.hash
  }))
  history.listen((location) => {
    store.dispatch(locationChange({
      pathname: location.pathname,
      search: location.search,
      hash: location.hash
    }))
    store.dispatch(updateSearchDataAction())
  })
}

export default locationListener
