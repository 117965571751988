import React, { useState } from 'react'
import styled, { css } from 'styled-components'

export const Tooltip2 = ({ tooltip, object }) => {
  const [hovering, setHovering] = useState(false)
  const [focus, setFocus] = useState(false)
  const hoverHandle = () => {
    setHovering(!hovering)
  }
  const keyboardFocus = (e) => {
    setHovering(!hovering)
  }
  const tooltipBtnFocus = () => {
    setFocus(!focus)
  }

  return (
    <Tooltip2Container type='button' onMouseEnter={hoverHandle} onMouseLeave={hoverHandle} onClick={keyboardFocus} tabIndex='0' onBlur={() => setHovering(false)}>
      <span>
        {object}
      </span>
      <TooltipArrow hovering={hovering} />
      <TooltipItself role='status' hovering={hovering} focus={focus} tabIndex='-1' onClick={tooltipBtnFocus}>
        {tooltip}
      </TooltipItself>
    </Tooltip2Container>
  )
}

const Tooltip2Container = styled.button`
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    position: static;
  }
  &:focus {
    border: none
  }
`

const TooltipArrow = styled.span`
  transform: rotate(45deg);
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border: .1rem solid ${props => props.theme.darkGrey};
  border-right: 0;
  border-bottom: 0;
  z-index: 5004;
  top: 4.9rem;
  background-color: ${props => props.theme.white};
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    top: auto;
    margin-top: 4.9rem;
  }
  ${props => !props.hovering && `
    display: none;
  `}
`

const TooltipItself = styled.span`
  text-align: left;
  position: absolute;
  padding: .6rem;
  border: .1rem solid ${props => props.theme.darkGrey};
  width: 32rem;
  background-color: ${props => props.theme.white};
  left: 0;
  box-shadow: .3rem .3rem .3rem #bbb;
  z-index: 5003;
  margin: 0;
  top: 5.5rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 95vw;
    top: auto;
    margin-top: 5.5rem;
  }
  ${props => props.focus && css`
    outline-style: solid;
  `}
  ${props => !props.hovering && css`
    display: none;
  `}
`
