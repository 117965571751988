import React from 'react'
import styled, { css } from 'styled-components'
import { ReviewStars } from '../ReviewStars/ReviewStars'
import { BlackLink } from '../_styled/Link'

export const ReviewRating = ({
  rating,
  ratingCount,
  productUrl,
  styles,
  simplify = false,
  handleClick,
  trackingClass,
  productName,
  category,
  trackingData,
  beFirstStyles,
  starStyles,
  starTextStyles,
  backgroundFill,
  starsFill,
  onPDP = false,
  pdpCard = false
}) => {
  let productReviewURL = ''
  let firstReviewURL = ''
  if (onPDP) {
    productReviewURL = `#customer-review-details`
    firstReviewURL = `#customer-review-details`
  } else if (pdpCard) {
    productReviewURL = `/${productUrl}#customer-review-details`
    firstReviewURL = `/${productUrl}#customer-review-details`
  } else {
    if (!onPDP && `${productUrl}`.indexOf('?') > -1) {
      productReviewURL = `/${productUrl}&expandProductReviews=t#customer-review-details`
      firstReviewURL = `/${productUrl}&expandProductReviews=t&firstReview=t#customer-review-details`
    } else {
      productReviewURL = `/${productUrl}?expandProductReviews=t#customer-review-details`
      firstReviewURL = `/${productUrl}?expandProductReviews=t&firstReview=t#customer-review-details`
    }
  }

  if (ratingCount > 0 || simplify) {
    return (
      <RatingContainer
        styles={`
          display: ${backgroundFill ? 'flex' : 'inline'};
        `}
      >
        <A
          as='a'
          onClick={productUrl ? () => {} : handleClick}
          href={onPDP ? productReviewURL : (productUrl ? productReviewURL : '/')}
          aria-label={`${rating} out of 5 stars`}
          styles={styles}
          className={trackingClass}
          data-content={productName}
          data-target={category}
          data-tracking={trackingData}
        >
          <ReviewStars starFill={starsFill} styles={starStyles} rating={rating} size='10rem' onPDP={backgroundFill} />
          {ratingCount &&
            <Span styles={starTextStyles}>( {ratingCount} )</Span>
          }
        </A>
        {onPDP && backgroundFill &&
          <A
            as='a'
            onClick={productUrl ? () => {} : handleClick}
            href='#customer-review-details'
            aria-label='write a review'
            styles={`
              text-decoration: underline;
              margin-left: 1rem;
              text-underline-offset: 4px;
              font-size: 1.6rem;
            `}
          >
            Write a review
          </A>
        }
      </RatingContainer>
    )
  }
  return (
    <FirstA as={productUrl ? 'a' : 'div'} center={!!productUrl} href={firstReviewURL}
      onClick={productUrl ? '' : handleClick}
      aria-label={`${rating} out of 5 stars`}
    >
      <SpanBeFirst styles={beFirstStyles}>Be first to write a review</SpanBeFirst>
    </FirstA>
  )
}

const A = styled(BlackLink)`
  color: ${props => props.theme.black};
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  ${props => props.styles};
`

const FirstA = styled.a`
  display: flex;
  justify-content: start;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.black};
  ${props => props.center && css`
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      justify-content: flex-start;
    }
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      justify-content: center;
    }
  `}
`

const SpanBeFirst = styled.span`
  margin-top: .2rem;
  display: inline-block;
  ${props => props.styles};
`

const Span = styled.span`
  ${props => props.styles};
`

const RatingContainer = styled.span`
  ${props => props.styles};
`
