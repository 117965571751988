import React from 'react'
import styled from 'styled-components'
import { KosherIcon } from '../_svgs/KosherIcon/KosherIcon'
import { CrueltyFreeIcon } from '../_svgs/CrueltyFreeIcon/CrueltyFreeIcon'
import { OrganicIcon } from '../_svgs/OrganicIcon/OrganicIcon'
import { GlutenFreeIcon } from '../_svgs/GlutenFreeIcon/GlutenFreeIcon'
import { VeganIcon } from '../_svgs/VeganIcon/VeganIcon'
import { VegetarianIcon } from '../_svgs/VegetarianIcon/VegetarianIcon'
import { NonGmoIcon } from '../_svgs/NonGmoIcon/NonGmoIcon'
import { NonGmoProjectIcon } from '../_svgs/NonGmoProjectIcon/NonGmoProjectIcon'
import { StarKosherIcon } from '../_svgs/StarKosherIcon/StarKosherIcon'
import { QualityCodeSeal } from '../_svgs/QualityCodeSeal/QualityCodeSeal'
import { Tooltip2 } from '../Tooltip2/Tooltip2'
import { MoneyBackGuarantee } from '../_svgs/MoneyBackGuarantee/MoneyBackGuarantee'

export const SpecialtyIcons = (props) => {
  return (
    <Container styles={props.styles}>
      <InnerContainer data-testid='SpecialtyIcons' styles={props.innerStyles}>
        {props.isSwanson &&
          <Tooltip2
            object={
              <SealContainer>
                <QualityCodeSeal />
              </SealContainer>
            }
            tooltip={
              <span>
                <span><HighLightWord>Discovered</HighLightWord> by our team of internal scientific &amp; wellness experts</span><br />
                <span><HighLightWord>Tested</HighLightWord> for unsurpassed purity, potency safety &amp; quality by internal &amp; third-party labs</span><br />
                <span><HighLightWord>Approved</HighLightWord> by Swanson Health's expert standards for quality products for mind, body &amp; home</span>
              </span>
            }
          />}
        {props.vegan &&
          <Tooltip2
            object={
              <SpecialtyIconContainer>
                <VeganIcon />
              </SpecialtyIconContainer>
            }
            tooltip={
              <span className='pdp-badge-popup'><strong>Vegan:</strong> This product was made without animal-derived foods, ingredients or by-products such as meat (including fish, shellfish & insects), dairy, eggs, honey, beeswax, wool or hair.</span>
            }
          />
        }
        {!props.vegan && props.vegetarian &&
          <Tooltip2
            object={
              <SpecialtyIconContainer>
                <VegetarianIcon />
              </SpecialtyIconContainer>
            }
            tooltip={
              <span className='pdp-badge-popup'><strong>Vegetarian:</strong> This product was made without meat or animal-derived ingredients like gelatin or keratin.</span>
            }
          />
        }
        {props.glutenFree &&
          <Tooltip2
            object={
              <SpecialtyIconContainer>
                <GlutenFreeIcon />
              </SpecialtyIconContainer>
            }
            tooltip={
              <span><strong>Gluten Free: </strong> This product is gluten-free, which means it has been tested to be free of gluten, i.e. less than 20 ppm.</span>
            }
          />
        }
        {props.kosher && !props.starK &&
          <Tooltip2
            object={
              <SpecialtyIconContainer>
                <KosherIcon />
              </SpecialtyIconContainer>
            }
            tooltip={
              <span><strong>Kosher: </strong>This product is kosher, meaning it meets Jewish guidelines for consumption & preparation&#x02014;look for the specific certifying agency on the product label.</span>
            }
          />
        }
        {props.starK &&
          <Tooltip2
            object={
              <SpecialtyIconContainer>
                <StarKosherIcon />
              </SpecialtyIconContainer>
            }
            tooltip={
              <span><strong>Star-K Kosher:</strong> This product is Star-K Kosher certified, meaning it meets Jewish guidelines for consumption and preparation as defined by the Star-K Kosher organization.</span>
            }
          />}
        {props.crueltyFree &&
          <Tooltip2
            object={
              <SpecialtyIconContainer>
                <CrueltyFreeIcon />
              </SpecialtyIconContainer>
            }
            tooltip={
              <span><strong>Cruelty Free:</strong> This product complies with PETA or Leaping Bunny standards, providing assurance that the product was developed without animal testing.</span>
            }
          />
        }
        {props.organic &&
          <Tooltip2
            object={
              <SpecialtyIconContainer>
                <OrganicIcon />
              </SpecialtyIconContainer>
            }
            tooltip={
              <span><strong>Organic: </strong>This product is USDA Certified Organic, which means it contains at least 95% organic ingredients & meets strict USDA guidelines.</span>
            }
          />
        }
        {props.projectVerified
          ? <Tooltip2
            object={
              <SpecialtyIconContainer>
                <NonGmoProjectIcon />
              </SpecialtyIconContainer>
            }
            tooltip={
              <span className='pdp-badge-popup'><strong>Non-GMO Project Verified: </strong>This product meets the rigorous guidelines for GMO avoidance set by the Non-GMO Project, the only independent certifier of non-GMO status in North America.</span>
            }
          />
          : (
            props.nonGmo
              ? <Tooltip2
                object={
                  <SpecialtyIconContainer>
                    <NonGmoIcon />
                  </SpecialtyIconContainer>
                }
                tooltip={
                  <span className='pdp-badge-popup'><strong>Non-GMO: </strong>This product was made without the use of ingredients that contain genetically modified organisms (GMOs).</span>
                }
              />
              : ''
          )}
        <Tooltip2
          object={
            <SpecialtyIconContainer>
              <MoneyBackGuarantee />
            </SpecialtyIconContainer>
          }
          tooltip={
            <span>Not satisfied? Contact us within 100 days of purchase for a refund of the purchase price. Shipping costs not included.</span>
          }
        />
      </InnerContainer>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 40rem;
  ${props => props.styles};
  @media(max-width: ${props => props.theme.breakpoints.md}){
    width: 100%;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
  ${props => props.styles};
`

const SpecialtyIconContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 8rem;
  color: ${props => props.theme.primary};
  margin: .4rem .6rem;
  line-height: 1.1rem;
  text-align: center;
`

const SealContainer = styled.span`
  display: block;
  width: 5rem;
  height: 5rem;
`
const HighLightWord = styled.span`
  color: green;
`
