import React from 'react'
import styled, { css } from 'styled-components'
import { LinkThatLooksLikeTransparentButton } from '../_styled/Link'
import { TransparentButton } from '../_inputs/Button/Button'
import { createLogger, format, transports } from 'winston'

export class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showMessage: props.showErrorMessage,
      hasError: false
    }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    const serviceName = window.location.hostname.indexOf('www.') > -1 ? 'prod-ws' : (window.location.hostname.indexOf('pre-prod.') > -1 ? 'pre-prod-ws' : (window.location.hostname.split('.swansonvitamins')[0]))

    const httpTransportOptions = {
      host: 'http-intake.logs.datadoghq.com',
      path: `/api/v2/logs?dd-api-key=pubac3655a4ce6335ef89e54e67e136f8f4&ddsource=nodejs&service=${serviceName}`,
      ssl: true
    }

    const logger = createLogger({
      level: 'error',
      exitOnError: false,
      format: format.json(),
      transports: [
        new transports.Http(httpTransportOptions),
      ],
    })

    logger.error(`React error occurred: ${error.message}`,
      {
        errorData: error,
        errorInfo: errorInfo,
        url: window.location.href
      }
    )
  }

  render () {
    if (this.state.hasError) {
      const clickHandler = () => {
        window.location.reload()
      }
      return (
        <>
          {this.state.showMessage &&
            <Container>
              <H1>Connection Error</H1>
              <SomethingWentWrongDiv styles={css`max-width: 428px`}>Something happened while loading this page. Please refresh the page.</SomethingWentWrongDiv>
              <RefreshBtn aria-label='Refresh Page' onClick={clickHandler}>Refresh Page</RefreshBtn>
              <SomethingWentWrongDiv styles={css`max-width: 475px`}>If this problem persists, please contact us for support.</SomethingWentWrongDiv>
              <ContactUsA title='Contact Us' href='/help/company-information/company-information-faqs/contact-us.html'>Contact Us</ContactUsA>
            </Container>
          }
        </>
      )
    }

    return this.props.children
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 30rem;
  width: 100%;
  margin-top: 6.4rem;
  padding-top: 10.4rem;
`

const H1 = styled.h1`
  display: flex;
  font-size: 3.6rem;
  margin-bottom: 1.6rem;
`

const SomethingWentWrongDiv = styled.div`
  display: flex;
  font-size: 2.4rem;
  ${props => props.styles};
`

const ContactUsA = styled(LinkThatLooksLikeTransparentButton)`
  border-radius: 0.8rem;
  height: 4.7rem;
  width: 24.9rem;
  padding: 0.8rem 1.6rem;
  font-size: 2.4rem;
  color: ${props => props.theme.black};
  border: 1px solid #D9D9D9;
  margin: 1.6rem 0 3.2rem 0;
`

const RefreshBtn = styled(TransparentButton)`
  border-radius: 0.8rem;
  height: 4.7rem;
  width: 24.9rem;
  padding: 0.8rem 1.6rem;
  font-size: 2.4rem;
  color: ${props => props.theme.black};
  border: 1px solid #D9D9D9;
  margin: 1.6rem 0 10.4rem 0;
`