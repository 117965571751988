import React from 'react'
import styled, {css} from 'styled-components'

export const Notification = ({background, show, svg, notificationText, buttonText, buttonURL}) => {
  return (
    <NotificationDiv show={show} background={background} className='Notification'>
      {svg}
      <NotificationTextDiv>{notificationText}</NotificationTextDiv>
      {buttonText &&
      <A href={buttonURL}>{buttonText}</A>
      }
    </NotificationDiv>
  )
}
const A = styled.a`
  color: ${props => props.theme.white}
`

const NotificationTextDiv = styled.div`
  margin-right: 1rem;
`

const NotificationDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.white};
  font-size: 1.8rem;
  height: 4rem;
  background-color: ${props => props.theme.primary};
  position: fixed;
  bottom: -4rem;
  left: 0;
  z-index: 21474836400;
  transition: bottom .75s
  
  ${(props) => props.background === 'success' && css`
    background-color: ${props => props.theme.primary};
  `}
  ${(props) => props.background === 'warn' && css`
    background-color: ${props => props.theme.darkRed};
`}
  ${(props) => props.background === 'info' && css`
    background-color: ${props => props.theme.darkBlue};
  `}
  ${(props) => props.show && css`
    bottom: 0;
  `}
`
