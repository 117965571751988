import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const InstagramIcon = props => {
  return (
    <Svg x='0px' y='0px' viewBox='0 0 288 288' className={`${props.classes}`} >
      <title>Instagram Icon</title>
      <Path d='M143,4.3C66.1,4.3,3.8,66.6,3.8,143.5S66.1,282.8,143,282.8s139.3-62.3,139.3-139.3S219.9,4.3,143,4.3z M234,210.9c0,12.7-10.4,23.1-23.1,23.1H77.1C64.4,234,54,223.6,54,210.9V77.1C54,64.4,64.4,54,77.1,54h133.8 c12.7,0,23.1,10.4,23.1,23.1V210.9z' />
      <Path d='M144.2,178.6c20,0,36.2-15.7,36.2-35c-0.1-19.3-16.3-35-36.2-35c-20,0-36.1,15.7-36.1,35 C108.1,162.9,124.3,178.6,144.2,178.6z' />
      <Path d='M185.1,109.6h20.3c4.5,0,8.1-3.6,8.1-8.1V82.1c0-4.5-3.6-8.1-8.1-8.1h-20.3c-4.5,0-8.1,3.7-8.1,8.1v19.4 C177,106,180.6,109.6,185.1,109.6z' />
      <Path d='M200.1,145.6c0,29.9-25.1,54.2-56,54.2c-30.9,0-55.9-24.3-55.9-54.2c0-5.4,0.8-10.5,2.3-15.5H74v76 c0,3.9,3.2,7.1,7.1,7.1h125.3c3.9,0,7.1-3.2,7.1-7.1v-76h-15.7C199.3,135,200.1,140.2,200.1,145.6z' />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
 &.footer-icon{
  width: 45px;
  margin: 1rem 1.2rem;
  height: 45px;
  @media(min-width: 768px) and (max-width: 990px){
   width: 25px;
   height: 25px;
   margin: 0.5rem
  }
  @media (min-width: 991px) and (max-width: 1365px ){
   width: 40px;
   height: 40px;
   margin: 0.5rem;
  }
 }
`
const Path = styled.path`
 fill: #2F711C;
`
