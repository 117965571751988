import React from 'react'
import styled from 'styled-components'
import { HeaderSearchDropdownRecords } from '../HeaderSearchDropdownRecords/HeaderSearchDropdownRecords'
import useAmplitude from '~/hooks/useAmplitude'

export const HeaderSearchDropdownItem = ({
  term,
  index,
  itemStyles,
  searchTerms,
  searchRecordsStyles
}) => {
  const {
    trackEvent: trackAmplitudeEvent,
    mapAmplitudeProduct
  } = useAmplitude()

  const handleClick = () => {
    trackAmplitudeEvent('search results clicked', {
      ProductDetails: [mapAmplitudeProduct(term)],
      Rank: index + 1,
      SearchResultLocation: 'Autocomplete'
    })
  }

  return (
    <Li
      data-testid="headerSearchItem"
      key={term.productPartNumber}
      styles={itemStyles}
      className={searchTerms && searchTerms.length > 4 ? 'oneItem' : 'twoItem'}
      data-cnstrc-item-id={term.productPartNumber}
      data-cnstrc-item-name={term.productName}
      onClick={() => handleClick()}
    >
      <HeaderSearchDropdownRecords
        term={term}
        searchRecordsStyles={searchRecordsStyles}
        index={index}
      />
    </Li>
  )
}

const Li = styled.li`
  width: 100%;
  margin-bottom: 1.6rem;
`
