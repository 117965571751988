import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const CrueltyFreeIcon = ({
  fillColor= '#23350d',
  height = '4.1rem',
  width = '100%'
}) => (
  <Svg 
    xmlns='http://www.w3.org/2000/svg'
    id='specialty-flag_cruelyfree' 
    data-name='specialty-flag cruelyfree' 
    viewBox='0 0 207.57 198.63'
    height={height}
    width={width}
  >
    <title>Cruelty Free</title>

    <defs>
      <clipPath id='clippath'>
        <Circle cx='104.23' cy='81.01' r='78.01'/>
      </clipPath>
    </defs>
    <Path fillColor={fillColor} d='M104.22,3c43.08,0,78.01,34.93,78.01,78.01s-34.93,78.01-78.01,78.01S26.21,124.09,26.21,81.01,61.13,3,104.22,3M104.22,0C59.55,0,23.21,36.34,23.21,81.01s36.34,81.01,81.01,81.01,81.01-36.34,81.01-81.01S148.89,0,104.22,0h0Z'/>
    <G>
      <Path fillColor={fillColor} d='M101.59,144.73c-6.03,1.48-11.83,2.9-17.63,4.31-.01.19-.02.38-.04.56.86.25,1.82.33,2.57.77,2.19,1.3,4.56,2.48,6.36,4.22,1.82,1.76.63,4.31-1.95,4.33-11.85.1-23.7.1-35.55-.06-1.06-.01-2.34-1.27-3.12-2.26-2.32-2.98-4.42-6.12-6.65-9.17-.28-.39-.78-.79-1.22-.86-6.66-.98-10.82-12.12-9.24-18.26.39-1.5,1.33-3.16,2.53-4.05,1.39-1.03,1.71-2.08,1.89-3.63,1.9-16.44,10.32-28.47,24.91-36.15,8.73-4.59,18.22-5.53,27.89-5.29,4.81.12,5.85-.95,6.22-5.75.2-2.55.87-5.06,1.39-7.99-1.15-.71-2.78-1.63-4.32-2.67-10.61-7.13-16.56-17.15-18.34-29.73-.1-.68-.03-1.38-.08-2.07-.18-2.33.96-3.1,3.16-2.82,6.93.85,13.22,3.35,18.83,7.47,6.12,4.49,10.78,10.18,13.75,17.21.22.52.5,1.01.89,1.41-2.68-11.4-9.25-19.97-19.39-26.13.53-1.67.96-3.33,1.59-4.92.96-2.45,2.19-2.84,4.38-1.4,9.88,6.53,16.02,15.63,18.12,27.27.7,3.88.63,7.89.93,12.02,2.97,1.1,6.31,2.2,9.54,3.57,7.23,3.06,12.63,8.07,15.48,15.5,1.25,3.26,2.25,6.63,3.23,9.99.81,2.81.04,5.39-1.98,7.41-3.77,3.76-8.23,6.33-13.62,6.86-2.41.24-3.4,1.3-3.69,3.73-1.09,9.17-4.33,17.75-7.43,26.38-1.75,4.87-3.04,9.89-4.52,14.85-.43,1.44-.26,2.62,1.19,3.56.73.48,1.24,1.44,1.63,2.28,1.25,2.67.44,3.82-2.5,3.76-3.03-.06-6.06-.11-9.08-.14-5.04-.06-5.48-.5-5.55-5.46-.04-2.68-.15-5.36-.26-8.04,0-.23-.22-.44-.32-.65ZM126.42,75.4c-2.12-.11-4.43,1.72-4.62,3.66-.18,1.89,1.99,4.06,4.16,4.17,2.62.13,4.59-1.37,4.79-3.67.19-2.16-1.75-4.03-4.33-4.17Z'/>
    </G>
    <g>
      <Path fillColor={fillColor} d='M31.61,190.1c-1.16.61-2.7,1.13-4.79,1.13-3.39,0-5.45-1.16-7.08-2.7-2.23-2.15-3.1-4.55-3.1-7.4,0-3.51,1.45-6.03,3.1-7.57,1.94-1.83,4.35-2.55,7.11-2.55,1.36,0,2.99.23,4.76,1.19v4.41c-1.77-2.03-3.97-2.18-4.67-2.18-3.89,0-6.41,3.19-6.41,6.76,0,4.29,3.31,6.61,6.56,6.61,1.8,0,3.39-.78,4.52-2.12v4.41Z'/>
      <Path fillColor={fillColor} d='M40.68,171.45c2.67,0,4.03.7,4.93,1.45,1.48,1.25,1.92,2.9,1.92,4.35,0,1.88-.75,3.54-2.26,4.55-.49.35-1.19.67-2.12.84l6.03,8.15h-4.7l-5.16-7.77h-.46v7.77h-3.77v-19.35h5.6ZM38.86,180.36h1.07c.72,0,3.71-.09,3.71-2.93s-2.96-2.9-3.66-2.9h-1.13v5.83Z'/>
      <Path fillColor={fillColor} d='M55.5,171.45v12.15c0,1.48.49,2.38.93,2.87.55.67,1.57,1.33,3.08,1.33s2.52-.67,3.07-1.33c.44-.49.93-1.39.93-2.87v-12.15h3.77v11.83c0,1.83-.29,3.68-1.45,5.16-1.39,1.83-3.83,2.79-6.32,2.79s-4.93-.96-6.32-2.79c-1.16-1.48-1.45-3.34-1.45-5.16v-11.83h3.77Z'/>
      <Path fillColor={fillColor} d='M82.42,174.7h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M89.84,171.45v16.1h5.98v3.25h-9.75v-19.35h3.77Z'/>
      <Path fillColor={fillColor} d='M103.99,174.7v16.1h-3.77v-16.1h-4.35v-3.25h12.47v3.25h-4.35Z'/>
      <Path fillColor={fillColor} d='M116,181.95l-6.64-10.5h4.47l4.15,6.73,4.15-6.73h4.47l-6.82,10.5v8.85h-3.77v-8.85Z'/>
      <Path fillColor={fillColor} d='M145.75,174.7h-6.5v4.32h6.09v3.25h-6.09v8.53h-3.77v-19.35h10.27v3.25Z'/>
      <Path fillColor={fillColor} d='M154.59,171.45c2.67,0,4.03.7,4.93,1.45,1.48,1.25,1.91,2.9,1.91,4.35,0,1.88-.75,3.54-2.26,4.55-.49.35-1.19.67-2.12.84l6.03,8.15h-4.7l-5.16-7.77h-.46v7.77h-3.77v-19.35h5.6ZM152.77,180.36h1.07c.72,0,3.71-.09,3.71-2.93s-2.96-2.9-3.66-2.9h-1.13v5.83Z'/>
      <Path fillColor={fillColor} d='M176.37,174.7h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M190.76,174.7h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
    </g>
  </Svg>
)

const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const G = styled.g`
  clip-path: url(#clippath);
`

const Circle = styled.circle`
  fill: none;
  stroke-width: 0;
`

const Path = styled.path`
  stroke-width: 0;
  fill: ${props => props.fillColor};
`