import { CART_ADD, CART_INFO_SAVE } from '../constants/cartConstants'
import { HEADER_CART_COUNT, HEADER_CART_DETAILS } from '../constants/headerConstants'
import { showNotificationWithTimeout } from '../actions/notificationActions'
import axios from 'axios'
import { sharedSetInstanceAction } from './sharedActions'
import { SHARED_AGE_VERIFIED } from '../constants/sharedConstants'

export function cartAction (product, quantity = 1, additionalCode, displayATCBanner = true, ogChecked = false, extraData = {}) {
  sessionStorage.setItem('cart', `${sessionStorage.getItem('cart')},${product?.productPartNumber}`)

  const cleanedExtraDataParams = Object.entries(extraData).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})

  const extraDataUrlParams = new URLSearchParams(cleanedExtraDataParams)

  return (dispatch) => {
    dispatch({
      type: CART_ADD,
      itemNumber: product?.productPartNumber
    })
    axios.get(`${window.location.origin}/cart.add.ajax.do?id=${product?.productPartNumber}&qty=${quantity}&og=${ogChecked}&${extraDataUrlParams.toString()}`)
      .then((response) => {
        dispatch({ type: CART_INFO_SAVE, data: response.data })
        if (additionalCode) {
          additionalCode()
        }

        if (displayATCBanner) {
          dispatch(showNotificationWithTimeout({
            time: 5000,
            background: 'success',
            notificationText: 'Item added to cart',
            buttonText: 'go to cart',
            buttonURL: `${window.location.origin}/ShoppingBag`,
            active: true
          }))
        }

        if (document.querySelector('.cart-menu')) {
          axios.get(`${window.location.origin}/def/header.cart.count.html`)
            .then((response) => {
              if (process.env.NODE_ENV !== 'development') {
                const cart = document.querySelector('.cart-menu')
                cart.querySelector('.cart__count').remove()
                cart.insertAdjacentHTML('afterBegin', response.data)
              }
            })
        }

        axios.get(`${window.location.origin}/cart?view=json`)
          .then((response) => {
            dispatch({ type: HEADER_CART_DETAILS, payload: response.data })
            const items = response?.data?.sortedCartItems?.map(product => {
              return ({
                "SKU": product?.adobeRecord?.productPartNumber,
                "ProductName": product?.adobeRecord.productName,
                "Quantity": product?.quantity,
                "ItemPrice": parseFloat(product?.adobeRecord?.productDiscountPrice),
                "RowTotal": parseFloat(product?.adobeRecord?.productDiscountPrice),
                "ProductURL": `https://www.swansonvitamins.com/${product?.adobeRecord?.longURL}`,
                "ImageURL": `https://www.swansonvitamins.com/${product?.adobeRecord?.productPartNumber}.jpg`,
                "ProductCategories": product?.adobeRecord?.itemCategoriesDTO.breadCrumbsDTOList[0].breadCrumbsList?.flatMap(breadcrumb => breadcrumb?.name)
              })
            })
            window.klaviyo.push(["track", "Added to Cart", {
              "$value": response?.data?.totalRoundedCost,
              "AddedItemProductName": product?.productName,
              "AddedItemSKU": product?.productPartNumber,
              "AddedItemCategories": product?.itemCategoriesDTO?.breadCrumbsDTOList[0]?.breadCrumbsList?.flatMap(breadcrumb => breadcrumb?.name),
              "AddedItemImageURL": `https://www.swansonvitamins.com/${product?.productPartNumber}.jpg`,
              "AddedItemPrice": parseFloat(product?.productDiscountPrice),
              "AddedItemQuantity": quantity,
              "ItemNames": response?.data?.sortedCartItems?.flatMap(product => product?.adobeRecord?.productName),
              "CheckoutURL": "https://www.swansonvitamins.com/order",
              "Items": items
            }])
          })

        axios.get(`${window.location.origin}/userinfo.json`)
          .then((response) => {
            dispatch({ type: HEADER_CART_COUNT, payload: response.data.cartCount })
          })
      })
      .catch(() => {
        dispatch(showNotificationWithTimeout({
          time: 5000,
          background: 'warn',
          notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
          active: true
        }))
      })
  }
}

/* created a redux action for ATCModal Pop up */
export function openCartDialogAction (product, quantity, updateCart) {
  return (dispatch) => {
    dispatch(sharedSetInstanceAction({
      name: 'atcModal',
      data: {
        product: product,
        quantity: quantity,
        updateCart
      }
    }))
    if (window.OG && window.OG.updateCart) {
      window.OG.updateCart([
        {
          id: product.productPartNumber,
          module: 'pdp',
          quantity: quantity
        }
      ])
    }
  }
}

export const ageVerifiedAction = (ageVerifiedState) => {
  return (dispatch) => {
    dispatch({
      type: SHARED_AGE_VERIFIED,
      payload: ageVerifiedState
    })
  }
}
