import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const InfoIcon = ({
  classes,
  styles,
  width = '50px',
  height,
  color = '#00833d'
}) => {
  return (
    <Svg id='iCircle'
      x='0px'
      y='0px'
      width={width}
      height={height}
      styles={styles}
      className={classes}
      viewBox='0 0 144.3 144.3'
    >
      <title>Information Icon</title>
      <PrimaryStrokeSvgs stroke={color} cx='72.2' cy='72.2' r='67.2' />
      <PrimaryFilledSvgs fill={color} cx='72.9' cy='32.5' r='13.5' />
      <PrimaryFilledSvgs fill={color} as='rect' x='61.6' y='55.2' width='22.4' height='72.3' />
    </Svg>

  )
}

const Svg = styled(SvgAttr)`
  ${props => props.styles};
`

const PrimaryFilledSvgs = styled.circle`
  fill: ${props => props.fill};
`
const PrimaryStrokeSvgs = styled.circle`
  stroke: ${props => props.stroke};
  fill: none;
  stroke-width: 10;
`