import { ERROR_REMOVE, ERROR_SET } from '../constants/errorConstants'

export const hideErrorsAction = () => (
  {
    type: ERROR_REMOVE
  }
)

export const showErrorsAction = (errors) => (
  {
    type: ERROR_SET, payload: errors
  }
)
