import styled from 'styled-components'

export const DiagonalCrossedOutText = styled.span`
  display: inline-block;
  position: relative;
  &::before {
    border-bottom: 0.1rem solid ${props => props.theme.black};
    position: absolute;
    content: "";
    width: 100%;
    height: 65%;
    transform: rotate(-10deg);
    margin-top: -0.3rem;
  }
`