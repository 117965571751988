import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import {
  cacheExchange,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  Provider as UProvider
} from 'urql'
import { theme } from '../../theme'
import { GlobalStyle } from '../../GlobalStyle'
import csrfExchange from '../../utils/interceptors/urql-csrf-exchange'
import '../../utils/amplitude/init'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'

export const AppContainer = ({
  store,
  children,
  mockClient,
  showErrorBoundary = true
}) => {
  const client = mockClient ? mockClient : createClient({
    url: '/graphql',
    exchanges: [
      dedupExchange,
      cacheExchange,
      errorExchange({
        onError(error) {
          console.error(error)
        },
      }),
      csrfExchange,
      fetchExchange
    ]
  })

  return (
    <UProvider value={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <Provider store={store}>
          <ErrorBoundary showErrorMessage={showErrorBoundary}>
              {children}
            </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </UProvider>
  )
}
