import React from 'react'
import { useSelector } from 'react-redux'
import { Field } from '../../_inputs/Field/Field'
import { CTAButton } from '../../_inputs/Button/Button'
import styled, {css} from 'styled-components'
import { sharedSMSSuccessAction } from '../../../actions/sharedActions'
import { VisuallyHiddenStyles } from '../../_styled/BootStrapGrid'
import { StandardLink } from '../../_styled/Link'

export const SMSSignUpForm = (props) => {
  const formData = {
    form: 'signUpSMS',
    dataType: 'json',
    method: 'post',
    action: '/ajax/smscreation.do',
    successAction: sharedSMSSuccessAction
  }
  const selector = useSelector(state => state.shared.sharedCheckSMS)
  const getState = fieldObj => {
    fieldObj.form = formData.form
    props.updateForm(fieldObj)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    props.updateForm(
      {
        form: formData.form,
        name: 'visitorId',
        value: document.cookie.replace(/(?:(?:^|.*;\s*)__attentive_id\s*=\s*([^;]*).*$)|^.*$/, '$1')
      }
    )
    props.submitForm(formData)
  }
  if (selector) {
    return (
      <SMSContainer className={props.dataLocation}>
        <SMSResponseHeader>Thank you for signing up for SMS!</SMSResponseHeader>
        <SMSSmallHeaderCheck>Check your text </SMSSmallHeaderCheck>
        <SMSSmallTextConfirm>Reply 'Y' to confirm your subscription</SMSSmallTextConfirm>
      </SMSContainer>
    )
  }
  return (
    <SMSContainer className={`${props.classes} cTest_sms-popup`}>
      <SMSHeader>LOOKING FOR MORE WAYS TO SAVE?</SMSHeader>
      <SMSSmallHeader>Get our best deals straight to your phone!</SMSSmallHeader>

      <Form onSubmit={submitHandler} className={`${props.formClass} ${props.formLocation}`}>
        <SMSField>
          <Field
            focused
            inputType='number'
            labelText='Phone Number'
            name='phone'
            id='smsnumber'
            returnState={getState}
            error={props.errorStates}
            addRef={props.addToRefsArray}
            labelStyles={css`${VisuallyHiddenStyles};`}
            placeholder='Enter your phone number'
          />
        </SMSField>
        <SMSCTAButton>
          <CTAButton type='submit'>Sign Me Up!</CTAButton>
        </SMSCTAButton>
      </Form>
      <SMSSmallText>By signing up via text, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from Swanson at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View <StandardLink href='http://attn.tv/swanson/terms.html' rel='noreferrer noopener' target='_blank'>Terms* & Privacy</StandardLink>.</SMSSmallText>

    </SMSContainer>
  )
}

const SMSContainer = styled.div`
  margin: 0;
  width: 100%;
  min-height: 40rem;
  display: flex;
  color: ${props => window.isCyberMondayWindow ? props.theme.white : props.theme.black};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 992px){
    justify-content: flex-start;
  }
`
const SMSHeader = styled.p`
  text-transform: uppercase;
  margin: 2rem 0 0 0;
  font-size: 5rem;
  color: ${props => window.isCyberMondayWindow ? '#a9ffd1' : props.theme.primary};
  line-height: 6.1rem;
  text-align: center;
  width: 100%;
  @media (max-width: 766px){
    margin: 2rem 0 0 0;  
    font-size: 3.5rem;
    line-height: 4.1rem;
}`
const SMSResponseHeader = styled.p`
  text-transform: uppercase;
  margin: 6rem 0 2rem 0;
  font-size: 5rem;
  color: ${props => window.isCyberMondayWindow ? '#a9ffd1' : props.theme.primary};
  line-height: 6.1rem;
  text-align: center;
  width: 100%;
  @media (max-width: 766px){
    margin: 2rem 0 0 0;  
    font-size: 3.5rem;
    line-height: 4.1rem;
  }
 `
const SMSSmallHeader = styled.p`
  font-size: 2rem;
  margin: 1rem 0;
  line-height: 2.8rem;
  text-align: center;
`
const SMSSmallText = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  color: ${props => window.isCyberMondayWindow ? props.theme.white : props.theme.black};
  line-height: 1.6rem;
  text-align: center;
  width: 100%;
`
const Form = styled.form`
  width: 100%;
  flex-direction: row; 
  display: flex;
  margin: 0 auto;
  align-items: flex-end;
  justify-content: center;
`
const SMSField = styled.div`
  width: 65%;
  input[type=number] {-moz-appearance: textfield;}
`
const SMSCTAButton = styled.div` 
  width: fit-content;
`
const SMSSmallHeaderCheck = styled(SMSSmallHeader)`
  font-size: 3rem;
`
const SMSSmallTextConfirm = styled(SMSSmallText)`
  font-size: 2.2rem;
  @media (max-width: 766px) {
    line-height: 2.2rem;
  }
`
