import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const Headset = (props) => {
  return (
    <Svg
     styles={props.styles}
     className={`${props.classes}`}
     x='0px'
     y='0px'
     width={`${props.width}px`}
     height={`${props.height}px`}
     viewBox='0 0 144 144'
    >
      <title>Headset</title>
      <path fill={props.color} d='M118.07,46.44C111.76,27.31,93.75,13.5,72.5,13.5S33.24,27.31,26.93,46.44C16.78,49.91,9.5,59.37,9.5,70.5
        c0,13.92,11.37,25.22,25.5,25.49v-46.4C40.64,33.78,55.29,22.5,72.5,22.5s31.86,11.28,37.5,27.09v41.84
        c-0.42,5.09-2.05,10.29-4.99,15c-4.17,6.67-17.14,11.04-23.34,12.67c-1.05-3.81-4.53-6.6-8.67-6.6c-4.97,0-9,4.03-9,9
        c0,4.97,4.03,9,9,9c2.98,0,5.61-1.45,7.24-3.68c8.62-1.6,24.07-7.35,29.84-16.58c2.97-4.75,4.89-9.89,5.83-15.05
        c11.25-2.81,19.58-12.79,19.58-24.7C135.5,59.37,128.22,49.91,118.07,46.44z'
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  ${props => props.styles};
`
