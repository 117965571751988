import React, {useRef} from 'react'
import styled, {css} from 'styled-components'
import { Field } from '../../_inputs/Field/Field'
import { Button } from '../../_inputs/Button/Button'
import { useSelector } from 'react-redux'
import { sharedEmailSuccessAction } from '../../../actions/sharedActions'
import Reaptcha from 'reaptcha'

export const CyberMondaySignUpForm = (props) => {
  const reCaptchaRef = useRef()
  const formData = {
    form: 'signUp',
    method: 'post',
    action: '/emailsignup.json',
    successAction: sharedEmailSuccessAction
  }

  const getState = fieldObj => {
    fieldObj.form = formData.form
    props.updateForm(fieldObj)
  }
  const captchaOnVerify = recaptchaResponse => {
    props.updateForm({
      form: formData.form,
      name: 'g-recaptcha-response',
      value: recaptchaResponse
    })
    props.updateForm({
      form: formData.form,
      name: 'emailConfirm',
      value: '' // this must match email
    })
    props.updateForm({
      form: formData.form,
      name: 'offer',
      value: '9'
    })
    props.updateForm({
      form: formData.form,
      name: '_offer',
      value: 'on'
    })
    props.updateForm({
      form: formData.form,
      name: 'recipe',
      value: 'y'
    })
    props.updateForm({
      form: formData.form,
      name: '_recipe',
      value: 'on'
    })
    props.updateForm({
      form: formData.form,
      name: 'eNewsletter',
      value: 'y'
    })
    props.updateForm({
      form: formData.form,
      name: '_eNewsletter',
      value: 'on'
    })
    props.updateForm({
      form: formData.form,
      name: 'updates',
      value: 'y'
    })
    props.updateForm({
      form: formData.form,
      name: '_updates',
      value: 'on'
    })
    props.updateForm({
      form: formData.form,
      name: 'submit',
      value: 'subscribe'
    })
    document.body.style.overflow = 'auto'
    props.submitForm(formData)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    reCaptchaRef.current.execute()
  }

  const checkEmail = useSelector(state => state.shared.sharedCheckEmail)

  return (
    <SignUpFormMain className={`${props.classes} ${!checkEmail ? 'email-signup-popup' : ''}`}>
      <SignUpFormContent className={props.classes}>
        <SignUpHeaderFooter>
          {!checkEmail
            ? <>
              <SignUpFormHeader className={props.classes}>
                <SignUpContent>
                  <Shipping>Be the First to Know</Shipping>
                  <BestDeals>Sign up for emails & get our best deals sent straight to your inbox</BestDeals>
                </SignUpContent>
              </SignUpFormHeader>
              <FormStyled onSubmit={submitHandler} className={`${props.classes} ${props.formClass} ${props.formLocation}`}>
                <Reaptcha
                  ref={reCaptchaRef}
                  sitekey={window.reCaptchaKey}
                  onVerify={captchaOnVerify}
                  size='invisible'
                />
                <Fieldset>
                  <legend className='visually-hidden'>Email Details</legend>
                    {props.errorStates?.form !== null &&
                    <div aria-live='assertive' role='alert' className='text-red'>{props.errorStates?.form}</div>
                    }
                    <Field
                      name='email'
                      fieldStyles={css`
                        text-align: center;
                        border: .1rem solid #a9ffd1;
                      `}
                      classes='SignUpForm__Field'
                      id={`signup-${props.ariaLocation}`}
                      placeholder='Enter your email address'
                      returnState={getState}
                      aria-label='enter email address'
                      error={props.errorStates}
                      addRef={props.addToRefsArray}
                      labelText='Enter your email address'
                      labelClass='visually-hidden'
                    />
                  <NavyBlueButton type='submit' aria-label='send email address'>SIGN UP FOR SAVINGS</NavyBlueButton>
                  <NoThanksBtn onClick={props.closeHandler}>NO, I'LL JUST PAY FULL PRICE</NoThanksBtn>
                </Fieldset>
              </FormStyled>

            </>
            : checkEmail === 'error'
              ? <SubmitResponse id='emailAlreadyReact' data-target={props.formLocation}>
                <ResponseHead><strong>Thank you!</strong> This email address is <strong>already</strong> signed up.</ResponseHead>
                <ResponseP>Thank you for your interest and be assured that you will continue to receive our emails at this address.</ResponseP>
              </SubmitResponse>
              : checkEmail === 'success'
                ? <SubmitResponse id='emailConfirmSuccessReact' data-target={props.formLocation}>
                  <ResponseHead>Your first offer is on the way!</ResponseHead>
                  <ResponseP>You're now signed up to receive exclusive deals and savings opportunities!</ResponseP>
                </SubmitResponse>
                : <SubmitResponse id='OopsErrorReact' data-target={props.formLocation}>
                  <ResponseHead><strong>Oops!</strong></ResponseHead>
                  <ResponseP>We are <strong>having trouble</strong> processing your request. Our apologies! Please <strong>try again</strong> later.</ResponseP>
                </SubmitResponse>
          }
        </SignUpHeaderFooter>
      </SignUpFormContent>
    </SignUpFormMain>

  )
}

const NavyBlueButton = styled(Button)`
  width: 100%;
  font-size: 1.8rem;
  margin: 1rem 0;
  background-color: #000d2f;
  border-color: #000d2f;
`

const Shipping = styled.div`
  font-size: 5rem;
  color: #a9ffd1;
  text-align: center;
  font-weight: bold;
  line-height: 6rem;
  @media (max-width: 766px){
    font-size: 3.55rem;
    line-height: 4.5rem;
  }
`

const BestDeals = styled.div`
  font-size: 2.3rem;
  margin-bottom: 4rem;
  line-height: 2.7rem;
  font-weight: normal;
  text-align: center;
  color: ${props => props.theme.white};
  @media (max-width: 766px){
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
`

const NoThanksBtn = styled(Button)`
  width: 100%;
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};
  border: none;
  font-size: 1.7rem;
`

const SubmitResponse = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  color: ${props => props.theme.darkGrey};
  text-align: center;
  font-size: 1.3rem;
  line-height: 2.5rem
`
const SignUpHeaderFooter = styled.div`
  padding: 1rem;
`
const SignUpContent = styled.div`
 line-height: 4rem;
`

const SignUpFormMain = styled.div`
  flex: 0 0 100%;
  margin: 0 auto;
  padding-top: 1rem;
  min-height:40rem;
  max-width: 65rem;
  @media(min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}){
    padding-bottom: 1rem;
    max-width: 35rem;
  }
`

const SignUpFormContent = styled.div`
   display: inline-block;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin: 0;
   width: 100%;
   @media(min-width: ${props => props.theme.breakpoints.sm}){
    flex-direction: row;
   }
   @media (min-width: ${props => props.theme.breakpoints.lg}){
    justify-content: flex-start;
    &.modalSignUp{
      justify-content: center;
    }
  }
`
const FormStyled = styled.form`
  width: 100%;
`

const Fieldset = styled.fieldset`
  width: 100%;
  margin-left: 1rem;
`
const SignUpFormHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: bold;
  margin-bottom: 0;
  @media(min-width: 992px){
  }
  @media(min-width: ${props => props.theme.breakpoints.md}) and (max-width: 1400px){
    font-size: 1.6rem;
  } 
  @media(max-width: ${props => props.theme.breakpoints.md}){
     font-size: 1.6rem;  width: 100%;
  }
  &.modalSignUp{
    display: none;
  }
`

const ResponseHead = styled.h3`
  margin: 6rem 0 2rem 0;
  color: #a9ffd1;
  font-size: 4rem;
  line-height: 4.5rem;
  @media (max-width: 766px){
    margin: 2rem 0 0 0;  
    font-size: 3.5rem;
    line-height: 4.1rem;
  }
`
const ResponseP = styled.p`
  color: ${props => props.theme.white};
  font-size: 1.8rem;
  line-height: 2.5rem;
`
