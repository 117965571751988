import { CategoryNavHighlightSpot } from '../CategoryNavHighlightSpot/CategoryNavHighlightSpot'
import { trackingStrip } from '../../../tealium'
import React from 'react'
import styled from 'styled-components'

export const HeaderCatFeaturedSpot = ({
  globalState = {},
  currentIndex = 0,
  additionalATCCode = () => {}
}) => {
  return (
    <>
      {globalState?.dropdownMap[globalState.categoryResultList[currentIndex].title] &&
        <>
          {globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].products !== null && globalState?.dropdownMap[globalState.categoryResultList[currentIndex].title].products.map((product) => (
            <CategoryNavHighlightSpot
              type='product'
              product={product.adobeRecord}
              additionalATCCode={additionalATCCode}
              category={globalState.categoryResultList[currentIndex].title}
            />))
          }
          {globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].blogs !== null && globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].blogs.length > 0 &&
            <SpotHeadline>Featured Article{globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].blogs !== null && globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].blogs.length > 1 ? 's' : ''}</SpotHeadline>
          }
          {globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].blogs !== null && globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].blogs.map((blog) => (
            <CategoryNavHighlightSpot
              type='blog'
              title={blog.title}
              src={blog.src}
              href={blog.href}
              category={globalState.categoryResultList[currentIndex].title}
              trackingData={`header|${trackingStrip(globalState.categoryResultList[currentIndex].title)}|featured-article|${trackingStrip(blog.title)}`}
            />))
          }
          {globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].productBanner &&
            <CategoryNavHighlightSpot
              type='productBanner'
              title={globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].productBanner.title}
              src={globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].productBanner.src}
              href={globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].productBanner.href}
              category={globalState.categoryResultList[currentIndex].title}
              altText={globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].productBanner.altText}
              trackingData={`header|${trackingStrip(globalState.categoryResultList[currentIndex].title)}|banner|${trackingStrip(globalState.dropdownMap[globalState.categoryResultList[currentIndex].title].productBanner.title)}`}
            />
          }
        </>
      }
    </>
  )
}

const SpotHeadline = styled.div`
  color: ${props => props.theme.primary} !important;
  margin: 0 0 1rem;
  text-align: center;
  font-size: 1.5rem;
  ${props => props.styles};
`