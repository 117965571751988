import React from 'react'
import styled from 'styled-components'
import { LoadAnimation } from '../LoadAnimation/LoadAnimation'

export const LoadAnimationWithOverlay = () => {
  return (
    <LoadAnimationContainer>
      <LoadAnimation />
    </LoadAnimationContainer>
  )
}

const LoadAnimationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,.8);
  width:      100%;
  height:     100%;
  z-index:    65665483648;
  top:        0;
  left:       0;
  position:   fixed;
`
