import React from 'react'
import styled from 'styled-components'

export const HeaderSearchDropdownRecords = ({
  term,
  index,
  searchRecordsStyles
}) => {
  return (
    <SearchRecords data-testid='searchRecords' data-item={term.productPartNumber} data-index={index} styles={searchRecordsStyles}>
      <ProductDetailsLink data-tracking={`header|search-dropdown|product-${term.productPartNumber}`} href={`/${term.longURL}`} alt={`${term.productName} - ${term.productPartNumber}`}>
        <ProductImage src={`https://media.swansonvitamins.com/images/items/150/${term.productPartNumber}.jpg`} alt={`${term.productName}`} />
        <ProductDetails>
          <ProductVendor>{term.productVendor}</ProductVendor>
          <ProductName>{term.productName}</ProductName>
          <ProductServing>{term.productDetails}</ProductServing>
        </ProductDetails>
      </ProductDetailsLink>
    </SearchRecords>
  )
}

export const SearchRecords = styled.div`
  width: 100%;
  font-weight: 700;
  ${props => props.styles};
`

export const ProductImage = styled.img`
  width: 80px;
  max-height: 80px
`

export const ProductDetails = styled.span`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  line-height: 1.5rem;
`

export const ProductDetailsLink = styled.a`
  text-decoration: none;
  display: flex;
`

export const ProductVendor = styled.div`
  font-size: 1.2rem;
  width: 100%;
  font-weight: 400;
  color: ${props => props.theme.black};
`

export const ProductName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${props => props.theme.primary};
  min-height: auto;
  margin-bottom: 0; 
  margin-block: 0.5em;
  line-height: 1.5;
  width: 100%;
`

export const ProductServing = styled.div`
  font-size: 1.2rem;
  width: 100%;
  min-height: 20px;
  font-weight: 400;
  color: ${props => props.theme.black};
`
