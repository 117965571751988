import { ROUTER_LOCATION_CHANGE } from '../constants/routerConstants'
import { APP_SORT } from '../constants/appConstants'
import { push } from '../actions/routerActions'
export function updateURLsAction (url) {
  return (dispatch, reduxStore) => {
    let location = document.createElement('a')
    location.href = url
    dispatch({ type: APP_SORT, payload: 'relevance' })
    dispatch({ type: ROUTER_LOCATION_CHANGE,
      payload: {
        pathname: location.pathname,
        search: location.search,
        hash: location.hash
      }
    })
    dispatch(push(url.replace(/ /g, '+')))
  }
}

export function clearURLsAction () {
  return (dispatch, reduxStore) => {
    let search = ''
    if (reduxStore().app.keyword !== null) {
      search = `?kw=${reduxStore().app.keyword}`
    }
    dispatch({ type: APP_SORT, payload: 'relevance' })
    dispatch({ type: ROUTER_LOCATION_CHANGE,
      payload: {
        pathname: '/q',
        search: search,
        hash: ''
      }
    })
    dispatch(push(`/q${search}`))
  }
}
