import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const Card = ({
  height = `2.7rem`,
  width = `2.7rem`,
  fill = '#2F711C',
  styles
}) => {
  return (
    <Svg x='0px' y='0px' viewBox='0 0 250 180' width={width} height={height} styles={styles}>
      <title>Credit Card Icon</title>
      <g>
        <Rect x='44.4' y='84.4' width='79.7' height='10.6' fill={fill}/>
        <Path
          d='M215,10.5H33.4c-12.7,0-23,10.4-23,23v11.7v24.9V146c0,12.7,10.4,23,23,23H215c12.7,0,23-10.4,23-23V70.1V45.3
            V33.5C238,20.8,227.6,10.5,215,10.5z M228.8,143c0,8.9-7.3,16.1-16.1,16.1H36.6c-8.9,0-16.1-7.3-16.1-16.1V70.1h208.3V143z
             M20.5,45.3v-8.8c0-8.9,7.3-16.1,16.1-16.1h176.1c8.9,0,16.1,7.3,16.1,16.1v8.8H20.5z'
          fill={fill}
        />
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  margin-right: 1rem;
  ${props => props.styles};
`

const Rect = styled.rect`
  fill: ${props => props.fill};
`

const Path = styled.path`
  fill: ${props => props.fill};
`
