import styled from 'styled-components'

export const BlackLink = styled.a`
  color: ${props => props.theme.black};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.darkOrange};
  }
`

export const StandardLink = styled.a`
  color: ${props => props.theme.primary};
  ${props => props.styles};
  &:visited {
    color: ${props => props.theme.primary};
  }
  &:hover {
    color: ${props => props.theme.darkOrange};
  }
`

export const LinkThatLooksLikeAButton = styled.a`
  height: 4rem;
  display: inline-flex;
  color: ${props => props.theme.darkestOrange};
  font-size: 1.4rem;
  font-family: inherit;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  font-weight: 400;
  padding: .2rem .8rem;
  touch-action: manipulation;
  cursor: pointer;
  border: .2rem solid;
  border-radius: .8rem;
  background-image: none;
  white-space: nowrap;
  transition: background-color .5s;
  text-decoration: none;
  @media(min-width: ${props => props.theme.breakpoints.sm}){
    min-width: ${props => props.minWidth || '12rem'};
  }
  ${props => props.styles};
`

export const LinkThatLookLikeCTAButton = styled(LinkThatLooksLikeAButton)`
  background-color: ${props => props.theme.white};
  border-color: ${props => props.theme.darkestOrange};
`

export const LinkThatLooksLikeTransparentButton = styled(LinkThatLooksLikeAButton)`
  background-color: transparent;
  border-color: ${props => props.theme.white};
`
