import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const NonGmoIcon = ({
  fillColor = '#23350d',
  height = '4.1rem',
  width = '100%'
}) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    id='specialty-flag_nongmo'
    data-name='specialty-flag nongmo'
    viewBox='0 0 162.02 198.63'
    height={height}
    width={width}
  >
    <title>Non-GMO</title>

    <Path fillColor={fillColor} d='M29.9,65.47c0-2.11.33-4,1.11-5.66s1.78-3,3-4.11,2.55-1.89,4.11-2.44c1.55-.56,3-.78,4.55-.78s3.11.22,4.55.78c1.55.56,2.89,1.33,4.11,2.44s2.22,2.44,3,4.11,1.11,3.55,1.11,5.66v2.66h-7.55v-2.66c0-1.89-.56-3.22-1.55-4.11-1-.89-2.22-1.33-3.66-1.33s-2.55.44-3.66,1.33c-1,.89-1.55,2.22-1.55,4.11v27.98c0,1.89.56,3.22,1.55,4.11,1,.89,2.22,1.33,3.66,1.33s2.55-.44,3.66-1.33c1-.89,1.55-2.22,1.55-4.11v-9.99h-6v-6.66h13.66v16.65c0,2.22-.33,4.11-1.11,5.66-.78,1.67-1.78,3-3,4-1.22,1.11-2.55,1.89-4.11,2.44-1.55.56-3,.78-4.55.78s-3.11-.22-4.55-.78c-1.55-.56-2.89-1.33-4.11-2.44s-2.22-2.44-3-4c-.78-1.67-1.11-3.55-1.11-5.66v-27.98h-.11Z'/>
    <Polygon fillColor={fillColor} points='62.09 53.03 69.42 53.03 79.19 81.12 79.3 81.12 88.96 53.03 96.29 53.03 96.29 106.1 88.74 106.1 88.74 73.79 88.63 73.79 81.08 96.55 77.3 96.55 69.87 73.79 69.75 73.79 69.75 106.1 62.2 106.1 62.2 53.03 62.09 53.03'/>
    <Path fillColor={fillColor} d='M127.49,59.8c-.78-1.67-1.78-3-3-4.11s-2.55-1.89-4.11-2.44c-1.44-.56-3-.78-4.55-.78s-3,.22-4.55.78c-1.55.56-2.89,1.33-4.11,2.44-1.22,1.11-2.22,2.44-3,4.11-.78,1.67-1.11,3.55-1.11,5.66v27.98c0,2.11.33,4,1.11,5.66.78,1.55,1.78,2.89,3,4,1.22,1.11,2.55,1.89,4.11,2.44,1.44.56,3,.78,4.55.78s3-.22,4.55-.78c1.55-.56,2.89-1.33,4.11-2.44,1.22-1,2.22-2.33,3-4,.78-1.55,1.11-3.44,1.11-5.66v-27.98c0-2.11-.33-4-1.11-5.66ZM121.16,93.55c0,1.89-.56,3.22-1.55,4.11-1.11.89-2.22,1.33-3.66,1.33s-2.66-.44-3.66-1.33c-1-.89-1.55-2.22-1.55-4.11v-28.09c0-1.89.56-3.22,1.55-4.11,1.11-.89,2.22-1.33,3.66-1.33s2.66.44,3.66,1.33c1,.89,1.55,2.22,1.55,4.11v28.09Z'/>
    <Path fillColor={fillColor} d='M81.01,3c43.08,0,78.01,34.93,78.01,78.01s-34.93,78.01-78.01,78.01S3,124.09,3,81.01,37.93,3,81.01,3M81.01,0C36.34,0,0,36.34,0,81.01s36.34,81.01,81.01,81.01,81.01-36.34,81.01-81.01S125.68,0,81.01,0h0Z'/>
    <Polygon fillColor={fillColor} points='55.23 110.66 30.97 142.78 39.07 148.88 63.33 116.86 55.23 110.66'/>
    <Polygon fillColor={fillColor} points='114.73 48.06 134.72 22.32 126.62 16.22 106.73 41.86 114.73 48.06'/>
    <g>
      <Path fillColor={fillColor} d='M8.11,190.8v-19.35h2.61l11.78,12.91v-12.91h3.77v19.35h-2.61l-11.78-12.94v12.94h-3.77Z'/>
      <Path fillColor={fillColor} d='M50.39,181.14c0,5.89-4.23,10.09-10.33,10.09s-10.33-4.21-10.33-10.09,4.23-10.12,10.33-10.12,10.33,4.24,10.33,10.12ZM46.51,181.14c0-3.92-2.67-6.7-6.44-6.7s-6.44,2.79-6.44,6.7,2.67,6.67,6.44,6.67,6.44-2.76,6.44-6.67Z'/>
      <Path fillColor={fillColor} d='M53.87,190.8v-19.35h2.61l11.78,12.91v-12.91h3.77v19.35h-2.61l-11.78-12.94v12.94h-3.77Z'/>
      <Path fillColor={fillColor} d='M75.68,179.51h7.54v3.25h-7.54v-3.25Z'/>
      <Path fillColor={fillColor} d='M96.67,180.44h8.88v.23c0,1.3-.17,5.37-2.84,8.01-2,2-4.47,2.55-6.87,2.55s-4.9-.49-7.02-2.61c-.67-.67-2.87-3.1-2.87-7.45,0-3.74,1.68-6.03,2.84-7.19,1.45-1.48,3.89-2.96,7.63-2.96,1.45,0,2.99.23,4.47,1.02,1.25.64,2.61,1.8,3.63,3.54l-3.28,1.77c-.67-1.31-1.65-1.97-2.26-2.29-.81-.41-1.83-.61-2.73-.61-1.54,0-3.28.55-4.47,1.71-1.1,1.07-1.94,2.93-1.94,5.11,0,2.58,1.1,4.12,1.92,4.9.87.84,2.23,1.65,4.29,1.65,1.28,0,2.73-.35,3.8-1.16.75-.58,1.51-1.57,1.83-2.96h-4.99v-3.25Z'/>
      <Path fillColor={fillColor} d='M111.64,171.45h2.73l5.13,12.3,5.37-12.3h2.73l3.25,19.35h-3.83l-1.74-11.92-5.19,11.92h-1.28l-4.96-11.92-1.97,11.92h-3.83l3.6-19.35Z'/>
      <Path fillColor={fillColor} d='M153.98,181.14c0,5.89-4.24,10.09-10.33,10.09s-10.33-4.21-10.33-10.09,4.24-10.12,10.33-10.12,10.33,4.24,10.33,10.12ZM150.09,181.14c0-3.92-2.67-6.7-6.44-6.7s-6.44,2.79-6.44,6.7,2.67,6.67,6.44,6.67,6.44-2.76,6.44-6.67Z'/>
    </g>
</Svg>
)
const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const Path = styled.path`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`

const Polygon = styled.polygon`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`