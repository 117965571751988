import React from 'react'
import styled from 'styled-components'
import { FeaturedProduct } from '../FeaturedProduct/FeaturedProduct'

export const CategoryNavHighlightSpot = ({
  type = 'product',
  title,
  href,
  src,
  product,
  category,
  altText,
  trackingData
}) => {
  return (
    <Container>
      {type === 'product' &&
        <SpotContainer data-testid='productSpot'>
          <FeaturedProduct category={category} product={product} />
        </SpotContainer>
      }
      {type === 'blog' &&
        <SpotContainer as='a' data-target={category} data-testid='blogSpot' href={href} className={category ? `cTest_header-FA-${category.replace(/[^A-Z]/g, '')}` : ''}>
          <div>{title}</div>
          <BlogImg alt={title} src={src} />
        </SpotContainer>
      }
      {type === 'productBanner' &&
        <SpotContainer as='a' data-tracking={trackingData} data-target={category} data-testid='productBannerSpot' href={href} title={title} className={category ? `cTest_header-banner-${category.replace(/[^A-Z]/g, '')}` : ''}>
          <ProductBannerImg alt={altText} src={src} />
        </SpotContainer>
      }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`

const SpotContainer = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.primary};
  &:hover {
    color: ${props => props.theme.darkOrange} !important;
  }
`
const BlogImg = styled.img`
  width: 15.5rem;
`
const ProductBannerImg = styled.img`
  width: 30rem;
`