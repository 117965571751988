import React, { useEffect, useRef } from 'react'
import { useUniqueId } from '../../../hooks/useUniqueId'
import styled, {css} from 'styled-components'

export const SelectBox = ({
  id,
  addRef,
  name,
  returnState,
  error,
  labelText,
  showLabel = true,
  orientation,
  labelClass,
  labelStyles,
  setState,
  styles,
  blankOption,
  options = [],
  valueKey = 'value',
  textKey = 'text',
  value,
  classes,
  qtyClasses,
  hiddenOption = false,
  placeholder,
  disabled = false,
  qtyStyles = ''
}) => {
  const ref = useRef()
  const idGenerator = useUniqueId('uniqueId')

  useEffect(() => {
    if (addRef) {
      addRef(name, ref.current)
    }
  }, [])

  const changeHandler = (e) => {
    returnState({ name: name, value: e.currentTarget.value, target: e.target })
  }

  const LabelText = (props) => {
    if (props.error && props.error[name]) {
      return (
        <div>
          <SelectBoxLabel styles={props.styles} error={true} htmlFor={props.id || idGenerator}>{props.text}</SelectBoxLabel>
          <SelectBoxErrorText>{props.error && props.error[props.name] ? props.error[props.name][0] : ''}</SelectBoxErrorText>
        </div>
      )
    }
    return <SelectBoxLabel styles={props.styles} htmlFor={props.id || idGenerator}>{props.text}</SelectBoxLabel>
  }

  return (

    <SelectBoxContainer className={classes} styles={styles} orientation={orientation} qtyStyles={qtyStyles}>
      {showLabel &&
        <LabelText
          text={labelText}
          id={id || idGenerator}
          name={name}
          error={error}
          classes={labelClass}
          styles={labelStyles}
        />
      }

      <SelectBoxStyle
        hasError={error?.[name]}
        className={qtyClasses}
      >
        <Select
          ref={ref}
          data-testid='SelectBox'
          id={id || idGenerator}
          aria-label={labelText}
          onChange={changeHandler}
          value={setState}
          name={name}
          tabIndex={0}
          disabled={disabled}
        >
          {placeholder ? <option value="" disabled selected hidden>{placeholder}</option> : ''}
          {blankOption ? <option value='' /> : ''}
          {options.map((option, i) => (
            <SelectOption
              value={option[valueKey]}
              key={i}
            >
              {option[textKey]}&nbsp;&nbsp;
            </SelectOption>
          ))}
         {hiddenOption &&
            <HiddenOption value={value}>{value}</HiddenOption>
          }
        </Select>

        <SelectBoxChevron />
      </SelectBoxStyle>
    </SelectBoxContainer>

  )
}
const SelectBoxLabel = styled.label`
  white-space: nowrap;
  ${props => props.error && css`
    padding: .4rem;
    background-color: ${props => props.theme.darkRed};
    color: ${props => props.theme.white};
  `}
  ${props => props.styles};
`

const SelectBoxErrorText = styled.span`
    color: ${props => props.theme.darkRed};
    padding-left: .6rem;
  `

const SelectBoxContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
  ${props => props.orientation === 'horizontal' ? css`
    align-items: center;
  ` : css`
    justify-content: center;
    flex-direction: column;
  `}
  ${props => props.styles};
  ${props => props.qtyStyles};
`
const SelectOption = styled.option`
  color: ${props => props.theme.black};
`

const Select = styled.select`
  width: 100%;
  font-size: 1.6rem;
  font-family: inherit;
  position: relative;
  z-index: 2;
  height: 4rem;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: .5rem 2rem .5rem .6rem;
  color: ${props => props.theme.black};
  font-weight: 400;
  ${props => props.styles};
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: ${props => `1px solid ${props.theme.black}`};
  }
`

const SelectBoxChevron = styled.div`
  border-color: ${props => props.theme.black};
  border-style: solid;
  border-width: 0.2rem 0.2rem 0 0;
  content: '';
  display: inline-block;
  height: 1rem;
  right: .6rem;
  position: absolute;
  z-index: 3;
  width: 1rem;
  top: 1.1rem;
  bottom: 0;
  transform: rotate(135deg);
  pointer-events: none;
`

const SelectBoxStyle = styled.div`
  padding: 0;
  margin: 0;
  border: .1rem solid ${props => props.theme.mediumGrey};
  height: 4rem;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  border-radius: 0.8rem;
  background-color: ${props => props.theme.white};
  ${props => props.hasError && css`
    border-color: ${props.theme.darkRed};
    margin: 0;
  `}
`

const HiddenOption = styled.option`
  display: none;
`