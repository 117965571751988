import { ORDER_SESSION_REMAIN, ORDER_SESSION_TIMER } from '../../constants/orderConstants'
import { APP_SET_DATA_COMPLETE } from '../../constants/appConstants'

export const resetSessionTimerAction = (initialStart = false) => {
  return dispatch => {
    const addedMinutes = 30
    let countDown = new Date()
    countDown = new Date(countDown.setMinutes(countDown.getMinutes() + addedMinutes))
    dispatch({ type: ORDER_SESSION_TIMER, payload: countDown })
    if (initialStart) {
      dispatch(sessionTimerAction())
    }
  }
}

export const sessionTimerAction = () => {
  return (dispatch, reduxState) => {
    window.sessionTimer = setInterval(() => {
      let currentTime = new Date().getTime()
      const expirationTime = new Date(reduxState().order.sessionTimer)
      const remainingTime = Math.floor(expirationTime - currentTime)
      if (remainingTime <= 0) {
        dispatch({ type: APP_SET_DATA_COMPLETE, payload: true })
      }

      dispatch({ type: ORDER_SESSION_REMAIN, payload: remainingTime })
      if (reduxState().order.sessionTimeRemain <= 0) {
        clearInterval(window.sessionTimer)
      }
    }, 10000)
  }
}
