import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const OptionButtonBox = ({ styles, boxStyles, useBox = false }) => {
  return (
    <>
      {!useBox ?
        <Svg styles={styles} className='option-button__svg' x='0px' y='0px' viewBox='0 0 36 36'>
          <title>Select option</title>
          <Path d='M34,5.5l-0.8-1.1c-0.3,0.1-1.6,1-1.6,1C31.3,5.7,23,13,23,13c-0.1,0.1-0.2,0.2-0.2,0.3
        c-3.3,3.6-7.8,8.8-9.3,11.4c0,0-3.2-6.1-4.2-5.9C8,19,7,20,5.1,21.2c0.9,0.3,1.7,0.9,2.4,1.8c0.7,0.9,2.9,5.7,2.9,5.7
        c0.6,1.5,1,2.6,1.2,3.3c0.5-0.4,1.3-1.1,2.6-2l1.6-1c1.2-2.6,3.1-5.7,5.7-9.4C22.3,18.4,33.7,5.7,34,5.5z'
          />
        </Svg>
        :
        <BoxDiv className='option-button__svg' styles={boxStyles}/>
      }
    </>
  )
}

const Path = styled.path`
  fill: ${props => props.theme.primary};
`

const Svg = styled(SvgAttr)`
  display: none;
  ${props => props.styles};
`

const BoxDiv = styled.div`
  display: none;
  width: 60%;
  height: 60%;
  background-color: ${props => props.theme.primary};
  ${props => props.styles};
`