import styled from 'styled-components'

export const Overlay = styled.div`
  z-index: ${props => props.zIndex || 1999999999}; //to hide the Live Chat button setting this above 2147483639, recaptcha popup is 2000000000
  position: ${props => props.position || 'fixed'};
  top: ${props => props.fromTop || 0};
  left: 0;
  bottom: ${props => props.fromBottom ? props.fromBottom : `calc(-100vh + ${(props.fromTop || 0)})`};
  right: 0;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'rgba(0,0,0, 0.6)'};
  overflow: auto;
  display: ${props => props.isVisible ? 'block' : 'none'};
`

export const HeaderOverlay = styled(Overlay)`
  @media (min-width: 1280px) {
    top: ${props => props.fromTop};
    bottom: calc(-100vh + 112px);
  }
  @media (max-width: 1279px) and (min-width: 744px) {
    top: 71px;
    bottom: calc(-100vh + 71px);
  }
  @media (max-width: 743px) {
    top: 92px;
    bottom: calc(-100vh + 92px);
  }
`
