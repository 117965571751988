import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const SignupTag = ({
  height = '17',
  width = '16',
  fill = '#fff'
}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 17" >
      <title>Signup Icon</title>
      <Path
        d="M14.2733 8.22L8.27325 2.21999C8.03325 1.97999 7.69992 1.83333 7.33325 1.83333H2.66659C1.93325 1.83333 1.33325
        2.43333 1.33325 3.16666V7.83333C1.33325 8.2 1.47992 8.53333 1.72659 8.77999L7.72659 14.78C7.96659 15.02 8.29992
        15.1667 8.66659 15.1667C9.03325 15.1667 9.36659 15.02 9.60659 14.7733L14.2733 10.1067C14.5199 9.86666 14.6666 9.53333
        14.6666 9.16666C14.6666 8.79999 14.5133 8.46 14.2733 8.22ZM8.66659 13.84L2.66659 7.83333V3.16666H7.33325V3.15999L13.3333
        9.16L8.66659 13.84Z"
        fill={fill}
      />
      <Path
        d="M4.33325 5.83333C4.88554 5.83333 5.33325 5.38561 5.33325 4.83333C5.33325 4.28104 4.88554 3.83333 4.33325 3.83333C3.78097
        3.83333 3.33325 4.28104 3.33325 4.83333C3.33325 5.38561 3.78097 5.83333 4.33325 5.83333Z"
        fill={fill}
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  fill: none;
`

const Path = styled.path`
  fill: ${props => props.fill};
`