import React from 'react'
import styled, { css } from 'styled-components'
import { TransparentButton } from '../../_inputs/Button/Button'
import { useSelector } from 'react-redux'

export const HeaderGreenBannerEchoText = ({
  toggleDropdown = () => {}
}) => {
  const displayEchoBanner = useSelector(state => state?.promoCode ? state.promoCode.displayEchoBanner : true)
  return (
    <>
      {window &&
        <HeaderGreenBannerEchoTextDiv className='echo-text-div' data-testid='green-banner-echo-txt'>
          {window.promoInfo && displayEchoBanner &&
            <>
              {(window.promoInfo.linkURL && window.promoInfo.shortDescription && window.promoInfo.imagePath && window.promoInfo.header) &&
                <HeaderGreenBannerEchoContentButton
                  id='promotion'
                  onClick={toggleDropdown}
                  data-testid='green-banner-echo-txt-btn'
                >
                  <HeaderGreenBannerEchoMsgDiv
                    styles={css`
                      text-align: center;
                      @media (max-width: 743px) {
                        display: flex;
                        flex-direction: column;
                      }
                    `}
                  >
                    {window.promoInfo.shortDescription}
                  </HeaderGreenBannerEchoMsgDiv>
                  <HeaderGreenBannerEchoMsgDiv styles={css`font-size: 1.5rem;`}>
                    &nbsp;Exclusions
                    {window.showSurchargeMsg &&
                      <>&nbsp;and Surcharge</>
                    }
                    &nbsp;Apply*
                  </HeaderGreenBannerEchoMsgDiv>
                </HeaderGreenBannerEchoContentButton>
              }
              {(window.promoInfo.linkURL && window.promoInfo.shortDescription && !(window.promoInfo.imagePath && window.promoInfo.header)) &&
                <HeaderGreenBannerEchoContentA
                  aria-label={window.promoInfo.shortDescription}
                  href={window.promoInfo.linkURL}
                  id='promotion'
                  data-tracking='header|echo-msg'
                  data-testid='green-banner-echo-txt-a'
                >
                  <HeaderGreenBannerEchoMsgDiv
                    styles={css`
                    text-align: center;
                    display: inline;
                    @media (max-width: 743px) {
                      display: flex;
                      flex-direction: column;
                    }
                    `}
                  >
                    {window.promoInfo.shortDescription}
                    {window?.promoInfo?.sourceCode !== 'INTZ' &&
                      <HeaderGreenBannerEchoDisclosureSpan>
                        &nbsp;Exclusions
                        {window.showSurchargeMsg &&
                          <>&nbsp;and Surcharge</>
                        }
                        &nbsp;Apply*
                      </HeaderGreenBannerEchoDisclosureSpan>
                    }
                  </HeaderGreenBannerEchoMsgDiv>
                </HeaderGreenBannerEchoContentA>
              }
            </>
          }
        </HeaderGreenBannerEchoTextDiv>
      }
    </>
  )
}

const HeaderGreenBannerEchoDisclosureSpan = styled.span`
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  @media (max-width: 743px) {
    font-size: 1.5rem;
  }
`

const HeaderGreenBannerEchoTextDiv = styled.div`
  display: flex;
  min-height: 4rem;
  justify-content: center;
  align-items: center;
  width: calc(100% - 170px);
  @media (max-width: 1279px) {
    width: 100%;
  }
`

const HeaderGreenBannerEchoContentButton = styled(TransparentButton)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  border: none;
  min-height: 4rem;
  height: auto;
  line-height: 1.6;
  padding-bottom: 0.4rem;
  @media (min-width: 1280px) {
    padding-left: 170px;
  }
`

const HeaderGreenBannerEchoMsgDiv = styled.div`
  font-size: 1.9rem;
  white-space: initial;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  ${props => props.styles};
`

const HeaderGreenBannerEchoContentA = styled.a`
  font-size: 2rem;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 170px);
  white-space: initial;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-bottom: 0.4rem;
  color: ${props => props.theme.white};
  &:hover {
    div {
      color: ${props => props.theme.white};
      text-decoration: underline;
    }
  }
  @media (min-width: 1280px) {
    padding-left: 170px;
  }
  @media (max-width: 1279px) {
    width: 100%;
  }
`