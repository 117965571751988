import React from 'react'
import {useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Chevron } from '../../_svgs/Chevron/Chevron'
import { SelectBox } from '../../_inputs/SelectBox/SelectBox'
import { changePageAction } from '../../../actions/changePageAction'
import { VisuallyHiddenStyles } from '../../_styled/BootStrapGrid'

export const Pagination = ({
  currentPage,
  classes='',
  location = '',
  labelClass = ''
}) => {
  let currentPageNumber = currentPage
  const pages = useSelector(state => state.favorites ? state.favorites.searchResultsDTO.pagesDTO.pages : state.page.page.pages)
  const dispatch = useDispatch()

  const changeHandler = (pageNumber, paginationInfo) => {
    dispatch(changePageAction(pageNumber.value, paginationInfo))
  }

  const forwardClickHandler = (paginationInfo) => {
    ++currentPageNumber
    if (currentPageNumber > pages.slice(-1)[0].nameAsInt) {
      --currentPageNumber
    }
    dispatch(changePageAction(currentPageNumber, paginationInfo))
  }

  const backClickHandler = (paginationInfo) => {
    --currentPageNumber
    if (currentPageNumber < 1) {
      currentPageNumber++
    }
    dispatch(changePageAction(currentPageNumber, paginationInfo))
  }

  let pageOptions = []
  for (let index = 0; index < pages.length; index++) {
    if (!isNaN(pages[index].name)) {
      pageOptions.push({ text: `page ${pages[index].name}`, value: parseInt(pages[index].name) })
    }
  }

  return (
    <PaginationDiv className={`${classes}`}>
      <PaginationChevronButton data-testid='Pagination__chevron-back' onClick={() => backClickHandler(pages)} aria-label='Previous page'>
        <Chevron styles={css`margin-right: .6rem;`} />
      </PaginationChevronButton>
      <PaginationSelectBox>
        <SelectBox
          id={`pagination-${location}`}
          setState={currentPage}
          styles={css`
            width: 100%;
            position: relative;
         `}
          classes={`Pagination__SelectBox_${location}`} labelText='select page number'
          labelStyles={css`
            ${VisuallyHiddenStyles};
          `}
          labelClass={labelClass}
          qtyClasses='cTest_pagination'
          returnState={(pageNumber) => changeHandler(pageNumber, pages)}
          options={pageOptions}
        />
      </PaginationSelectBox>

      <PaginationChevronButton data-testid='Pagination__chevron-forward' onClick={() => forwardClickHandler(pages)} aria-label='Next page'>
        <Chevron
          point='right'
          styles={css`margin-left: .6rem;transform: rotate(180deg);`}
        />
      </PaginationChevronButton>
    </PaginationDiv>
  )
}

const PaginationDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  @media(min-width: ${props => props.theme.breakpoints.md}){
    margin-left: 0;
  }
  ${props => props.styles};
`
const PaginationChevronButton = styled.button`
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 2.0rem;
  height: 2.0rem;
  padding: 0;
  background: transparent;
`
const PaginationSelectBox = styled.div`
  width: 100%;
  position: relative;
`

