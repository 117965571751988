import React from 'react'
import {css} from 'styled-components'
import { useDispatch } from 'react-redux'
import { CLEAR_OOS, SHARED_CLEAR_INSTANCE } from '../../constants/sharedConstants'
import { LightBox } from '../LightBox/LightBox'
import { LightBoxCentered, LightBoxSM } from '../_styled/LightBoxOptions'
import { Form } from '../_forms/Form/Form'
import { OutOfStockForm } from '../_pdp/OutOfStockForm/OutOfStockForm'

export const OutOfStockModal = ({ productName, productPartNumber, productPrice }) => {
  const dispatch = useDispatch()
  const closeHandler = () => {
    dispatch({ type: SHARED_CLEAR_INSTANCE })
    dispatch({ type: CLEAR_OOS })
  }

  return (
    <LightBox
      styles={css`${LightBoxSM};${LightBoxCentered};`}
      show
      closeHandler={closeHandler}
    >
      <Form
        productPrice={productPrice}
        productName={productName}
        productPartNumber={productPartNumber}
        WrappedForm={OutOfStockForm}
        location='search'
      />
    </LightBox>
  )
}
