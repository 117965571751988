import React from 'react'
import styled from 'styled-components'
import { Product } from '../Product/Product'
import { useDispatch } from 'react-redux'
import { SHARED_CLEAR_INSTANCE } from '../../constants/sharedConstants'
import { openCartDialogAction } from '../../actions/cartAction'
import { ProductCard } from '../ProductCard/ProductCard'
import { PDPCarouselProductCard } from '../PDPCarouselProductCard/PDPCarouselProductCard'
export const CarouselProduct = ({
  product,
  classes,
  index,
  focus,
  styles,
  actionId,
  carouselName,
  updateCart,
  useProductCards = false,
  useProduct = false,
  productCardStyles,
  usePDPProd = false,
  ...props
}) => {
  const dispatch = useDispatch()

  const additionalAfterAddToCartCode = () => {
    dispatch({ type: SHARED_CLEAR_INSTANCE })
    dispatch(openCartDialogAction(product, 1, updateCart))
  }
  return (
    <CarouselProductDiv
      data-testid='carousel-product'
      styles={styles}
      data-cost={product.productDiscountPrice}
      data-id={product.productPartNumber}
      data-actionid={actionId}
      data-groupid={product.masterGroupItem ? product.masterGroupItem : product.productPartNumber}
      data-index={index}
      data-name={carouselName}
      className={classes}
      {...props}
    >
      {useProductCards &&
        <ProductCard
          focus={focus}
          product={product}
          displayATCBanner={false}
          isCarousel
          additionalAfterAddToCartCode={additionalAfterAddToCartCode}
          updateCart={updateCart}
          productCardStyles={productCardStyles}
        />
      }
      {useProduct &&
        <Product
          focus={focus}
          product={product}
          displayATCBanner={false}
          isCarousel
          additionalAfterAddToCartCode={additionalAfterAddToCartCode}
          updateCart={updateCart}
        />
      }
      {usePDPProd &&
        <PDPCarouselProductCard
          focus={focus}
          product={product}
          displayATCBanner={false}
          isCarousel
          additionalAfterAddToCartCode={additionalAfterAddToCartCode}
          updateCart={updateCart}
          styles={productCardStyles}
        />
      }
    </CarouselProductDiv>
  )
}

const CarouselProductDiv = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  ${props => props.styles};
`
