import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const Gear = ({
  height = `2.7rem`,
  width = `2.7rem`,
  fill = '#00833d',
  styles
}) => {
  return (
    <Svg x='0px' y='0px' viewBox='0 0 612 612' width={width} height={height} styles={styles}>
      <title>Gear Icon</title>
      <g>
        <Path
          d='M308.1,154.6c-83.6,0-151.4,67.8-151.4,151.4c0,83.6,67.8,151.4,151.4,151.4S459.6,389.6,459.6,306
            C459.6,222.4,391.8,154.6,308.1,154.6z M308.1,440.6c-74.3,0-134.6-60.3-134.6-134.6c0-74.3,60.3-134.6,134.6-134.6
            S442.7,231.7,442.7,306C442.7,380.3,382.5,440.6,308.1,440.6z'
          fill={fill}
        />
        <Path
          d='M601.3,359.5V253l-66.3-9.2c-5.3-19.4-12.9-37.9-22.7-55l40.9-54l-74.1-76.5l-56.4,41.2
            c-16.8-9.5-34.8-17-53.8-22.2l-10.3-67.2H252.1l-9.4,67.4c-18.9,5.2-36.9,12.8-53.6,22.3l-54.8-40.1l-74.1,76.5l39.9,52.6
            c-9.7,17-17.3,35.4-22.6,54.7l-66.7,10.3v106.5l67,9.3c5.1,18.2,12.2,35.5,21.2,51.6l-40.2,53l74.1,76.5l54.3-39.7
            c17.4,10.1,36.3,18.1,56.2,23.6l10.3,67.2h106.5l9.4-67.4c19.2-5.3,37.5-13,54.4-22.7l53.3,38.9l74.1-76.5L512.4,423
            c9.4-16.6,16.9-34.4,22.1-53.2L601.3,359.5z M493.3,423l15.3,19.6l23.6,30.1l-55.3,57.1l-40-26l-14.9-10
            c-19.3,11.9-40.5,20.9-63.1,26.5l-4,17.8l-9.5,49H266l-5.8-42.1l-3.3-23.9c-24.6-5.6-47.6-15.3-68.2-28.4L174.3,503l-41.1,26.8
            l-55.3-57.1l29.4-37.6c0,0,0,0,0,0.1l10.8-13.6c-12.2-19.8-21.4-41.7-26.9-65l-65.9-11.2v-79.4c0,0,48.2-6.7,48.2-6.7L91,257
            c5.6-24.5,15.2-47.4,28.1-68.1l-39.7-51.2l55.3-57.1l54.9,38c20.3-12.7,42.9-22.1,67-27.7l10.4-66.1h79.4l9.2,65.9
            c22.5,5.1,45.7,14.5,65,25.9l58.3-37.6l55.3,57.1l-40.9,52.5c13,20.7,22.6,42.2,28.2,66.8l65.2,12.2v79.4l-65.4,8.6
            C515.6,380,506.1,402.6,493.3,423z'
          fill={fill}
        />
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  margin-right: 1rem;
  ${props => props.styles};
`

const Path = styled.path`
  fill: ${props => props.fill};
`
