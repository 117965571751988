import { useState, useEffect, useCallback } from 'react'

const waitForKlaviyo = (callback) => {
    const checkKlaviyo = () => {
        if (window._klOnsite) {
            callback()
        } else {
            setTimeout(checkKlaviyo, 100)
        }
    }
    checkKlaviyo()
}

const useKlaviyo = () => {
    const [ isKlaviyoLoaded, setIsKlaviyoLoaded ] = useState(false)

    useEffect(() => {
        waitForKlaviyo(() => {
            setIsKlaviyoLoaded(true)
        })
    }, []);

    const trackEvent = useCallback((eventName, properties) => {
        if (isKlaviyoLoaded) {
            window._klOnsite = window._klOnsite || []
            if (window._klOnsite) {
                window._klOnsite.push(['track', eventName, properties])
            }
        } else {
            console.warn('Klaviyo is not loaded yet')
        }
    }, [isKlaviyoLoaded])

    const identifyUser = useCallback((userDetails) => {
        if (isKlaviyoLoaded) {
            window._klOnsite = window._klOnsite || []
            if (window._klOnsite) {
                window._klOnsite.push(['track', userDetails])
            }
        } else {
            console.warn('Klaviyo is not loaded yet')
        }
    }, [isKlaviyoLoaded])

    return {
        isKlaviyoLoaded,
        trackEvent,
        identifyUser
    }
}

export default useKlaviyo