import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const QualityCodeSeal = props => {
  return (
    <Svg x='0px' y='0px' viewBox='32 32 118 118' className={`${props.classes}`} >
      <title>Quality Code Seal</title>
      <g>
          <PathTurtleGreen d="M51.08,109c-0.52-1.2-1.14-2.03-2.13-2.73l0.56-1.31l-1.14-2.02l-1,2.52c-0.9-0.3-2.44-0.63-4.45,0.26
          c-3.28,1.45-4.6,4.84-3.11,8.24c1.49,3.39,4.89,4.71,8.17,3.27C51.28,115.77,52.58,112.39,51.08,109z M47.04,115.06
          c-2.18,0.96-4.38,0.16-5.31-1.94c-0.92-2.11-0.03-4.27,2.15-5.23c1.2-0.53,2.04-0.45,2.64-0.32l-0.73,1.81l1.14,2.1l1.17-3.13
          c0.36,0.3,0.74,0.72,1.06,1.44C50.1,111.95,49.2,114.11,47.04,115.06z"/>
          <PathTurtleGreen d="M47.58,96.24c-0.18-1.51-0.94-2.9-2.14-3.61c-0.98-0.59-2.13-0.63-3.23-0.5l-7.15,0.87l0.28,2.28l7.34-0.89
          c0.89-0.11,1.47,0.12,1.8,0.35c0.45,0.28,0.92,0.85,1.03,1.76c0.11,0.91-0.22,1.57-0.58,1.95c-0.27,0.3-0.78,0.66-1.67,0.77
          l-7.34,0.89l0.28,2.28l7.14-0.87c1.11-0.13,2.21-0.45,3.01-1.25C47.37,99.29,47.76,97.75,47.58,96.24z"/>
          <PathTurtleGreen d="M48.01,80.68l0.37-2.44L36,81.35l-0.27,1.78l10.89,6.76l0.37-2.46l-2.29-1.4l0.71-4.7L48.01,80.68z
          M42.96,85.04l-3.94-2.29l4.44-1.03L42.96,85.04z"/>
          <PathTurtleGreen d="M49.51,70.31l-1.31,3.39l-9.14-3.53l-0.83,2.14l10.98,4.25l2.13-5.53L49.51,70.31z"/>
          <PathTurtleGreen d="M43.39,61.26l-1.14,1.99l10.21,5.86l1.14-2L43.39,61.26z"/>
          <PathTurtleGreen d="M50.03,55.32l1.66-2.06l-1.54-1.24l-4.75,5.93l1.55,1.24l1.65-2.07l7.65,6.13l1.43-1.79L50.03,55.32z"/>
          <PathTurtleGreen d="M61.57,50.34l-0.75-7.58l-2.13,1.7l0.59,4.78l-4.53-1.62l-2.13,1.7l7.15,2.46l3.37,4.21l1.79-1.43L61.57,50.34
          z"/>
          <PathTurtleGreen d="M81.14,43.26c-0.39,0.99-1.15,1.75-2.19,2.11c-1.87,0.65-4.23-0.04-5.07-2.51c-0.7-2.06,0.12-4.39,2.36-5.15
          c0.4-0.14,1.7-0.49,3.12,0.33l-0.87-2.53c-1.21-0.21-2.19-0.02-2.97,0.25c-1.59,0.54-2.84,1.44-3.59,2.87
          c-0.65,1.21-0.98,2.95-0.3,4.97c0.56,1.64,1.53,2.86,3.24,3.65c1.24,0.56,2.66,0.83,4.61,0.16c1.2-0.41,1.98-1.01,2.53-1.59
          L81.14,43.26z"/>
          <PathTurtleGreen d="M96.2,39.09c-0.18-3.58-2.88-6.02-6.59-5.84c-3.7,0.19-6.15,2.89-5.97,6.48c0.18,3.58,2.88,6,6.59,5.82
          C93.94,45.36,96.39,42.68,96.2,39.09z M90.13,43.47c-2.29,0.12-4-1.48-4.12-3.86c-0.12-2.38,1.42-4.16,3.71-4.27
          c2.3-0.12,4.01,1.49,4.12,3.87C93.97,41.6,92.42,43.36,90.13,43.47z"/>
          <PathTurtleGreen d="M108.67,36.91c-1.03-1.26-2.37-1.78-3.96-2.17l-2.98-0.74l-2.86,11.42l2.95,0.74c1.6,0.4,2.88,0.56,4.45-0.05
          c1.78-0.69,2.97-2.13,3.45-4.03C110.18,40.16,109.8,38.3,108.67,36.91z M107.41,41.49c-0.31,1.22-1.11,2.25-2.18,2.68
          c-1.18,0.47-2.42,0.19-2.98,0.06l-0.69-0.17l1.9-7.58l0.69,0.17c0.75,0.19,1.87,0.54,2.62,1.46
          C107.51,39.01,107.71,40.29,107.41,41.49z"/>
          <PathTurtleGreen d="M120.87,43.09l0.9-1.76l-5.82-2.97l-5.35,10.5l5.82,2.97l0.9-1.76l-3.78-1.93l1.46-2.86l3.62,1.85l0.9-1.76
          l-3.62-1.85l1.2-2.34L120.87,43.09z"/>
          <PathTurtleGreen d="M123.59,75.55c-0.61,0.21-1.29,0.41-2.01,0.59c2.22,4.4,3.5,9.38,3.5,14.65c0,17.93-14.59,32.53-32.53,32.53
          c-1.9,0-3.75-0.17-5.56-0.48c-0.45,0.61-0.99,1.22-1.56,1.8c2.29,0.5,4.67,0.75,7.12,0.75c19.07,0,34.59-15.53,34.59-34.59
          C127.15,85.32,125.87,80.15,123.59,75.55z M92.55,56.2c-18,0-32.85,13.83-34.44,31.44c0.72-0.14,1.43-0.23,2.09-0.27
          c1.71-16.33,15.57-29.1,32.34-29.1c5.15,0,10.02,1.2,14.35,3.34c0.41-0.54,0.86-1.1,1.33-1.64C103.54,57.56,98.2,56.2,92.55,56.2z"
          />
          <PathTurtleGreen d="M82.42,135.32l-1.75-0.43l-7.78,10.19l2.42,0.61l1.61-2.15l4.61,1.16l0.41,2.65l2.4,0.6L82.42,135.32z
          M78.08,141.9l2.66-3.7l0.6,4.51L78.08,141.9z"/>
          <PathTurtleGreen d="M95.19,137.4c-0.85-0.77-1.77-0.92-3.09-0.94l-3.43-0.04l-0.14,11.77l2.29,0.03l0.06-4.36l1.48,0.02
          c1.36,0.01,2.33-0.36,2.99-1.01c0.92-0.89,1-2.15,1-2.62C96.37,139.41,96.13,138.26,95.19,137.4z M93.35,141.55
          c-0.5,0.36-1.07,0.36-1.51,0.35l-0.94-0.01l0.04-3.46l0.8,0.01c0.54,0.01,1.14,0.05,1.62,0.39c0.33,0.25,0.65,0.7,0.63,1.35
          C93.99,140.69,93.77,141.22,93.35,141.55z"/>
          <PathTurtleGreen d="M106.56,138.37c-0.17-0.83-0.63-1.91-1.73-2.55c-1-0.58-1.93-0.53-3.23-0.27l-3.36,0.67l2.33,11.55l2.25-0.45
          l-0.87-4.27l1.46-0.3c1.33-0.27,2.2-0.84,2.72-1.61C106.84,140.09,106.66,138.82,106.56,138.37z M103.88,140.27
          c-0.41,0.46-0.96,0.57-1.4,0.66l-0.92,0.19l-0.68-3.39l0.78-0.16c0.54-0.11,1.14-0.19,1.67,0.04c0.38,0.18,0.78,0.54,0.91,1.19
          C104.34,139.29,104.23,139.85,103.88,140.27z"/>
          <PathTurtleGreen d="M117.52,142.57l2.62-1.13l-5.33-3.1c0.48-0.32,0.79-0.67,0.98-0.98c0.6-0.93,0.63-2.04,0.17-3.09
          c-0.35-0.81-0.99-1.63-2.12-1.97c-0.68-0.21-1.61-0.27-3.1,0.37l-3.13,1.35l4.65,10.82l2.11-0.91l-1.87-4.35l0.25-0.11
          L117.52,142.57z M112.47,137.83l-0.6,0.25l-1.4-3.26l0.63-0.28c0.39-0.17,2.05-0.86,2.74,0.74
          C114.52,136.89,112.87,137.66,112.47,137.83z"/>
          <PathTurtleGreen d="M129.81,127c-2.24-2.79-5.87-3.18-8.76-0.85c-2.89,2.33-3.28,5.95-1.03,8.74c2.25,2.79,5.86,3.17,8.75,0.85
          C131.65,133.4,132.06,129.79,129.81,127z M127.46,134.11c-1.79,1.44-4.11,1.15-5.6-0.7c-1.49-1.86-1.29-4.2,0.5-5.64
          c1.79-1.45,4.12-1.14,5.61,0.72C129.47,130.34,129.25,132.67,127.46,134.11z"/>
          <PathTurtleGreen d="M132.91,112.89l-1.47,2.04l4.6,7.16l-8.26-2.06l-1.47,2.04l12.45,2.85l0.81-1.11L132.91,112.89z"/>
          <PathTurtleGreen d="M144.96,109.26l-1.66,3.9l-2.95-1.26l1.58-3.74l-1.82-0.77l-1.59,3.74l-2.42-1.03l1.66-3.9l-1.82-0.77
          l-2.55,6.01l10.83,4.6l2.55-6.02L144.96,109.26z"/>
          <PathTurtleGreen d="M148.49,97.87c-0.81-1.72-2.33-2.81-4.26-3.16c-1.93-0.34-3.76,0.17-5.07,1.39c-1.18,1.11-1.61,2.49-1.89,4.11
          l-0.54,3.03l11.59,2.06l0.53-2.99C149.14,100.69,149.2,99.4,148.49,97.87z M146.9,102l-0.12,0.7l-7.7-1.37l0.12-0.7
          c0.14-0.76,0.41-1.9,1.27-2.72c0.85-0.8,2.13-1.09,3.34-0.87c1.23,0.22,2.33,0.95,2.82,1.99C147.19,100.17,146.99,101.42,146.9,102
          z"/>
          <PathPrairieGreen d="M126.35,63.8c-0.08,0.69-0.45,1.28-1.09,1.77c-1.07,0.8-2.13,1.64-3.2,2.55c-0.65,0.55-1.32,1.14-1.99,1.74
          c-0.5,0.45-1,0.92-1.51,1.41c-0.96,0.9-1.93,1.86-2.93,2.88c-0.42,0.43-0.85,0.87-1.28,1.32c-3.98,4.16-8.02,8.9-12.35,14.47
          c-5.51,7.09-10,13.7-13.72,20.19c-1.43,2.5-2.62,4.75-3.63,6.87c-0.06,0.11-0.11,0.22-0.18,0.32c-0.3,0.5-0.7,0.92-1.2,1.25
          l-0.17,0.11c-1.04,0.69-2.09,1.38-3.13,2.09c-0.66,0.45-1.32,0.91-1.98,1.38c-0.83,0.6-1.66,1.22-2.46,1.86
          c-0.17,0.13-0.34,0.27-0.51,0.41c-0.12,0.1-3.07,2.59-3.07,2.59L71,123.97c-0.53-1.69-1.16-3.43-1.95-5.31l-0.15-0.36l-0.12-0.28
          c-0.52-1.23-1.03-2.48-1.58-3.72c-1.12-2.6-2.34-5.18-3.79-7.58c-1.13-1.87-2.2-3.19-3.39-4.16c-0.23-0.2-0.46-0.37-0.7-0.54
          c-0.56-0.38-1.18-0.7-1.98-1l-3.01-1.16l2.28-2.29c0.54-0.55,1.12-1.12,1.73-1.67c0.58-0.54,1.21-1.06,1.88-1.54
          c1.22-0.89,2.57-1.68,4.13-2.22c0.54-0.19,1.06-0.33,1.56-0.43c0.51-0.1,0.99-0.14,1.45-0.14c2.04,0,3.77,0.93,5.46,2.93
          c1.96,2.32,3.33,4.96,4.51,7.48c0.06,0.13,0.12,0.26,0.19,0.4c0.74-1.14,1.48-2.25,2.24-3.34c5.61-8.07,12.24-15.69,20.25-23.28
          c3.23-3.06,6.41-5.81,9.59-8.29c1-0.78,2.01-1.53,3.01-2.26c0.59-0.43,1.16-0.84,1.75-1.25c0.56-0.39,1.13-0.77,1.69-1.15
          c0.23-0.16,0.47-0.32,0.72-0.47c1.35-0.89,2.83-1.79,4.49-2.76c0.45-0.26,0.9-0.39,1.36-0.39c0.83,0,1.58,0.43,2.08,1.19
          c0.3,0.46,0.65,0.98,1.03,1.45C126.22,62.45,126.43,63.11,126.35,63.8z"/>
          <PolygonTurtleGreen points="139.58,134.64 138.12,134.64 138.12,134.19 141.53,134.19 141.53,134.64 140.07,134.64 140.07,138.52
          139.58,138.52 139.58,134.64 "/>
          <PolygonTurtleGreen points="142.42,134.19 142.92,134.19 144.5,136.55 146.08,134.19 146.57,134.19 146.57,138.52 146.08,138.52
          146.08,135.01 144.5,137.33 144.48,137.33 142.9,135.02 142.9,138.52 142.42,138.52 142.42,134.19 "/>
</g>
</Svg>
  )
}

const Svg = styled(SvgAttr)`
 &.footer-quality{
  width: 9rem;
  @media (max-width: 767px) {
    width: 10rem;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    width: 8rem;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 9rem;
  }
 }
`
const PathPrairieGreen = styled.path`
  fill: ${props => props.theme.prairieGreen};
`
const PathTurtleGreen = styled.path`
  fill: ${props => props.theme.turtleGreen};
`
const PolygonTurtleGreen = styled.polygon`
  fill: ${props => props.theme.turtleGreen};
`
