import { ERROR_SET, ERROR_REMOVE } from '../constants/errorConstants'

export const errorReducer = (reduxState = {}, action) => {
  switch (action.type) {
    case ERROR_SET: {
      return { ...reduxState, ...action.payload }
    }
    case ERROR_REMOVE: {
      return null
    }
    default:
      return reduxState
  }
}
