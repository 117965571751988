import React from 'react'
import { StyleSheetManager } from 'styled-components'
import { Context as ResponsiveContext } from 'react-responsive'


export const SSRContainer = ({children, sheet}) => {
  return (
    <StyleSheetManager sheet={sheet.instance}>
      <ResponsiveContext.Provider value={window.isMobile ? {width: 400} : {width: 1300}}>
        {children}
      </ResponsiveContext.Provider>
    </StyleSheetManager>
  )
}