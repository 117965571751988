export const theme = {
  primary: '#2F711C',
  darkestGreen: '#016731',
  turtleGreen: '#23350D',
  prairieGreen: '#57A300',
  treeGreen: '#2F711C',
  secondary: '#7fba00',
  brandedLtGreen: '#57A445',
  lightGreen: '#ddebb4', // do not use as text on white background
  lighterGreen: '#f0f8e8',
  buttercupYellow: '#ffc614',
  yellow: '#ffc72c',
  lightOrange: '#ff8200',
  darkOrange: '#d44400',
  darkestOrange: '#B93E09',
  starColor: '#60A000',
  red: '#FC000F',
  darkRed: '#990028',
  darkViolet: '#8c4799',
  darkBlue: '#007f96',
  white: '#fff', // do not use as text on white background
  lightGrey: '#fafafa', // do not use as text on white background
  gray85: '#D9D9D9',
  darkerLightGrey: '#D7D2D2',
  platinumGrey: '#E6E6E6',
  mediumGrey: '#9b9d9d', // do not use as text on white background
  darkGrey: '#545454',
  brandedBlack: '#1A1C16',
  tuskGreen: '#CEE4B5',
  black: '#000',
  breakpoints: {
    xs: '360px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    mobile: '744px', //Tablet view to be (max-width: 1280px) and (min-width: 744px)
    desktop: '1280px'
  }
}
