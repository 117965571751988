import { NOTIFICATION_HIDE, NOTIFICATION_SHOW } from '../constants/notificationConstants'

export const showNotificationWithTimeout = (notificationInfo) => {
  return function (dispatch, reduxState) {
    dispatch(showNotification(notificationInfo))

    setTimeout(() => {
      dispatch(hideNotification())
    }, notificationInfo.time)
  }
}

export const showNotification = (info) => (
  {
    type: NOTIFICATION_SHOW,
    payload: info
  }
)

export const hideNotification = () => (
  {
    type: NOTIFICATION_HIDE
  }
)
