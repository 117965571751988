import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const VeganIcon = ({
  fillColor = '#23350d',
  strokeColor = '#23350d',
  height = '4.1rem',
  width = '100%'
}) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    id='specialty-flag_vegan'
    data-name='specialty-flag vegan'
    viewBox='0 0 162.02 201.56'
    height={height}
    width={width}
  >
    <title>Vegan</title>
    <defs>
      <clipPath id='clippath'>
        <Circle strokeColor={strokeColor} cx='81.01' cy='81.01' r='78.01'/>
      </clipPath>
    </defs>
    <PathTwo fillColor={fillColor} d='M81.01,3c43.08,0,78.01,34.93,78.01,78.01s-34.93,78.01-78.01,78.01S3,124.09,3,81.01,37.93,3,81.01,3M81.01,0C36.34,0,0,36.34,0,81.01s36.34,81.01,81.01,81.01,81.01-36.34,81.01-81.01S125.68,0,81.01,0h0Z'/>
    <g>
      <PathOne strokeColor={strokeColor} d='M80.97,16.79s70.65,48.09-.06,100.41c0,0-70.35-48.31.06-100.41'/>
      <Line strokeColor={strokeColor} x1='80.91' y1='117.19' x2='80.91' y2='44.44'/>
      <Line strokeColor={strokeColor} x1='81.01' y1='75.16' x2='96.2' y2='62.64'/>
      <Line strokeColor={strokeColor} x1='81.01' y1='95.07' x2='96.2' y2='82.54'/>
      <Line strokeColor={strokeColor} x1='79.91' y1='75.16' x2='64.72' y2='62.64'/>
      <Line strokeColor={strokeColor} x1='79.91' y1='95.07' x2='64.72' y2='82.54'/>
    </g>
    <g>
      <G>
        <g>
          <g>
            <PathOne strokeColor={strokeColor} d='M39.59,97.64s70.65,48.09-.06,100.41c0,0-70.35-48.31.06-100.41'/>
            <Line strokeColor={strokeColor} x1='39.52' y1='198.05' x2='39.52' y2='125.29'/>
            <Line strokeColor={strokeColor} x1='39.62' y1='156.01' x2='54.81' y2='143.49'/>
            <Line strokeColor={strokeColor} x1='39.62' y1='175.92' x2='54.81' y2='163.39'/>
            <Line strokeColor={strokeColor} x1='38.53' y1='156.01' x2='23.34' y2='143.49'/>
            <Line strokeColor={strokeColor} x1='38.53' y1='175.92' x2='23.34' y2='163.39'/>
          </g>
          <g>
            <PathOne strokeColor={strokeColor} d='M122.36,97.64s70.65,48.09-.06,100.41c0,0-70.35-48.31.06-100.41'/>
            <Line strokeColor={strokeColor} x1='122.3' y1='198.05' x2='122.3' y2='125.29'/>
            <Line strokeColor={strokeColor} x1='122.39' y1='156.01' x2='137.59' y2='143.49'/>
            <Line strokeColor={strokeColor} x1='122.39' y1='175.92' x2='137.59' y2='163.39'/>
            <Line strokeColor={strokeColor} x1='121.3' y1='156.01' x2='106.11' y2='143.49'/>
            <Line strokeColor={strokeColor} x1='121.3' y1='175.92' x2='106.11' y2='163.39'/>
          </g>
        </g>
      </G>
      <Circle strokeColor={strokeColor} cx='81.01' cy='81.01' r='78.01'/>
    </g>
    <g>
      <PathTwo fillColor={fillColor} d='M36.45,171.45l5.16,13,5.16-13h4.12l-8.18,19.35h-2.26l-8.12-19.35h4.12Z'/>
      <PathTwo fillColor={fillColor} d='M63.89,174.7h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <PathTwo fillColor={fillColor} d='M77.34,180.44h8.88v.23c0,1.3-.17,5.37-2.84,8.01-2,2-4.47,2.55-6.87,2.55s-4.9-.49-7.02-2.61c-.67-.67-2.87-3.1-2.87-7.45,0-3.74,1.68-6.03,2.84-7.19,1.45-1.48,3.89-2.96,7.63-2.96,1.45,0,2.99.23,4.47,1.02,1.25.64,2.61,1.8,3.63,3.54l-3.28,1.77c-.67-1.31-1.65-1.97-2.26-2.29-.81-.41-1.83-.61-2.73-.61-1.54,0-3.28.55-4.47,1.71-1.1,1.07-1.94,2.93-1.94,5.11,0,2.58,1.1,4.12,1.91,4.9.87.84,2.23,1.65,4.29,1.65,1.28,0,2.73-.35,3.8-1.16.75-.58,1.51-1.57,1.83-2.96h-4.99v-3.25Z'/>
      <PathTwo fillColor={fillColor} d='M100.57,186.74h-7.8l-1.71,4.06h-4.09l8.33-19.35h2.96l8.09,19.35h-4.06l-1.71-4.06ZM99.47,183.66l-2.75-6.96-2.76,6.96h5.51Z'/>
      <PathTwo fillColor={fillColor} d='M108.6,190.8v-19.35h2.61l11.78,12.91v-12.91h3.77v19.35h-2.61l-11.78-12.94v12.94h-3.77Z'/>
    </g>
  </Svg>
)

const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const G = styled.g`
  clip-path: url(#clippath);
`

const Line = styled.line`
  stroke-linecap: round;
  stroke-width: 5.73px;
  fill: none;
  stroke: ${props => props.strokeColor};
  stroke-miterlimit: 10;
`

const Circle = styled.circle`
  fill: none;
  stroke: ${props => props.strokeColor};
  stroke-miterlimit: 10;
`

const PathOne = styled.path`
  stroke-linecap: round;
  stroke-width: 5.73px;
  fill: none;
  stroke: ${props => props.strokeColor};
  stroke-miterlimit: 10;
`

const PathTwo = styled.path`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`