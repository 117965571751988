import React from 'react'
import styled, {css} from 'styled-components'
import { useSelector } from 'react-redux'
import { Gear } from '../../_svgs/Gear/Gear'
import { Card } from '../../_svgs/Card/Card'
import { HeartNoFill } from '../../_svgs/HeartNoFill/HeartNoFill'
import { HistoryIcon } from '../../_svgs/HistoryIcon/HistoryIcon'
import { Lock } from '../../_svgs/Lock/Lock'
import { AddressIcon } from '../../_svgs/AddressIcon/AddressIcon'
import { RewardsRibbon } from '../../_svgs/RewardsRibbon/RewardsRibbon'
import { EasyRefill } from '../../_svgs/EasyRefill/EasyRefill'

export const AccountDropdown = () => {
  const userName = useSelector(state => state.header?.userName ? state.header.userName : '')

  return (
    <AccountDropdownContainer data-testid='myAccountMenu'>
      <AccountDropdownTitleDiv data-testid='account-userName'>Hi, {userName}!</AccountDropdownTitleDiv>
      <AccountDropdownAContainer>
        <AccountDropdownA
          href='/myaccount'
          aria-label='Account Settings'
          data-testid='acc-drpdwn-account-settings'
          data-tracking='header|my-account-dropdown|account-settings'
        >
          <Gear width='16' height='16' fill='#000' styles={css`margin-right: 0.5rem;`}/>Account Settings
        </AccountDropdownA>
      </AccountDropdownAContainer>
      <AccountDropdownAContainer>
        <AccountDropdownA
          href='/myaccount/orders.html'
          aria-label='Order History'
          data-testid='acc-drpdwn-order-history'
          data-tracking='header|my-account-dropdown|order-history'
        >
          <HistoryIcon width='16' height='16' styles={css`margin-right: 0.5rem;`}/>Order History
        </AccountDropdownA>
      </AccountDropdownAContainer>
      <AccountDropdownAContainer>
        <AccountDropdownA
          href='/myaccount/payments.html'
          aria-label='Payment Methods'
          data-testid='acc-drpdwn-payment-methods'
          data-tracking='header|my-account-dropdown|payment-methods'
        >
          <Card width='16' height='16' fill='#000' styles={css`margin-right: 0.5rem;`}/>Payment Methods
        </AccountDropdownA>
      </AccountDropdownAContainer>
      <AccountDropdownAContainer>
        <AccountDropdownERA
          href='/myaccount/rewards.html'
          aria-label='Wellness Rewards'
          data-testid='acc-drpdwn-rewards'
          data-tracking='header|my-account-dropdown|rewards'
        >
          <RewardsRibbon color='#000' width='16' height='16' styles={css`margin-right: 0.5rem;`}/>Wellness Rewards
        </AccountDropdownERA>
      </AccountDropdownAContainer>
      <AccountDropdownAContainer>
        <AccountDropdownA
          href='/myaccount/addresses.html'
          aria-label='Address Book'
          data-testid='acc-drpdwn-address-book'
          data-tracking='header|my-account-dropdown|address-book'
        >
          <AddressIcon width='16' height='16' styles={css`margin-right: 0.5rem;`}/>Address Book
        </AccountDropdownA>
      </AccountDropdownAContainer>
      <AccountDropdownAContainer>
        <AccountDropdownA
          href='/myaccount/favorites.html'
          aria-label='Favorites'
          data-testid='acc-drpdwn-favorites'
          data-tracking='header|my-account-dropdown|favorites'
        >
          <HeartNoFill width='16' height='16' fill='#000' styles={css`margin-right: 0.5rem;`}/>Favorites
        </AccountDropdownA>
      </AccountDropdownAContainer>
      <AccountDropdownAContainer>
        <AccountDropdownERA
          href='/myaccount/easyrefill.html'
          aria-label='Easy Refill'
          data-testid='acc-drpdwn-easy-refill'
          data-tracking='header|my-account-dropdown|easy-refill'
        >
          <EasyRefill
            height={15}
            width={77}
          />
        </AccountDropdownERA>
      </AccountDropdownAContainer>
      <AccountDropdownAContainer styles={css`margin-bottom: 0;`}>
        <AccountDropdownA
          href='/j_spring_security_logout'
          aria-label='Sign Out'
          data-testid='acc-drpdwn-sign-out'
          data-tracking='header|my-account-dropdown|sign-out'
        >
          <Lock color='#000' width='16' height='16' styles={css`margin-right: 0.5rem;`}/>Sign Out
        </AccountDropdownA>
      </AccountDropdownAContainer>
    </AccountDropdownContainer>
  )
}

const AccountDropdownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.8rem;
`

const AccountDropdownTitleDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.primary};
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1.6rem;
`

const AccountDropdownAContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2.4rem;
  ${props => props.styles};
`

const AccountDropdownA = styled.a`
  display: flex;
  color: ${props => props.theme.black};
  text-decoration: none;
  padding: 2px 4px;
  align-items: center;
  &:hover {
    color: ${props => props.theme.black};
    text-decoration: underline !important;
  }
`

const AccountDropdownERA = styled.a`
  color: ${props => props.theme.black};
  text-decoration: none;
  padding: 2px 4px;
  &:hover {
    color: ${props => props.theme.black};
    cursor: pointer;
    text-decoration: underline !important;
  }
`
