import React from 'react'
import styled from 'styled-components'

export const HeaderDropDown = ({
  tag,
  role,
  styles,
  classes,
  dataTestid,
  fromTop,
  children
}) => {
  return (
    <DropDown as={tag ? tag : 'li'} fromTop={fromTop} role={role} styles={styles} className={classes} data-testid={dataTestid}>
      {children}
    </DropDown>
  )
}

const DropDown = styled.li`
  position: absolute;
  z-index: 900000;
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};
  height: 0;
  top: ${props => props.fromTop ? props.fromTop : '100%'};
  left: 0;
  right: 0;
  margin: auto;
  overflow:hidden;
  visibility: hidden;
  &.active{
   height: auto;
   text-align: center;
   transition: height 0.5s linear;
   padding: 1rem;
   width: 24rem;
   border: 0.1rem solid ${props => props.theme.darkGrey};
   visibility: visible;
  }
  &.activeCart{
    height: 550px;
    width: 33rem;
    transition: height 0.5s linear; 
    padding: 1.5rem;
    border: 0.1rem solid ${props => props.theme.darkGrey};
    left: -22rem;
    visibility: visible;
    text-align: left;
    overflow-y: auto;
    font-size: 1.3rem
  }
  &.headActive{
    left: 0;
    height: 400px;
    width: 24rem;
    padding: 1.5rem;
    border: 0.1rem solid ${props => props.theme.darkGrey};
    transition: height 0.5s linear; 
    visibility: visible;
    opacity: 1;
    
  }
  &.activeMenu{
    height: 48rem;
    padding: 1rem;
    max-width: 140rem;
    width: 100%;
    border: 0.1rem solid ${props => props.theme.darkGrey};
    visibility: visible;
    opacity: 1;
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      height: auto;
      top: 0;
      border: 0;
    }
    @media(min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
      top: 80px;
    }
  }
  &.overflow-visible{
    overflow: visible;
  }
  &.mobileMenuDD{
    display: block;
    width: 100%;
    height: auto;
    overflow: auto;
    visibility: visible;
  }
  ${props => props.styles};
`
