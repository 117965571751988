import { css } from 'styled-components'

export const LightBoxCentered = css`
  margin: 4rem auto 0;
  padding: 4.0rem 1rem 1rem;
  position: relative;
`

export const LightBoxBottomRight = css`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    right: initial;
    width: 100%;
  }
`

export const LightBoxBottom = css`
  position: absolute;
  bottom: 2rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    bottom: initial;
    right: 2rem;
  }
`

export const LightBoxCenteredPopup = css`
  margin: 4rem auto 0;
  position: relative;  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 65rem;
    width: 100%;
  }
`

export const LightBoxSM = css`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 56.8rem;
  }
`

export const LightBoxMD = css`
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 103.8rem;
  }
`

export const CyberMondayPopup = css`
  ${LightBoxCenteredPopup};
  background-color: rgb(2 20 70);
  margin: 4rem auto 0;
  background-image: url('https://media.swansonvitamins.com/images/asset/homepage/banner/CM-Pop-Up-BG-750px.jpg');
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 750px 75%;
`
