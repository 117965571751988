import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../_inputs/Button/Button'

export const HeaderGreenBannerEchoContent = ({
  toggleDropdown = () => {},
  show = false,
  styles
}) => {
  const [imgURL, setImgURL] = useState('')

  useEffect(() => {
    if (window) {
      setImgURL(window?.promoInfo?.imagePath)
    }
  }, [imgURL])

  useEffect(() => {
    if (window && imgURL) {
      if (imgURL.indexOf('.jpg') > -1 || imgURL.indexOf('.png') > -1) {
        setImgURL([imgURL.slice(0, imgURL.length - 4), '_m', imgURL.slice(imgURL.length - 4)].join(''))
      }
    }
  }, [])

  return (
    <>
      {window && show &&
        <HeaderGreenBannerEchoContentDiv styles={styles} data-testid='green-banner-echo-content'>
          <HeaderGreenBannerEchoContentA
            role='complementary'
            data-tracking='header|echo-content'
            aria-labelledby='promotion'
            href={window.promoInfo.linkURL}
          >
            <img
              src={`https://media.swansonvitamins.com/images/asset${imgURL}`}
              alt={window.promoInfo.header}
            />
          </HeaderGreenBannerEchoContentA>
          <HeaderGreenBannerEchoContentCloseBtn
            data-tracking='header|echo-close-x'
            onClick={toggleDropdown}
          >
            <span>&times;</span>
          </HeaderGreenBannerEchoContentCloseBtn>
        </HeaderGreenBannerEchoContentDiv>
    }
    </>
  )
}

const HeaderGreenBannerEchoContentDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 3.6rem;
  background-color: ${props => props.theme.white};
  ${props => props.styles};
`

const HeaderGreenBannerEchoContentCloseBtn = styled(Button)`
  position: absolute;
  border: none;
  right: 0.5rem;
  top: 0;
  min-width: 0;
  background-color: transparent;
  font-size: 2.8rem;
  color: ${props => props.theme.black};
  &:focus {
    border: 1px solid ${props => props.theme.black};
    box-shadow: none;
  }
  @media (max-width: 765px) {
    top: 0.4rem;
  }
`

const HeaderGreenBannerEchoContentA = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;
  
  img {
    max-width: 100rem;
    width: 100%;
  }
`
