import {
  CONTINUED_PROBLEM_ERROR,
  INVALID_EMAIL_PASS_ERROR,
  RESET_EMAIL_PASS_ERROR,
  RESET_CONTINUED_PROB_ERROR
} from '../constants/loginConstants'

const loginReducer = (reduxState = {
  invalidEmailPassError: false,
  continuedProblemError: false
}, action) => {
  switch (action.type) {
    case INVALID_EMAIL_PASS_ERROR:
      return { ...reduxState, invalidEmailPassError: true}
    case CONTINUED_PROBLEM_ERROR:
      return { ...reduxState, continuedProblemError: true}
    case RESET_EMAIL_PASS_ERROR:
      return { ...reduxState, invalidEmailPassError: false}
    case RESET_CONTINUED_PROB_ERROR:
      return { ...reduxState, continuedProblemError: false}
    default:
      return reduxState
  }
}

export default loginReducer
