import {
  SHARED_LOCK_SCROLL,
  SHARED_CHECK_SMS,
  SHARED_SET_INSTANCE,
  SHARED_CHECK_EMAIL,
  UPDATE_OOS_MESSAGE,
  UPDATE_OOS_SUBMITTED,
  UPDATE_OOS_EMAIL_SHOW
} from '../constants/sharedConstants'
import axios from "axios"
import { showNotificationWithTimeout } from "./notificationActions"
import { utagLink } from "../tealium"
import { CHECKOUT_NEXT_STEP } from '../constants/checkoutConstants'
import { checkoutAlwaysRunAction } from './checkoutActions/checkoutActions'
import {
  CONTINUED_PROBLEM_ERROR,
  INVALID_EMAIL_PASS_ERROR,
} from '../constants/loginConstants'
export const lockScrollAction = payload => (
  ({ type: SHARED_LOCK_SCROLL, payload: payload })
)

export const sharedSmsSuccessAction = payload => {
  return (dispatch, reduxStore) => {
    dispatch({ type: SHARED_CHECK_SMS, payload: payload })
  }
}

export const sharedEmailSuccessAction = (payload) => {
  return (dispatch, reduxStore) => {
    dispatch({ type: SHARED_CHECK_EMAIL, payload: payload.response })
  }
}

/* payload should be in the form of:
{
  name: 'instanceName',
  data: {...} // data to be used by the instance.
}
*/
export const sharedSetInstanceAction = payload => (
  { type: SHARED_SET_INSTANCE, payload: payload }
)

export const sharedSMSSuccessAction = (payload) => {
  return (dispatch) => {
    dispatch({ type: SHARED_CHECK_SMS, payload: payload })
  }
}

export const submitOOSAction = (data, captchaToken) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: (`${window.location.origin}/ajax/emailwhenavailable.do`),
      data: data,
      params: captchaToken ? {'g-recaptcha-response': captchaToken} : null,
    }).then((response) => {
      if (!response.data.success) {
        dispatch({type: UPDATE_OOS_MESSAGE, payload: response.data?.emailMessage ? response.data.emailMessage : (response.data?.message ? response.data.message : '')})
      } else {
        if (data.oosEmailSignup && response.data?.emailMessage) {
          utagLink({
            'submit_location': 'pdp|outOfStock',
            'email_status': response.data.emailMessage === 'error' ? 'Existing' : 'New',
            'tealium_event': 'email-signup-submit'
          })
          dispatch({type: UPDATE_OOS_EMAIL_SHOW, payload: true})
          dispatch({type: SHARED_CHECK_EMAIL, payload: response.data.emailMessage})
        }
        dispatch({type: UPDATE_OOS_SUBMITTED, payload: true})
      }
    }).catch(() => {
      dispatch(showNotificationWithTimeout({
        time: 5000,
        background: 'warn',
        notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
        active: true
      }))
    })
  }
}

export const loginSuccessAction = data => {
  const loginLocation = window.location.pathname
  return function (dispatch, reduxState) {
    const state = reduxState()
    if (loginLocation.match(/order/i)) {
      dispatch(checkoutAlwaysRunAction(data))
    }
    if (!data.auth && data.messages.form[0] === 'Invalid username or password') {
      if (state.login.invalidEmailPassError) {
        dispatch({ type: CONTINUED_PROBLEM_ERROR })
      } else {
        dispatch({ type: INVALID_EMAIL_PASS_ERROR })
      }
    }
    if (data.auth) {
      data.stateId = 'backToLoggedInEvent'
      if (loginLocation.match(/order/i)) {
        dispatch({ type: CHECKOUT_NEXT_STEP, payload: data })
      }
      if (loginLocation.includes('Thank')) {
        window.location = '/myaccount'
      } else {
        window.location = loginLocation
      }
    }
  }
}
