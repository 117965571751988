import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const PlusIcon = ({
  isMinus,
  strokeColor = '#000',
  classes,
  styles
}) => {
  return (
    <PlusIconSVG
      styles={styles}
      viewBox='0 0 10 10'
      className={classes}
    >
      <title>{isMinus ? 'minus icon' : 'plus icon'}</title>
      <PlusIconLine
        className={`${isMinus ? 'PlusIcon--active' : ''}`}
        transform={isMinus ? 'rotate(90)' : ''}
        stroke={strokeColor}
        strokeWidth='2px' x1='5' x2='5' y2='10'
      />
      <line stroke={strokeColor} strokeWidth='2px' x1='10' y1='5' y2='5' />
    </PlusIconSVG>
  )
}

const PlusIconSVG = styled(SvgAttr)`
  height: 12px;
  width: 12px;
  ${props => props.styles};
`
const PlusIconLine = styled.line`
 &.PlusIcon--active{
  transform: ${props => props.transform};
  position: absolute;
  transition: transform .5s;
  transform-origin: center;
 }
`
