import React from 'react'
import { SignUpForm } from '../../_forms/SignUpForm/SignUpForm'
import styled, { css } from 'styled-components'
import { Form } from '../../_forms/Form/Form'
import { LightBox } from '../../LightBox/LightBox'
import { CyberMondayPopup, LightBoxCenteredPopup } from '../../_styled/LightBoxOptions'
import { CyberMondaySignUpForm } from '../../_forms/CyberMondaySignUpForm/CyberMondaySignUpForm'
import { TabbedContainer } from '../../TabbedContainer/TabbedContainer'
import { MailIcon } from '../../_svgs/MailIcon/MailIcon'
import { SMSIcon } from '../../_svgs/SMSIcon/SMSIcon'
import { SMSSignUpForm } from '../../_forms/SMSSignUpForm/SMSSignUpForm'

export const SignUpModal = ({
  defaultTab = 0,
  closeHandler,
  formLocation,
  closeHandlerAfter = false
}) => {
  return (
    <LightBox
      show styles={window.isCyberMondayWindow ? CyberMondayPopup : LightBoxCenteredPopup}
      closeHandler={closeHandler}
      xStyles={window.isCyberMondayWindow ? css`color: ${props => props.theme.white};` : css`color: ${props => props.theme.black}`}
      closeHandlerAfter={closeHandlerAfter}
    >
      <TabbedContainer
        showTabs={formLocation === 'footer-signup'}
        showActiveTab={false}
        isTabsLocatedOnTop={false}
        tabsDataTarget={['email-submit-trck', 'sms-submit-track']}
        tabs={[
          <>
            <SMSEmailTab>
              <IconDiv>
                <MailIcon width={28} height={28} color='#FFF' />
              </IconDiv>
              <UnlockSMSEmail>Unlock Email Alerts & Deals</UnlockSMSEmail>
            </SMSEmailTab>
          </>,
          <>
            <SMSEmailTab>
              <IconDiv>
                <SMSIcon width={29} height={29} black='#FFF' white='#000' />
              </IconDiv>
              <UnlockSMSEmail>Unlock SMS Text Alerts & Deals</UnlockSMSEmail>
            </SMSEmailTab>
          </>
        ]}
        views={[
          <Form WrappedForm={window.isCyberMondayWindow ? CyberMondaySignUpForm : SignUpForm} formClass='email-submit-trck' formLocation={formLocation} closeHandler={closeHandler} />,
          <Form WrappedForm={SMSSignUpForm} formClass='sms-submit-track' formLocation={formLocation} />
        ]}
        defaultTab={defaultTab}
      />
    </LightBox>
  )
}
const SMSEmailTab = styled.div`
  display: flex;
`
const IconDiv = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
`
const UnlockSMSEmail = styled.div`
  line-height: 3.6rem;
  display: flex;
  align-items: center;
`
