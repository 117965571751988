import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const Cart = ({
  width = '31',
  height = '30',
  circleFill = '#fff',
  pathFill = '#2F711C'
}) => {
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 31 30'>
      <title>Go to Cart</title>
      <Circle
        cx='15.8'
        cy='15'
        r='15'
        fill={circleFill}
      />
      <Path
        d='M20.2535 17.2C21.1532 17.2 21.9449 16.708 22.3528 15.964L26.6472 8.176C27.091 7.384 26.5152 6.4 25.6036 6.4H7.85014L6.72256
        4H6.80005L3.80005 6L5.19913 6.4L9.51753 15.508L7.89813 18.436C7.02245 20.044 8.17402 22 9.99735 22H24.392V19.6H9.99735L11.3169
        17.2H20.2535ZM8.98972 8.8H23.5643L20.2535 14.8H11.8327L8.98972 8.8ZM9.99735 23.2C8.67784 23.2 7.61023 24.28 7.61023 25.6C7.61023
        26.92 8.67784 28 9.99735 28C11.3169 28 12.3965 26.92 12.3965 25.6C12.3965 24.28 11.3169 23.2 9.99735 23.2ZM21.9929 23.2C20.6734
        23.2 19.6058 24.28 19.6058 25.6C19.6058 26.92 20.6734 28 21.9929 28C23.3124 28 24.392 26.92 24.392 25.6C24.392 24.28 23.3124 23.2
        21.9929 23.2Z'
        fill={pathFill}
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  fill: none;
`

const Circle = styled.circle`
  fill: ${props => props.fill};
`

const Path = styled.path`
  fill: ${props => props.fill};
`