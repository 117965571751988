import React from 'react'
import styled from 'styled-components'

export const Headline = ({ children, width, styles, as, ...props }) => (
  <HeadlineBackground>
    <HeadlineText {...props} as={as} styles={styles} width={width}>{children}</HeadlineText>
  </HeadlineBackground>
)

const HeadlineText = styled.h2`
  ${props => props.css};
  position: absolute;
  background-color: ${props => props.theme.white};
  font-size: 2.2rem;
  font-weight: normal;
  letter-spacing: .1rem;
  top: 90%;
  left: 50%;
  padding: 1rem;
  margin: 0;
  width: ${props => props.width ? props.width : 'auto'};
  transform: translate(-50%,-50%);
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    text-align: center;
  }
  @media (min-width:1024px) and (max-width:1366px) {
    text-align: center;
  }
`

const HeadlineBackground = styled.div`
  border-bottom: ${props => props.theme.primary} solid .1rem;
  position: relative;
  height: 3rem;
  margin: 2rem 2rem 2.5rem;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    border: 0;
  }
`
