import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const MagnifyingGlass = ({
  width = '2.4rem',
  height = '2.4rem',
  stroke = '#fff',
  classes
}) => {
  return (
    <SvgAttr className={classes} x='0px' y='0px' width={width} height={height} viewBox='0 0 14 14'>
      <title>search</title>
      <circle stroke={stroke} fill='none' cx='5' cy='5' r='4.5' />
      <line stroke={stroke} x1='8.5' y1='8.5' x2='11.5' y2='11.5' />
    </SvgAttr>
  )
}
