import React, { useEffect, useState } from 'react'
import { CategoryMenuContent } from '../CategoryMenuContent/CategoryMenuContent'
import styled, { css } from 'styled-components'
import { HeaderDropDown } from '../HeaderDropDown/HeaderDropDown'
import { useDispatch, useSelector } from 'react-redux'
import { Overlay } from '../../Overlay/Overlay'
import axios from 'axios'
import { setHeaderDropdown } from '../../../actions/headerActions'

export const CategoryNav = (props) => {
  const [activeState, setActiveState] = useState(-1)
  const [globalState, setGlobalState] = useState(window.globalState)
  const isOpen = useSelector(store => store.header.dropdownOpen)
  const [previousFocus, setPreviousFocus] = useState()
  const [isHovered, setIsHovered] = useState(false)
  const [pendingState, setPendingState] = useState(-1)
  const [pendingFocus, setPendingFocus] = useState(null)
  const dispatch = useDispatch()

  const ulLeaveHandler = () => {
    setIsHovered(false)
    setPendingState(-1)
    setPendingFocus(null)
    setActiveState(-1)
    dispatch(setHeaderDropdown(false))
    if (previousFocus) {
      previousFocus.focus()
    }
  }

  const ilEnterHandler = (e, index) => {
    setIsHovered(true)
    if (!isOpen) {
      setPendingState(index)
      setPendingFocus(e.target)
    } else {
      setActiveState(index)
      setPreviousFocus(e.target)
    }
  }

  const onTimeout = () => {
    if (!isOpen) {
      setActiveState(pendingState)
      setPreviousFocus(pendingFocus)
      dispatch(setHeaderDropdown(true))
      if (props.cartBlur) {
        props.cartBlur()
      }
    }
  }

  useEffect(() => {
    const timer = isHovered && setTimeout(onTimeout, 500)
    return () => {
      clearTimeout(timer)
    }
  },[isHovered, pendingState])

  useEffect(() => {
    if (!window.globalState) {
      axios.get('/headerlinks.json')
        .then((response) => {
          setGlobalState(response.data)
        })
    }
  }, [globalState])

  const mouseLeaveHandler = () => {
    dispatch(setHeaderDropdown(false))
    if (previousFocus) {
      previousFocus.focus()
    }
  }

  const shopBlur = () => {
    dispatch(setHeaderDropdown(false))
  }

  const megaMenuChange = (e, index) => {
    setActiveState(index)
    setPreviousFocus(e.target)
    dispatch(setHeaderDropdown(true))
    if (props.cartBlur) {
      props.cartBlur()
    }
    e.preventDefault()
  }

  const innerMenuState = useSelector(state => state.header.innerMenuOpen)

  return (
    <HeaderLower id={props.topId} data-site-area='top-navigation'>
      <HeaderInnerLimitLower
        role='navigation'
        aria-label='Main menu'
      >
        <HeaderLowerNavUl
          onFocus={props.cartBlur}
          onMouseLeave={ulLeaveHandler}
        >
          {globalState && globalState?.categoryResultList.map((list, index) => (
            <HeaderLowerNavLi
              key={`headerLower${index}`}
              onMouseEnter={(e) => { ilEnterHandler(e, index) }}
            >
              <CategoryNavBtn
                styles={css`
                  color: ${props => props.theme.black} !important;
                `
                }
                aria-haspopup='true'
                aria-expanded={index === activeState}
                onClick={(e) => (window.innerWidth < 1024 ? e.preventDefault() : megaMenuChange(e, index))}
                className={`${list.titleDisplay ? `cTest_header-${list.titleDisplay.replace(/[^A-Z]/g, '')} ` : ''}${index === activeState ? 'dropdown-active' : ''}`}
              >
                {list.titleDisplay}
              </CategoryNavBtn>
            </HeaderLowerNavLi>
          ))}
          <HeaderDropDown
            tag='li'
            classes={`${isOpen ? 'activeMenu' : 'none'} ${innerMenuState !== 'none' ? 'overflow-visible' : ''} cTest_header-${activeState > -1 ? globalState.categoryResultList[activeState].title.replace(/[^A-Z]/g, '') : ''}-dropdown`}
            styles={css`
              &.activeMenu {
                border: 0.1rem solid ${props => props.theme.platinumGrey};
              }
              @media (min-width: 1024px) {
                &.activeMenu {
                  display: flex;
                  justify-content: center;
                  height: auto;
                  max-width: initial;
                  min-height: 51.6rem;
                }
              }
              @media (min-width: 768px) and (max-width: 1023px) {
                &.overflow-visible {
                  overflow-y: scroll;
                }
                &.activeMenu {
                  top: 4.8rem;
                }
              } 
            `}
          >
            <CategoryMenuContent
              category={activeState}
              isOpen={isOpen}
              shopMenuBlur={shopBlur}
              closeHandler={mouseLeaveHandler}
              additionalATCCode={props.additionalATCCode}
              globalState={globalState}
            />
          </HeaderDropDown>
        </HeaderLowerNavUl>

        <Overlay isVisible={isOpen} fromTop='4.9rem' position='absolute' zIndex={85}/>
      </HeaderInnerLimitLower>
    </HeaderLower>
  )
}

const HeaderLower = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 0.1rem solid ${props => props.theme.darkerLightGrey};
  padding: 0;
  position: relative;
  width: 100%;
  @media (max-width: 1279px) {
    display: none;
  }
`
const HeaderInnerLimitLower = styled.div`
  width: 100%;
  max-width: 1280px;
  justify-content: center;
  display: flex;
  margin-top: 0.8rem;
  padding: 0;
`
const HeaderLowerNavUl = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding-left: 0;
  max-width: 128rem;
`

const CategoryNavBtn = styled.button`
  min-width: auto !important;
  background-color: transparent;
  text-align: center;
  white-space: normal !important;
  max-height: initial;
  text-decoration: none;
  line-height: 1.5rem;
  border: none !important;
  font-size: 1.7rem !important;
  padding: 0 20px !important;
  transition: none !important;
  min-height: 40px !important;
  &:hover, &.dropdown-active {
    border-radius: 4px 4px 0 0;
    background-color: ${props => props.theme.buttercupYellow};
    transition: none;
  }
  ${props => props.styles};
`

const HeaderLowerNavLi = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  color: #2F711C;
`
