import React, {useRef} from 'react'
import styled, {css} from 'styled-components'
import { Field } from '../../_inputs/Field/Field'
import { CTAButton } from '../../_inputs/Button/Button'
import { useSelector } from 'react-redux'
import { sharedEmailSuccessAction } from '../../../actions/sharedActions'
import { utagLink } from '../../../tealium'
import { VisuallyHiddenStyles } from '../../_styled/BootStrapGrid'
import Reaptcha from 'reaptcha'

export const SignUpForm = (props) => {
  const reCaptchaRef = useRef()
  const formData = {
    form: 'signUp',
    method: 'post',
    action: '/emailsignup.json',
    successAction: sharedEmailSuccessAction
  }

  const getState = fieldObj => {
    fieldObj.form = formData.form
    props.updateForm(fieldObj)
  }

  const captchaOnVerify = recaptchaResponse => {
    props.updateForm({
      form: formData.form,
      name: 'g-recaptcha-response',
      value: recaptchaResponse
    })
    props.updateForm({
      form: formData.form,
      name: 'emailConfirm',
      value: '' // this must match email
    })
    props.updateForm({
      form: formData.form,
      name: 'offer',
      value: '9'
    })
    props.updateForm({
      form: formData.form,
      name: '_offer',
      value: 'on'
    })
    props.updateForm({
      form: formData.form,
      name: 'recipe',
      value: 'y'
    })
    props.updateForm({
      form: formData.form,
      name: '_recipe',
      value: 'on'
    })
    props.updateForm({
      form: formData.form,
      name: 'eNewsletter',
      value: 'y'
    })
    props.updateForm({
      form: formData.form,
      name: '_eNewsletter',
      value: 'on'
    })
    props.updateForm({
      form: formData.form,
      name: 'updates',
      value: 'y'
    })
    props.updateForm({
      form: formData.form,
      name: '_updates',
      value: 'on'
    })
    props.updateForm({
      form: formData.form,
      name: 'submit',
      value: 'subscribe'
    })
    document.body.style.overflow = 'auto'
    props.submitForm(formData)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    reCaptchaRef.current.execute()
  }

  const checkEmail = useSelector(state => state.shared.sharedCheckEmail)

  if (checkEmail) {
    utagLink({
      'submit_location' : props.formLocation,
      'email_status' : checkEmail === 'error' ? 'Existing' : 'New',
      'tealium_event' : 'email-signup-submit'
    })
  }

  return (
    <SignUpFormMain className={`${props.classes} ${!checkEmail ? 'email-signup-popup' : ''}`}>
      <SignUpFormContent className={props.classes}>
        <SignUpHeaderFooter>
          {!checkEmail
            ? <>
              <SignUpFormHeader className={props.classes}>
                <SignUpContent>
                  <OFF>20% OFF</OFF>
                  <LineThrough><Plus>Plus</Plus></LineThrough>
                  <Shipping>Free shipping</Shipping>
                  <Order>on today's order</Order>
                  <BestDeals>Get our best deals & news sent straight to your inbox!</BestDeals>
                </SignUpContent>
              </SignUpFormHeader>
              <FormStyled onSubmit={submitHandler} className={`${props.classes} ${props.formClass} ${props.formLocation}`}>
                <Reaptcha
                  ref={reCaptchaRef}
                  sitekey={window.reCaptchaKey}
                  onVerify={captchaOnVerify}
                  size='invisible'
                  />
                <Fieldset>
                  <Legend>Email Details</Legend>
                  <SignUpFormField>
                    {props.errorStates?.form !== null &&
                      <div aria-live='assertive' role='alert' className='text-red'>{props.errorStates?.form}</div>
                    }
                    <Field
                      name='email'
                      classes='SignUpForm__Field'
                      id={`signup-${props.ariaLocation}`}
                      placeholder='Enter your email address'
                      returnState={getState}
                      aria-label='Enter email address'
                      error={props.errorStates}
                      addRef={props.addToRefsArray}
                      labelText='Enter your email address'
                      labelStyles={css`${VisuallyHiddenStyles}`}
                    />
                  </SignUpFormField>
                  <CTAButton type='submit' aria-label='send email address'>Sign Up</CTAButton>
                </Fieldset>
              </FormStyled>
              <SignUpFormFooter>
                <NoThanks>
                  <NoThanksBtn onClick={props.closeHandler}>No Thanks</NoThanksBtn>
                </NoThanks>
                <GrayText>Offer good for first-time registrants only.</GrayText>
                <GrayText>Free standard shipping applies to orders $49+.</GrayText>
                <GrayText>Not valid on sale items. Exclusions apply.</GrayText>
              </SignUpFormFooter>
          </>
            : checkEmail === 'error'
              ? <SubmitResponse id='emailAlreadyReact' data-target={props.formLocation}>
                <ResponseHead><strong>Thank you!</strong> This email address is <strong>already</strong> signed up.</ResponseHead>
                <ResponseP>Thank you for your interest and be assured that you will continue to receive our emails at this address.</ResponseP>
              </SubmitResponse>
              : checkEmail === 'success'
                ? <SubmitResponse id='emailConfirmSuccessReact' data-target={props.formLocation}>
                  <ResponseHead>Your first offer is on the way!</ResponseHead>
                  <ResponseP>You're now signed up to receive exclusive deals and savings opportunities!</ResponseP>
                </SubmitResponse>
                : <SubmitResponse id='OopsErrorReact' data-target={props.formLocation}>
                  <ResponseHead><strong>Oops!</strong></ResponseHead>
                  <ResponseP>We are <strong>having trouble</strong> processing your request. Our apologies! Please <strong>try again</strong> later.</ResponseP>
                </SubmitResponse>
          }
        </SignUpHeaderFooter>
      </SignUpFormContent>
    </SignUpFormMain>

  )
}

const LineThrough = styled.div`
  position: relative;
  z-index: 1;
  font-size: 3.8rem;
  text-align: center;
  &:before{
    border-top: 2px solid ${props => props.theme.darkGrey};
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 85%;
    z-index: -1;
  }
`

const Legend = styled.legend`
  ${VisuallyHiddenStyles};
`

const Plus = styled.span`
  background: ${props => props.theme.white};
  padding: 0 1rem;
  text-transform: uppercase;
  color: ${props => props.theme.black};
`

const OFF = styled.div`
  font-size: 7rem;
  line-height: 8rem;
  color:${props => props.theme.primary};
  font-weight: bold;
  text-align: center;
  @media(max-width: 766px){
     font-size: 5.83rem;
     line-height: 6.5rem;
  }
`
const Shipping = styled.div`
  font-size: 5rem;
  color: ${props => props.theme.primary};
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 6rem;
  @media (max-width: 766px){
    font-size: 3.55rem;
    line-height: 4.5rem;
  }
`

const Order = styled.div`
  font-size: 3.8rem;
  line-height: 4.5rem;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.black};
    @media (max-width: 766px){
      font-size: 3rem; 
      line-height: 4rem; 
    }

`
const BestDeals = styled.div`
  font-size: 1.7rem;
  line-height: 2.7rem;
  font-weight: normal;
  text-align: center;
  color: ${props => props.theme.black};
  @media (max-width: 766px){
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
`
const NoThanks = styled.div`
  display: flex;
  justify-content: flex-end;
`
const NoThanksBtn = styled.button`
  background: transparent;
  color: ${props => props.theme.primary};
  text-decoration: underline;
  border: none;
  font-size: 1.4rem;
`

const GrayText = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  color: ${props => props.theme.darkGrey};
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.5rem;
  @media (max-width: 766px){
   font-size: 1.1rem;
  }
`

const SubmitResponse = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  color: ${props => props.theme.darkGrey};
  text-align: center;
  font-size: 1.3rem;
  line-height: 2.5rem
`
const SignUpHeaderFooter = styled.div`
  padding: 1rem;
`
const SignUpContent = styled.div`
 line-height: 4rem;
`

const SignUpFormMain = styled.div` 
  flex: 0 0 100%;
     margin: 0 auto;
     padding-top: 1rem;
     min-height:40rem;
     max-width: 65rem;
     @media(min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}){
       padding-bottom: 1rem;
       max-width: 90%;
     }
`
const SignUpFormContent = styled.div`
   display: inline-block;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin: 0;
   width: 100%;
   @media(min-width: ${props => props.theme.breakpoints.sm}){
    flex-direction: row;
   }
   @media (min-width: ${props => props.theme.breakpoints.lg}){
    justify-content: flex-start;
    &.modalSignUp{
      justify-content: center;
    }
  }
`
const FormStyled = styled.form`
  width: 100%;
`

const Fieldset = styled.fieldset`
    width: 100%;
    border: 0;
    margin-left: 1rem;
    display: flex;
    align-items: flex-end;
    @media(max-width: 767px){
      width: 97%;
      margin: 0 0.1rem;
    }
    @media(min-width: 1200px){
      width: 95%;
    }
    &.modalSignUp{
      @media(min-width: 1200px) {
        width: 85%;
      }
    }
`
const SignUpFormHeader = styled.div`
     width: 100%;
     text-align: center;
     font-size: 2.2rem;
     line-height: 3rem;
     font-weight: bold;
     margin-bottom: 0;
     @media(min-width: 992px){
        text-align: left;
     }
     @media(min-width:768px) and (max-width: 1400px){
       font-size: 1.6rem;
     }
      @media(max-width: 768px){
       font-size: 1.6rem;
       width: 100%;
     }
     &.modalSignUp{
       display: none;
     }

`

const SignUpFormFooter = styled.div`
     width: 100%;
     text-align: center;
     font-size: 2.2rem;
     font-weight: bold;
     margin-bottom: 0;
     @media(min-width: 992px){
        text-align: left;
     }
     @media(min-width:768px) and (max-width: 1400px){
       font-size: 1.6rem;
     }
      @media(max-width: 768px){
       font-size: 1.6rem;
       width: 100%;
     }
     &.modalSignUp{
       display: none;
     }

`
const SignUpFormField = styled.div`
  width: 80%;
  margin-right: 1.2rem;
`

const ResponseHead = styled.h3`
 margin: 6rem 0 2rem 0;
 color: ${props => props.theme.primary};
 font-size: 4rem;
 line-height: 4.5rem;
  @media (max-width: 766px){
      margin: 2rem 0 0 0;  
      font-size: 3.5rem;
      line-height: 4.1rem;
  }
`
const ResponseP = styled.p`
 font-size: 1.8rem;
 line-height: 2.5rem;
`
