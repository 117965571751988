import axios from '../../../../node_modules/axios/index.js'

let api = axios.create()

const refreshCsrfToken = async () => {
  const csrf = await api.get('/get-csrf-for-session')
    .then(response => response.data.token)
    .catch(() => {
      // eslint-disable-next-line no-undef, no-restricted-globals
      if (confirm('Your session has expired. Please reload the page to continue.')) {
        window.location.reload()
      }
    })
  sessionStorage.setItem('csrf', csrf)
  return csrf
}
api.interceptors.request.use((config) => {
  try {
    if (['swansonvitamins', 'localhost'].includes(config?.url)) {
      config.headers['X-Csrf-Token'] = sessionStorage.getItem('csrf')
    }
  } catch (error) {
    console.error('axios:interceptors', error)
  }
  return config
})
api.interceptors.response.use((response) => response, async (error) => {
  // CSRF token is invalid, refresh
  if (error?.response?.status === 403) {
    error.config.headers['X-Csrf-Token'] = await refreshCsrfToken()
    // Retry request bypassing interceptors (to avoid infinite loop)
    return axios.request(error.config)
  }
  console.error('axios:interceptors', error)
  return Promise.reject(error)
})
export default api
