import React from 'react'
import styled from 'styled-components'

import { Image } from '../../Image/Image'

export const SearchImage = (props) => (
  <StyledImage
    src={props.src}
    ref={thisRef => props.setRef(thisRef)}
    onLoad={props.onLoad}
    onError={props.onError}
    alt={props.alt}
    height='150'
    width='150'
  />
)

const StyledImage = styled(Image)`
  display: block;
  width: ${props => props.width ? props.width : '15rem'};
  @keyframes loaded {
    0% {
      opacity: 0.1
    }
    
    100% {
      opacity: 1
    }
  }
  
  &.loaded:not(.has-error) {
    animation: loaded 300ms ease-in-out;
  }
  &.has-error {
    content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=')
  }
  @media (max-width: 360px) {
    width: 100px;
  }
`
