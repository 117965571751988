import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export const AnimateFromNone = ({
  children,
  visible = false,
  styles,
  width = true
}) => {
  const [animate, setAnimate] = useState(false)
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    } else {
      setAnimate(false)
    }
  }, [visible])

  return (
    <AnimateDiv width={width} styles={styles} visible={visible} className={visible ? 'item-active' : ''} animate={animate}>
      {children}
    </AnimateDiv>
  )
}

const AnimateDiv = styled.div`
  display: none;
  transition: all .6s linear;
  opacity: 0;
  ${props => props.width && `
    width: 100%;
  `}
  ${props => props.visible && `
    display: flex;
  `}
  ${props => props.animate && `
    opacity: 1;
  `}
  ${props => props.styles};
`
