import styled, { css } from 'styled-components'
import React, {useEffect, useState} from 'react'
import { FeedbackIcon } from '../../_svgs/FeedbackIcon/FeedbackIcon'
import { HeaderMailIcon } from '../../_svgs/HeaderMailIcon/HeaderMailIcon'
import { ButtonThatLookLikeLink, TransparentButton } from '../../_inputs/Button/Button'
import { utagLink } from '../../../tealium'
import { ControlPoint } from '../../_svgs/ControlPoint/ControlPoint'

export const HelpDeskDropdown = ({
  closeHandler = () => {}
}) => {
  const [isCallToggle, setIsCallToggle] = useState(true)

  const toggleTextCall = () => {
    setIsCallToggle(!isCallToggle)
  }

  const displayHours = () => {
    if (window.showThanksgivingHours) {
      return (
        <>
          November 23rd &mdash; closed<br />
          November 24th &mdash; 7am - 12am CST<br />
          November 25th &mdash; 7am - 12am CST<br />
          November 26th &mdash; 9am - 9pm CST<br />
          November 27th &mdash; 7am - 12am CST<br />
          November 28th &mdash; 7am - 12am CST
        </>
      )
    } else if (window.showChristmasHours) {
      return (
        <>
          December 24th &mdash; closed<br/>
          December 25th &mdash; closed<br />
          December 26th - 30th &mdash; 7am - 12am CST<br />
        </>
      )
    } else if (window.showNewYearsHours) {
      return (
        <>
          December 31st &mdash; closed<br/>
          January 1st &mdash; 9am - 9pm CST<br />
          January 2nd &mdash; 7am - 12am CST<br />
        </>
      )
    }
    return (
      <>
        <HelpDeskTextDiv styles={css`color: #545454;`}>Monday&nbsp;&mdash;&nbsp;Saturday&nbsp;7:00&nbsp;am - 12am&nbsp;CST</HelpDeskTextDiv>
      </>
    )
  }

  useEffect(() => {
    utagLink({
      'tealium_event': 'medallia_feedback_load'
    })
  }, [])

  const fireFeedback = () => {
    if (closeHandler) {
      closeHandler()
    }
    utagLink({
      'tealium_event': 'medallia_feedback_trigger'
    })
  }
  
  return (
    <HelpDeskDropdownContainer data-testid='help-desk-dropdown'>
      <HelpDeskHeaderDiv>Help & Support</HelpDeskHeaderDiv>
      <HelpDeskTextDiv styles={css`margin: 1.2rem 0;`}>Contact a Customer Support Representative</HelpDeskTextDiv>
      <HelpDeskADiv>
        <HelpDeskAContainer>
          <HelpDeskBtn
            data-tracking='header|help-dropdown|feedback'
            onClick={() => { fireFeedback() }}
          >
            <FeedbackIcon/>&nbsp;Website Feedback
          </HelpDeskBtn>
          <HelpDeskA
            data-tracking='header|help-dropdown|email'
            href='mailto:customercare@swansonsupport.com'
          >
            <HeaderMailIcon fill='#2F711C'/>&nbsp;Email
          </HelpDeskA>
        </HelpDeskAContainer>
      </HelpDeskADiv>
      <HelpDeskTextDiv styles={css`color: #545454; margin: 1.2rem 0 1.6rem 0; @media(max-width: 1279px) { display: none; }`}>
        Call or Text&nbsp;
        <HelpDeskA
          href='tel:1-800-824-4491'
          aria-label='call 1-800-824-4491'
        >
          1-800-824-4491
        </HelpDeskA>
      </HelpDeskTextDiv>
      <HelpDeskCallTextToggleDiv>
        <HelpDeskTextBtn onClick={toggleTextCall}>
          <HelpDeskToggle>
            <HelpDeskGreenCircle/>
          </HelpDeskToggle>
          <HelpDeskToggleText className={isCallToggle ? 'active' : ''}>Call</HelpDeskToggleText>
          <HelpDeskToggleText className={!isCallToggle ? 'active' : ''}>Text</HelpDeskToggleText>
        </HelpDeskTextBtn>
        <HelpDeskA
          styles={css`margin-left: 1rem;`}
          href={isCallToggle ? 'tel:1-800-824-4491' : 'sms:1-800-824-4491'}
          data-tracking={`header|help-dropdown|${isCallToggle ? 'call' : 'text'}`}
          aria-label={`${isCallToggle ? 'call' : 'text'} 1-800-824-4491`}
        >
          1-800-824-4491
        </HelpDeskA>
      </HelpDeskCallTextToggleDiv>
      <HelpDeskTextDiv styles={css`color: #545454;`}>
        {displayHours()}
      </HelpDeskTextDiv>
        <nav aria-label="breadcrumb">
      <HelpDeskContactUsDiv>
        <HelpDeskContactUsA
          href='/help/company-information/company-information-faqs/contact-us.html'
          aria-label='contact us'
          aria-current='page'
          data-tracking='header|help-dropdown|contact-us'
        >
          <ControlPoint/> Additional Ways to Contact Us
        </HelpDeskContactUsA>
      </HelpDeskContactUsDiv>
        </nav>
    </HelpDeskDropdownContainer>
  )
}

const HelpDeskDropdownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 16px;
`

const HelpDeskHeaderDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 3.2rem;
  justify-content: center;
  color: #2F711C
`

const HelpDeskTextDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.black};
  ${props => props.styles};
`

const HelpDeskADiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const HelpDeskA = styled.a`
  color: #2F711C;
  text-decoration: none;
  margin-left: 3rem;
  &:hover {
    color: #2F711C !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
  ${props => props.styles};
`

const HelpDeskBtn = styled(ButtonThatLookLikeLink)`
  color: #2F711C;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const HelpDeskCallTextToggleDiv = styled.div`
  @media (min-width: 1280px) {
    display: none;
  }
  @media (max-width: 1279px) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  }
`

const HelpDeskToggle = styled.div`
  position: absolute;
  border: 1px solid ${props => props.theme.mediumGrey};
  border-radius: 24px;
  height: 32px;
  width: 100px;
`

const HelpDeskGreenCircle = styled.div`
  background-color: #2F711C;
  border-radius: 24px;
`

const HelpDeskTextBtn = styled(TransparentButton)`
  color: black;
  padding: 0;
  margin: 0;
  width: 100px;
  border: none;
`

const HelpDeskToggleText = styled.span`
  padding: 3px 11px;
  color: ${props => props.theme.black};
  border-radius: 24px;
  &.active {
    background-color: ${props => props.theme.secondary};
    color: ${props => props.theme.white};
  }
  ${props => props.styled};
`

const HelpDeskAContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 350px;
`

const HelpDeskContactUsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.6rem;
`

const HelpDeskContactUsA = styled.a`
  color: #2F711C;
  &:hover {
    color: #2F711C !important;
    text-decoration: underline !important;
  }
`