import React, { useEffect, useState, useRef } from 'react'
import { Overlay } from '../Overlay/Overlay'
import { FocusTrap } from '../FocusTrap/FocusTrap'
import styled, { css } from 'styled-components'
import { Button } from '../_inputs/Button/Button'

export const LightBoxDrawer = ({
  children,
  closeHandler,
  closeHandlerAfter = false,
  styles
}) => {
  const drawerRef = useRef()
  const [ start, setStart ] = useState(false)
  const [ end, setEnd ] = useState(false)

  useEffect(() => {
    setStart(true)
    return () => {
      setStart(false)
      setEnd(false)
    }
  }, [])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) accessibilityCloseHandler()
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [closeHandler])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) accessibilityCloseHandler()
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [closeHandler])

  const escHandler = (e) => {
    if (e.which === 27) {
      accessibilityCloseHandler()
    }
  }

  const accessibilityCloseHandler = () => {
    setEnd(true)
    setTimeout(() => {
      if (closeHandler) {
        closeHandler()
      }
    }, 500)
  }

  const lightBoxCloseHandler = (e) => {
    setEnd(true)
    setTimeout(() => {
      if (e.target.classList.contains('LightBox--close')) {
        if (closeHandler && !closeHandlerAfter) {
          closeHandler()
        }
        if (closeHandler && closeHandlerAfter) {
          closeHandler()
        }
      }
    }, 500)
  }

  return (
    <>
      <FocusTrap show >
        <Drawer
          role='dialog'
          start={start}
          end={end}
          styles={styles}
          animation={css`
            transform: translateX(${(start && !end) ? '0' : '100%'});
            transition: transform 0.5s ease-in-out;
          `} ref={drawerRef}>
          <X
            tabIndex={0}
            className='LightBox--close'
            data-testid='LightBoxDrawer__x'
            aria-label='Close'
            onKeyUp={escHandler}
            onClick={lightBoxCloseHandler}
            minWidth='auto'
          >
            <span onClick={lightBoxCloseHandler} className='LightBox--close LightBox--close-x'>&times;</span>
          </X>
          {children}
        </Drawer>
      </FocusTrap>
      <Overlay
        isVisible={start}
        fromTop={0}
        fromBottom='0'
        data-testid='LightBoxDrawer__overlay'
      />
    </>
  )
}

const X = styled(Button)`
  font-size: 2.8rem;
  background-color: transparent;
  color: ${props => props.theme.black};
  z-index: 90000000020;
  line-height: 1.3rem;
  position: absolute;
  right: 1rem;
  top: 0;
  cursor: pointer;
  border: none;
  ${props => props.styles};
  &:focus {
    border: 1px solid ${props => props.theme.black};
    box-shadow: none;
  }
`

const Drawer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${props => props.theme.white};
  height: 100%;
  box-shadow: 0 0.1rem 0.3rem ${props => props.theme.mediumGrey};
  width: 30.4rem;
  padding: 4.0rem 1rem 1rem;
  z-index: 19999999999999999999;
  ${props => props.styles};
  ${props => props.animation};
`
