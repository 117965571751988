import React from 'react'
import styled, { css } from 'styled-components'
import { LightBox } from '../../LightBox/LightBox'
import { LightBoxBottom } from '../../_styled/LightBoxOptions'
import { ShareIosIcon } from '../../_svgs/ShareIosIcon/ShareIosIcon'
import { PlusSignIOS } from '../../_svgs/PlusSignIOS/PlusSignIOS'
import { MdMax, MdMin } from '../../Responsive/Responsive'
import { MobileLogoIconOnly } from '../../_svgs/MobileLogoIconOnly/MobileLogoIconOnly'

export const HomeScreenModal = ({
  closeHandler
}) => {
  return (
    <LightBox styles={LightBoxBottom} show closeHandler={closeHandler}>
      <div>
        <Headline><MobileLogoIconOnly styles={LogoOnlyCss} /> Add Swanson.com to your home screen</Headline>
        <ListOl>
          <li>Tap <ShareIosIcon /> on the <><MdMax>bottom</MdMax><MdMin>top</MdMin></> of the screen.
            <>
              <MdMax>
                <Img src='https://media.swansonvitamins.com/images/common/site/pwa.1621521678004/iOsBarPhones.png' alt='iPhone Example' />
              </MdMax>
              <MdMin>
                <Img src='https://media.swansonvitamins.com/images/common/site/pwa.1621521678004/iOsBariPad.png' alt='iPad Example' />
              </MdMin>
            </>
          </li>
          <li>Tap <PlusSignIOS /> 'Add to Home Screen'.</li>
        </ListOl>
      </div>
    </LightBox>
  )
}

const Headline = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
`

const ListOl = styled.ol`
  padding-left: 2rem;
`

const Img = styled.img`
  max-width: 24rem;
  display: block;
`

const LogoOnlyCss = css`
  margin-right: 1rem;
`
