import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const HamburgerMenu = ({
  classes,
  width = '40',
  height = '40',
  fill = '#fff'
}) => {
  return (
    <Svg className={classes} x='0px' y='0px' width={width} height={height} viewBox='0 0 20 20'>
      <title>Menu Icon</title>
      <Path fill={fill} d='M17.5 6h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z' />
      <Path fill={fill} d='M17.5 11h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z' />
      <Path fill={fill} d='M17.5 16h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z' />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  fill: none;
`

const Path = styled.path`
  fill: ${props => props.fill};
`