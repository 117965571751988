import { useState, useEffect } from 'react'

export const useMonetate = (shouldTimeout = true, timeout = 10000) => {
  const [monetate, setMonetate] = useState(null)
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.monetate) {
        setMonetate(window.monetate)
        clearInterval(interval)
      }
    }, 500)
    shouldTimeout && setTimeout(() => clearInterval(interval), timeout)
    return () => clearInterval(interval)
  }, [])
  return monetate
}
