import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const EasyRefill = (props) => {
  if (props.isStacked) {
    return (
      <SvgAttr x='0px' y='0px' viewBox='0 0 100 50' className={`${props.classes}`} width={`${props.width}px`} height={`${props.height}px`}>
        <title>Stacked EasyRefill Logo</title>
        <g>
          <g>
            <DarkGreenPath
              d='M12.1,33.9H21l-0.7,2.3H14l-0.6,2.4h5.5l-0.6,2.3h-5.5l-0.7,2.5h6.4l-0.6,2.3H9.1L12.1,33.9z' />
            <DarkGreenPath d='M19.7,43.5c0-0.9,0.3-1.6,0.8-2.2c0.7-0.7,1.8-1.1,3-1.1c1,0,1.8,0.1,2.5,0.4l0.1-0.1c0-0.2,0.1-0.4,0.1-0.7
                                                c0-0.6-0.5-1-1.7-1c-0.9,0-1.8,0.2-2.4,0.5l-0.4-2c1-0.4,2.1-0.7,3.3-0.7c2.5,0,3.7,1,3.7,2.8c0,0.4-0.1,0.9-0.2,1.4l-1.3,5h-2.5
                                                l0.3-1c-0.8,0.8-1.7,1.2-2.7,1.2C20.8,45.9,19.7,44.9,19.7,43.5z M25.7,42.4v-0.3c-0.5-0.1-1-0.3-1.7-0.3s-1.2,0.2-1.5,0.5
                                                c-0.3,0.3-0.3,0.6-0.3,0.9c0,0.5,0.5,1,1.2,1C24.5,44.2,25.4,43.3,25.7,42.4z' />
            <DarkGreenPath d='M28.7,44.3l1.5-1.5c0.9,0.9,1.9,1.2,2.7,1.2c0.7,0,1.1-0.3,1.1-0.8c0-0.3-0.4-0.5-1-0.8
                                                c-1.4-0.6-2.7-1.2-2.7-2.7c0-1.8,1.4-3.2,3.7-3.2c1.5,0,2.8,0.7,3.5,1.3l-1.3,1.7c-0.9-0.7-1.6-1-2.3-1s-1,0.3-1,0.8
                                                c0,0.4,0.5,0.6,1.2,1c1.2,0.5,2.5,1.1,2.5,2.5c0,2-1.5,3.2-3.7,3.2C31.4,46,29.8,45.5,28.7,44.3z' />
            <DarkGreenPath d='M38.7,36.7h2.6l0.4,6.1l3.4-6.1h2.8L42.2,46c-1.2,1.8-2.2,2.5-3.8,2.5c-1,0-1.5-0.3-2.1-0.6l1.3-1.9
                                                c0.3,0.2,0.7,0.4,1,0.4c0.4,0,0.7-0.1,1-0.6L38.7,36.7z' />
          </g>
          <g>
            <LightGreenPath d='M53,41.1h-0.3h-2.9l-1.3,4.6h-1.3l3.2-11.9h4.2c1.5,0,2.6,0.4,3.3,1.1c0.5,0.5,0.8,1.3,0.8,2
                                                c0,1-0.4,1.9-1.1,2.5c-0.8,0.8-2.1,1.2-3.3,1.4l2.4,4.7h-1.4L53,41.1z M53.3,40c1.6,0,2.7-0.4,3.3-1.1c0.4-0.4,0.7-1,0.7-1.7
                                                c0-0.5-0.2-0.9-0.5-1.3c-0.4-0.4-1.2-0.7-2.3-0.7h-3.2L50.1,40H53.3z' />
            <LightGreenPath d='M60.1,41.9c0,0.1,0,0.3,0,0.4c0,1.6,1,2.6,2.7,2.6c1.2,0,1.9-0.4,2.7-1l0.7,0.8c-0.9,0.7-1.9,1.3-3.5,1.3
                                                c-2.3,0-3.8-1.5-3.8-3.7c0-1.4,0.7-2.9,1.7-3.9c0.9-0.9,2.1-1.4,3.4-1.4c2.2,0,3.4,1.5,3.4,3.3c0,0.6-0.1,1.1-0.3,1.6H60.1z
                                                M60.3,40.9H66c0.1-0.1,0.1-0.4,0.1-0.7c0-1.2-0.7-2.3-2.2-2.3c-0.9,0-1.8,0.4-2.4,1C60.9,39.5,60.5,40.1,60.3,40.9z' />
            <LightGreenPath d='M69.9,38.1h-1.2L69,37h1.2l0.3-1c0.2-0.9,0.5-1.4,0.9-1.9c0.5-0.5,1.2-0.8,2.1-0.8c0.7,0,1.3,0.1,1.8,0.4
                                                L75,34.8c-0.5-0.3-0.9-0.3-1.4-0.3c-0.8,0-1.4,0.5-1.8,1.6L71.5,37h2.8L74,38.1h-2.8l-2.1,7.7h-1.3L69.9,38.1z M76.2,37h1.3
                                                l-2.3,8.8h-1.3L76.2,37z M77,33.6h1.5l-0.4,1.5h-1.5L77,33.6z' />
            <LightGreenPath d='M80.8,33.4h1.3l-3.3,12.4h-1.3L80.8,33.4z' />
            <LightGreenPath d='M84.4,33.4h1.3l-3.3,12.4h-1.3L84.4,33.4z' />
          </g>
        </g>
        <g>
          <LightGreenPath d='M59.8,18.5c-0.7-0.2-1.2,0.2-1.4,0.9c-0.9,4.1-4.1,7.4-8.1,8.7c-2.8,0.9-6,0.5-8.7-0.9c-2.3-1.2-3.9-3-5-5.3
                                l3.2,1.4c0.5,0.2,1.2,0,1.4-0.7c0.4-0.5,0-1.2-0.5-1.6l-6.5-2.8c-0.4-0.2-0.7-0.2-1.1,0.2c-0.4,0.2-0.5,0.5-0.5,0.9l-0.4,7.1
                                c-0.2,0.7,0.4,1.1,0.9,1.1c0,0,0,0,0.2,0c0.5,0,1.2-0.5,1.2-1.1l0.2-3.4c1.2,2.5,3.2,4.6,5.8,6c1.2,0.7,2.7,1.1,3.9,1.4
                                c2.3,0.5,4.4,0.4,6.5-0.2c5-1.6,8.5-5.3,9.7-10.4C60.9,19.2,60.5,18.7,59.8,18.5z' />
          <LightGreenPath d='M34.9,15.7c0.5,0.2,1.2-0.2,1.4-0.9c0.9-4.2,4.1-7.4,8.1-8.7c3-0.9,6-0.5,8.8,1.1c2.3,1.2,4.1,3.2,5,5.3
                                l-3.4-1.4c-0.5-0.2-1.2,0-1.6,0.7c-0.2,0.5,0,1.2,0.7,1.6l6.4,2.8c0,0,0.2,0,0.4,0.2c0.2,0.2,0.5,0,0.9-0.2
                                c0.2-0.2,0.5-0.5,0.5-0.9l0.5-6.9c0-0.5-0.5-1.2-1.1-1.2C61,7,60.5,7.7,60.3,8.2l-0.2,3.4C58.9,9.1,57,7,54.5,5.8
                                C51.3,4,47.6,3.6,44.1,4.5c-5,1.2-8.7,5.3-9.7,10.3C33.8,15,34.2,15.5,34.9,15.7z' />
        </g>

      </SvgAttr>
    )
  }

  return (
    <SvgAttr xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 715.18 144.7' className={`${props.classes}`} width={`${props.width}px`} height={`${props.height}px`}>
      <title>Easy Refill Logo</title>
      <g>
        <LightGreenPath d='m123.43,68c-2.57-.51-5.3,1.08-5.86,3.91-4,18.72-17.93,33.59-36.43,39.26-13.06,3.81-27.2,2.33-39.24-4.35-10.05-5.5-17.71-13.78-22.25-23.88l12.85,5.46c2.47,1.03,5.61.05,6.68-2.68,
                    1.03-2.47.05-5.61-2.68-6.68l-26.88-11.54-1.03-.21c-1.29-.26-2.67,0-3.86.57-1.23.82-2.36,2.47-2.41,4.06L.03,101.7c-.31,2.88,1.91,5.19,4.79,5.5,2.88.31,5.19-1.91,5.5-4.79l1.2-13.31c5.66,11.26,
                    14.45,20.51,25.65,26.68,14.46,7.96,31.22,9.7,47.11,5.11,21.9-6.6,38.55-24.4,43.27-46.72.56-2.83-1.29-5.6-4.12-6.17Z' />
        <LightGreenPath d='m11,55.39c2.57.51,5.3-1.08,5.86-3.91,4-18.72,17.93-33.59,36.43-39.26,13.06-3.81,27.2-2.33,39.24,4.35,10.05,5.5,17.71,13.78,22.25,23.88l-12.85-5.46c-2.47-1.03-5.61-.05-6.68,2.68-1.03,
                    2.47-.05,5.61,2.68,6.68l26.88,11.54,1.03.21c1.29.26,2.67,0,3.86-.57,1.23-.82,2.36-2.47,2.41-4.06l2.29-29.76c.31-2.88-1.91-5.19-4.79-5.5-2.88-.31-5.19,1.91-5.5,4.79l-1.2,13.31c-5.66-11.26-14.45-20.51-25.65-26.68C82.8-.35,
                    66.04-2.09,50.15,2.5,28.25,9.1,11.6,26.9,6.88,49.22c-.56,2.83,1.29,5.6,4.12,6.17Z' />
        <DarkGreenPath d='m226,32.23h-34.43l-4.45,21.37h32.99l-3.41,16.07h-32.99l-5.44,26.11h34.43l-3.41,16.07h-53.08l20.13-95.68h53.08l-3.41,16.07Z' />
        <DarkGreenPath d='m284.38,49.73h17.5l-13.04,62.11h-17.5l1.71-8.03c-3.52,4.16-7.29,6.31-8.5,7.03-3.92,2.15-7.58,3.01-11.88,3.01-7.46,0-14.31-2.01-19.68-7.89-5.5-6.02-7.13-13.63-6.4-21.95.98-11.19,
                    5.51-20.37,11.93-26.54,5.47-5.16,13.91-9.75,25.38-9.75,4.45,0,8.25.86,12.07,3.01,3.28,1.86,5.23,4.16,6.7,7.03l1.71-8.03Zm-31.98,17.93c-4.34,3.73-6.97,9.18-7.42,14.34-.48,5.45,1.16,9.75,
                    3.66,12.34,2.37,2.44,5.96,4.02,11.12,4.02,3.44,0,8.24-.72,12.72-4.45,4.49-3.73,7.01-9.61,7.47-14.92.39-4.45-.99-8.32-3.34-11.05-1.82-2.15-5.32-4.73-11.64-4.73-4.45,0-8.72,1.29-12.58,4.45Z' />
        <DarkGreenPath d='m347.11,64.8c-1.18-1.29-2.38-2.29-4.17-3.16-1.79-.86-3.33-1.29-5.77-1.29-.72,0-4.17.14-6.06,2.01-.64.72-1.45,1.86-1.62,3.73-.11,1.29.09,2.29,1.01,3.16.93.86,2.17,1.43,4.82,2.3l5.16,
                    1.72c3.34,1.15,7.03,3.3,9.12,5.59,3.26,3.73,3.56,8.46,3.27,11.76-.63,7.17-3.95,12.34-7.85,15.92-7.02,6.46-15.27,7.32-21.01,7.32-6.31,0-10.93-1.29-14.37-3.01-3.02-1.58-6.48-4.59-8.83-7.32l11.4-10.62c1.26,
                    2.01,3.1,3.87,5.29,5.16,2.05,1.15,4.56,2.01,7,2.01,2.29,0,5.21-.57,6.91-1.87,1.53-1.15,2.71-3.16,2.92-5.45.1-1.15-.06-2.58-1.24-3.87-1.19-1.15-3.27-2.01-5.9-3.01l-4.15-1.72c-2.49-1-5.38-2.44-7.74-5.02-2.62-2.87-3.65-5.88-3.23-10.62.54-6.17,
                    3.57-11.33,7.46-14.78,3.89-3.44,9.85-6.02,18.17-6.02,4.02,0,8.56.57,12.02,2.01,2.64,1,5.49,2.87,7.37,4.3l-9.98,10.76Z' />
        <DarkGreenPath d='m382.27,102.52l-18.48-52.79h19.8l11.83,35.29,24.6-35.29h20.37l-69.99,94.96h-19.8l31.66-42.17Z' />
      </g>
      <g>
        <LightGreenPath d='m498.87,16.17c7.89,0,16.89.43,23.34,7.03,5.14,5.16,5.88,11.48,5.39,17.07-.67,7.6-3.01,14.77-9.38,20.37-5.6,5.02-13.74,7.89-20.82,8.46l21.36,42.75h-12.91l-20.29-41.89h-2.72l-8.83,
                    41.89h-10.62l20.13-95.68h15.35Zm-14.06,44.33h6.74c4.45,0,12.5-.29,18.23-5.02,3.1-2.58,6.23-7.32,6.79-13.63.64-7.32-2.61-11.19-5.33-12.91-2.72-1.72-8.94-2.73-13.25-2.73h-6.02l-7.16,34.28Z' />
        <LightGreenPath d='m582.8,96.64c-3.13,4.59-7.87,9.61-12.12,12.34-6.22,3.87-12.6,4.59-17.62,4.59-7.03,0-13.66-1.29-19-7.46-3.26-3.73-6.99-10.33-6.02-21.37.89-10.19,5.55-19.22,12.42-25.53,6.71-6.17,13.64-8.32,
                    21.53-8.32,10.04,0,15.61,3.59,18.75,7.03,3.91,4.45,7.22,12.62,4.45,26.25h-46.33c-.88,8.46,1.57,13.2,3.93,15.78,3.14,3.44,7.07,4.45,11.37,4.45,3.44,0,7.65-.57,11.77-3.3,3.2-2.15,6.55-6.02,8.55-9.18l8.34,
                    4.73Zm-6.76-21.09c.23-4.3-1.23-8.89-3.99-11.76-3.02-3.16-7.28-3.73-10.58-3.73-5.31,0-9.59,1.43-13.49,5.02-2.81,2.58-5.29,6.31-6.65,10.47h34.71Z' />
        <LightGreenPath d='m614.23,61.78l-10.55,50.06h-10.33l10.55-50.06h-5.74l1.98-9.47h5.74l5.43-25.96c1.01-5.02,2.41-11.19,7.41-15.78,4.21-3.87,9.03-4.88,13.48-4.88,2.87,0,5.7.43,8.21,1.29l-2.17,
                    10.04c-1.95-.72-4.33-1.29-6.2-1.29-3.44,0-5.58,1.43-6.51,2.3-1.72,1.58-3.1,4.3-4.13,9.47l-5.18,24.82h14.63l-1.98,9.47h-14.63Z' />
        <LightGreenPath d='m653.67,52.32l-12.52,59.53h-10.33l12.52-59.53h10.33Zm-5.37-27.11c1.27-1.43,3.54-2.73,6.41-2.73,2.01,0,3.39.57,4.44,1.72,1.18,1.29,1.61,2.87,1.45,4.73-.23,2.58-1.39,4.45-2.8,5.74-2.04,
                    2.01-4.37,2.44-5.95,2.44-1.87,0-3.39-.57-4.7-2.01-1.05-1.15-1.48-2.73-1.33-4.45.19-2.15,1.22-4.16,2.48-5.45Z' />
      </g>
      <g>
        <LightGreenPath d='m689,7.27l-22.06,104.57h-10.33l22.06-104.57h10.33Z' />
        <LightGreenPath d='m715.18,7.27l-22.06,104.57h-10.33l22.06-104.57h10.33Z' />
      </g>
    </SvgAttr>
  )
}
const LightGreenPath = styled.path`
  fill: #55a546;
`

const DarkGreenPath = styled.path`
  fill: #263518;

`
