import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const QuestionWithCircle = ({
  width = '25',
  height = '24',
  fill = '#2F711C'
}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 24">
      <title>Let Us Help You</title>
      <Path
        d="M11.6 19.2H14V16.8H11.6V19.2ZM12.8 0C6.17605 0 0.800049 5.376 0.800049 12C0.800049 18.624 6.17605 24
        12.8 24C19.424 24 24.8 18.624 24.8 12C24.8 5.376 19.424 0 12.8 0ZM12.8 21.6C7.50805 21.6 3.20005 17.292
        3.20005 12C3.20005 6.708 7.50805 2.4 12.8 2.4C18.092 2.4 22.4 6.708 22.4 12C22.4 17.292 18.092 21.6 12.8
        21.6ZM12.8 4.8C10.148 4.8 8.00005 6.948 8.00005 9.6H10.4C10.4 8.28 11.48 7.2 12.8 7.2C14.12 7.2 15.2 8.28
        15.2 9.6C15.2 12 11.6 11.7 11.6 15.6H14C14 12.9 17.6 12.6 17.6 9.6C17.6 6.948 15.452 4.8 12.8 4.8Z"
        fill={fill}
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  fill: none;
`

const Path = styled.path`
  fill: ${props => props.fill};
`
