import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const Star = ({
   width,
   height,
   poly1Fill = '#00833D',
   poly2Fill = '#FFFFFF',
   styles
}) => {
  return (
    <Svg
      styles={styles}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      height={height}
      width={width}
    >
      <title>Star</title>
      <g>
        <Polygon
          fill={poly1Fill}
          points="24.9,0.53 32.47,18.21 49.41,18.33 36.73,30.5 40.05,47.14
          24.9,37.93 9.76,47.14 13.16,30.41 0.4,18.33 17.33,18.21"
        />
        <Polygon
          fill={poly2Fill}
          points="24.9,8.33 29.98,21.33 41.82,21.41 33.12,29.42 36.18,41.46
          24.9,34.19 13.63,41.46 16.68,29.42 7.99,21.41 19.82,21.33"
        />
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  ${props => props.styles};
`

const Polygon = styled.polygon`
  fill: ${props => props.fill};
`