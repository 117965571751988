import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { LazyImage } from '../LazyImage/LazyImage'
import { ReviewRating } from '../ReviewRating/ReviewRating'
import { AddToCartButton } from '../_inputs/AddToCartButton/AddToCartButton'
import { ProductFlag } from '../ProductFlag/ProductFlag'
import { useDispatch } from 'react-redux'
import { BlackLink } from '../_styled/Link'
import { CTAButtonAlt } from '../_inputs/Button/Button'
import { useMediaQuery } from 'react-responsive'
import { sharedSetInstanceAction } from '../../actions/sharedActions'
import { Price } from '../Price/Price'
import { NameContainer, NameElement, BrandElement } from '../_styled/ProductStyling'
import { LinkThatLookLikeCTAButton } from '../_styled/Link'

export const Product = ({
  product,
  promotions,
  useHorizontalOrientation = false,
  additionalAddToCartCode = () => {},
  additionalOutOfStockCode = () => {},
  additionalAfterAddToCartCode = () => {},
  displayATCBanner,
  focus = false,
  imageWidth = '150px',
  overrideProductHeight = '',
  isCarousel = false,
  productFlagStyles,
  updateCart = false,
  pCPositon = 'absolute',
  aTCJustify = 'start',
  styles,
  imageContainerStyles,
  uLStyles,
  renderWithProductName = true,
  onMYACFav = false,
  ...props
}) => {
  const dispatch = useDispatch()
  const isSMMobile = useMediaQuery({ query: '(max-width:576px)' }) || useHorizontalOrientation
  const outOfStockHandler = () => {
    if (additionalOutOfStockCode) {
      additionalOutOfStockCode()
    }
    dispatch(sharedSetInstanceAction({ name: 'OutOfStockModal', data: { productName: product.productName, productPartNumber: product.productPartNumber, productDiscountPrice: product.productDiscountPrice } }))
  }

  const nameAndBrand = <NameContainer>
    <BrandElement>{product.productVendor}</BrandElement>
    <NameElement useHorizontalOrientation={isSMMobile}>{product.productName}</NameElement>
  </NameContainer>

  let constructorOptions = {}

  try {
    constructorOptions = JSON.parse(product.constructorOptions)
  } catch (error) {
    console.error('Constructor Options', product.constructorOptions)
  }

  return (
    <>
      <ProductOuterContainer
        useHorizontalOrientation={isSMMobile}
        className={overrideProductHeight}
        styles={styles}
        data-cnstrc-item-id={constructorOptions?.item_id}
        data-cnstrc-item-name={constructorOptions?.item_name}
        data-cnstrc-item-variation-id={constructorOptions?.variationId}
        data-cnstrc-strategy-id={constructorOptions?.strategy_id}
        data-cnstrc-item-price={product.productDiscountPrice}
        {...props}
      >
        <ProductContainer useHorizontalOrientation={isSMMobile}>
          <HeadlineA
            id={focus ? 'productFocus' : false}
            className={`cTest_product-${product.productPartNumber}`}
            href={`/${product.longURL}`}
            title={`${product.productName} - ${product.productPartNumber}`}
            useHorizontalOrientation={isSMMobile}
          >
            {isSMMobile && renderWithProductName &&
              <>{nameAndBrand}</>
            }
            <ImageContainer styles={imageContainerStyles} useHorizontalOrientation={isSMMobile}>
              <ProductFlag
                onMYACFav={onMYACFav}
                styles={productFlagStyles}
                newWebItem={product.newWebItem}
                newImproved={!!product.flagMap?.new_improved}
                bogo={product.bogo}
                outletmall={product.outletmall}
                rewards2x={product?.rewards2x || product?.flags?.indexOf('reward2x') > -1 || product?.flagMap?.reward2x}
                rewards3x={product?.rewards3x || product?.flags?.indexOf('reward3x') > -1 || product?.flagMap?.reward3x}
                rewards4x={product?.rewards4x || product?.flags?.indexOf('reward4x') > -1 || product?.flagMap?.reward4x}
              />
              <LazyImage
                src={`https://media.swansonvitamins.com/images/items/250/${product.productPartNumber}.jpg`}
                alt={`${product.productVendor} ${product.productName}`}
                width={imageWidth}
                height={imageWidth}
              />
              {!isSMMobile && renderWithProductName &&
                <>
                  {nameAndBrand}
                </>
              }
            </ImageContainer>
          </HeadlineA>
        </ProductContainer>
        <Ul styles={uLStyles} useHorizontalOrientation={isSMMobile} position={isSMMobile ? 'absolute' : pCPositon}>
          <li>
            <ReviewRating
              productUrl={product.longURL}
              styles={css`
                    justify-content: center;
                  `}
              rating={product.rating}
              ratingCount={product.numReviews}
            />
            <ProductDetails >{product.productDetails}</ProductDetails>
            <PromotionContainer>
              {promotions}
            </PromotionContainer>
          </li>
          {product.statusId !== 'D' &&
            <PriceLi tabIndex='0'>
              <Price product={product} />
            </PriceLi>
          }
          {product.statusId === 'D' &&
            <AddToCartLi useHorizontalOrientation={isSMMobile} aTCJustify={aTCJustify}>
              <LinkThatLookLikeCTAButton
                href={`/${product.longURL}#productCarousel`}
                title={`${product.productName} - ${product.productPartNumber}`}
              >
                See More Options
              </LinkThatLookLikeCTAButton>
            </AddToCartLi>
          }
          {!product.statusUnavailable && product.statusId !== 'D' &&
            <AddToCartLi useHorizontalOrientation={isSMMobile} aTCJustify={aTCJustify}>
              <AddToCartButton
                data-cnstrc-btn="add_to_cart"
                product={product}
                additionalAfterAddToCartCode={additionalAfterAddToCartCode}
                displayATCBanner={displayATCBanner}
                isCarousel={isCarousel}
                updateCart={updateCart}
                ageVerifyRequired={(product?.flags?.indexOf('age_verification') > -1) || (product?.flagMap?.age_verification)}
              />
            </AddToCartLi>
          }
          {product.statusUnavailable &&
            <OutOfStockLi useHorizontalOrientation={isSMMobile}>
              <CTAButtonAlt onClick={outOfStockHandler}>Get In-Stock Alert</CTAButtonAlt>
            </OutOfStockLi>
          }
        </Ul>
      </ProductOuterContainer>
    </>
  )
}

const ProductOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 22rem;
  &.favoriteProductHeight {
    height: 100%;
  }
  ${(props) => !props.useHorizontalOrientation && css`
      flex: 1 0 auto;
  `}
  ${props => props.styles};

`

const PromotionContainer = styled.div`
  margin-top: .6rem;
`

const HeadlineA = styled(BlackLink)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${(props) => props.useHorizontalOrientation && `
    align-items: stretch;
  `};
  ${(props) => !props.useHorizontalOrientation && css`
      flex-direction: column;
  `};
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    align-items: stretch;
  }
  
`

const PriceLi = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: .6rem;
  ${(props) => !props.useHorizontalOrientation && css`
    justify-content: center;
`}
`

const AddToCartLi = styled.div`
  display: flex;
  justify-content: ${props => props.aTCJustify};
  align-items: center;
  ${(props) => !props.useHorizontalOrientation && css`
      justify-content: center;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  ${(props) => !props.useHorizontalOrientation && css`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  `}
  ${props => props.styles};
`

const OutOfStockLi = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  ${(props) => !props.useHorizontalOrientation && css`
    justify-content: center;
    align-items: center;
  `}
`

const ProductContainer = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  ${(props) => !props.useHorizontalOrientation && css`
    text-align: center;
    flex-direction: column;
  `}
`

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  list-style: none;
  position: ${props => props.position};
  left: 16rem;
  top: 5rem;
  padding-inline-start: 0px;
  
  @media (max-width: 360px) {
    left: 11rem;
  }
  ${(props) => !props.useHorizontalOrientation && css`
    position: static;
    left: initial;
    bottom: initial;
    height: auto;
  `}
  ${props => props.styles};
`

const ProductDetails = styled.div`
  font-size: 1.2rem;
  padding: 0 5px;
  text-align: center;
  ${(props) => props.useHorizontalOrientation && css`
    text-align: left;
  `}
`
Product.propTypes = {
  product: PropTypes.object
}
