import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const XIcon = () => {
  return (
      <Svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 360 360" style={{ enableBackground:'new 0 0 360 360' }}>
<title>X Logo</title>
    <g>
    <Path style={{fill:'#2F711C'}} d="M128.23,85.75c-0.26-0.38-0.53-0.52-0.99-0.52c-9.16,0.01-18.32,0.01-27.48,0.01c-0.2,0-0.4,0-0.72,0
        c0.18,0.28,0.29,0.45,0.4,0.61c10.8,15.45,21.61,30.9,32.41,46.35c14.3,20.45,28.6,40.91,42.9,61.36
        c8.79,12.57,17.58,25.14,26.37,37.7c10.18,14.55,20.36,29.11,30.52,43.67c0.25,0.36,0.51,0.45,0.91,0.45
        c9.18-0.01,18.36-0.01,27.54-0.01c0.19,0,0.39,0,0.7,0c-0.19-0.28-0.3-0.46-0.43-0.63c-3.35-4.79-6.7-9.58-10.05-14.37
        c-13.25-18.95-26.5-37.91-39.75-56.86c-11-15.74-22.01-31.47-33.02-47.21C161.1,132.79,144.66,109.28,128.23,85.75z"/>
    <Path style={{fill:'#2F711C'}} className="xIcon" d="M179.89,11.66c-93.19,0-168.74,75.55-168.74,168.74s75.55,168.74,168.74,168.74s168.74-75.55,168.74-168.74
        S273.08,11.66,179.89,11.66z M285.95,288.92c-20.76,0-41.53,0-62.29,0.01c-0.45,0-0.69-0.16-0.93-0.51
        c-6.68-9.73-13.36-19.46-20.05-29.19c-6.89-10.03-13.79-20.06-20.69-30.1c-5.56-8.1-11.13-16.19-16.69-24.29
        c-0.1-0.15-0.22-0.29-0.35-0.48c-1.07,1.24-2.11,2.44-3.14,3.64c-17.54,20.39-35.08,40.78-52.62,61.16
        c-5.53,6.42-11.06,12.84-16.57,19.27c-0.29,0.34-0.57,0.48-1.03,0.48c-6.07-0.01-12.14-0.01-18.21,0c0-0.04,0-0.07,0-0.11
        c0.15-0.16,0.32-0.31,0.46-0.47c2.8-3.25,5.6-6.51,8.4-9.76c12.93-15.03,25.87-30.06,38.8-45.09
        c10.63-12.35,21.26-24.7,31.89-37.05c1.22-1.41,2.43-2.83,3.66-4.25c-0.07-0.12-0.13-0.21-0.19-0.3c-2.14-3.11-4.27-6.22-6.41-9.33
        c-8.19-11.92-16.39-23.84-24.58-35.76c-8.37-12.18-16.74-24.36-25.11-36.55c-8.08-11.75-16.16-23.51-24.24-35.26
        c-0.88-1.28-1.78-2.54-2.67-3.81c0.02-0.04,0.04-0.1,0.06-0.11c0.15-0.01,0.3,0,0.44,0c21.01,0,42.01,0,63.02,0
        c7.6,11.07,15.21,22.14,22.81,33.21c10.43,15.19,20.87,30.37,31.31,45.56c0.29,0.42,0.58,0.83,0.89,1.27
        c0.16-0.18,0.28-0.3,0.39-0.42c12.31-14.3,24.61-28.6,36.92-42.91c9.94-11.55,19.89-23.11,29.83-34.66
        c0.57-0.67,1.11-1.37,1.67-2.05c6.29,0,12.58,0,18.88,0c-1.01,1.2-2.01,2.41-3.03,3.6c-20.56,23.89-41.12,47.79-61.67,71.68
        c-4.73,5.5-9.46,10.99-14.19,16.49c-0.13,0.15-0.26,0.31-0.4,0.49c28.76,41.85,57.51,83.67,86.32,125.6
        C286.31,288.92,286.13,288.92,285.95,288.92z"/>
    </g>
    </Svg>


  )
}

const Svg = styled(SvgAttr)`
 &.footer-icon{
  width: 45px;
  margin: 1rem 1.2rem;
  height: 45px;
  @media(min-width: 768px) and (max-width: 990px){
   width: 25px;
   height: 25px;
   margin: 0.5rem
  }
  @media (min-width: 991px) and (max-width: 1365px ){
   width: 40px;
   height: 40px;
   margin: 0.5rem;
  }
 }
`

const Path = styled.path`
  fill: #2F711C;
`
