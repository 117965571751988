export const useSignifyd = () => {
  const isClient = typeof window.location !== 'undefined'

  const initializeScript = (sessionId) => {

    let script = document.getElementById('sig-api')

    if (!script && isClient) {
      script = document.createElement('script')

      script.defer = true
      script.type = 'text/javascript'
      script.id = 'sig-api'
      script.setAttribute('data-order-session-id', sessionId)
      script.src = 'https://cdn-scripts.signifyd.com/api/script-tag.js'

      document.head.appendChild(script)
    }

  }

  return {
    initializeScript
  }
}
