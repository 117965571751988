import axios from 'axios'
import { APP_ERROR, LOADING_COMPLETE } from '../constants/appConstants'
import {
  HEADER_CART_COUNT,
  HEADER_USER_STATUS,
  HEADER_USER_NAME,
  HEADER_CART_DETAILS,
  HEADER_ITEM_RECORDS,
  HEADER_SEARCH_TERMS,
  HEADER_DROPDOWN
} from '../constants/headerConstants'
import { setUserIdAmplitude } from '~/hooks/useAmplitude'

import { useSignifyd } from '../hooks/useSignifyd'

export function headerInfo () {
  return (dispatch) => {
    axios.get(`${window.location.origin}/userinfo.json`)
      .then((response) => {
        const { initializeScript } = useSignifyd();
        initializeScript(response.data.signifydSessionId)

        dispatch({ type: HEADER_USER_STATUS, payload: response.data.isLoggedIn })
        dispatch({ type: HEADER_CART_COUNT, payload: parseInt(response.data.cartCount) })
        dispatch({ type: HEADER_USER_NAME, payload: response.data.userFirstName })
        setUserIdAmplitude(response.data)
      })
      .catch(() => {
        console.error('woot')
      })
    axios.get(`${window.location.origin}/cart?view=json`)
      .then((response) => {
        dispatch({ type: HEADER_CART_DETAILS, payload: response.data })
      })
      .catch(() => {
        dispatch({ type: LOADING_COMPLETE })
        dispatch({ type: APP_ERROR, payload: {} })
      })
  }
}
export const clearHeader = () => {
  return (dispatch) => {
    dispatch({ type: HEADER_SEARCH_TERMS, payload: [] })
    dispatch({ type: HEADER_ITEM_RECORDS, payload: [] })
  }
}

export const headerSearch = payload => {
  return (dispatch) => {
    dispatch({ type: HEADER_SEARCH_TERMS, payload: payload.result.searchTerms })
    dispatch({ type: HEADER_ITEM_RECORDS, payload: payload.result.itemRecords })
  }
}

export const itemRecordUpdate = payload => {
  return (dispatch) => {
    dispatch({ type: HEADER_ITEM_RECORDS, payload: payload.result.itemRecords })
  }
}

export const setHeaderDropdown = (payload) => {
  return (dispatch) => {
    dispatch({ type: HEADER_DROPDOWN, payload: payload})
  }
}

export function checkIfMobile() {
  if (window && navigator) {
    let hasTouchScreen = false
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0
    } else {
      const mQ = window.matchMedia?.("(pointer:coarse)")
      if (mQ?.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches
      } else if ("orientation" in window) {
        hasTouchScreen = true
      } else {
        const UA = navigator.userAgent
        hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
      }
    }
    return hasTouchScreen
  } else {
    return false
  }
}