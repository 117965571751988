import React from 'react'
import styled from 'styled-components'
export const ShowPassword = (props) => {
  const onClickHandler = () => {
    props.flipPassword()
  }

  return (
    <ShowPasswordContainerBtn type='button' className='ShowPassword' onClick={onClickHandler}>
      <ShowMessageSpan id={`showPasswordMessage-${props.id}`}>
        {props.passwordType === 'text' ? 'Hide' : 'Show'}
      </ShowMessageSpan>
    </ShowPasswordContainerBtn>
  )
}

const ShowPasswordContainerBtn = styled.button`
  position: absolute;
  color: ${props => props.theme.black};
  top: 3rem;
  right: 2rem;
  background: transparent;
  border: none;
`

const ShowMessageSpan = styled.span`
  font-size: 1.6rem;
  text-decoration: underline;
`
