import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const Lock = ({
  width = '3rem',
  color = '#00833D',
  classes,
  styles
}) => (
  <Svg
    className={`${classes}`}
    width={`${width}`}
    x='0px'
    y='0px'
    viewBox='0 0 361.1 361.1'
    styles={styles}
  >
    <title>Lock</title>
    <path fill={color} d='M274.8,122.3v-9.1c0-52-42.2-84.2-94.2-84.2s-94.2,32.2-94.2,84.2v9.1C69,122.3,55,136.4,55,153.7v147
     c0,17.3,14.1,31.4,31.4,31.4h188.4c17.3,0,31.4-14.1,31.4-31.4v-147C306.2,136.4,292.1,122.3,274.8,122.3z M117.8,113.2
     c0-34.7,28.1-52.8,62.8-52.8s62.8,18.1,62.8,52.8v9.1H117.8V113.2z M274.8,300.7H86.4v-147h188.4V300.7L274.8,300.7z' />
    <path fill={color} d='M192.6,262.6h-24c-4.4,0-8-3.6-8-8v-54.8c0-4.4,3.6-8,8-8h24c4.4,0,8,3.6,8,8v54.8
      C200.6,259,197,262.6,192.6,262.6z'
    />
  </Svg>

)

const Svg = styled(SvgAttr)`
  ${props => props.styles};
`