export const ORDER_CART = 'ORDER_CART'
export const ORDER_UPDATE_TOTALS = 'ORDER_UPDATE_TOTALS'
export const ORDER_SHIPPING_SELECTED = 'ORDER_SHIPPING_SELECTED'
export const ORDER_APPLY_PROMO = 'ORDER_APPLY_PROMO'
export const ORDER_APPLY_COUPON = 'ORDER_APPLY_COUPON'
export const ORDER_PROMO_TEXT = 'ORDER_PROMO_TEXT'
export const ORDER_IS_CALIFORNIA = 'ORDER_IS_CALIFORNIA'
export const ORDER_IS_PROP65 = 'ORDER_IS_PROP65'
export const ORDER_IS_EU = 'ORDER_IS_EU'
export const ORDER_RESTRICTED = 'ORDER_RESTRICTED'
export const ORDER_BACKORDERED = 'ORDER_BACKORDERED'
export const ORDER_SESSION_TIMER = 'ORDER_SESSION_TIMER'
export const ORDER_SESSION_REMAIN = 'ORDER_SESSION_REMAIN'
