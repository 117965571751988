import { ROUTER_PUSH, ROUTER_GO, ROUTER_BACK, ROUTER_FORWARD, ROUTER_REPLACE, ROUTER_LOCATION_CHANGE } from '../constants/routerConstants'
// actions.js
export const push = (href) => ({
  type: ROUTER_PUSH,
  payload: href
})
export const replace = href => ({
  type: ROUTER_REPLACE,
  payload: href
})
export const go = (index) => ({
  type: ROUTER_GO,
  payload: index
})
export const goBack = () => ({
  type: ROUTER_BACK
})
export const goForward = () => ({
  type: ROUTER_FORWARD
})

export const locationChange = ({ pathname, search, hash }) => ({
  type: ROUTER_LOCATION_CHANGE,
  payload: {
    pathname,
    search,
    hash
  }
})
