import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const StarKosherIcon = ({
  fillColor = '#23350d',
  height = '4.1rem',
  width = '100%'
}) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    id='specialty-flag_kosher'
    data-name='specialty-flag kosher'
    viewBox='0 0 162.02 228.14'
    height={height}
    width={width}
  >
    <title>Kosher Certified</title>
    
    <Path fillColor={fillColor} d='M90.27,95.07l-10.4-12.3v12.3h-11v-26.6h10.8v8.5l13-8.5s8.6.1,8.7,0-12.7,8.3-12.7,8.3l15.5,18.4h-13.9v-.1Z'/>
    <Path fillColor={fillColor} d='M81.01,3c43.08,0,78.01,34.93,78.01,78.01s-34.93,78.01-78.01,78.01S3,124.09,3,81.01,37.93,3,81.01,3M81.01,0C36.34,0,0,36.34,0,81.01s36.34,81.01,81.01,81.01,81.01-36.34,81.01-81.01S125.68,0,81.01,0h0Z'/>
    <Path fillColor={fillColor} d='M82.92,20.77l12.26,37.73,1.35,4.15h44.02l-32.09,23.31-3.53,2.56,1.35,4.15,12.26,37.72-32.09-23.32-3.53-2.56-3.53,2.56-32.09,23.32,12.26-37.72,1.35-4.15-3.53-2.56-32.09-23.31h44.02l1.35-4.15,12.26-37.73M82.92,1.36l-17.96,55.29H6.82l47.03,34.16-17.97,55.28,47.03-34.17,47.03,34.17-17.96-55.28,47.03-34.16h-58.14L82.92,1.36h0Z'/>
    <g>
      <Path fillColor={fillColor} d='M33.02,174.34l7.11-8.18h4.76l-8.18,8.93,8.62,10.41h-4.99l-6.87-8.67-.44.43v8.24h-3.77v-19.35h3.77v8.18Z'/>
      <Path fillColor={fillColor} d='M66.2,175.85c0,5.89-4.23,10.09-10.33,10.09s-10.33-4.21-10.33-10.09,4.23-10.12,10.33-10.12,10.33,4.24,10.33,10.12ZM62.31,175.85c0-3.92-2.67-6.7-6.44-6.7s-6.44,2.79-6.44,6.7,2.67,6.67,6.44,6.67,6.44-2.76,6.44-6.67Z'/>
      <Path fillColor={fillColor} d='M79.19,170.57c-1.13-1.51-2.47-1.65-3.16-1.65-2.03,0-2.67,1.28-2.67,2.26,0,.46.15.9.61,1.3.46.44,1.1.67,2.32,1.1,1.51.52,2.93,1.04,3.92,1.94.87.78,1.65,2.09,1.65,4.06,0,3.77-2.73,6.35-6.73,6.35-3.57,0-5.66-2.18-6.67-3.66l2.47-2.35c.93,1.89,2.7,2.58,4.06,2.58,1.71,0,2.99-1.04,2.99-2.67,0-.7-.23-1.25-.75-1.74-.73-.67-1.89-1.04-2.99-1.42-1.02-.35-2.23-.81-3.22-1.68-.64-.55-1.54-1.62-1.54-3.6,0-2.84,1.91-5.69,6.21-5.69,1.22,0,3.51.23,5.48,2.26l-1.97,2.58Z'/>
      <Path fillColor={fillColor} d='M88.93,173.64h8.18v-7.48h3.77v19.35h-3.77v-8.62h-8.18v8.62h-3.77v-19.35h3.77v7.48Z'/>
      <Path fillColor={fillColor} d='M116.02,169.41h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M125.27,166.16c2.67,0,4.03.7,4.93,1.45,1.48,1.25,1.91,2.9,1.91,4.35,0,1.88-.75,3.54-2.26,4.55-.49.35-1.19.67-2.12.84l6.03,8.15h-4.7l-5.16-7.77h-.46v7.77h-3.77v-19.35h5.6ZM123.44,175.07h1.07c.72,0,3.71-.09,3.71-2.93s-2.96-2.9-3.66-2.9h-1.13v5.83Z'/>
      <Path fillColor={fillColor} d='M34.3,219.61c-1.16.61-2.7,1.13-4.79,1.13-3.39,0-5.45-1.16-7.08-2.7-2.23-2.15-3.1-4.55-3.1-7.4,0-3.51,1.45-6.03,3.1-7.57,1.94-1.83,4.35-2.55,7.11-2.55,1.36,0,2.99.23,4.76,1.19v4.41c-1.77-2.03-3.97-2.18-4.67-2.18-3.89,0-6.41,3.19-6.41,6.76,0,4.29,3.31,6.61,6.56,6.61,1.8,0,3.39-.78,4.52-2.12v4.41Z'/>
      <Path fillColor={fillColor} d='M48.51,204.21h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M57.76,200.96c2.67,0,4.03.7,4.93,1.45,1.48,1.25,1.91,2.9,1.91,4.35,0,1.88-.75,3.54-2.26,4.55-.49.35-1.19.67-2.12.84l6.03,8.15h-4.7l-5.16-7.77h-.46v7.77h-3.77v-19.35h5.6ZM55.93,209.87h1.07c.73,0,3.71-.09,3.71-2.93s-2.96-2.9-3.65-2.9h-1.13v5.83Z'/>
      <Path fillColor={fillColor} d='M74.89,204.21v16.1h-3.77v-16.1h-4.35v-3.25h12.47v3.25h-4.35Z'/>
      <Path fillColor={fillColor} d='M85.39,200.96v19.35h-3.77v-19.35h3.77Z'/>
      <Path fillColor={fillColor} d='M100.07,204.21h-6.5v4.32h6.09v3.25h-6.09v8.53h-3.77v-19.35h10.27v3.25Z'/>
      <Path fillColor={fillColor} d='M107.08,200.96v19.35h-3.77v-19.35h3.77Z'/>
      <Path fillColor={fillColor} d='M122.22,204.21h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M130.92,200.96c2.7,0,5.05.29,7.19,1.89,2.35,1.77,3.71,4.58,3.71,7.8s-1.33,6-3.89,7.8c-2.26,1.6-4.38,1.86-7.08,1.86h-4.99v-19.35h5.05ZM129.64,217.06h1.16c.96,0,3.05-.06,4.73-1.28,1.54-1.1,2.41-3.07,2.41-5.13s-.84-4-2.38-5.16c-1.57-1.16-3.48-1.28-4.76-1.28h-1.16v12.85Z'/>
    </g>
  </Svg>
)

const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const Path = styled.path`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`
