import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const GlutenFreeIcon = ({
  fillColor = '#23350d',
  height = '4.1rem',
  width = '100%'
}) => (
  <Svg 
    xmlns='http://www.w3.org/2000/svg'
    id='specialty-flag_glutenfree' 
    data-name='specialty-flag glutenfree' 
    viewBox='0 0 195.71 198.61'
    height={height}
    width={width}
  >
    <title>Gluten Free</title>

    <Polygon fillColor={fillColor} points='72.51 110.64 48.25 142.76 56.35 148.86 80.61 116.84 72.51 110.64'/>
    <Polygon fillColor={fillColor} points='132.01 48.04 152 22.3 143.9 16.2 124.01 41.84 132.01 48.04'/>
    <g>
      <Path fillColor={fillColor} d='M78.01,97.02c-.1,4.7,1.7,9.3,5.3,12.9,3.6,3.6,8.2,5.3,12.9,5.3,1,0,1.9-.8,1.9-1.9.1-4.7-1.7-9.3-5.3-12.9-3.6-3.6-8.2-5.3-12.9-5.3-1.1,0-1.9.8-1.9,1.9h0Z'/>
      <Path fillColor={fillColor} d='M106.61,100.32c-3.6,3.6-5.3,8.2-5.3,12.9,0,1,.8,1.9,1.9,1.9,4.7,0,9.3-1.7,12.9-5.3,3.6-3.6,5.3-8.2,5.3-12.9,0-1-.8-1.9-1.9-1.9-4.7,0-9.3,1.8-12.9,5.3h0Z'/>
      <Path fillColor={fillColor} d='M78.01,78.62c-.1,4.7,1.7,9.3,5.3,12.9,3.6,3.6,8.2,5.3,12.9,5.3,1,0,1.9-.8,1.9-1.9.1-4.7-1.7-9.3-5.3-12.9-3.6-3.6-8.2-5.3-12.9-5.3-1.1,0-1.9.9-1.9,1.9h0Z'/>
      <Path fillColor={fillColor} d='M119.51,76.72c-4.7,0-9.3,1.7-12.9,5.3-3.6,3.6-5.3,8.2-5.3,12.9,0,1,.8,1.9,1.9,1.9,4.7,0,9.3-1.7,12.9-5.3,3.6-3.6,5.3-8.2,5.3-12.9,0-1-.9-1.9-1.9-1.9h0Z'/>
      <Path fillColor={fillColor} d='M79.91,58.42c-1,0-1.9.8-1.9,1.9-.1,4.7,1.7,9.3,5.3,12.9,3.6,3.6,8.2,5.3,12.9,5.3,1,0,1.9-.8,1.9-1.9.1-4.7-1.7-9.3-5.3-12.9-3.6-3.6-8.3-5.4-12.9-5.3h0Z'/>
      <Path fillColor={fillColor} d='M106.61,63.62c-3.6,3.6-5.3,8.2-5.3,12.9,0,1,.8,1.9,1.9,1.9,4.7,0,9.3-1.7,12.9-5.3,3.6-3.6,5.3-8.2,5.3-12.9,0-1-.8-1.9-1.9-1.9-4.6,0-9.3,1.8-12.9,5.3h0Z'/>
      <Path fillColor={fillColor} d='M79.91,40.02c-1,0-1.9.8-1.9,1.9-.1,4.7,1.7,9.3,5.3,12.9,3.6,3.6,8.2,5.3,12.9,5.3,1,0,1.9-.8,1.9-1.9.1-4.7-1.7-9.3-5.3-12.9-3.6-3.6-8.3-5.4-12.9-5.3h0Z'/>
      <Path fillColor={fillColor} d='M106.61,45.22c-3.6,3.6-5.3,8.2-5.3,12.9,0,1,.8,1.9,1.9,1.9,4.7,0,9.3-1.7,12.9-5.3,3.6-3.6,5.3-8.2,5.3-12.9,0-1-.8-1.9-1.9-1.9-4.6,0-9.3,1.8-12.9,5.3h0Z'/>
      <Path fillColor={fillColor} d='M93.01,34.02c0,5,2.1,9.6,5.4,12.8.7.7,1.9.7,2.7,0,3.3-3.3,5.4-7.8,5.4-12.8s-2.1-9.6-5.4-12.8c-.7-.7-1.9-.7-2.6,0-3.5,3.2-5.5,7.7-5.5,12.8h0Z'/>
      <Path fillColor={fillColor} d='M100.41,141.32c1.5,0,2.7-1.2,2.7-2.7v-17.6c0-1.5-1.2-2.7-2.7-2.7h-1.6c-1.5,0-2.7,1.2-2.7,2.7v17.6c0,1.5,1.2,2.7,2.7,2.7h1.6Z'/>
    </g>
    <Path fillColor={fillColor} d='M98.29,3c43.08,0,78.01,34.93,78.01,78.01s-34.93,78.01-78.01,78.01S20.28,124.09,20.28,81.01,55.21,3,98.29,3M98.29,0C53.62,0,17.28,36.34,17.28,81.01s36.34,81.01,81.01,81.01,81.01-36.34,81.01-81.01S142.96,0,98.29,0h0Z'/>
    <g>
      <Path fillColor={fillColor} d='M25.22,180.42h8.88v.23c0,1.3-.17,5.37-2.84,8.01-2,2-4.47,2.55-6.88,2.55s-4.9-.49-7.02-2.61c-.67-.67-2.87-3.1-2.87-7.45,0-3.74,1.68-6.03,2.84-7.19,1.45-1.48,3.89-2.96,7.63-2.96,1.45,0,2.99.23,4.47,1.02,1.25.64,2.61,1.8,3.63,3.54l-3.28,1.77c-.67-1.31-1.65-1.97-2.26-2.29-.81-.41-1.83-.61-2.73-.61-1.54,0-3.28.55-4.47,1.71-1.1,1.07-1.94,2.93-1.94,5.11,0,2.58,1.1,4.12,1.91,4.9.87.84,2.23,1.65,4.29,1.65,1.28,0,2.73-.35,3.8-1.16.75-.58,1.51-1.57,1.83-2.96h-4.99v-3.25Z'/>
      <Path fillColor={fillColor} d='M41.34,171.43v16.1h5.98v3.25h-9.75v-19.35h3.77Z'/>
      <Path fillColor={fillColor} d='M53.08,171.43v12.15c0,1.48.49,2.38.93,2.87.55.67,1.57,1.33,3.07,1.33s2.52-.67,3.07-1.33c.44-.49.93-1.39.93-2.87v-12.15h3.77v11.83c0,1.83-.29,3.68-1.45,5.16-1.39,1.83-3.83,2.79-6.32,2.79s-4.93-.96-6.32-2.79c-1.16-1.48-1.45-3.34-1.45-5.16v-11.83h3.77Z'/>
      <Path fillColor={fillColor} d='M75.35,174.68v16.1h-3.77v-16.1h-4.35v-3.25h12.47v3.25h-4.35Z'/>
      <Path fillColor={fillColor} d='M92.81,174.68h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M96.46,190.78v-19.35h2.61l11.78,12.91v-12.91h3.77v19.35h-2.61l-11.78-12.94v12.94h-3.77Z'/>
      <Path fillColor={fillColor} d='M136.05,174.68h-6.5v4.32h6.09v3.25h-6.09v8.53h-3.77v-19.35h10.27v3.25Z'/>
      <Path fillColor={fillColor} d='M144.89,171.43c2.67,0,4.03.7,4.93,1.45,1.48,1.25,1.91,2.9,1.91,4.35,0,1.88-.75,3.54-2.26,4.55-.49.35-1.19.67-2.12.84l6.03,8.15h-4.7l-5.16-7.77h-.46v7.77h-3.77v-19.35h5.6ZM143.07,180.34h1.07c.72,0,3.71-.09,3.71-2.93s-2.96-2.9-3.66-2.9h-1.13v5.83Z'/>
      <Path fillColor={fillColor} d='M166.68,174.68h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M181.06,174.68h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
    </g>
  </Svg>
)
const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const Polygon = styled.polygon`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`

const Path = styled.path`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`