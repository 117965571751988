import { ROUTER_PUSH, ROUTER_GO, ROUTER_BACK, ROUTER_FORWARD, ROUTER_REPLACE } from '../constants/routerConstants'

export const routerMiddleware = (history) => () => (next) => (action) => {
  switch (action && action.type) {
    case ROUTER_PUSH:
      history.push(action.payload)
      break
    case ROUTER_REPLACE:
      history.replace(action.payload)
      break
    case ROUTER_GO:
      history.go(action.payload)
      break
    case ROUTER_BACK:
      history.goBack()
      break
    case ROUTER_FORWARD:
      history.goForward()
      break
    default:
      return next(action)
  }
}

export default routerMiddleware
