import { ROUTER_LOCATION_CHANGE } from '../constants/routerConstants'

const initialState = {
  pathname: '/',
  search: '',
  hash: ''
}

export const routerReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE:
      action.payload.search = action.payload.search.replace(/ /g, '+')
      return {
        ...reduxState,
        ...action.payload
      }
    default:
      return reduxState
  }
}

export default routerReducer
