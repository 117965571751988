import { push } from '../actions/routerActions'
import { APP_SORT } from '../constants/appConstants'
import { FAV_SORT } from '../constants/favoriteConstants'

export function changePageAction (pageNumber, paginationInfo) {
  return (dispatch) => {
    for (const page of paginationInfo) {
      if (pageNumber.toString() === page.name) {
        let location = document.createElement('a')
        location.href = page.uri
        dispatch(push({ pathname: location.pathname, search: location.search }))
        break
      }
    }
    window.scrollTo(0, 0)
  }
}

export const changeSortAction = (sortValue, sortArray) => (dispatch) => {
  dispatch({ type: APP_SORT, payload: sortValue })
  sortArray.forEach((option) => {
    let location = document.createElement('a')
    location.href = option.seoURL
    if (option.value === sortValue) {
      dispatch(push({ pathname: location.pathname, search: location.search }))
    }
  })

  window.scrollTo(0, 0)
}

export const changeFavSortAction = (sortValue, sortArray) => (dispatch) => {
  dispatch({ type: FAV_SORT, payload: sortValue })
  sortArray.forEach((option) => {
    let location = document.createElement('a')
    location.href = option.url
    if (option.label === sortValue) {
      dispatch(push({ pathname: location.pathname, search: location.search }))
    }
  })
}
