import React from 'react'
import styled, { css } from 'styled-components'
import { DiagonalCrossedOutText } from '../_styled/DiagonalCrossedOutText'
import { VisuallyHidden } from '../_styled/VisuallyHidden'

export function Price ({ product, styles }) {
  const everyDayLowPrice = (product) => {
    return (product.discount > 0.00 && product.everyDayLowPrice - product.productDiscountPrice > 0.00)
  }

  return <Container styles={styles}>
    {product.bogo &&
      <PriceText highLight>
        2 for&nbsp;
      </PriceText>
    }
    {product.tooLowToShow && !product.statusUnavailable &&
      <BigPriceText>
        <VisuallyHidden>retail price</VisuallyHidden>
        ${product.everyDayLowPrice}
      </BigPriceText>
    }
    {product.tooLowToShow && !product.statusUnavailable && (product.discount > 0.0) &&
      <PriceRightSide className='d-inline-flex align-items-start flex-column mr-2'>
        <PriceText highLight>
          Save&nbsp;{((product.percentDiscount * 100).toFixed(0))}% in cart
        </PriceText>
      </PriceRightSide>
    }
    {(!product.tooLowToShow) &&
      <BigPriceText
        bogo={product.bogo || (!product.bogo && everyDayLowPrice(product))}
        unavailable={product.statusUnavailable}>
          <VisuallyHidden>price</VisuallyHidden>
          ${product.productDiscountPrice}
      </BigPriceText>
    }
    {everyDayLowPrice(product) && (!product.bogo) && (!product.tooLowToShow) &&
      <PriceRightSide className='d-inline-flex align-items-start flex-column mr-2'>
      {everyDayLowPrice(product) && (!product.tooLowToShow) && (product.discount >= 0.0) &&
        <PriceText className='align-items-center'>{((product.percentDiscount * 100).toFixed(0))}
          <span aria-label='percent off'>% OFF</span>
        </PriceText>
      }
      <DiagonalCrossedOutText>
        <VisuallyHidden>retail price</VisuallyHidden>
          ${product.everyDayLowPrice}
      </DiagonalCrossedOutText>
    </PriceRightSide>
    }
  </Container>
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: .6rem;
  ${(props) => !props.useHorizontalOrientation && `
    justify-content: center;
  `}
  ${props => props.styles}
`

const PriceText = styled.div`
  font-size: 1.4rem;
  ${props => props.highLight && css`
    color: ${props => props.theme.darkRed};
  `}
`

const BigPriceText = styled.div`
  font-size: 1.8rem;
  margin-right: .6rem;
  ${props => props.bogo && css`
    color: ${props => props.theme.darkRed};
  `}
  ${props => props.unavailable && css`
    color: ${props => props.theme.darkGrey};
  `}
  ${props => props.highLight && css`
    color: ${props => props.theme.darkRed};
  `}
`

const PriceRightSide = styled.div`
  border-left: .1rem solid ${props => props.theme.black};
  padding-left: 1rem;
`
