import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const NonGmoProjectIcon = ({
  fillColor = '#23350d',
  height = '4.1rem',
  width = '100%'
}) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    id='specialty-flag_nongmoprojectverified'
    data-name='specialty-flag nongmoprojectverified'
    viewBox='0 0 207.58 220.23'
    height={height}
    width={width}
  >
    <title>Non-GMO Verified</title>
    
    <Path fillColor={fillColor} d='M161.26,23.83C146.02,8.59,125.76.2,104.31.2s-41.82,8.39-56.95,23.63c-15.25,15.25-23.63,35.5-23.63,56.95s8.39,41.82,23.63,56.95c15.25,15.25,35.5,23.63,56.95,23.63s41.82-8.39,56.95-23.63c15.25-15.25,23.63-35.5,23.63-56.95s-8.39-41.71-23.63-56.95ZM26.26,80.79C26.26,37.73,61.14,2.73,104.31,2.73c28.82,0,53.9,15.63,67.43,38.73-9.45,8.75-20.53,20.18-30.45,33.02,3.5-9.68,4.2-18.08,2.57-22.52-2.33-6.42-10.97-3.38-13.77-2.33-2.68,1.05-8.63,5.37-14.58,13.07-5.95,7.7-5.83,9.45-5.83,9.45,0,0-5.83-13.77-9.8-20.77-3.97-7-12.02-17.27-20.07-21.93-8.52-4.9-15.4,2.68-15.98,13.07-.7,13.65,6.18,31.15,6.18,31.15,0,0-7.23,9.92-3.97,19.48,3.27,9.45,15.63,14.93,20.3,16.22,4.78,1.17,13.18,1.63,17.85,1.98s8.75,1.28,8.75,1.28c0,0-.7,1.63-.7,3.62,0,1.17.58,2.45,1.4,3.27-8.87,16.92-12.13,26.6-12.13,26.6,0,0-5.6-18.67-25.78-37.1-20.18-18.43-33.83-20.42-33.83-20.42,0,0,17.5,17.5,29.05,42.12,4.78,10.27,8.05,18.55,10.15,24.73-31.73-9.92-54.83-39.55-54.83-74.67ZM77.01,71.45c-.7-2.45-2.92-10.38-2.8-15.98.12-5.6,3.62-11.78,11.78-6.07s17.03,21,22.05,33.6,6.3,22.87,6.18,25.2c0,0,0,1.17-.23,1.63-.35.47-1.63-.47-3.03-1.17-1.28-.58-7.82-2.68-14-3.62s-11.55-2.45-16.57-5.72-5.83-8.4-5.25-12.95,3.85-8.63,3.85-8.63c0,0-1.28-3.85-1.98-6.3ZM132.31,81.02c-2.22,8.05-14.82,26.13-14.82,26.13-2.1-18.78-6.3-29.05-6.3-29.05,0,0,1.75-5.83,5.72-10.15,3.97-4.32,9.1-10.5,15.4-8.4,6.42,1.98,2.22,13.42,0,21.47ZM128.34,155.1c2.92-14.58,9.68-39.2,24.5-66.62,8.63-15.87,16.45-28.93,22.87-39.2,4.2,9.68,6.65,20.3,6.65,31.5-.12,34.77-22.75,64.17-54.02,74.32Z'/>
    <Path fillColor={fillColor} d='M104.31,3c43.08,0,78.01,34.93,78.01,78.01s-34.93,78.01-78.01,78.01S26.3,124.09,26.3,81.01,61.22,3,104.31,3M104.31,0C59.64,0,23.3,36.34,23.3,81.01s36.34,81.01,81.01,81.01,81.01-36.34,81.01-81.01S148.98,0,104.31,0h0Z'/>
    <g>
      <Path fillColor={fillColor} d='M31.32,186.48v-19.35h2.61l11.78,12.91v-12.91h3.77v19.35h-2.61l-11.78-12.94v12.94h-3.77Z'/>
      <Path fillColor={fillColor} d='M73.61,176.82c0,5.89-4.23,10.09-10.33,10.09s-10.33-4.21-10.33-10.09,4.23-10.12,10.33-10.12,10.33,4.24,10.33,10.12ZM69.72,176.82c0-3.92-2.67-6.7-6.44-6.7s-6.44,2.79-6.44,6.7,2.67,6.67,6.44,6.67,6.44-2.76,6.44-6.67Z'/>
      <Path fillColor={fillColor} d='M77.08,186.48v-19.35h2.61l11.78,12.91v-12.91h3.77v19.35h-2.61l-11.78-12.94v12.94h-3.77Z'/>
      <Path fillColor={fillColor} d='M98.89,175.2h7.54v3.25h-7.54v-3.25Z'/>
      <Path fillColor={fillColor} d='M119.89,176.13h8.88v.23c0,1.3-.17,5.37-2.84,8.01-2,2-4.47,2.55-6.87,2.55s-4.9-.49-7.02-2.61c-.67-.67-2.87-3.1-2.87-7.45,0-3.74,1.68-6.03,2.84-7.19,1.45-1.48,3.89-2.96,7.63-2.96,1.45,0,2.99.23,4.47,1.02,1.25.64,2.61,1.8,3.63,3.54l-3.28,1.77c-.67-1.31-1.65-1.97-2.26-2.29-.81-.41-1.83-.61-2.73-.61-1.54,0-3.28.55-4.47,1.71-1.1,1.07-1.94,2.93-1.94,5.11,0,2.58,1.1,4.12,1.92,4.9.87.84,2.23,1.65,4.29,1.65,1.28,0,2.73-.35,3.8-1.16.75-.58,1.51-1.57,1.83-2.96h-4.99v-3.25Z'/>
      <Path fillColor={fillColor} d='M134.85,167.13h2.73l5.13,12.3,5.37-12.3h2.73l3.25,19.35h-3.83l-1.74-11.92-5.19,11.92h-1.28l-4.96-11.92-1.97,11.92h-3.83l3.6-19.35Z'/>
      <Path fillColor={fillColor} d='M177.19,176.82c0,5.89-4.24,10.09-10.33,10.09s-10.33-4.21-10.33-10.09,4.24-10.12,10.33-10.12,10.33,4.24,10.33,10.12ZM173.31,176.82c0-3.92-2.67-6.7-6.44-6.7s-6.44,2.79-6.44,6.7,2.67,6.67,6.44,6.67,6.44-2.76,6.44-6.67Z'/>
      <Path fillColor={fillColor} d='M10.62,196.81c1.87,0,3.17.2,4.4,1.27,1.35,1.2,1.7,2.82,1.7,4.02,0,.65-.1,2.45-1.37,3.72-.92.92-2.3,1.47-4.22,1.47h-2.1v6.17h-3.25v-16.67h4.85ZM9.02,204.51h1.32c.62,0,1.42,0,2.12-.53.6-.47.9-1.22.9-1.95,0-.92-.45-1.55-.92-1.9-.67-.47-1.52-.53-2.3-.53h-1.12v4.9Z'/>
      <Path fillColor={fillColor} d='M23.94,196.81c2.3,0,3.47.6,4.25,1.25,1.27,1.07,1.65,2.5,1.65,3.75,0,1.62-.65,3.05-1.95,3.92-.42.3-1.02.58-1.82.72l5.2,7.02h-4.05l-4.45-6.7h-.4v6.7h-3.25v-16.67h4.82ZM22.37,204.48h.92c.62,0,3.2-.08,3.2-2.52s-2.55-2.5-3.15-2.5h-.97v5.02Z'/>
      <Path fillColor={fillColor} d='M50.46,205.16c0,5.07-3.65,8.7-8.9,8.7s-8.9-3.62-8.9-8.7,3.65-8.72,8.9-8.72,8.9,3.65,8.9,8.72ZM47.11,205.16c0-3.37-2.3-5.77-5.55-5.77s-5.55,2.4-5.55,5.77,2.3,5.75,5.55,5.75,5.55-2.38,5.55-5.75Z'/>
      <Path fillColor={fillColor} d='M59.41,196.81v11.25c0,1.47-.1,3.8-1.95,5.05-.72.47-1.72.75-2.72.75-1.2,0-2.62-.4-3.82-1.55l1.97-2.37c.32.47.85.9,1.62.9.7,0,1.1-.3,1.27-.58.4-.57.38-1.67.38-2.7v-10.75h3.25Z'/>
      <Path fillColor={fillColor} d='M72.46,199.61h-6v3.72h5.75v2.8h-5.75v4.55h6v2.8h-9.25v-16.67h9.25v2.8Z'/>
      <Path fillColor={fillColor} d='M87.71,212.88c-1,.52-2.32.97-4.12.97-2.92,0-4.7-1-6.1-2.32-1.92-1.85-2.67-3.92-2.67-6.37,0-3.02,1.25-5.2,2.67-6.52,1.67-1.57,3.75-2.2,6.12-2.2,1.17,0,2.57.2,4.1,1.02v3.8c-1.52-1.75-3.42-1.88-4.02-1.88-3.35,0-5.52,2.75-5.52,5.82,0,3.7,2.85,5.7,5.65,5.7,1.55,0,2.92-.67,3.9-1.82v3.8Z'/>
      <Path fillColor={fillColor} d='M96.24,199.61v13.87h-3.25v-13.87h-3.75v-2.8h10.75v2.8h-3.75Z'/>
      <Path fillColor={fillColor} d='M109.56,196.81l4.45,11.2,4.45-11.2h3.55l-7.05,16.67h-1.95l-7-16.67h3.55Z'/>
      <Path fillColor={fillColor} d='M133.21,199.61h-6v3.72h5.75v2.8h-5.75v4.55h6v2.8h-9.25v-16.67h9.25v2.8Z'/>
      <Path fillColor={fillColor} d='M141.19,196.81c2.3,0,3.47.6,4.25,1.25,1.27,1.07,1.65,2.5,1.65,3.75,0,1.62-.65,3.05-1.95,3.92-.43.3-1.03.58-1.83.72l5.2,7.02h-4.05l-4.45-6.7h-.4v6.7h-3.25v-16.67h4.82ZM139.61,204.48h.92c.62,0,3.2-.08,3.2-2.52s-2.55-2.5-3.15-2.5h-.97v5.02Z'/>
      <Path fillColor={fillColor} d='M153.96,196.81v16.67h-3.25v-16.67h3.25Z'/>
      <Path fillColor={fillColor} d='M166.61,199.61h-5.6v3.72h5.25v2.8h-5.25v7.35h-3.25v-16.67h8.85v2.8Z'/>
      <Path fillColor={fillColor} d='M172.66,196.81v16.67h-3.25v-16.67h3.25Z'/>
      <Path fillColor={fillColor} d='M185.71,199.61h-6v3.72h5.75v2.8h-5.75v4.55h6v2.8h-9.25v-16.67h9.25v2.8Z'/>
      <Path fillColor={fillColor} d='M193.21,196.81c2.33,0,4.35.25,6.2,1.62,2.02,1.52,3.2,3.95,3.2,6.72s-1.15,5.17-3.35,6.72c-1.95,1.38-3.77,1.6-6.1,1.6h-4.3v-16.67h4.35ZM192.11,210.68h1c.83,0,2.62-.05,4.07-1.1,1.32-.95,2.07-2.65,2.07-4.42s-.72-3.45-2.05-4.45c-1.35-1-3-1.1-4.1-1.1h-1v11.07Z'/>
    </g>
  </Svg>
)
const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const Path = styled.path`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`
