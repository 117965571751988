import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const GiftIcon = ({ width, height, color = '#000' }) => {
  return (
    <SvgAttr x='0px' y='0px' width={`${width}px`} height={`${height}px`}
      viewBox='0 0 600 600'>
      <title>Gift Icon</title>
      <Path color={color} d='M469.5,122.9c-2.4-0.4-66.9-0.6-66.9-0.6c9.2-12.3,14.7-27.5,14.7-44C417.3,37.9,384.4,5,344,5c-24.4,0-42.3,8.7-56.5,27.5
	    C275.6,48.1,266.9,70,256,97.7c-10.9-27.6-19.6-49.5-31.5-65.2C210.3,13.7,192.4,5,168,5c-40.4,0-73.3,32.9-73.3,73.3
	    c0,16.5,5.5,31.7,14.7,44H5.7V236h26.7c0.9,0.4,2.7,269.5,2.7,269.5h397.9c5.1,0,14.7,0,14.7,0h29.6v-44h-0.3V239.6h29.3V232v-7.8
    	V122.9H469.5z M282.9,109.3c22.5-57,31-75,61.1-75c24.3,0,44,19.7,44,44s-19.7,44-44,44h-66.2C279.6,117.8,281.3,113.5,282.9,109.3z
	    M168,34.3c30.1,0,38.6,18,61.1,75c1.6,4.1,3.3,8.5,5.1,13H168c-24.3,0-44-19.7-44-44S143.8,34.3,168,34.3z M197.3,476.2h-1H79.1
	    H63.4V239.6h1h132h1V476.2z M197.3,210.3H49.7H28.3v-58.7h21.5h147.6V210.3z M285.3,476.2h-58.7V151.6c3.1,0,41.8,0,58.7,0V476.2z
	    M447.6,461.5v14.7h-14.7H314.7V239.6h132.9V461.5z M482.8,210.3h-20.5H314.7v-58.7h147.6h20.5V210.3z' />
    </SvgAttr>
  )
}

const Path = styled.path`
 fill: ${props => props.color}
`
