import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Button } from '../../_inputs/Button/Button'
import { FocusTrap } from '../../FocusTrap/FocusTrap'
import { HelpDeskDropdown } from '../HelpDeskDropdown/HelpDeskDropdown'
import { CartDropdown } from '../CartDropdown/CartDropdown'
import { AccountDropdown } from '../AccountDropdown/AccountDropdown'
import { MobileCategoryMenu } from '../MobileCategoryMenu/MobileCategoryMenu'

export const HeaderMenuDropdown = ({
  menuType = 0,
  closeHandler = () => {},
  cartCount = 0,
  emailOpenHandler = () => {}
}) => {
  const [removeFocus, setRemoveFocus] = useState(false)
  const [tabElement, setTabElement] = useState(null)

  const escHandler = (e) => {
    if (e.which === 27) {
      if (closeHandler) {
        closeHandler()
      }
    }
  }

  const setFocus = (value, element) => {
    setRemoveFocus(value)
    setTabElement(element)
  }

  return (
    <FocusTrap show doRemove={removeFocus} elementOverride={tabElement}>
      <HeaderMenuDropdownContainer styles={menuType === 4 ? css`left: 0;` : ''}>
        <HeaderMenuDropdownDiv>
          <X
            aria-label='Close'
            minWidth='4rem'
            onKeyUp={escHandler}
            onClick={closeHandler}
          >
            <span onClick={closeHandler}>&times;</span>
          </X>
          {menuType === 1 ?
            <AccountDropdown/>
            :
            <>
              {menuType === 2 ?
                <CartDropdown cartCount={cartCount}/>
                :
                <>
                  {menuType === 3 ?
                    <HelpDeskDropdown closeHandler={closeHandler}/>
                    :
                    <>
                      {menuType === 4 &&
                        <MobileCategoryMenu setFocus={setFocus} emailOpenHandler={emailOpenHandler}/>
                      }
                    </>
                  }
                </>
              }
            </>
          }
        </HeaderMenuDropdownDiv>
      </HeaderMenuDropdownContainer>
    </FocusTrap>
  )
}

const HeaderMenuDropdownContainer = styled.div`
  display: flex;
  width: 369px;
  position: absolute;
  background-color: ${props => props.theme.white};
  z-index: 2147483001;
  top: 70px;
  right: 0;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow-y: scroll;
  max-height: 590px;
  @media (max-width: 1279px) {
    bottom: 47px;
    top: auto;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-height: 630px) and (min-height: 465px) {
    max-height: 400px;
  }
  @media (max-height: 464px) and (min-height: 355px) {
    max-height: 310px;
  }
  @media (max-height: 354px) {
    max-height: 210px;
  }
  ${props => props.styles};
`

const HeaderMenuDropdownDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
`

const X = styled(Button)`
  font-size: 2.8rem;
  background-color: transparent;
  color: ${props => props.theme.black};
  z-index: 90000000020;
  line-height: 1.3rem;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
  ${props => props.styles};
  &:focus {
    border: 1px solid ${props => props.theme.black};
    box-shadow: none;
  }
`