import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { LinkThatLooksLikeAButton } from '../../_styled/Link'
import { ShoppingCartCheckout } from '../../_svgs/ShoppingCartCheckout/ShoppingCartCheckout'

export const CartDropdown = ({ cartCount }) => {
  const cartDetails = useSelector(state => state.header?.cartDetails)

  return (
    <CartDropdownContainer data-testid='cartMenu' margin={cartCount === 0 ? '21px 66px' : '21px 16px'}>
      <CartDropdownTitleDiv>My Shopping Cart</CartDropdownTitleDiv>
      {cartCount === 0 ?
        <>
          <CartDropdownTextDiv styles={css`margin-bottom: 2.4rem;`}>Your Swanson Cart is currently empty</CartDropdownTextDiv>
          <CartDropdownTextDiv styles={css`color: ${props => props.theme.primary}; font-size: 20px;`}>Shop for:</CartDropdownTextDiv>
          <CartDropdownEmptyA
            href='/q?kw=most_reviewed+20+top_rated+4.6+most_bought'
            title='Top-Rated Products'
            data-tracking='header|cart|top-rated'
          >
            Top-Rated Products
          </CartDropdownEmptyA>
          <CartDropdownEmptyA
            href='/morewaystobuy/Buy+One%2C+Get+One/q'
            title='Buy One, Get One Free'
            data-tracking='header|cart-dropdown|bogo'
          >
            Buy One, Get One Free
          </CartDropdownEmptyA>
          <CartDropdownEmptyA
            href='/morewaystobuy/On+Sale+Now/q'
            title='On Sale Now'
            data-tracking='header|cart-dropdown|on-sale-now'
          >
            On Sale Now
          </CartDropdownEmptyA>
          <CartDropdownEmptyA
            href='/morewaystobuy/New+Arrivals/q'
            title='New Arrivals'
            data-tracking='header|cart-dropdown|new-arrivals'
          >
            New Arrivals
          </CartDropdownEmptyA>
        </>
        :
        <>
          <CartDropdownTextDiv>{cartCount} items in Shopping Cart</CartDropdownTextDiv>
          <CartDropdownItemContainer>
            {cartDetails?.sortedCartItems && cartDetails.sortedCartItems.map((item, index) => {
              return (
                <CartDropdownItemDiv>
                  <CartDropdownProductA
                    href={'/' + item.adobeRecord.longURL}
                    aria-label={item.adobeRecord.productName}
                    styles={index + 1 === cartDetails.sortedCartItems.length ? '' : css`margin-bottom: 2rem;`}
                    data-tracking={`header|cart-dropdown|product-${item.productPartNumber}`}
                  >
                    <CartDropdownProductImg
                      src={`https://media.swansonvitamins.com/images/items/150/${item.productPartNumber}.jpg`}
                      alt={`${item.adobeRecord.productVendor} ${item.adobeRecord.productName}`}
                      title={item.adobeRecord.productName}
                    />
                    <CartDropdownProductDetailsDiv>
                      <CartDropdownProductTextDiv className='cart-brandText' styles={css`color: ${props => props.theme.primary};`}>{item.adobeRecord.productVendor}</CartDropdownProductTextDiv>
                      <CartDropdownProductTextDiv>{item.adobeRecord.productName}</CartDropdownProductTextDiv>
                      <CartDropdownProductTextDiv styles={css`font-weight: 700;`}>
                          {item.quantity}&nbsp;@&nbsp;{item.adobeRecord.productDiscountPrice}&nbsp;each
                          {item.adobeRecord.bogo &&
                            <>&nbsp;+&nbsp;{item.quantity}&nbsp;free</>
                          }
                      </CartDropdownProductTextDiv>
                      <CartDropdownProductTextDiv>{item.adobeRecord.productDetails}</CartDropdownProductTextDiv>
                    </CartDropdownProductDetailsDiv>
                  </CartDropdownProductA>
                </CartDropdownItemDiv>
              )
            })}
          </CartDropdownItemContainer>
          <CartDropdownSubtotalDiv>
            <SubtotalTextDiv>Subtotal:</SubtotalTextDiv>
            <SubtotalTextDiv>{(cartDetails?.totalDiscountPrice)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</SubtotalTextDiv>
          </CartDropdownSubtotalDiv>
          <CartDropdownAContainer>
            <CartDropdownA
              href='/cart'
              aria-label='View my Cart'
              data-tracking='header|cart-dropdown|view-cart'
            >
              View My Cart
            </CartDropdownA>
          </CartDropdownAContainer>
          <CartDropdownAContainer>
            <CartDropdownBtnA
              href='/order'
              aria-label='Checkout Now'
              data-tracking='header|cart-dropdown|checkout'
            >
              <ShoppingCartCheckout/> Checkout Now
            </CartDropdownBtnA>
          </CartDropdownAContainer>
        </>
      }
    </CartDropdownContainer>
  )
}

const CartDropdownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: ${props => props.margin};
`

const CartDropdownTitleDiv = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: 700;
  width: 100%;
  justify-content: center;
  margin-bottom: 2.4rem;
  color: ${props => props.theme.primary};
`

const CartDropdownTextDiv = styled.div`
  display: flex;
  width: 100%;
  font-weight: 700;
  justify-content: center;
  margin-bottom: 2rem;
  ${props => props.styles};
`

const CartDropdownEmptyA = styled.a`
  display: flex;
  width: 100%;
  font-weight: 700;
  justify-content: center;
  text-decoration: none;
  color: ${props => props.theme.black};
  &:hover {
    color: ${props => props.theme.primary};
    text-decoration: underline;
  }
  ${props => props.styles};
`

const CartDropdownItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 349px;
  width: 100%;
  overflow-y: auto;
  @media (max-height: 715px) and (min-height: 635px) {
    max-height: 200px;
  }
  @media (max-height: 634px) {
    max-height: 105px;
  }
`

const CartDropdownItemDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
`

const CartDropdownProductImg = styled.img`
  display: flex;
  width: 80px;
  max-height: 80px;
  max-width: 100%;
`

const CartDropdownProductDetailsDiv = styled.div`
  display: flex; 
  flex-wrap: wrap;
  width: calc(100% - 80px);
`

const CartDropdownProductTextDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  ${props => props.styles};
`

const CartDropdownProductA = styled.a`
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  color: black;
  &:hover {
    color: inherit;
    text-decoration: none;
    .cart-brandText {
      text-decoration: underline;
    }
  }
  ${props => props.styles};
`

const CartDropdownSubtotalDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 3rem 0 2rem 0;
`

const SubtotalTextDiv = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 1.6rem;
`

const CartDropdownA = styled.a`
  text-decoration: none;
  color: ${props => props.theme.primary};
  margin-bottom: 3rem;
  font-weight: bold;
  font-size: 16px;
  &:hover {
    text-decoration: underline !important;
    color: ${props => props.theme.primary};
  }
`

const CartDropdownBtnA = styled(LinkThatLooksLikeAButton)`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  border-radius: 8px;
  border: none;
  padding: 16px 30px;
  font-size: 20px;
  &:hover {
    background-color: ${props => props.theme.buttercupYellow};
    color: ${props => props.theme.black};
    border: none;
    path {
      fill: ${props => props.theme.black};
    }
  }
`

const CartDropdownAContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`