import React from 'react'
import styled from 'styled-components'

import { SvgAttr } from '../SvgAttr/SvgAttr'

export const VegetarianIcon = ({
  fillColor = '#23350d',
  height = '4.1rem',
  width = '100%'
}) => (
  <Svg 
    xmlns='http://www.w3.org/2000/svg' 
    id='specialty-flag_vegetarian' 
    data-name='specialty-flag vegetarian'
    viewBox='0 0 196.78 198.69'
    height={height}
    width={width}
  >
    <title>Vegetarian</title>

    <g>
      <Path fillColor={fillColor} d='M156.2,23.77C140.87,8.44,120.49,0,98.91,0s-42.07,8.44-57.3,23.77c-15.34,15.34-23.77,35.71-23.77,57.3s8.33,41.96,23.66,57.3c15.34,15.34,35.71,23.77,57.3,23.77s42.07-8.44,57.3-23.77c15.23-15.34,23.77-35.71,23.77-57.3s-8.33-42.07-23.66-57.3ZM98.8,159.13c-43.06,0-78.07-34.89-78.07-78.07S55.62,3,98.8,3c26.84,0,50.53,13.54,64.53,34.07-6.65-1.05-16.1-1.63-26.61.23-20.19,3.73-35.01,10.04-42.13,29.17-4.32,11.55-.47,22.4,3.62,28.59,1.4-3.15,3.62-7.7,7.23-12.95-.7-1.63-1.98-5.13-1.87-9.57.12-6.18,1.17-7.35,1.17-7.35,0,0,.47,8.17,3.38,13.07h0c1.63-2.1,3.38-4.43,5.37-6.77.82-1.05,1.75-1.98,2.57-2.8h0s-2.45-6.3-.93-13.19c1.52-6.77,3.03-8.05,3.03-8.05,0,0-1.52,7.35,0,12.37.58,2.1,1.28,3.62,1.87,4.55,3.85-3.85,7.35-6.65,10.62-8.99h0s-1.4-2.45-.82-5.6c.58-3.15,2.33-5.02,2.33-5.02,0,0-1.4,5.13.93,8.99h0c4.43-2.92,8.29-4.67,11.55-6.53,9.57-5.25,20.42-5.95,20.42-5.95,0,0-7.35,6.3-13.19,10.97-5.72,4.67-18.9,16.45-26.49,35.59-4.2,10.62-6.65,17.27-8.05,21.12,9.1,2.22,18.32.23,26.14-7.35,10.62-10.27,13.77-22.99,16.8-34.07,2.45-8.75,5.83-15.99,8.64-21,5.13,10.39,7.94,22.05,7.94,34.42,0,43.18-34.89,78.18-78.07,78.18Z'/>
      <Path fillColor={fillColor} d='M155.04,45.83s-13.07,3.97-31.04,22.17c-18.2,18.44-31.27,52.51-31.27,52.51L58.54,48.39h-24.39l46.44,95.57h19.6s11.9-46.09,24.27-67.33c12.6-21.47,30.57-30.81,30.57-30.81Z'/>
    </g>
    <g>
      <Path fillColor={fillColor} d='M18.44,171.51l5.16,13,5.16-13h4.12l-8.18,19.35h-2.26l-8.12-19.35h4.12Z'/>
      <Path fillColor={fillColor} d='M45.88,174.76h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M59.33,180.5h8.88v.23c0,1.3-.17,5.37-2.84,8.01-2,2-4.47,2.55-6.88,2.55s-4.9-.49-7.02-2.61c-.67-.67-2.87-3.1-2.87-7.45,0-3.74,1.68-6.03,2.84-7.19,1.45-1.48,3.89-2.96,7.63-2.96,1.45,0,2.99.23,4.47,1.02,1.25.64,2.61,1.8,3.63,3.54l-3.28,1.77c-.67-1.31-1.65-1.97-2.26-2.29-.81-.41-1.83-.61-2.73-.61-1.54,0-3.28.55-4.47,1.71-1.1,1.07-1.94,2.93-1.94,5.11,0,2.58,1.1,4.12,1.91,4.9.87.84,2.23,1.65,4.29,1.65,1.28,0,2.73-.35,3.8-1.16.75-.58,1.51-1.57,1.83-2.96h-4.99v-3.25Z'/>
      <Path fillColor={fillColor} d='M82.42,174.76h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M92.77,174.76v16.1h-3.77v-16.1h-4.35v-3.25h12.47v3.25h-4.35Z'/>
      <Path fillColor={fillColor} d='M109.82,186.8h-7.8l-1.71,4.06h-4.09l8.32-19.35h2.96l8.09,19.35h-4.06l-1.71-4.06ZM108.72,183.72l-2.75-6.96-2.76,6.96h5.51Z'/>
      <Path fillColor={fillColor} d='M123.45,171.51c2.67,0,4.03.7,4.93,1.45,1.48,1.25,1.91,2.9,1.91,4.35,0,1.88-.75,3.54-2.26,4.55-.49.35-1.19.67-2.12.84l6.03,8.15h-4.7l-5.16-7.77h-.46v7.77h-3.77v-19.35h5.6ZM121.62,180.41h1.07c.72,0,3.71-.09,3.71-2.93s-2.96-2.9-3.66-2.9h-1.13v5.83Z'/>
      <Path fillColor={fillColor} d='M138.27,171.51v19.35h-3.77v-19.35h3.77Z'/>
      <Path fillColor={fillColor} d='M154.13,186.8h-7.8l-1.71,4.06h-4.09l8.33-19.35h2.96l8.09,19.35h-4.06l-1.71-4.06ZM153.03,183.72l-2.75-6.96-2.76,6.96h5.51Z'/>
      <Path fillColor={fillColor} d='M162.16,190.86v-19.35h2.61l11.78,12.91v-12.91h3.77v19.35h-2.61l-11.78-12.94v12.94h-3.77Z'/>
    </g>
  </Svg>
)

const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const Path = styled.path`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`
