import React, { useState } from 'react'
import styled from 'styled-components'
import { ButtonThatLookLikeLink } from '../../_inputs/Button/Button'
import { HeaderGreenBannerEchoText } from '../HeaderGreenBannerEchoText/HeaderGreenBannerEchoText'
import { SignupTag } from '../../_svgs/SignupTag/SignupTag'
import { HeaderGreenBannerEchoContent } from '../HeaderGreenBannerEchoContent/HeaderGreenBannerEchoContent'

export const HeaderGreenBanner = ({
  emailOpenHandler = () => {}
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const isIntl = window ? (window?.isIntl ? window.isIntl : false) : false

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <>
      <HeaderGreen role='banner' data-testid='header-green-container'>
        <SkipToMain href='#mainContent'>
          <SkipSpan>Skip to main content</SkipSpan>
        </SkipToMain>
        <HeaderGreenBannerEchoText
          toggleDropdown={toggleDropdown}
        />
        {!isIntl &&
          <HeaderGreenBannerButton
            data-testid='signupDealsBtn'
            data-tracking='header|email-signup'
            className='header-signup klaviyo_form_trigger'
            onClick={emailOpenHandler}
            aria-label='Sign up for deals and tips'
          >
            <SignupTag/>&nbsp;Sign Up for Deals!
          </HeaderGreenBannerButton>
        }
      </HeaderGreen>
      <HeaderGreenBannerEchoContent
        show={showDropdown}
        toggleDropdown={toggleDropdown}
      />
  </>
  )
}

const SkipToMain = styled.a`
  position: absolute;
  top: -4rem;
  &:focus {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.2rem 0 0.2rem 0.2rem;
    background-color: rgba(84, 84, 84, 0.3);
    height: 100%;
    top: 0;
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 765px) {
    display: none;
    &:focus {
      display: none;
    }
  }
`
const SkipSpan = styled.span`
  border-radius: 0;
  border: 1px ${props => props.theme.black} dotted;
  background-color: ${props => props.theme.buttercupYellow};
  padding: 0.5rem 2rem;
  color: ${props => props.theme.black};
`

const HeaderGreen = styled.div`
  display: flex;
  width: 100%;
  background-color: ${props => props.theme.turtleGreen};
  color: ${props => props.theme.white};
  padding: 0.1rem 8px;
  min-height: 4rem;
  position: relative;
  @media (max-width: 765px) {
    top: 0;
  }
`
const HeaderGreenBannerButton = styled(ButtonThatLookLikeLink)`
  color: ${props => props.theme.white};
  padding: 0 0.5rem;
  text-decoration: none;
  height: auto;
  min-height: auto;
  display: flex;
  align-items: center;
  border: 0;
  visibility: visible;
  width: 170px;
  font-size: 1.6rem;
  &:hover,
  &:focus{
    color: ${props => props.theme.white};
    text-decoration: none;
    cursor: pointer;
  }
  @media (max-width: 1279px) {
    display: none;
  }
`
