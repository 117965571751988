import { css } from 'styled-components'

export const Row = css`
   display: flex;
   flex-wrap: wrap;
   margin-right: -5px;
   margin-left: -5px;
`
export const DisplayMdBlock = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
  display: block;
 }
`
export const Col = css`
 position: relative;
 width: 100%;
 padding-right: 5px;
 padding-left: 5px;
`
export const Col1 = css`
 flex: 0 0 8.33333%;
 max-width: 8.33333%;
 ${Col};
`
export const ColSm1 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  ${Col};
 }
`
export const ColMd1 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  ${Col};
 }
`
export const ColL1 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  ${Col};
 }
`
export const ColXl1 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  ${Col};
 }
`
export const Col2 = css`
 flex: 0 0 16.666667%;
 max-width: 16.666667%;
 ${Col};
`
export const ColSm2 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  ${Col};
 }
`
export const ColMd2 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  ${Col};
 }
`
export const ColL2 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  ${Col};
 }
`
export const ColXl2 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  ${Col};
 }
`
export const Col3 = css`
 flex: 0 0 25%;
 max-width: 25%;
 ${Col};
`
export const ColSm3 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
  flex: 0 0 25%;
  max-width: 25%;
  ${Col};
 }
`
export const ColMd3 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
  flex: 0 0 25%;
  max-width: 25%;
  ${Col};
 }
`
export const ColL3 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex: 0 0 25%;
    max-width: 25%;
    ${Col};
 }
`
export const ColXl3 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
    flex: 0 0 25%;
    max-width: 25%;
    ${Col};
 }
`
export const Col4 = css`
 flex: 0 0 33.33333%;
 max-width: 33.33333%;
 ${Col};
`
export const ColSm4 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  ${Col};
 }
`
export const ColMd4 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  ${Col};
 }
`
export const ColL4 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  ${Col};
 }
`
export const ColXl4 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  ${Col};
 }
`
export const Col5 = css`
 flex: 0 0 41.66667%;
 max-width: 41.66667%;
 ${Col};
`
export const ColSm5 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  ${Col};
 }
`
export const ColMd5 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  ${Col};
 }
`
export const ColL5 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  ${Col};
 }
`
export const ColXl5 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  ${Col};
 }
`
export const Col6 = css`
 flex: 0 0 50%;
 max-width: 50%;
 ${Col};
`
export const ColSm6 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
   flex: 0 0 50%;
   max-width: 50%;
   ${Col};
 }
`
export const ColMd6 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
   flex: 0 0 50%;
   max-width: 50%;
   ${Col};
 }
`
export const ColL6 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
   flex: 0 0 50%;
   max-width: 50%;
   ${Col};
 }
`
export const ColXl6 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
   flex: 0 0 50%;
   max-width: 50%;
   ${Col};
 }
`
export const Col7 = css`
 flex: 0 0 58.33333%;
 max-width: 58.33333%;
 ${Col};
`
export const ColSm7 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
   flex: 0 0 58.33333%;
   max-width: 58.33333%;
   ${Col};
 }
`
export const ColMd7 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
   flex: 0 0 58.33333%;
   max-width: 58.33333%;
   ${Col};
 }
`
export const ColL7 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
   flex: 0 0 58.33333%;
   max-width: 58.33333%;
   ${Col};
 }
`
export const ColXl7 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
   flex: 0 0 58.33333%;
   max-width: 58.33333%;
   ${Col};
 }
`
export const Col8 = css`
 flex: 0 0 66.66667%;
 max-width: 66.66667%;
 ${Col};
`
export const ColSm8 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
   flex: 0 0 66.66667%;
   max-width: 66.66667%;
   ${Col};
 }
`
export const ColMd8 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
   flex: 0 0 66.66667%;
   max-width: 66.66667%;
   ${Col};
 }
`
export const ColL8 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
   flex: 0 0 66.66667%;
   max-width: 66.66667%;
   ${Col};
 }
`
export const ColXl8 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
   flex: 0 0 66.66667%;
   max-width: 66.66667%;
   ${Col};
 }
`
export const Col9 = css`
 flex: 0 0 75%;
 max-width: 75%;
 ${Col};
`
export const ColSm9 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
   flex: 0 0 75%;
   max-width: 75%;
   ${Col};
 }
`
export const ColMd9 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
   flex: 0 0 75%;
   max-width: 75%;
   ${Col};
 }
`
export const ColL9 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
   flex: 0 0 75%;
   max-width: 75%;
   ${Col};
 }
`
export const ColXl9 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
   flex: 0 0 75%;
   max-width: 75%;
   ${Col};
 }
`
export const Col10 = css`
 flex: 0 0 83.33333%;
 max-width: 83.33333%;
 ${Col};
`
export const ColSm10 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
   flex: 0 0 83.33333%;
   max-width: 83.33333%;
   ${Col};
 }
`
export const ColMd10 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
   flex: 0 0 83.33333%;
   max-width: 83.33333%;
   ${Col};
 }
`
export const ColL10 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
   flex: 0 0 83.33333%;
   max-width: 83.33333%;
   ${Col};
 }
`
export const ColXl10 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
   flex: 0 0 83.33333%;
   max-width: 83.33333%;
   ${Col};
 }
`
export const Col11 = css`
 flex: 0 0 91.66667%;
 max-width: 91.66667%;
 ${Col};
`
export const ColSm11 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
   flex: 0 0 91.66667%;
   max-width: 91.66667%;
   ${Col};
 }
`
export const ColMd11 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
   flex: 0 0 91.66667%;
   max-width: 91.66667%;
   ${Col};
 }
`
export const ColL11 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
   flex: 0 0 91.66667%;
   max-width: 91.66667%;
   ${Col};
 }
`
export const ColXl11 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
   flex: 0 0 91.66667%;
   max-width: 91.66667%;
   ${Col};
 }
`
export const Col12 = css`
 flex: 0 0 100%;
 max-width: 100%;
 ${Col};
`
export const ColSm12 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}){
   flex: 0 0 100%;
   max-width: 100%;
   ${Col};
 }
`
export const ColMd12 = css`
 @media (min-width: ${props => props.theme.breakpoints.md}) {
   flex: 0 0 100%;
   max-width: 100%;
   ${Col};
 }
`
export const ColL12 = css`
 @media (min-width: ${props => props.theme.breakpoints.lg}) {
   flex: 0 0 100%;
   max-width: 100%;
   ${Col};
 }
`
export const ColXl12 = css`
 @media (min-width: ${props => props.theme.breakpoints.xl}) {
   flex: 0 0 100%;
   max-width: 100%;
   ${Col};
 }
`

export const MLSM0 = css`
 @media (min-width: ${props => props.theme.breakpoints.sm}) {
  margin-left: 0;
 }
`

export const VisuallyHiddenStyles = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export const FluidImage = css`
  max-width: 100%;
  height: auto;
`
