import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const TriangleWarning = ({
  width,
  height,
  polygonFill = '#FFFFFF',
  polygonStroke = '#FC000F',
  pathFill = '#FC000F',
  styles
}) => {
  return (
    <Svg
      styles={styles}
      height={height}
      width={width}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
    >
      <title>exclamation point</title>
      <g>
        <Polygon
          fill={polygonFill}
          stroke={polygonStroke}
          points="24,11.58 7.1,40.86 40.9,40.86 "
        />
        <Path
          fill={pathFill}
          d="M22.78,34.79c0-0.74,0.53-1.29,1.22-1.29c0.69,0,1.22,0.55,1.22,1.29c0,0.73-0.53,1.28-1.22,1.28
          C23.31,36.07,22.78,35.52,22.78,34.79z M23.11,26.65c0,0-0.06-1.04-0.06-1.96c0-0.92,1.91-0.74,1.91,0c0,1-0.06,1.96-0.06,1.96
          s-0.3,3.96-0.26,4.84c0.03,0.88-1.22,0.91-1.26,0C23.33,30.59,23.11,26.65,23.11,26.65z"
        />
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  ${props => props.styles};
`

const Polygon = styled.polygon`
  stroke: ${props => props.stroke};
  fill: ${props => props.fill};
`

const Path = styled.path`
  fill: ${props => props.fill};
`