/* WARNING: CHANGES MADE HERE AFFECT WHOLE WEBSITE */

import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { formClearAction, formInitAction, formSubmitAction, formUpdateAction } from '../../../actions/formActions'

const ConnectedComponent = (props) => {
  const refArray = useRef([])
  const addToRefsArray = (name, ref) => {
    if (ref && !refArray.current.includes(ref)) {
      refArray.current.push({ name: name, ref: ref })
    }
  }

  useEffect(() => {
    if (props.errorStates && Object.keys(props.errorStates).length !== 0) {
      refArray.current.every((refItem) => {
        if (props.errorStates.hasOwnProperty(refItem.name)) {
          console.log(refItem)
          refItem.ref.focus()
          return false
        }
        return true
      })
    }
  }, [props.errorStates])

  return (
    <props.WrappedForm
      {...props}
      addToRefsArray={addToRefsArray}
      updateForm={props.formUpdate}
      submitForm={props.externalSubmit ? props.externalSubmit : props.formSubmit}
      initForm={props.formInit}
      clearForm={props.formClear}
      errorStates={props.errorStates}
      formState={props.formState}
      refArray={refArray}
    />
  )
}

const mapDispatchToProps = dispatch => (
  {
    formUpdate: (update) => (dispatch(formUpdateAction(update))),
    formSubmit: (formName) => (dispatch(formSubmitAction(formName))),
    formInit: (formName, defaultValues) => (dispatch(formInitAction(formName, defaultValues))),
    formClear: (formData) => (dispatch(formClearAction(formData)))
  }
)

const mapStateToProps = state => {
  return {
    formState: state.forms,
    errorStates: state.errors
  }
}

export const Form = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
