import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { MdMin, MdMax } from '../../Responsive/Responsive'
import { FocusTrap } from '../../FocusTrap/FocusTrap'
import { trackingStrip } from '../../../tealium'
import { HeaderCatFeaturedSpot } from '../HeaderCatFeaturedSpot/HeaderCatFeaturedSpot';

export const CategoryMenuContent = ({
  classes,
  category,
  closeHandler,
  additionalATCCode,
  globalState,
  isOpen
}) => {
  const [menuTitle, setMenuTitle] = useState('none')
  const mouseHandler = () => {
    setMenuTitle('none')
  }

  const ref = useRef()
  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [category])

  if (category === -1) {
    return null
  }
  return (
    <FocusTrap show={category + 2} styles={css`
      display: flex;
      flex-grow: 1;
      padding-left: 5rem;
      padding-right: 5rem;
      visibility: ${isOpen ? 'visible' : 'hidden !important'};
      @media (max-width: ${props => props.theme.breakpoints.md}) {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
      }
      @media (min-width: 1024px) {
        max-width: 1024px;
        position: relative;
      }
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}>
      <MdMin>
        <XDesktop
          ref={ref}
          as='button'
          aria-expanded={isOpen}
          aria-label='Close'
          className={`cTest_header-${globalState.categoryResultList[category].title.replace(/[^A-Z]/g, '')}-close`}
          onClick={closeHandler}
          fontSize='4rem'
        >
          &times;
        </XDesktop>
      </MdMin>
      <CategoryMenuUl
        data-testid='lowerNav'
        className={classes}
        menuTitle={menuTitle}
        onMouseLeave={mouseHandler}
        hasHighLightSpot={globalState.categoryResultList[category].categoryResultList.length < 7}
      >
        <StickyLi>
          <MdMax>
            <X ref={ref} as='button' data-testid='cat-menu-content-back' href='#toTop' onClick={closeHandler} fontSize='1.6rem'>
              &laquo; back
            </X>
          </MdMax>
          <MainHeadline
            as='a'
            aria-label={globalState.categoryResultList[category].title}
            data-target={globalState.categoryResultList[category].title}
            data-tracking={`header|${trackingStrip(globalState.categoryResultList[category].title)}|${trackingStrip(globalState.categoryResultList[category].title)}`}
            className={`bypassInterstitialPage header_link-tracking cTest_header-${globalState.categoryResultList[category].title.replace(/[^A-Z]/g, '')}-${globalState.categoryResultList[category].title.replace(/['&.]/g, '').replace(/[\s\s+ ]/g, '-')}`}
            href={globalState?.categoryResultList[category].dimensionUri.seoURL}
          >
            {globalState.categoryResultList[category].title}
          </MainHeadline>
        </StickyLi>
        {globalState.categoryResultList[category].categoryResultList.map((list, index) => (
          <>
            {category === 0 && index === 8 &&
              <OtherHeadline>Other Top Categories</OtherHeadline>
            }
            {/*This is temporary until dropdown collection is moved from GroupBy to Constructor. This code should be removed then. START*/}
            {(globalState.categoryResultList[category].title === 'Vitamins and Supplements' && list.title === 'Letter Vitamins') ?
              <CategoryMenuLiContainer>
                <CategoryMenuSpecialLi
                  key={`categoryResultList${index}`}
                >
                  <SecondaryA
                    data-target={`${globalState.categoryResultList[category].title}|${list.title}`}
                    className={`bypassInterstitialPage header_link-tracking cTest_header-${globalState.categoryResultList[category].title.replace(/[^A-Z]/g, '')}-${list.title.replace(/['&.,]/g, '').replace(/[\s\s+ ]/g, '-')}`}
                    href={list.dimensionUri.seoURL}
                    data-tracking={`header|${trackingStrip(globalState.categoryResultList[category].title)}|${trackingStrip(list.title)}`}
                  >
                    {`${list.title}`}
                  </SecondaryA>
                  {globalState.dropdownMap[globalState.categoryResultList[category].title].subs[index].subs !== null &&
                    <CategoryMenuInnerUl>
                      {list.categoryResultList.slice(0, globalState.categoryResultList[category].categoryResultList.length > 4 ? (category === 0 ? 4 : 5) : list.categoryResultList.length).map((innerList, index) => (
                        <CategoryMenuInnerLi key={`categoryResultList${index}`}>
                          <A
                            data-target={`${globalState.categoryResultList[category].title}|${list.title}|${innerList.title}`}
                            className={`bypassInterstitialPage cTest_header-${globalState.categoryResultList[category].title.replace(/[^A-Z]/g, '')}-${innerList.title.replace(/['&.,]/g, '').replace(/[\s\s+ ]/g, '-')}`}
                            href={innerList.dimensionUri.seoURL}
                            data-tracking={`header|${trackingStrip(globalState.categoryResultList[category].title)}|${trackingStrip(list.title)}|${trackingStrip(innerList.title)}`}
                          >
                            {innerList.title}
                          </A>
                        </CategoryMenuInnerLi>
                      ))}
                    </CategoryMenuInnerUl>
                  }
                </CategoryMenuSpecialLi>
                <CategoryMenuSpecialLi
                  key='categoryResultList3'
                  styles={css`margin: 3rem 0 0.8rem 0;`}
                >
                  <SecondaryA
                    data-target="Vitamins and Supplements|Men's Health"
                    className='bypassInterstitialPage header_link-tracking cTest_header-VS-Mens-Health'
                    href='/mens-health'
                    data-tracking='header|vitamins-and-supplements|mens-health'
                  >
                    Men's Health
                  </SecondaryA>
                </CategoryMenuSpecialLi>
                <CategoryMenuSpecialLi key='categoryResultList4'>
                  <SecondaryA
                    data-target="Vitamins and Supplements|Women's Health"
                    className='bypassInterstitialPage header_link-tracking cTest_header-VS-Womens-Health'
                    href='/womens-health'
                    data-tracking='header|vitamins-and-supplements|womens-health'
                  >
                    Women's Health
                  </SecondaryA>
                </CategoryMenuSpecialLi>
                {/*This is temporary until dropdown collection is moved from GroupBy to Constructor. This code should be removed then. END*/}
              </CategoryMenuLiContainer>
            :
              <CategoryMenuLi
                key={`categoryResultList${index}`}
                hasHighLightSpot={globalState.categoryResultList[category].categoryResultList.length < 7}
              >
                <SecondaryA
                  data-target={`${globalState.categoryResultList[category].title}|${list.title}`}
                  className={`bypassInterstitialPage header_link-tracking cTest_header-${globalState.categoryResultList[category].title.replace(/[^A-Z]/g, '')}-${list.title.replace(/['&.,]/g, '').replace(/[\s\s+ ]/g, '-')}`}
                  href={list.dimensionUri.seoURL}
                  data-tracking={`header|${trackingStrip(globalState.categoryResultList[category].title)}|${trackingStrip(list.title)}`}
                >
                  {`${list.title}`}
                </SecondaryA>
                {globalState.dropdownMap[globalState.categoryResultList[category].title].subs[index].subs !== null &&
                  <CategoryMenuInnerUl>
                    {list.categoryResultList.slice(0, globalState.categoryResultList[category].categoryResultList.length > 4 ? (category === 0 ? 4 : 5) : list.categoryResultList.length).map((innerList, index) => (
                      <CategoryMenuInnerLi key={`categoryResultList${index}`}>
                        <A
                          data-target={`${globalState.categoryResultList[category].title}|${list.title}|${innerList.title}`}
                          className={`bypassInterstitialPage cTest_header-${globalState.categoryResultList[category].title.replace(/[^A-Z]/g, '')}-${innerList.title.replace(/['&.,]/g, '').replace(/[\s\s+ ]/g, '-')}`}
                          href={innerList.dimensionUri.seoURL}
                          data-tracking={`header|${trackingStrip(globalState.categoryResultList[category].title)}|${trackingStrip(list.title)}|${trackingStrip(innerList.title)}`}
                        >
                          {innerList.title}
                        </A>
                      </CategoryMenuInnerLi>
                    ))}
                  </CategoryMenuInnerUl>
                }
              </CategoryMenuLi>
            }
          </>
        ))}
      </CategoryMenuUl>
      {globalState.categoryResultList[category].categoryResultList.length < 7 &&
        <SpotDiv>
          <HeaderCatFeaturedSpot
            globalState={globalState}
            currentIndex={category}
            additionalATCCode={additionalATCCode}
          />
        </SpotDiv>
      }
    </FocusTrap>
  )
}
const X = styled.a`
  font-size: ${props => props.fontSize || '4rem'};
  color: ${props => props.theme.black};
  padding: 0 1rem;
  margin-left: auto;
  white-space: nowrap;
  background: transparent;
  position: absolute;
  right: 0;
  border: 0;
  text-decoration: none !important;
  z-index: 1;
`

const MainHeadline = styled.h3`
  letter-spacing: 1px;
  font-size: 2.4rem;
  font-weight: bold;
  margin: 0 auto 4rem auto;
  text-decoration: none;
  color: #2F711C;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin: 0;
  }
  &:hover {
    text-decoration: underline !important;
    color: #2F711C !important;
  }
`

const OtherHeadline = styled.h3`
  text-align: center;
  width: 100%;
  color: #2F711C;
`

const XDesktop = styled(X)`
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  line-height: 3rem;
  top: 0;
  right: 0;
`

const A = styled.a`
  text-decoration: none;
  color: ${props => props.theme.black};
  white-space: nowrap;
  text-overflow: ellipsis; 
  overflow: hidden;
  height: 3rem;
  line-height: 3rem;
  display: block;
  vertical-align: middle;
  margin-right: .4rem;
  &:hover {
    text-decoration: underline !important;
    color: #2F711C;
  }
`

const SecondaryA = styled.a`
  color: #2F711C;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3rem;
  line-height: 3rem;
  display: block;
  vertical-align: middle;
  font-weight: bold;
  margin-right: .4rem;
  &:hover {
    color: #2F711C !important;
    text-decoration: underline !important;
  }
`

const StickyLi = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  top: 0;
  @media (max-width: ${props => props.theme.breakpoints.md}){
    background-color: ${props => props.theme.white};
    position: sticky;
  }
`

const CategoryMenuUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-self: start;
  list-style: none;
  padding: 0;
  margin: 0;
  ${props => props.hasHighLightSpot && css`
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 66%;
      flex-grow: 1;
    }
  `};
  @media(min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 100%;
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const SpotDiv = styled.div`
  width: 33%;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
  @media(min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none
  }
`

const CategoryMenuLi = styled.li`
  width: ${props => props.hasHighLightSpot ? '33%' : '25%'};
  margin: 0 0 2rem 0;
  
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 50%;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 33%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`

const CategoryMenuInnerLi = styled.li`
  margin: 0;
  color: ${props => props.theme.black} !important;
  &:hover {
    color: ${props => props.theme.darkOrange} !important;
  }
 
`

const CategoryMenuInnerUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const CategoryMenuLiContainer = styled.div`
  width: 33%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`

const CategoryMenuSpecialLi = styled.li`
  width: 100%;
  ${props => props.styles};
`