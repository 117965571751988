import { NOTIFICATION_HIDE, NOTIFICATION_SHOW } from '../constants/notificationConstants'
const initialState = {
  time: 0,
  background: '',
  notificationText: '',
  buttonText: '',
  active: false }

const notificationReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_SHOW:
      return { ...reduxState, ...action.payload }

    case NOTIFICATION_HIDE:
      return { ...reduxState, ...reduxState.notification, active: false }

    default:
      return reduxState
  }
}

export default notificationReducer
