import React from 'react'
import styled from 'styled-components'

export const Accessibility = (props) => {
  return (
    <Svg x='0px' y='0px' viewBox='0 0 213.9 80.4' className={`${props.classes}`} width={`${props.width}px`}>
      <title>Essential Accessibility Icon</title>
      <g> <path fill='rgb(188,217,234)' d='M66.7,80.4H40.2C18,80.4,0,62.4,0,40.2v0C0,18,18,0,40.2,0l26.5,0c22.2,0,40.2,18,40.2,40.2v0 C106.9,62.4,88.9,80.4,66.7,80.4z' />
        <path fill='rgb(188,217,234)' d='M173.7,80.4h-26.5c-22.2,0-40.2-18-40.2-40.2v0c0-22.2,18-40.2,40.2-40.2l26.5,0c22.2,0,40.2,18,40.2,40.2v0 C213.9,62.4,195.9,80.4,173.7,80.4z' />
        <circle fill='rgb(255,255,255)' cx='107.6' cy='40.2' r='27' /><path fill='rgb(255,255,255)' d='M37.4,64.7L37.4,64.7L37.4,64.7z' />
        <g> <path fill='rgb(16,60,96)' d='M45.7,23.2c2.3,0,4.1-1.8,4.1-4.1S48,15,45.7,15c-2.3,0-4.1,1.8-4.1,4.1S43.4,23.2,45.7,23.2z' />
          <path fill='rgb(16,60,96)' d='M56.5,50.4c-0.8,0.3-1.4,0.9-1.6,1.8l0,0.1c-1.2,3.6-4,6.5-7.8,7.7c-1.6,0.5-3.3,0.6-4.9,0.4l0,0h0 c-2.4-0.4-4.8-1.6-6.5-3.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1-0.1-0.1-0.1-0.1-0.2 c-0.9-1.2-1.7-2.6-2.2-4.2l0,0c-0.5-1.7-0.7-3.5-0.4-5.4c0.6-4,3-7.3,6.2-9.1l0.1-0.1c0.8-0.3,1.3-1,1.5-1.9 c0.2-1.3-0.7-2.6-2-2.8c-0.5-0.1-1,0-1.4,0.2h0c-4.8,2.4-8.4,7.2-9.2,13c-0.4,2.7-0.1,5.4,0.6,7.8l0,0 c2.8,8.9,11.9,13.9,20.6,11.2c5.5-1.7,9.5-6,11.1-11.3l0,0c0.1-0.4,0.2-0.9,0-1.4C59.1,50.7,57.8,50,56.5,50.4z' />
          <path fill='rgb(16,60,96)' d='M75.9,53.7c-0.5-0.9-1.6-1.2-2.5-0.7l-2.7,1.5l-6.9-10.4c-0.3-0.4-0.8-1.2-2.1-1.3h-13v-4.1h9 c1.3,0,2.3-1.2,2.3-2.6c0-1.4-1-2.6-2.3-2.6h-9v-5.4c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9v17c0,1.5,1.2,2.8,2.7,2.9 c0.2,0,0.4,0,0.6,0h15.1l6.6,9.9c0.3,0.5,0.8,0.8,1.3,0.9c0.1,0,0.3,0,0.4,0.1c0,0,0,0,0.1,0c0.2,0,0.5,0,0.7-0.1c0,0,0.1,0,0.1,0 c0,0,0.1,0,0.1,0l4.7-2.6C76.1,55.7,76.4,54.6,75.9,53.7z' />
        </g>
        <path fill='rgb(16,60,96)' d='M180.9,64.4c0,0.2-0.2,0.4-0.4,0.4h-36.2c-0.2,0-0.4-0.2-0.4-0.4V51.2c0-0.2,0.2-0.4,0.4-0.4h36.2 c0.2,0,0.4,0.2,0.4,0.4V64.4 M157.4,57.1h3.6c0.2,0,0.4-0.2,0.4-0.4v-3.6c0-0.2-0.2-0.4-0.4-0.4h-3.6c-0.2,0-0.4,0.2-0.4,0.4v3.6 C157,56.9,157.2,57.1,157.4,57.1 M150.7,58.4h-3.6c-0.2,0-0.4,0.2-0.4,0.4v3.6c0,0.2,0.2,0.4,0.4,0.4h3.6c0.2,0,0.4-0.2,0.4-0.4 v-3.6C151,58.5,150.9,58.4,150.7,58.4 M150.7,57.1h3.6c0.2,0,0.4-0.2,0.4-0.4v-3.6c0-0.2-0.2-0.4-0.4-0.4h-3.6 c-0.2,0-0.4,0.2-0.4,0.4v3.6C150.3,56.9,150.4,57.1,150.7,57.1 M164.2,57.1h3.6c0.2,0,0.4-0.2,0.4-0.4v-3.6c0-0.2-0.2-0.4-0.4-0.4 h-3.6c-0.2,0-0.4,0.2-0.4,0.4v3.6C163.8,56.9,164,57.1,164.2,57.1 M171,57.1h3.6c0.2,0,0.4-0.2,0.4-0.4v-3.6c0-0.2-0.2-0.4-0.4-0.4 H171c-0.2,0-0.4,0.2-0.4,0.4v3.6C170.6,56.9,170.7,57.1,171,57.1 M171,58.4h-3.6c-0.2,0-0.4,0.2-0.4,0.4v3.6c0,0.2,0.2,0.4,0.4,0.4 h3.6c0.2,0,0.4-0.2,0.4-0.4v-3.6C171.3,58.5,171.2,58.4,171,58.4 M157.4,58.4h-3.6c-0.2,0-0.4,0.2-0.4,0.4v3.6 c0,0.2,0.2,0.4,0.4,0.4h3.6c0.2,0,0.4-0.2,0.4-0.4v-3.6C157.8,58.5,157.6,58.4,157.4,58.4 M177.7,58.4h-3.6c-0.2,0-0.4,0.2-0.4,0.4 v3.6c0,0.2,0.2,0.4,0.4,0.4h3.6c0.2,0,0.4-0.2,0.4-0.4v-3.6C178.1,58.5,177.9,58.4,177.7,58.4 M164.2,58.4h-3.6 c-0.2,0-0.4,0.2-0.4,0.4v3.6c0,0.2,0.2,0.4,0.4,0.4h3.6c0.2,0,0.4-0.2,0.4-0.4v-3.6C164.6,58.5,164.4,58.4,164.2,58.4 M144.8,48.6 c0,0.2,0.1,0.4,0.3,0.4h34.6c0.2,0,0.3-0.2,0.3-0.4V15.9c0-0.2-0.1-0.4-0.3-0.4h-34.6c-0.2,0-0.3,0.2-0.3,0.4V48.6z M149.5,42.8 v-22c0-0.2,0.1-0.3,0.3-0.3h25.5c0.2,0,0.3,0.1,0.3,0.3v22c0,0.2-0.1,0.3-0.3,0.3h-25.5C149.6,43.1,149.5,42.9,149.5,42.8' />
        <g>
          <path fill='rgb(16,60,96)' d='M204.1,80v-4.5h-1.6v-0.9h4.3v0.9h-1.6V80H204.1z' />
          <path fill='rgb(16,60,96)' d='M207.5,80v-5.4h1.6l1,3.7l1-3.7h1.6V80h-1v-4.2l-1.1,4.2h-1l-1.1-4.2V80H207.5z' />
        </g>
        <g>
          <path fill='rgb(16,60,96)' d='M118.5,35.1L97.1,23.9c0,4.6,0.5,7.6,3.7,9.2l10.8,5.6L118.5,35.1z' />
          <path fill='rgb(16,60,96)' d='M121.3,36.6l-7,3.6l0,0l-13.5,7c-3.1,1.7-3.8,5.3-3.8,9.3l19.5-10.1C120.9,44,121.4,41.1,121.3,36.6 L121.3,36.6z' />
        </g>
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
 &.accessibility-icon {
    width: 6.5rem;
    margin-left: 2rem;
    @media (max-width: 767px) {
     display: block;
     width: 6.5rem;
     margin: 1rem auto;
    }
    @media (min-width: 768px) and (max-width: 950px) {
     margin: 1rem;
    }
 }
`
