import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const MobileLogoIconOnly = ({
  classes,
  width,
  styles,
}) => {
  return (
    <Svg styles={styles} className={classes} x='0px' y='0px' width={`${width}px`}
      viewBox='0 0 120 80'>
      <title>Mobile Logo - Icon Only</title>
      <g>
        <GreenPath d='M26.3,3.7c0,0-2.1,15.7,13.9,17.6C40.2,21.3,42.2,5.6,26.3,3.7' />
        <GreenPath d='M93.5,3.7c0,0,2.1,15.7-13.9,17.6C79.6,21.3,77.6,5.6,93.5,3.7' />
        <GreenPath d='M29.7,74.3c0,0,15.4,3.5,18.8-12.1C48.5,62.2,33.1,58.7,29.7,74.3' />
        <GreenPath d='M71.6,62.7c0,0,3.8,15.4,19.3,11.4C90.9,74,87,58.7,71.6,62.7' />
        <GreenPath d='M1.8,42.8c0,0,12.9,21.2,35.8,7.2C37.6,50,23.4,28.4,1.8,42.8' />
        <GreenPath d='M82.7,49.9c0,0,20.2,14.3,35.8-7.7C118.4,42.2,96.8,28,82.7,49.9' />
        <GreenPath
          d='M70.6,18c0,5.8-4.7,10.4-10.4,10.4c-5.8,0-10.4-4.7-10.4-10.4c0-5.8,4.7-10.4,10.4-10.4S70.6,12.3,70.6,18' />
        <GreenPath d='M27,26.1c13.8,0.9,22.3,5.8,32.6,15.9c0.3,0.3,0.7,0.3,1,0c10.3-10.1,19.3-15,33.2-15.9c0.8,0,1.1,1,0.3,1.3
    c-15.8,7.4-28.9,20.5-33.4,40.1c-0.1,0.6-1.2,0.6-1.4,0c-4.2-19.8-16.9-32.8-32.6-40.1C26,27.1,26.3,26,27,26.1' />
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  ${props => props.styles};
`

const GreenPath = styled.path`
  fill: ${props => props.theme.brandedLtGreen};
`
