import { APP_SET_DATA_COMPLETE, APP_SET_DATA_RERENDER } from '../constants/appConstants'
import { FACET_GROUP_EXPAND } from '../constants/facetGroupConstants'
import { BREADCRUMB_UPDATE } from '../constants/breadcrumbConstants'
import { utagLink } from "../tealium"
import { trackTealium } from "../tealium"

const analyticMiddleware = store => next => action => {
  let result = next(action)
  switch (action.type) {
    case APP_SET_DATA_COMPLETE: {
      let productIDs = '',
        browseType = '',
        browseValue = '',
        searchRef = '',
        categoryPath = '',
        tempCatPath = '',
        page_name = '',
        channel = '',
        adobeEvents = '',
        internalSearchType = '',
        tealiumEvent = '',
        catLongestLength = 0,
        productPos = [],
        productMasterIDs = [],
        tempObj = store.getState().facetGroup.facets.facetsMap

      store.getState().productData.productData.forEach((product) => {
        productIDs += `${product.productPartNumber},`
        if (`${product.masterGroupItem}`) {
          productMasterIDs.push(`${product.masterGroupItem}`)
        } else {
          productMasterIDs.push(`${product.productPartNumber}`)
        }
      })

      store.getState().facetGroup.facets.facetKeys.forEach((catName) => {
        browseType += `${catName},`
      })

      for (let prop in tempObj) {
        let subObj = tempObj[prop]
        for (let subProp in subObj) {
          browseValue += `${subObj[subProp].name},`
        }
      }
      productIDs = productIDs.split(',').slice(0, -1)
      browseType = browseType.split(',').slice(0, -1)
      browseValue = browseValue.split(',').slice(0, -1)

      store.getState().breadcrumb.breadcrumb.forEach((ref) => {
        searchRef += `${ref.name},`
        tempCatPath = `${ref.uri}`.split('/')
        if (tempCatPath.length > catLongestLength) {
          catLongestLength = tempCatPath.length
          categoryPath = `${ref.uri}`
        }
      })

      searchRef = searchRef.split(',')
      if (searchRef.length > 3) {
        searchRef = searchRef.slice(0, -2)
      } else if (searchRef.length === 3) {
        searchRef = searchRef[0]
      } else {
        searchRef = ''
      }

      store.getState().productData.productData.forEach((product, index) => {
        productPos.push(`${index + 1}`)
      })

      if (store.getState().app.keyword) {
         if (store.getState().productData.productData.length > 0) { // Search with results
          page_name = 'search'
          channel = 'shp|search'
          adobeEvents = 'event1'
          internalSearchType = 'OSS|All'
          tealiumEvent = 'search'
        } else { // Null Search 404
          page_name = 'nullSearch|404'
          channel = 'shp|error'
          adobeEvents = 'event1,event2'
          tealiumEvent = 'null_search'
          productIDs = 'nullProduct'
        }
      } else { // Category Page
        page_name = `browse:${store.getState().app.title.toLowerCase().replace(/ - /g, '|').replace(/ /g, '_')}`
        channel = 'shp|browse'
        tealiumEvent = 'sub_category'
      }

      if (window.location.href.indexOf('lp=t') > -1) {
        try {
          channel = 'shp|landingpages'
          page_name = window.location.href.split('swansonvitamins.com/')[1]
          if (page_name.indexOf('?lp=t') > -1) {
            page_name = 'lp|' + page_name.split('?')[0].replace(/[\W_]+/g, '_')
          } else {
            page_name = 'lp|' + page_name.split('&')[0].replace(/[\W_]+/g, '_')
          }
        } catch (e) {
          page_name = 'lp|unknown'
          if (window.location.href.split('swansonvitamins.com/').hasOwnProperty(0)) {
            page_name = window.location.href.split('swansonvitamins.com/')[0]
            page_name = 'lp|' + page_name.split('?').hasOwnProperty(0) ? page_name.split('?')[0].replace(/[\W_]+/g, '_') : 'lp|unknown';
          }
        }
      }

      let recommendationData = store.getState().productData.recommendationData
      if (recommendationData) {
        let recProdData
        const flagTypes = ['top_seller', 'top_rated', 'most_viewed', 'popular_brand']
        for (let cli = recommendationData.length - 1; cli > -1; cli--) {
          if (recommendationData[cli].result.length > 0) {
            recProdData = recommendationData[cli].result[0]
            if (recProdData.adobeRecord) {
              productIDs.unshift(recProdData.adobeRecord.productPartNumber)
              if (recProdData.adobeRecord.masterGroupItem) {
                productMasterIDs.unshift(recProdData.adobeRecord.masterGroupItem)
              } else {
                productMasterIDs.unshift(recProdData.adobeRecord.productPartNumber)
              }
              productPos.unshift(`${flagTypes[cli]}${cli + 1}`)
            }
          }
        }
      }

      const tealiumFields = {
        'page_name': page_name,
        'channel': channel,
        'adobe_events': adobeEvents,
        'is_pwa': (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches) ? 'true' : '',
        'refine_type': browseType,
        'refine_value': browseValue,
        'product_id': productIDs,
        'master_item_id': productMasterIDs,
        'aa_event_product_id': productIDs,
        'search_results_location': productPos,
        'search_keyword': store.getState().app.keyword,
        'search_results_count': store.getState().page.page.numItems,
        'search_results_total': store.getState().app.totalPageResults,
        'page_sort_options': store.getState().app.sort,
        'internal_search_type': internalSearchType,
        'tealium_event': tealiumEvent,
        'search_id': store.getState().app.searchId,
        'search_refinement_attribute': searchRef,
        'category_path': categoryPath,
        'page_url': window.location.href,
        'page_url_trimmed': window.location.origin + window.location.pathname
      }
      window.utag_data = { ...window.utag_data, ...tealiumFields }

      trackTealium(action.type, tealiumFields)

      break
    }
    case (APP_SET_DATA_RERENDER || BREADCRUMB_UPDATE): {
      utagLink({
        'tealium_event': 'react_render'
      })

      break
    }
    case FACET_GROUP_EXPAND: {
      const tealiumFields = {
        'search_id': store.getState().app.searchId,
        'search_refine_toggle': sessionStorage.getItem('toggleExpanded')
      }
      sessionStorage.removeItem('toggleExpanded')

      trackTealium(action.type, tealiumFields)

      break
    }
    default:
      return
  }
  return result
}

export default analyticMiddleware
