import {
  SHARED_CHECK_EMAIL,
  SHARED_LOADING_END,
  SHARED_LOADING_START,
  SHARED_LOCK_SCROLL,
  SHARED_CHECK_SMS,
  SHARED_SET_INSTANCE,
  SHARED_CLEAR_INSTANCE,
  UPDATE_OOS_MESSAGE,
  UPDATE_OOS_SUBMITTED,
  UPDATE_OOS_EMAIL_SHOW,
  CLEAR_OOS,
  SET_SECOND_LOADING,
  OG_UPDATE_CHECKED,
  SHARED_AGE_VERIFIED
} from '../constants/sharedConstants'

const sharedReducer = (
  reduxState = { ageVerified: false, lockScroll: false, sharedCheckEmail: false, sharedCheckSMS: false, loading: false, secondLoading: false, instance: { name: undefined, data: {} } },
  action
) => {
  switch (action.type) {
    case SHARED_LOCK_SCROLL: {
      return { ...reduxState, lockScroll: action.payload }
    }
    case SHARED_CHECK_EMAIL:
      return { ...reduxState, sharedCheckEmail: action.payload }
    case SHARED_CHECK_SMS:
      return { ...reduxState, sharedCheckSMS: action.payload }
    case SHARED_LOADING_START:
      return { ...reduxState, loading: true }
    case SHARED_SET_INSTANCE: {
      return { ...reduxState, instance: action.payload }
    }
    case SHARED_CLEAR_INSTANCE: {
      return { ...reduxState, instance: { name: undefined, data: {} } }
    }
    case SHARED_LOADING_END: {
      return {...reduxState, loading: false}
    }
    case UPDATE_OOS_MESSAGE: {
      return {...reduxState, oosMessage: action.payload}
    }
    case UPDATE_OOS_SUBMITTED: {
      return {...reduxState, oosSubmitted: action.payload}
    }
    case CLEAR_OOS: {
      return {...reduxState, oosSubmitted: false, oosMessage: ''}
    }
    case UPDATE_OOS_EMAIL_SHOW: {
      return {...reduxState, oosEmailShow: action.payload}
    }
    case SET_SECOND_LOADING: {
      return {...reduxState, secondLoading: action.payload}
    }
    case OG_UPDATE_CHECKED: {
      return {...reduxState, ogChecked: action.payload}
    }
    case SHARED_AGE_VERIFIED: {
      return { ...reduxState, ageVerified: action.payload}
    }
    default:
      return reduxState
  }
}

export default sharedReducer
