import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { LightBox } from '../LightBox/LightBox'
import { utagLink } from '../../tealium'

export const YouTubeVideoModal = ({
  videoID,
  closeHandler = () => {}
}) => {
  useEffect(() => {
    utagLink({
      "tealium_event": 'trigger_youtube_tracking'
    })
  }, [])

  return (
    <LightBox
      styles={css`    
        width: 70rem;
        height: 40rem;
      `}
      show
      closeHandler={closeHandler}
    >
      <YouTubeModalContainerDiv>
        <iframe
          width='560'
          height='315'
          src={`https://www.youtube.com/embed/${videoID}?enablejsapi=1&rel=0`}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
          id={videoID}
        />
      </YouTubeModalContainerDiv>
    </LightBox>
  )
}

const YouTubeModalContainerDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`
