import {
  COMPARE_ADD_ITEM,
  COMPARE_REMOVE_ITEM,
  COMPARE_CLEAR_ITEMS,
  COMPARE_SHOW_BAR,
  COMPARE_HIDE_BAR,
  SET_COMPARE_ERROR,
  SET_COMPARE_ITEMS
} from '../constants/compareConstants'
import { gql } from 'urql'
import { SHARED_LOADING_END, SHARED_LOADING_START } from '../constants/sharedConstants'
import { updateURLsAction } from './updateURLsAction'
import { showNotificationWithTimeout } from './notificationActions'
import { utagLink } from '../tealium'

export const comparePageAddItem = (itemNumber, client) => {
  return (dispatch, reduxState) => {
    dispatch({ type: SHARED_LOADING_START })
    const COMPARE_ADD = gql`query($partNumber:String!){getItem(partNumber:$partNumber){discount everyDayLowPrice percentDiscount productDiscountPrice productName productPartNumber productVendor longURL newWebItem bogo outletmall rating numReviews productDetails statusUnavailable bulletsList productPillSize productForm vegan vegetarian glutenFree tooLowToShow kosher flags organicUSDA nonGMO sfHtml}}`

    client.query(COMPARE_ADD, {
      partNumber: itemNumber.toUpperCase()
    }).toPromise().then(result => {
      if (result.error) {
        dispatch({ type: SHARED_LOADING_END })
        if (result.error.networkError) {
          dispatch(showNotificationWithTimeout({
            time: 5000,
            background: 'warn',
            notificationText: 'Uh oh! Looks like we’re having trouble connecting. Please try refreshing the page or check your internet connection. If the issue persists please contact us for support.',
            active: true
          }))
        } else {
          dispatch({ type: SET_COMPARE_ERROR, payload: 'Please enter a valid item number.' })
        }
      } else {
        let newCompareItems = [...reduxState().compare.compareItems, result.data.getItem]
        let url = '/compare?submit'

        for (let cai = 0; cai < newCompareItems.length; cai++) {
          url += '&compareItem' + (cai + 1) + '=' + newCompareItems[cai].productPartNumber
        }

        dispatch({ type: SET_COMPARE_ERROR, payload: '' })
        dispatch({ type: SET_COMPARE_ITEMS, payload: newCompareItems})
        dispatch(updateURLsAction(url))
        dispatch({ type: SHARED_LOADING_END })

        utagLink({
          'compare_action_pid': result.data.getItem.productPartNumber,
          'compare_action_type': 'add',
          'tealium_event': 'product_compare_action'
        })
      }
    })
  }
}

export const comparePageDelete = (productNumber) => {
  return (dispatch, reduxStore) => {
    let newProducts = reduxStore().compare.compareItems.filter(item => item.productPartNumber !== productNumber)
    let url = '/compare?submit'

    if (newProducts.length > 0) {
      for (let cdi = 0; cdi < newProducts.length; cdi++) {
        url += '&compareItem' + (cdi + 1) + '=' + newProducts[cdi].productPartNumber
      }
    }

    dispatch({ type: SET_COMPARE_ITEMS, payload: newProducts })
    dispatch(updateURLsAction(url))

    utagLink({
      'compare_action_pid': productNumber,
      'compare_action_type': 'delete',
      'tealium_event': 'product_compare_action'
    })
  }
}

export const compareAddItemAction = (item) => (
  {
    type: COMPARE_ADD_ITEM,
    payload: item
  }
)

export const compareRemoveItemAction = (item) => (
  {
    type: COMPARE_REMOVE_ITEM,
    payload: item
  }
)

export const compareRemoveAllItemAction = () => (
  {
    type: COMPARE_CLEAR_ITEMS
  }
)

export const compareHideBar = () => (
  {
    type: COMPARE_HIDE_BAR
  }
)

export const compareShowBar = () => (
  {
    type: COMPARE_SHOW_BAR
  }
)

export const compareProducts = () => {
  return (dispatch, reduxState) => {
    let compareUrl = '/compare?submit'
    reduxState().compare.items.forEach((item, index) => {
      compareUrl += `&compareItem${index + 1}=${item.productPartNumber}`
    })
    window.sessionStorage.setItem('searchUrl', window.location.href)
    dispatch(compareRemoveAllItemAction())
    window.location = window.location.origin + compareUrl
  }
}
