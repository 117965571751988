import React from 'react'
import { useDispatch } from 'react-redux'
import { LightBox } from '../LightBox/LightBox'
import styled from 'styled-components'
import { SHARED_CLEAR_INSTANCE } from '../../constants/sharedConstants'
import { StandardLink, LinkThatLookLikeCTAButton } from '../_styled/Link'

export const AddToFavSignInModal = ({
  loginHref,
  registerHref
}) => {
  const dispatch = useDispatch()

  const closeHandler = () => {
    if (document) {
      document.body.style.overflow = 'auto'
    }
    dispatch({ type: SHARED_CLEAR_INSTANCE })
  }

  return (
    <LightBox
      productTitle='Add to Favorites'
      closeHandler={closeHandler}
      show
      xStyles={`
        position: relative;
        right: 0rem;
      `}
      styles={`
        padding: 1rem;
      `}
    >
      <DisclaimerTextDiv>Please sign-in to your account to add this item to your favorite’s list.</DisclaimerTextDiv>
      <SignInButtonA aria-label='Sign In' href={loginHref}>Sign In</SignInButtonA>
      <DisclaimerTextDiv>Don't have an account? <StandardLink aria-label='Create account' href={registerHref}>Create one here</StandardLink></DisclaimerTextDiv>
    </LightBox>
  )
}

const DisclaimerTextDiv = styled.div`
  margin: 1rem 0rem;
`

const SignInButtonA = styled(LinkThatLookLikeCTAButton)`
  background-color: ${props => props.theme.darkestOrange};
  color: ${props => props.theme.white};
  border: 0rem;
  width: 100%;
`
