import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const CircleWarning = ({
  width = '25',
  height = '24',
  fill = '#FC000F',
  styles
}) => {
  return (
    <Svg styles={styles} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 24">
      <title>exclamation point</title>
      <Path
        fill={fill}
        d="M12.4999 18.26C12.9896 18.26 13.3866 17.863 13.3866 17.3733C13.3866 16.8836 12.9896 16.4867 12.4999 16.4867C12.0103
           16.4867 11.6133 16.8836 11.6133 17.3733C11.6133 17.863 12.0103 18.26 12.4999 18.26Z"
      />
      <Path
        fill={fill}
        d="M12.5007 15.0733C12.3238 15.0733 12.1543 15.0031 12.0292 14.8781C11.9042 14.7531 11.834 14.5835 11.834
           14.4067V6.40667C11.834 6.22986 11.9042 6.06029 12.0292 5.93527C12.1543 5.81024 12.3238 5.74001 12.5007 5.74001C12.6775
           5.74001 12.847 5.81024 12.9721 5.93527C13.0971 6.06029 13.1673 6.22986 13.1673 6.40667V14.4067C13.1673 14.5835 13.0971
           14.7531 12.9721 14.8781C12.847 15.0031 12.6775 15.0733 12.5007 15.0733Z"
      />
      <Path
        fill={fill}
        d="M12.5007 22.6667C10.391 22.6667 8.3287 22.0411 6.57457 20.869C4.82045 19.6969 3.45328 18.031 2.64594 16.082C1.83861
           14.1329 1.62737 11.9882 2.03895 9.91904C2.45052 7.84991 3.46642 5.94929 4.95818 4.45753C6.44994 2.96577 8.35056
           1.94987 10.4197 1.53829C12.4888 1.12671 14.6335 1.33795 16.5826 2.14528C18.5317 2.95262 20.1976 4.31979 21.3697
           6.07392C22.5417 7.82804 23.1673 9.89033 23.1673 12C23.1673 14.829 22.0435 17.5421 20.0431 19.5425C18.0427 21.5429
           15.3296 22.6667 12.5007 22.6667ZM12.5007 2.66667C10.6547 2.66667 8.85019 3.21406 7.31533 4.23962C5.78047 5.26518
           4.5842 6.72284 3.87778 8.42829C3.17136 10.1337 2.98653 12.0104 3.34666 13.8208C3.70679 15.6313 4.5957 17.2944 5.90099
           18.5997C7.20628 19.905 8.86932 20.7939 10.6798 21.154C12.4903 21.5141 14.3669 21.3293 16.0724 20.6229C17.7778 19.9165
           19.2355 18.7202 20.261 17.1853C21.2866 15.6505 21.834 13.846 21.834 12C21.834 9.52465 20.8507 7.15068 19.1003
           5.40034C17.35 3.65 14.976 2.66667 12.5007 2.66667Z"
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  fill: none;
  ${props => props.styles};
`
const Path = styled.path`
  fill: ${props => props.fill};
`