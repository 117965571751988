import { useState, useEffect } from 'react'

export function debounce (value, delay) {
  const [debounceValue, setDebounceValue] = useState(value)
  const [timeoutId, setTimeoutId] = useState(undefined)
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    setTimeoutId(setTimeout(() => {
      setDebounceValue(value)
    }, delay))
  }, [value])
  return debounceValue
}
