import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const DeleteIcon = props => {
  function setColor (color) {
    let removeIconColor = ''
    if (color === 'green') {
      removeIconColor = '#00833d'
    } else if (color === 'white') {
      removeIconColor = '#fff'
    } else {
      removeIconColor = '#000000'
    }
    return removeIconColor
  }

  return (
    <Svg className={`delete-icon-svg ${props.classes}`} viewBox='0 0 10 10'>
      <title>Delete Icon</title>
      <line className={`${props.isMinus ? 'plus-icon--active' : ''}`} stroke={setColor(props.color)} strokeWidth='2px' x1='5' x2='5' y2='10' />
      <line stroke={setColor(props.color)} strokeWidth='2px' x1='10' y1='5' y2='5' />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  height: 2rem;
  width: 2rem;
  transform: rotate(45deg);
  cursor: pointer;
`