import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const RewardsRibbon = ({
  height = `27`,
  width = `27`,
  color= '#00833d',
  styles
}) => {
  return (
    <Svg width={width} height={height} styles={styles} xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 100 100'>
      <title>Swanson Wellness Rewards</title>
      <g>
        <Path fill={color} d='M38.43,38.32c1.5,2.28,3.49,4.13,5.44,6.02c1.22,1.19,2.44,2.37,3.61,3.6c1.12,1.18,3.2,1.22,4.3,0.05
        c0.87-0.93,1.76-1.84,2.67-2.71c1.8-1.72,3.61-3.41,5.21-5.32c1.51-1.8,2.62-3.76,2.82-6.19c0.17-2.01-0.19-3.86-1.13-5.66
        c-1.95-3.63-7.43-5.65-11.73-0.68c-0.83-0.97-1.78-1.78-2.95-2.34c-2.85-1.36-6.49-0.37-8.23,2.25c-1.31,1.96-1.8,4.08-1.61,6.42
        C36.96,35.44,37.52,36.94,38.43,38.32z'></Path>
        <Path fill={color} d='M72.99,57.64l-0.01-0.09c0.05-0.11,0.07-0.25,0.05-0.45c-0.02-0.18-0.14-0.53-0.17-0.52
        c0,0-1.47-7.64-1.01-8.07c0.47-0.43,6.64-2.03,6.97-3.42c0.33-1.39-3.38-7.92-3.27-9.24c0.12-1.32,4.13-6.87,3.77-8.04
        c-0.38-1.23-7.83-4.19-8.11-4.78s-0.58-8.5-1.52-9.25c-0.64-0.51-5.77-0.28-7.6-0.25c-0.7,0.01-4.48-8.41-5.13-8.67
        c-0.65-0.26-7.41,5.14-8.05,5.12c-0.65-0.02-6.14-4.74-7.05-4.61c-1.33,0.19-5.02,7.13-6.03,8.02c-1.01,0.89-7.96-1.64-8.87-1.01
        C25,13.72,26.55,21.96,26.2,22.5s-6.79,4.16-6.79,4.16l-0.04,0.02c-0.26,0.15-0.48,0.36-0.65,0.64c-0.42,0.68-0.36,1.51,0.09,2.12
        c0,0,4.01,5.3,4.03,6.37c0.01,0.7-4.5,6.5-4.5,6.5l-0.03,0.04c-0.15,0.26-0.23,0.56-0.24,0.88c-0.01,0.8,0.47,1.48,1.16,1.77
        c0,0,7,2.79,7.32,3.36c0.32,0.56-0.37,8.43-0.37,8.43l0,0.05c0.02,0.3,0.1,0.6,0.27,0.87c0.41,0.68,1.19,1.01,1.93,0.88
        c0,0,1.53-0.19,3.28-0.33c-0.37,1.03-0.74,2.07-1.11,3.11c-0.45,1.25-8.42,22.95-8.68,23.99c-0.46,1.84,0.79,2.95,2.41,2.63
        c1.12-0.22,11.13-3.05,11.76-3.15c0.37-0.06,0.57,0.03,0.73,0.25c0.69,0.96,6.18,7.91,6.48,8.34c0.99,1.45,3.37,0.63,3.73-0.8
        c0.4-1.61,3.03-10.97,3.24-11.6c0.15-0.47,0.33-0.93,0.56-1.47c0.39,0.56,3.67,14.36,4.8,14.71c0.4,0.02,0.71,0.02,1.03,0.02
        c0.83-0.4,6.89-8.3,7.61-9.42c0.07-0.12,0.19-0.19,0.31-0.14c0.6,0.26,11.16,2.64,11.98,2.91c1.9,0.62,2.49-2.03,2.49-2.35
        c-0.46-1.44-9.48-27.11-9.44-27.25C71.47,58.03,73.07,57.94,72.99,57.64z M30.43,54.11c-0.41-0.4,0.21-6.8-0.47-7.97
        c-0.68-1.16-6.46-3.19-6.62-3.84c-0.14-0.56,3.65-6.38,3.73-7.15c0.1-0.96-3.72-5.67-3.52-6.32c0.17-0.55,5.51-3.39,6.24-4.53
        c0.73-1.14-0.56-7.38-0.05-7.81c0.4-0.34,5.85,1.46,8,0.57s3.36-5.89,4.33-6.33c0.53-0.24,5.75,3.91,7.1,3.93s5.95-4.13,6.59-3.93
        c0.55,0.17,3.82,6.38,4.44,6.84c0.77,0.58,6.4-0.12,6.84,0.38c0.38,0.43,0.29,6.39,0.89,7.6c0.59,1.21,6.73,3.4,6.84,4.06
        c0.09,0.53-3.93,4.44-3.93,6.46c0,0,3.28,6.47,3.04,7.1c-0.2,0.54-6.85,2.06-6.85,2.06s1.98,7.49,1.56,8.02
        c-0.31,0.39-8.26,1.49-8.24,1.53c-0.44,0.94-3.99,5.27-4.07,5.43c-1.52-0.96-4.7-3.39-5.83-3.48c-1.42-0.11-5.75,3.92-6.31,3.8
        c-0.46-0.1-4.22-4.71-5.12-5.49C36.83,53.18,30.82,54.49,30.43,54.11z M48.53,72.21c-0.55,1.8-1.01,3.62-1.53,5.43
        c-0.55,1.91-2.43,8.06-2.61,9.28c-0.04,0.25-0.18,0.47-0.3,0.77c-0.47-0.23-0.66-0.68-0.94-1.03c-0.69-0.86-3.53-4.39-4.21-5.34
        c-0.5-0.7-1.28-1-2.14-0.82c-1.45,0.31-6.71,1.84-7.95,2.04c-0.37,0.06-1.49,0.5-1.79,0.37c0.28-0.78,0.55-1.56,0.83-2.33
        c0.36-0.97,8.41-22.4,8.76-22.16c1.11,0.75,5.31,6.65,5.31,6.65s0.43,0.49,0.72,0.64c0.71,0.36,1.54,0.22,2.1-0.28
        c0,0,2.98-2.75,4.71-4.02c0.01,0,0.02,0,0.03,0c0.57,0.32,1.14,0.65,1.7,0.99c0.05,0.03,0.05,0.16,0.07,0.24
        C50.81,64.34,49,70.68,48.53,72.21z M73.5,81.77c0.02,0.05,0.01,0.11,0.04,0.15c0.18,0.21,0.26,0.48,0.1,0.67
        c-0.21,0.25-0.57,0.2-0.84,0.11c-1.05-0.34-2.14-0.51-3.2-0.8c-1.01-0.27-5.05-1.34-6.1-1.36c-0.68-0.01-1.2,0.24-1.63,0.83
        c-0.67,0.94-3.4,4.19-3.94,4.99c-0.27,0.4-0.55,0.82-0.93,1.21c-0.5-0.6-0.42-1.3-0.6-1.91c-0.23-0.79-3.23-13.6-3.29-14.27
        c-0.07-0.72,1.48-5.84,1.63-6.36c0.12-0.38,0.4-0.48,0.76-0.27c0.12,0.07,0.23,0.15,0.34,0.22c0.5,0.27,0.95,0.48,1.37,0.75
        c0.18,0.12,0.32,0.15,0.44,0.11c0.11,0.03,0.22,0.05,0.31,0.05c0.8,0.02,5.05-8.04,5.05-8.04s3.13,0.53,2.35,0.69
        c0.49,1.41,0.98,2.81,1.5,4.21C67.45,64.28,73.04,80.43,73.5,81.77z'></Path>
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  margin-right: 1rem;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  ${props => props.styles};
`

const Path = styled.path`
  fill: ${props => props.fill};
  stroke: none;
  opacity: 1;
`
