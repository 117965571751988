import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const PlusSignIOS = () => {
  return (
    <Svg width='20px' x='0px' y='0px'
      viewBox='0 0 190 190'>
      <title>iOS Plus Sign</title>
      <g>
        <PathGray d='M169.1,182.5H21.9c-6.6,0-12-5.4-12-12V23.2c0-6.6,5.4-12,12-12h147.2c6.6,0,12,5.4,12,12v147.2
        C181.1,177.1,175.7,182.5,169.1,182.5z' />
        <g>
          <PathWhite d='M88.9,139.2v-36H52.8V90.6h36.2v-36H102v36h36.2v12.6H102v36H88.9z' />
        </g>
      </g>
    </Svg>
  )
}
const Svg = styled(SvgAttr)``

const PathGray = styled.path`
  fill: #686868;
`

const PathWhite = styled.path`
  fill: #fff;
`
