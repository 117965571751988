import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const PinterestIcon = props => {
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 288 288' className={`${props.classes}`} >
      <title>Pinterest Icon</title>
      <Path d='M144,4.7C67.1,4.7,4.8,67,4.8,143.9S67.1,283.1,144,283.1s139.3-62.3,139.3-139.2S220.9,4.7,144,4.7z M144.1,232.6c-8.8,0-17.2-1.3-25.2-3.6c3.3-5.3,7-12.2,8.9-19c1-4,6.2-24.4,6.2-24.4c3.1,5.9,12.1,11.1,21.7,11.1 c28.5,0,47.9-26,47.9-60.8c0-26.3-22.3-50.8-56.2-50.8c-42.1,0-63.4,30.2-63.4,55.4c0,15.3,5.8,28.9,18.2,33.8 c2,0.9,3.8,0.1,4.4-2.2c0.4-1.5,1.4-5.5,1.8-7.1c0.6-2.2,0.3-3-1.3-4.9c-3.6-4.2-5.9-9.7-5.9-17.4c0-22.4,16.8-42.5,43.7-42.5 c23.8,0,36.9,14.6,36.9,34c0,25.6-11.3,47.2-28.1,47.2c-9.3,0-16.2-7.7-14-17.1c2.6-11.2,7.8-23.4,7.8-31.5c0-7.2-3.9-13.3-12-13.3 c-9.5,0-17.1,9.9-17.1,23c0,8.3,2.8,14,2.8,14s-9.7,41.2-11.4,48.4c-1.5,6.8-1.8,14.2-1.5,20.4c-31.2-13.7-53.1-45-53.1-81.3 c0-49,39.8-88.8,88.8-88.8s88.8,39.8,88.8,88.6C232.8,192.9,193.1,232.6,144.1,232.6z' />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
 &.footer-icon{
  width: 45px;
  margin: 1rem 1.2rem;
  height: 45px;
  @media(min-width: 768px) and (max-width: 990px){
   width: 25px;
   height: 25px;
   margin: 0.5rem
  }
  @media (min-width: 991px) and (max-width: 1365px ){
   width: 40px;
   height: 40px;
   margin: 0.5rem;
  }
 }
`

const Path = styled.path`
  fill: #2F711C;
`
