/**
 * Created by taylor.houston on 10/31/2018.
 */
import React from 'react'
import { Hidden } from '../../_styled/Hidden'
import { connect, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Pagination } from '../Pagination/Pagination'
import { SelectBox } from '../../_inputs/SelectBox/SelectBox'
import { changeSortAction, changeFavSortAction } from '../../../actions/changePageAction'

const ConnectedComponent = ({
  changeSort,
  sortOptions,
  sort,
  location = 'top',
  recordsFrom,
  recordsTo,
  totalResults,
  paginationValue = 24
}) => {
  const currentPage = useSelector(state => state.favorites ? state.favorites.searchResultsDTO.pagesDTO.currentPage : state.page.page.currentPage)

  const changeHandler = (sort) => {
    changeSort(sort.value, sortOptions)
  }

  const initialSelectedSort = sortOptions.find(option => option.selected)

  let sortBySelect = []
  if (location === 'top') {
    sortBySelect = (
      <SelectBox
        valueKey={useSelector(state => state.favorites ? 'label' : 'value')}
        textKey={useSelector(state => state.favorites ? 'label' : 'text')}
        setState={initialSelectedSort?.value || sort}
        labelText='Sort By'
        orientation='horizontal'
        options={sortOptions}
        styles={css`
          display: block;
          margin: -2rem 1rem;
          @media (min-width: ${props => props.theme.breakpoints.sm}){
            display: flex;
          }
        `}
        returnState={changeHandler}
        qtyClasses='cTest_sortBy'
        id='SortBy'
      />
    )
  }

  return (
    <SearchFilter data-testid='search-filter-bar' className={`SearchFilterBar ${location === 'top' ? 'SearchFilterBar--border-bottom' : 'SearchFilterBar--border-top'}`}>

      {sortBySelect}
      <Items>{recordsFrom} - {recordsTo} of {totalResults} items</Items>
      {parseInt(totalResults) > paginationValue &&
        <Pagination
          location={location}
          labelClass='visually-hidden'
          currentPage={currentPage}
        />
      }
      <Hidden aria-live='assertive' aria-atomic='true'>Page changed to page {currentPage}</Hidden>
    </SearchFilter>

  )
}

const SearchFilter = styled.div`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  height: 5rem;
  &.SearchFilterBar--border-bottom{
    border-bottom: 0.1rem solid ${props => props.theme.lightGrey};
  }
  &.SearchFilterBar--border-top{
    border-top: 0.1rem solid ${props => props.theme.lightGrey};
  }
`
const Items = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: auto;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    padding-left: 1rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}){
    margin-left: auto;
    margin-right: .5rem;
  }
`

const mapDispatchToProps = (dispatch) => (
  {
    changeSort: (sort, sortOptions) => sortOptions[1].url.indexOf('favorites') > -1 ? dispatch(changeFavSortAction(sort, sortOptions)) : dispatch(changeSortAction(sort, sortOptions))
  }
)

const mapStateToProps = (state) => {
  let stateInfo = {}

  if (state.favorites) { // Favorites State
    stateInfo = {
      sort: state.favorites.sort,
      sortOptions: state.favorites.sortDTOList,
      totalResults: state.favorites.searchResultsDTO.numRecordsTotal,
      recordsTo: state.favorites.searchResultsDTO.numRecordsTo,
      recordsFrom: state.favorites.searchResultsDTO.numRecordsFrom,
      currentPage: state.favorites.searchResultsDTO.pagesDTO.currentPage
    }
  } else { // Search state
    stateInfo = {
      sort: state.app.sort,
      sortOptions: state.page.sorts,
      totalResults: state.app.totalPageResults,
      recordsTo: state.app.recordsTo,
      recordsFrom: state.app.recordsFrom,
      currentPage: state.page.page.currentPage
    }
  }
  return (stateInfo)
}
export const SearchFilterBar = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
