import React from 'react'
import { LoginForm } from '../../_forms/LoginForm/LoginForm'
import { Form } from '../../_forms/Form/Form'
import { LightBoxDrawer } from '../../LightBoxDrawer/LightBoxDrawer'
import { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import { SHARED_CLEAR_INSTANCE } from '../../../constants/sharedConstants'

export const SlideInLogin = () => {
  const dispatch = useDispatch()

  const closeHandler = () => {
    dispatch({ type: SHARED_CLEAR_INSTANCE })
    document.body.style.overflow = 'auto'
  }

  return (
    <>
      <LightBoxDrawer
        show
        closeHandler={closeHandler}
        styles={css`
          margin: 0px;
          padding: 1.6rem;
          width: 37.2rem;
          right: 0;
          @media (min-width: 320px) and (max-width: 359px) {
            width: 29rem !important;
          }
          @media (min-width: 360px) and (max-width: 459px) {
            width: 32.8rem !important;
          }
          @media (min-width: 460px) {
            width: 35.2rem !important;
          }
        `}
      >
        <Form
          WrappedForm={LoginForm}
          showAccountCreation
        />
      </LightBoxDrawer>
    </>
  )
}
