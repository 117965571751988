import { map, pipe } from 'wonka'

export default ({ forward }) => ops$ => {
  return pipe(
    ops$,
    map(operation => {
      return {
        ...operation,
        context: {
          ...operation.context,
          fetchOptions: {
            ...operation.context.fetchOptions,
            headers: {
              ...operation.context.fetchOptions?.headers,
              'X-Csrf-Token': sessionStorage.getItem('csrf')
            },
          },
        },
      }
    }),
    forward,
  )
}