import {
  HEADER_USER_STATUS,
  HEADER_USER_NAME,
  HEADER_CART_COUNT,
  HEADER_CART_DETAILS,
  HEADER_ITEM_RECORDS,
  HEADER_SEARCH_TERMS,
  HEADER_INNER_MENU_OPEN,
  HEADER_DROPDOWN,
  SET_SHOW_OVERLAY,
  TRIGGER_CLOSE_DROPDOWN
} from '../constants/headerConstants'

const headerReducer = (reduxState = { innerMenuOpen: false, dropdownOpen: false, showOverlay: false, triggerCloseDropdown: true }, action) => {
  switch (action.type) {
    case HEADER_USER_STATUS: {
      return { ...reduxState, userStatus: action.payload }
    }
    case HEADER_CART_COUNT: {
      return { ...reduxState, cartCount: action.payload }
    }
    case HEADER_CART_DETAILS: {
      return { ...reduxState, cartDetails: action.payload }
    }
    case HEADER_ITEM_RECORDS: {
      return { ...reduxState, itemRecords: action.payload }
    }
    case HEADER_USER_NAME: {
      return { ...reduxState, userName: action.payload }
    }
    case HEADER_SEARCH_TERMS: {
      return { ...reduxState, searchTerms: action.payload }
    }
    case HEADER_INNER_MENU_OPEN: {
      return { ...reduxState, innerMenuOpen: action.payload }
    }
    case HEADER_DROPDOWN: {
      return { ...reduxState, dropdownOpen: action.payload}
    }
    case SET_SHOW_OVERLAY: {
      return { ...reduxState, showOverlay: action.payload}
    }
    case TRIGGER_CLOSE_DROPDOWN: {
      return { ...reduxState, triggerCloseDropdown: action.payload }
    }
    default:
      return reduxState
  }
}

export default headerReducer
