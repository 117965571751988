import React, { useState, useEffect, useRef } from 'react'
import { Field } from '../../_inputs/Field/Field'
import { headerSearch, clearHeader, itemRecordUpdate, setHeaderDropdown } from '../../../actions/headerActions'
import { useDispatch, useSelector } from 'react-redux'
import { useMonetate } from '../../../hooks/useMonetate'
import { useConstructor } from '../../../hooks/useConstructor'
import styled, { css } from 'styled-components'
import { debounce } from '../Debounce/Debounce'
import { MagnifyingGlass } from '../../_svgs/MagnifyingGlass/MagnifyingGlass'
import { TransparentButton } from '../../_inputs/Button/Button'
import { HeaderSearchDropdownItem } from '../HeaderSearchDropdownItem/HeaderSearchDropdownItem'
import { VisuallyHiddenStyles } from '../../_styled/BootStrapGrid'
import { SET_SHOW_OVERLAY, TRIGGER_CLOSE_DROPDOWN } from '../../../constants/headerConstants'
import Cookies from 'js-cookie'

export const HeaderSearch = ({
  mobileSearch,
  submitForm,
  searchFieldZIndex = '86',
  searchFormId = 'header-searchForm',
  fieldId,
  HeaderSearchItemEle = HeaderSearchDropdownItem,
  itemStyles,
  secondaryButtonStyles,
  searchResultsListStyles,
  searchRecordsStyles,
  searchFormStyles,
  toggleEchoContent,
  showEchoContent = false
}) => {
  const [inputValue, setInputValue] = useState('')
  const [currentResultIndex, setCurrentResultIndex] = useState(-1)
  const [debounceValue, setDebounceValue] = useState('')
  const [hoverValue, setHoverValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [constructorEnabled, setConstructorEnabled] = useState(false)
  const searchTerms = useSelector(state => state.header.searchTerms)
  const itemRecords = useSelector(state => state.header.itemRecords)
  const triggerClose = useSelector(state => state.header.triggerCloseDropdown)
  const searchResults = ((searchTerms || itemRecords) && inputValue.length > 2 && (itemRecords && itemRecords.length > 0))
  const dispatch = useDispatch()

  const formData = {
    form: 'SearchForm',
    action: `/sayt?query=${debounceValue}`,
    successAction: headerSearch,
    method: 'get',
    noAnalytics: true
  }

  const hoverData = {
    form: 'SearchForm',
    action: `/sayt?query=${hoverValue}`,
    successAction: itemRecordUpdate,
    method: 'get'
  }

  const debounceTerm = debounce(debounceValue, 600)
  const hoverTerm = debounce(hoverValue, 600)
  const node = useRef()


  useEffect(() => {
    mobileSearch(searchResults)
  }, [searchResults])

  const monetate = useMonetate(false)


  const search = (term, options = { callback: () => {}, formData }) => {
    if (term.length < 3) return // we don't show search results below 3 characters anyway
    if (constructorEnabled) {
      searchConstructor(term, options.callback)
    } else {
      submitForm(options.formData)
    }
  }

  useEffect(() => {
    // when monetate loads we might want to wait a little for it to set the cookies after registering the window object
    setConstructorEnabled(Cookies.get('search-test') === 'constructor' || window.constructorForceEnabled)
  }, [monetate, constructorEnabled]) // if monetate does initialize we want to re-evaluate the cookie

  const { searchConstructor } = useConstructor(submitHandler)

  useEffect(() => {
    if (hoverTerm) {
      setInputValue(hoverTerm)
      setSearchTerm(hoverTerm)
      search(hoverTerm, { callback: itemRecordUpdate, formData: hoverData })
    }
  }, [hoverTerm])

  useEffect(() => {
    if (debounceTerm) {
      setInputValue(debounceTerm)
      setSearchTerm(debounceTerm)
      search(debounceTerm, { callback: headerSearch, formData })
    }
  }, [debounceTerm])

  useEffect(() => {
    if (triggerClose) {
      closeDropDown()
      dispatch({ type: TRIGGER_CLOSE_DROPDOWN, payload: false })
    }
  }, [triggerClose])

  const valueChangeHandler = (e) => {
    let searchValue = e.value
    setInputValue(searchValue)
    setDebounceValue(searchValue)
  }

  // by switching this to a function it will get hoisted and js build will not complain
  function submitHandler (e) {
    e.preventDefault()
    window.location.href = `${window.location.protocol}//${window.location.hostname}/q?kw=${inputValue}&search-completed=true`
  }

  const clickHandler = (e) => {
    let clickValue = e.currentTarget.firstChild.innerText
    setInputValue(clickValue || '')
    window.location.href = `${window.location.protocol}//${window.location.hostname}/q?kw=${clickValue}&search-completed=true`
  }

  const closeDropDown = () => {
    document.body.style.overflow = 'auto'
    setInputValue('')
    setCurrentResultIndex(-1)
    dispatch({ type: SET_SHOW_OVERLAY, payload: false })
    dispatch(clearHeader())
  }

  const replaceValue = (term) => {
    if (debounceTerm) {
      let newTerm = term.replace(debounceTerm, `<b>${debounceTerm}</b>`)
      return (<span dangerouslySetInnerHTML={{ __html: newTerm }} />)
    }
  }

  const keyPressHandler = (e) => {
    if (e.key === 'ArrowDown' && currentResultIndex < searchTerms.length) {
      if (currentResultIndex === -1) {
        setCurrentResultIndex(0)
        setHoverValue(searchTerms[0].value)
      } else if (currentResultIndex < (searchTerms.length - 1)) {
        setCurrentResultIndex(currentResultIndex + 1)
        setHoverValue(searchTerms[currentResultIndex + 1].value)
      }
    } else if (e.key === 'ArrowDown' && currentResultIndex > searchTerms.length) {
      setCurrentResultIndex(0)
      setHoverValue(searchTerms[0].value)
    }
    if (e.key === 'ArrowUp' && currentResultIndex > 0 && currentResultIndex < searchTerms.length) {
      setHoverValue(searchTerms[currentResultIndex - 1].value)
      setCurrentResultIndex(currentResultIndex - 1)
    } else if (e.key === 'ArrowUp' && currentResultIndex > searchTerms.length) {
      setCurrentResultIndex(searchTerms.length - 1)
      setHoverValue(searchTerms[searchTerms.length - 1].value)
    }

    if (e.key === 'Enter') {
      e.currentTarget.value = searchTerms?.[currentResultIndex + 1]?.value ?? ""
    }
    if (e.key === 'Escape') {
      closeDropDown()
    }
    if (e.key === 'Backspace' && inputValue.length === 0) {
      closeDropDown()
    }
    if (document.querySelector('#echoContent button')) {
      document.querySelector('#echoContent button').click()
    }
  }

  if (searchResults) {
    document.body.style.overflow = 'hidden'
    if (showEchoContent) {
      toggleEchoContent(false)
    }
    dispatch({ type: SET_SHOW_OVERLAY, payload: true })
    dispatch(setHeaderDropdown(false))
  }

  return (
    <SearchDiv>
      <SearchForm styles={searchFormStyles} ref={node} data-testid='searchForm' id={searchFormId} data-cnstrc-search-form>
        <SearchAreaDiv>
          <SearchField data-testid='searchValue' zIndex={searchFieldZIndex}>
            <Field
              dataTestid='searchField'
              name='search'
              showLabel={false}
              id={fieldId}
              placeholder='search'
              keyPressHandler={(e) => keyPressHandler(e)}
              autocomplete='off'
              value={inputValue}
              returnState={valueChangeHandler}
              label='Header-Search-Form'
              labelText='Suggested search Terms'
              labelStyles={css`${VisuallyHiddenStyles}`}
              includeTracking={true}
              fieldStyles={css`
                border: none !important;
                background-color: initial;
                ::placeholder {
                  color: ${props => props.theme.darkGrey} !important;
                  opacity: 1;
                }
                :-ms-input-placeholder {
                  color: ${props => props.theme.darkGrey} !important;
                }
                ::-ms-input-placeholder {
                  color: ${props => props.theme.darkGrey} !important;
                }
              `}
            />
            {(searchResults && searchTerms && itemRecords) &&
              <SearchFieldAlertSpan
                aria-describedby={fieldId}
                role='alert'
              >
                Showing {searchTerms.length} results on the right panel and showing {itemRecords.length} product suggestions on the left panel for {searchTerm}
              </SearchFieldAlertSpan>
            }
          </SearchField>
          <CTAButtonSearch
            data-testid='testSubmit'
            className='cTest_searchForm-btn'
            aria-label='Search Button'
            onClick={submitHandler}
            type='submit'
            data-cnstrc-search-submit-btn
          >
            <MagnifyingGlass stroke='#545454' width={20} height={20} />
          </CTAButtonSearch>
        </SearchAreaDiv>
        {searchResults &&
          <SearchResultList
            className='cTest_header-search-dropdown'
            data-testid='testResults'
            styles={searchResultsListStyles}
            data-cnstrc-autosuggest
          >
            <CloseButton onClick={closeDropDown} className='cTest_header-sdd-close' aria-label='Close Search dropdown'>&times;</CloseButton>
            <SearchResultTermsContainer>
              <SearchResultTerms
                data-cnstrc-item-section="Search Suggestions"
              >
                {(searchTerms && (searchTerms.length > 0))
                  ? searchTerms.map((term, i) =>
                    <SearchResult
                      className={`cTest_header-sdd-${term.value.replace(/['&.,]/g, '').replace(/[\s\s+ ]/g, '-')} header-sdd-${term.value.replace(/['&.,]/g, '').replace(/[\s\s+ ]/g, '-')}`}
                      data-testid={term.value} key={term.value}
                      onClick={(e) => clickHandler(e)}
                      highlight={currentResultIndex === i}
                      onMouseEnter={() => {
                        setHoverValue(term.value)
                        setCurrentResultIndex(-1)
                      }}
                      tabIndex='0'
                      data-val={term.value}
                      data-cnstrc-item-name={term.value}
                    >
                      <VisuallyHidden aria-live='polite' aria-atomic='true'>
                        {term.value}
                      </VisuallyHidden>
                      {replaceValue(term.value)}
                    </SearchResult>
                  )
                  :
                  (itemRecords && !(searchTerms)) ?
                    <NoSuggestion data-cnstrc-item-name='No terms match your search' data-testid='noTerms'>No terms match your search</NoSuggestion>
                    :
                    ''
                }
              </SearchResultTerms>
              <SearchResultProducts
                data-cnstrc-item-section="Products"
              >
                <Suggestion>Product suggestions for '{searchTerm}' :</Suggestion>
                <VisuallyHidden aria-atomic='true' aria-live='assertive'>Showing search results for {inputValue}</VisuallyHidden>
                {(itemRecords &&
                  <HeaderSearchItemEleContainer>
                    {itemRecords.map((term, i) => {
                        return (term && !term.statusUnavailable) &&
                          <HeaderSearchItemEle
                            term={term}
                            index={i}
                            key={term.productPartNumber}
                            itemStyles={itemStyles}
                            searchTerms={searchTerms}
                            searchRecordsStyles={searchRecordsStyles}
                            secondaryButtonStyles={secondaryButtonStyles}
                            closeDropDown={closeDropDown}
                          />
                      }
                    )}
                  </HeaderSearchItemEleContainer>
                )}
              </SearchResultProducts>
            </SearchResultTermsContainer>
          </SearchResultList>
        }
      </SearchForm>
    </SearchDiv>
  )
}

const SearchDiv = styled.div`
  width: 100%;
  @media (min-width: 850px) {
    position: relative;
  }
  @media print {
    display: none !important;
  }
`

const SearchAreaDiv = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  border: 1px solid ${props => props.theme.mediumGrey};
  border-radius: 8px;
`

const SearchForm = styled.form`
  display: flex;
  height: auto;
  align-items: center;
  margin: 0; // for cms landing pages
  width: 100%;
  z-index: 100;
  ${props => props.styles};
`

const SearchField = styled.div`
  width: 94%;
  position: relative;
  text-align: right;
  z-index: ${props => props.zIndex};
`

const SearchResultList = styled.div`
  position: absolute;
  z-index: 103;
  background-color: ${props => props.theme.white};
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  display: flex;
  flex-flow: row;
  border-radius: 8px;
  top: 42px;
  @media (max-width: 1279px) and (min-width: 850px) {
    left: -100px;
  }
  @media (max-width: 743px) {
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    top: 51px;
    height: 45rem;
  }
  @media (max-width: 475px) {
    width: 100%;
  }
  @media (max-height: 684px) and (min-height: 480px){
    height: 377px;
  }
  @media (max-height: 479px) {
    height: 250px;
  }
  ${props => props.styles};
`

const SearchResult = styled.div`
  margin: 1rem 0;
  padding: 0 1rem;
  ${props => props.highlight && css`
    background-color: ${props => props.theme.lightGrey};
  `}
  &:hover{
    background-color: ${props => props.theme.lightGrey};
  }
  & span{
    font-weight: bold;
  }
  & span b{
    color: ${props => props.theme.primary};
  }
`

const Suggestion = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bold;
  padding-left: 10px;
  a {
    text-decoration: none;
  }
  @media (max-width: 743px) {
    width: 100%;
  }
`

const NoSuggestion = styled.p`
  text-align: center;
  font-weight: bold;
  margin-top: 0.5rem;
  @media (max-width: 743px){
    text-align: left;
    padding-left: 1rem;
  }
`

const SearchResultTerms = styled.div`
  width: 50%;
  border-right: 0.1rem solid ${props => props.theme.darkGrey};   
  @media (max-width: 743px){
    width: 100%;
    border-right: none;
  }
`

const SearchResultProducts = styled.div`
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  flex-flow: column;
  @media (max-width: 743px) {
    width: 100%;
    margin-top: 2rem;
  }
`

const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  right: 0.1rem;
  border: none;
  color: ${props => props.theme.black};
  font-size: 3rem;
  display: flex;
  height: 4rem;
  width: 4rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 743px){
    z-index: 100;
  }
`

const VisuallyHidden = styled.div`
  width: 1px;
  height: 1px;
  overflow: hidden;    
`

const CTAButtonSearch = styled(TransparentButton)`
  z-index: 51;
  border-radius: 0 1rem 1rem 0;
  @media(max-width: 743px){
    min-width: auto;
  }
`

const HeaderSearchItemEleContainer = styled.ul`
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
  height: 100%;
  max-height: 400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const SearchResultTermsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 43px 83px 16px 83px;
  @media (max-width: 1279px) {
    height: fit-content;
  }
  @media (max-width: 1279px) and (min-width: 850px) {
    width: 691px;
  }
  @media (max-width: 743px) {
    padding: 11px 56px 93px 56px;
    overflow: scroll;
  }
`

const SearchFieldAlertSpan = styled.span`
  ${VisuallyHiddenStyles};
`