import React from 'react'
import styled from 'styled-components'
import Responsive from 'react-responsive'

export const XsMax = props => <Responsive {...props} maxWidth={575} />
export const SmMax = props => <Responsive {...props} maxWidth={576} />
export const MdMax = props => <Responsive {...props} maxWidth={766} />
export const LgMax = props => <Responsive {...props} maxWidth={992} />
export const XlMax = props => <Responsive {...props} maxWidth={1200} />
export const SmMin = props => <Responsive {...props} minWidth={576} />
export const MdMin = props => <Responsive {...props} minWidth={767} />
export const LgMin = props => <Responsive {...props} minWidth={992} />
export const XlMin = props => <Responsive {...props} minWidth={1200} />

export const CSSMdMax = styled.div`
  @media (min-width: 766px) {
    display: none;
  }
`

export const CSSMdMin = styled.div`
  @media (max-width: 766px) {
    display: none;
  }
`

export const CSSLgMax = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`

export const CSSLgMin = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`