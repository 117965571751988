import React, { useState } from 'react'
import { Accessibility } from '../_svgs/Accessibility/Accessibility'
import { QualityCodeSeal } from '../_svgs/QualityCodeSeal/QualityCodeSeal'
import { PlusIcon } from '../_svgs/PlusIcon/PlusIcon'
import { FooterThreeColumn } from '../FooterThreeColumn/FooterThreeColumn'
import styled from 'styled-components'
import { CSSLgMax, CSSLgMin } from '../Responsive/Responsive'
import { ButtonThatLookLikeFooterLink } from '../_inputs/Button/Button'

export const Footer = () => {
  const [isActiveCC, setMenuCCState] = useState(false)
  const [isActiveAS, setMenuASState] = useState(false)
  const [isActiveInt, setMenuIntState] = useState(false)
  const [feedbackFormOpen, setFeedbackForm] = useState(false)

  const clickHandlerCC = () => {
    setMenuCCState(!isActiveCC)
  }
  const clickHandlerAS = () => {
    setMenuASState(!isActiveAS)
  }
  const clickHandlerInt = () => {
    setMenuIntState(!isActiveInt)
  }

  const clickHandlerFeedbackForm = () => {
    setFeedbackForm(!feedbackFormOpen)
    if (window) {
      if (window.KAMPYLE_ONSITE_SDK) {
        feedbackFormRender()
      }
    }
  }

  const feedbackFormRender = () => {
    let nebStatus = window.KAMPYLE_ONSITE_SDK.loadForm(13949)
    if (nebStatus) {
      window.KAMPYLE_ONSITE_SDK.showForm(13949)
    }
  }

  return (

    <FooterElement data-site-area='footer' id='reactFooter'>
      <FooterThreeColumn />
      <FooterMain>
        <FooterWrapper>
          <FooterMainCol>
            <CSSLgMin>
              <FooterMainH3>
                <FooterMainA data-target='customer-care' className='cTest_footer_customer-care' href='/help'>Customer Care</FooterMainA>
              </FooterMainH3>
            </CSSLgMin>
            <CSSLgMax>
              <FooterMainButton data-testid='customerCareBtn' onClick={() => { clickHandlerCC() }}>
                <FooterMainSpanbutton>Customer Care
                  <FooterMainPlus>
                    <PlusIcon strokeColor='#000' isMinus={isActiveCC} />
                  </FooterMainPlus>
                </FooterMainSpanbutton>
              </FooterMainButton>
            </CSSLgMax>
            <FooterUnorderedList data-testid='customerList' className={isActiveCC ? 'active' : ''}>
              <FooterListItem>
                <FooterMainA data-target='promotion-details' className='cTest_footer_promotion-details' href='/lp/promotions.html' >
                  *promotion details
                </FooterMainA>
              </FooterListItem>
              <FooterListItem>
                <ButtonThatLookLikeFooterLink id='mdFormButton' onClick={clickHandlerFeedbackForm}>
                  website feedback
                </ButtonThatLookLikeFooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterMainA data-target='help' className='cTest_footer_help' href='/help' >
                  help
                </FooterMainA>
              </FooterListItem>
              <FooterListItem>
                <FooterMainA data-target='shipping' className='cTest_footer_shipping' href='/help/shipping-information/shipping-information-faqs/shipping-methods-and-rates.html'>
                  shipping
                </FooterMainA>
              </FooterListItem>
              <FooterListItem>
                <FooterMainA data-target='returns' className='cTest_footer_returns' href='/help/order-information/order-information-faq/returns.html'>
                  returns
                </FooterMainA>
              </FooterListItem>
            </FooterUnorderedList>
          </FooterMainCol>
          <FooterMainCol>
            <CSSLgMin>
              <FooterMainH3>
                <FooterMainA data-target='about-swanson' className='cTest_footer_about-swanson' href='/celebrate'>About Swanson</FooterMainA>
              </FooterMainH3>
            </CSSLgMin>
            <CSSLgMax>
              <FooterMainButton data-testid='aboutSwansonBtn' onClick={() => { clickHandlerAS() }}>
                <FooterMainSpanbutton>About Swanson
                  <FooterMainPlus>
                    <PlusIcon strokeColor='#000' isMinus={isActiveAS} />
                  </FooterMainPlus>
                </FooterMainSpanbutton>
              </FooterMainButton>
            </CSSLgMax>
            <FooterUnorderedList data-testid='aboutSwansonList' className={isActiveAS ? 'active' : ''}>
              <FooterListItem>
                <FooterMainA data-target='our-quality-code' className='cTest_footer_quality-code' href='/help/guarantees/guarantees-faqs/quality-control.html'>
                  our quality code
                </FooterMainA>
              </FooterListItem>
              <FooterListItem>
                <FooterMainA data-target='social-responsibility' className='cTest_footer_social-responsibility' href='/help/company-information/company-information-faqs/corporate-social-responsibility.html'>
                  social responsibility
                </FooterMainA>
              </FooterListItem>
              <FooterListItem>
                <FooterMainA data-target='privacy-security' className='cTest_footer_privacy-security' href='/help/security-privacy/security-privacy-information/security-and-privacy-information.html'>
                  privacy & security
                </FooterMainA>
              </FooterListItem>
              <FooterListItem>
                <FooterMainA data-target='swanson-cares' className='cTest_footer_swanson-cares' href='/lp/swansoncares.html'>
                  swanson cares
                </FooterMainA>
              </FooterListItem>
              <FooterListItem>
                <FooterMainA data-target='request-catalog' className='cTest_footer_request-catalog' href='/CatalogRequest'>
                  request a catalog
                </FooterMainA>
              </FooterListItem>
            </FooterUnorderedList>
          </FooterMainCol>
          <FooterMainCol>
            <FooterMainH3>
              <FooterMainA data-target='swanson-coupons' className='cTest_footer_swanson-coupons' href='/lp/couponsandpromotions.html'>Swanson Coupons</FooterMainA>
            </FooterMainH3>
          </FooterMainCol>
          <FooterMainCol>
            <FooterMainH3>
              <FooterMainA data-target='careers' className='cTest_footer_careers' href='/career' >Careers</FooterMainA>
            </FooterMainH3>
          </FooterMainCol>
          <FooterMainCol>
            <CSSLgMin>
              <FooterMainH3>
                <FooterMainA data-target='international' className='cTest_footer_international' href='/lp/international.html'>International</FooterMainA>
              </FooterMainH3>
            </CSSLgMin>
            <CSSLgMax>
              <FooterMainButton data-testid='internationalBtn' onClick={() => { clickHandlerInt() }}>
                <FooterMainSpanbutton>International
                  <FooterMainPlus>
                    <PlusIcon strokeColor='#000' isMinus={isActiveInt} />
                  </FooterMainPlus>
                </FooterMainSpanbutton>
              </FooterMainButton>
            </CSSLgMax>
            <FooterUnorderedList data-testid='internationalList' className={isActiveInt ? 'active' : ''}>
              <FooterListItem>
                <FooterMainA data-target='distributors' className='cTest_footer_distributors' href='/lp/international.html'>
                  distributors
                </FooterMainA>
              </FooterListItem>
            </FooterUnorderedList>
          </FooterMainCol>
        </FooterWrapper>
        <FooterBottom>
          <FooterBottomWrapper>
            <FooterBottomLogo>
              <a
                data-target='quality-code-seal'
                className='cTest_footer_quality-code-seal'
                href='/quality/quality.html'
                title='Swanson Quality Code Seal'
              >
                <QualityCodeSeal classes='footer-quality' />
              </a>
            </FooterBottomLogo>
            <FooterBottomDisclaimer>
              <p>
                *Some exclusions apply.{' '}
                <GreenText
                  data-target='see-details'
                  className='cTest_footer_see-details'
                  href='/lp/promotions.html'
                >
                  See Details
                </GreenText>
              </p>
              <p>
                These statements have not been evaluated by the FDA and are not
                intended to diagnose, treat, cure or prevent any disease or
                health condition.
              </p>
              <SEOContainer>
                <SEOA href='/ncat1/Vitamins+and+Supplements/ncat2/Supplements/ncat3/Amino+Acids/q' title='Amino Acids' data-target='seo-amino-acids'>Amino Acids</SEOA>
                <SEOA href='/beauty-personal-care' title='Beauty & Personal Care' data-target='seo-beauty'>Beauty</SEOA>
                <SEOA href='/Brands' title='Shop by Brand' data-target='seo-brands'>Brands</SEOA>
                <SEOA href='/ncat1/Vitamins+and+Supplements/ncat2/Supplements/ncat3/CBD/q' title='CBD' data-target='seo-cbd'>CBD</SEOA>
                <SEOA href='/health-concerns' title='Health Concerns' data-target='seo-concerns'>Concerns</SEOA>
                <SEOA href='/protein-fitness' title='Protein & Fitness' data-target='seo-fitness'>Fitness</SEOA>
                <SEOA href='/health-food-grocery' title='Health Food & Grocery' data-target='seo-grocery'>Grocery</SEOA>
                <SEOA href='/home-pet' title='Home & Pet' data-target='seo-home'>Home</SEOA>
                <SEOA href='/minerals' title='Minerals' data-target='seo-minerals'>Minerals</SEOA>
                <SEOA href='/vitamins-supplements' title='Vitamins & Supplements' data-target='seo-vitamins'>Vitamins</SEOA>
              </SEOContainer>
              <SEOContainer>
                <SEOA href='/herbs' title='Herbs' data-target='seo-herbs'>Herbs</SEOA>
                <SEOSpan>/</SEOSpan>
                <SEOA href='/herbs-a-b' title='Herbs A-B' data-target='seo-herbs-a-b'>A-B</SEOA>
                <SEOSpan>&bull;</SEOSpan>
                <SEOA href='/herbs-c-f' title='Herbs C-F' data-target='seo-herbs-c-f'>C-F</SEOA>
                <SEOSpan>&bull;</SEOSpan>
                <SEOA href='/herbs-g-k' title='Herbs G-K' data-target='seo-herbs-g-k'>G-K</SEOA>
                <SEOSpan>&bull;</SEOSpan>
                <SEOA href='/herbs-l-n' title='Herbs L-N' data-target='seo-herbs-l-n'>L-N</SEOA>
                <SEOSpan>&bull;</SEOSpan>
                <SEOA href='/herbs-o-r' title='Herbs O-R' data-target='seo-herbs-o-r'>O-R</SEOA>
                <SEOSpan>&bull;</SEOSpan>
                <SEOA href='/herbs-s-z' title='Herbs S-Z' data-target='seo-herbs-s-z'>S-Z</SEOA>
              </SEOContainer>
              <p>
                &copy; {new Date().getFullYear()} Swanson -
                <GreenText
                  data-target='terms-of-service'
                  className='cTest_footer_terms-of-service'
                  href='/help/guarantees/guarantees-faqs/terms-of-service.html'
                >
                  Terms of Service
                </GreenText>{' '}
                -
                <GreenText
                  data-target='privacy-policy'
                  className='cTest_footer_privacy-policy'
                  href='/help/security-privacy/security-privacy-information/security-and-privacy-information.html'
                >
                  Privacy Policy
                </GreenText>{' '}
                -
                <GreenText
                  data-target='sitemap'
                  className='cTest_footer_sitemap'
                  href='/q'
                >
                  Sitemap
                </GreenText>
                <a
                  data-target='accessiblity-icon'
                  className='cTest_footer_accessiblity-icon'
                  href='https://www.essentialaccessibility.com/swanson-health-products?utm_source=swansonhealthproductshomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=swansonhealthproducts'
                  title='Accessiblity Icon'
                >
                  <Accessibility classes='accessibility-icon' width={30} />
                </a>
              </p>
            </FooterBottomDisclaimer>
          </FooterBottomWrapper>
        </FooterBottom>
      </FooterMain>
    </FooterElement>
  )
}
const FooterElement = styled.footer`
  width: 100%;
  @media print {
    display: none;
  }
`
const FooterMain = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  @media (max-width: 1279px) {
    margin-bottom: 10rem;
  }
`
const SEOA = styled.a`
  margin-right: .8rem;
  text-decoration: none;
  color: #2F711C;
  &:visited {
    color: ${props => props.theme.primary};
  }
  &:hover{
    color: ${props => props.theme.darkOrange};
    text-decoration: none;
  }
`

const SEOSpan = styled.span`
  margin-right: .8rem;
  text-decoration: none;
  color: #2F711C;
`

const SEOContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  @media print {
    display: none;
  }
`

const FooterUnorderedList = styled.ul`
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  font-size: 14px;
  @media (max-width: 992px) {
    margin: 0;
    padding: 0;
    display: none;
    &:last-child {
      border: 0;
    }
     &.active {
      display: block;
      margin-top: 0.5rem;
    }
  }
`
const FooterListItem = styled.li`
  line-height: 3rem;
  @media (max-width: 992px) {
    border-bottom: 1.5px solid ${props => props.theme.white};
    padding: 0.5rem 0 0.5rem 0.5rem;
    line-height: 2.5rem;
  }
`
const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding: 1.5rem 0;
  }
  @media print {
    display: none;
  }
`
const FooterMainCol = styled.div`
  width: 20%;
  margin-bottom: 0.5rem;
  padding: 0;
  @media (max-width: 992px) {
    width: 100%;
    padding: 1rem 0.8rem;
    margin-bottom: 0;
    border-bottom: 0.1rem solid  ${props => props.theme.mediumGrey};
    order: ${props => props.order};
    &:last-child {
      border: 0;
    }
  }
`
const FooterMainH3 = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
`
const FooterMainSpanbutton = styled.span`
  font-size: 1.4rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 1.5rem;
  }
`
const FooterMainA = styled.a`
  display: inline-block;
  margin-right: 0.5rem;
  color: ${props => props.theme.black} !important;
  text-decoration: none;
  &:hover {
   color: ${props => props.theme.black};
   text-decoration: underline;
  } 
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 1.5rem;
  }
`
const FooterMainPlus = styled.span`
  display: flex;
  margin-right: 1rem;
  align-items: center;
`
const FooterMainButton = styled.button`
  flex: 0 0 100%;
  max-width: 100%;
  min-height: auto;
  width: 100%;
  color:  ${props => props.theme.black};
  padding: 0;
  background-color: ${props => props.theme.white};
  border: none;
  align-items: center;
  height: fit-content;
`
const FooterBottom = styled.div`
  border-top: 0.1rem solid  ${props => props.theme.darkGrey};
  padding: 3rem 0;
  width: 100%;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
`
const FooterBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const FooterBottomLogo = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 3rem;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    flex: 0 0 25%;
    max-width:25%;
    justify-content: flex-start;
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    justify-content: flex-start;
  }
`
const FooterBottomDisclaimer = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 1rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) and (max-width: ${props => props.theme.breakpoints.lg}) {
    flex: 0 0 75%;
    max-width: 75%;
    justify-content: flex-start;
  }
  @media (min-width: 992px) {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    justify-content: flex-start;
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}) and (max-width: ${props => props.theme.breakpoints.xl}) {
    padding-left: 4rem;
  }
  @media (min-width: 1201px) {
    padding-left: 2rem;
  }
`
const GreenText = styled.a`
  color:  #2F711C;
  display: inline-block;
  width: fit-content; // old framework
  text-decoration: none;
  &:hover{
   text-decoration: underline;
  }
`
