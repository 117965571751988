import React from 'react'
import { useDispatch } from 'react-redux'
import { LightBox } from '../LightBox/LightBox'
import { SHARED_CLEAR_INSTANCE } from '../../constants/sharedConstants'
import { LightBoxCentered, LightBoxSM } from '../_styled/LightBoxOptions'
import { DisclaimerDiv } from '../AgeVerifyModal/AgeVerifyModal'
import { css } from 'styled-components'

export const RefusedAgeVerifyModal = () => {
  const dispatch = useDispatch()

  const closeHandler = () => {
    if (document) {
      document.body.style.overflow = 'auto'
    }
    dispatch({ type: SHARED_CLEAR_INSTANCE })
  }

  return (
    <LightBox closeHandler={closeHandler} show styles={css`${LightBoxCentered}${LightBoxSM}`}>
      <DisclaimerDiv>
        Looks like we couldn't verify your age. Add item to your cart again to verify your age.
      </DisclaimerDiv>
    </LightBox>
  )
}
