import {
  SET_COMPARE_ITEMS,
  COMPARE_ADD_ITEM,
  COMPARE_REMOVE_ITEM,
  COMPARE_CLEAR_ITEMS,
  COMPARE_HIDE_BAR,
  COMPARE_SHOW_BAR, SET_COMPARE_ERROR
} from '../constants/compareConstants'

const initialState = {
  barVisible: typeof window?.location !== 'undefined' ? !!sessionStorage.getItem('compare') : '',
  items: typeof window?.location !== 'undefined' ? (JSON.parse(sessionStorage.getItem('compare')) || []) : [],
  compareItems: window?.initialState?.data ? window?.initialState?.data?.getItems : [],
  error: null
}

const compareReducer = (reduxState = initialState, action) => {
  switch (action.type) {
    case SET_COMPARE_ITEMS:
      return {...reduxState, compareItems: action.payload }

    case SET_COMPARE_ERROR:
      return { ...reduxState, error: action.payload }

    case COMPARE_ADD_ITEM:
      if (reduxState.items.length + 1 > 3) {
        return reduxState
      }
      const resultAdd = [...reduxState.items, action.payload]
      if(typeof window.location !== 'undefined') {
        sessionStorage.setItem('compare', JSON.stringify(resultAdd))
      }

      return { ...reduxState, items: resultAdd }

    case COMPARE_REMOVE_ITEM:
      let index = 0
      reduxState.items.forEach((item, i) => {
        if (item.productPartNumber === action.payload.productPartNumber) {
          index = i
        }
      })
      const resultRemove = [
        ...reduxState.items.slice(0, index),
        ...reduxState.items.slice(index + 1)
      ]
      if(typeof window.location !== 'undefined') {
        sessionStorage.setItem('compare', JSON.stringify(resultRemove))
      }

      return { ...reduxState, items: resultRemove }

    case COMPARE_CLEAR_ITEMS:
      if(typeof window.location !== 'undefined') {
        sessionStorage.removeItem('compare')
      }

      return { ...reduxState, items: [] }

    case COMPARE_HIDE_BAR:
      return { ...reduxState, barVisible: false }

    case COMPARE_SHOW_BAR:
      return { ...reduxState, barVisible: true }

    default:
      return reduxState
  }
}

export default compareReducer
