import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../_svgs/SvgAttr/SvgAttr'

export const ReviewStars = ({
  size,
  rating,
  styles,
  starFill = '#60A000',
  onPDP = '#fff',
  svgStyles
}) => {
  return (
    <Container styles={styles} size={size}>
      <Svg styles={svgStyles} viewBox='0 0 96 19'>
        <title>Review Stars</title>
        <Path fill={starFill} as='rect' x='0.4' y='0.7' className='star-border' width={`${rating * 20}%`} height='18' />
        <g>
          <g>
            <Line fill={onPDP} x1='0' y1='1' x2='0' y2='18' />
            <Line fill={onPDP} as='path' d='M96,19.2H0V0h96V19.2z M6.9,6.4l-6,0.9l4.3,4.2l-1,6l5.3-2.8l5.4,2.8l-1-6l4.4-4.3l-6-0.9L9.6,0.9L6.9,6.4z
            M26.1,6.4l-6,0.9l4.4,4.2l-1,6l5.4-2.8l5.4,2.8l-1-6l4.4-4.3l-6-0.9l-2.8-5.4L26.1,6.4z M45.3,6.4l-6,0.9l4.4,4.2l-1,6l5.3-2.8
            l5.4,2.8l-1-6l4.4-4.3l-6-0.9L48,0.9L45.3,6.4z M64.5,6.4l-6,0.9l4.4,4.2l-1,6l5.4-2.8l5.4,2.8l-1-6L76,7.2l-6-0.9l-2.8-5.4
            L64.5,6.4z M83.7,6.4l-6,0.9l4.3,4.2l-1,6l5.4-2.8l5.4,2.8l-1-6l4.4-4.3l-6-0.9l-2.8-5.4L83.7,6.4z'
            />
          </g>
        </g>
        <g>
          <Path fill={starFill} d='M57,7.2l-6.1-0.9L48,0.7l-2.8,5.6l-6.1,0.9l4.5,4.4l-1,6.1l5.4-2.9l5.5,2.9l-1-6.1L57,7.2z M48,13.9l-4.2,2.2
            l0.8-4.8L41,7.9l4.8-0.7L48,2.8l2.2,4.4L55,7.9l-3.5,3.4l0.8,4.8L48,13.9z'
          />
          <Path fill={starFill} d='M76.2,7.2l-6.1-0.9l-2.9-5.6l-2.8,5.6l-6.1,0.9l4.5,4.4l-1,6.1l5.4-2.9l5.5,2.9l-1-6.1L76.2,7.2z M67.2,13.9
            l-4.2,2.2l0.8-4.8l-3.5-3.4L65,7.2l2.2-4.4l2.2,4.4l4.8,0.7l-3.5,3.4l0.8,4.8L67.2,13.9z'
          />
          <Path fill={starFill} d='M37.9,7.2l-6.1-0.9l-2.9-5.6l-2.8,5.6l-6.1,0.9l4.5,4.4l-1,6.1l5.4-2.9l5.5,2.9l-1-6.1L37.9,7.2z M28.8,13.9
            l-4.2,2.2l0.8-4.8l-3.5-3.4l4.8-0.7l2.2-4.4L31,7.2l4.8,0.7l-3.5,3.4l0.8,4.8L28.8,13.9z'
          />
          <Path fill={starFill} d='M95.4,7.2l-6.1-0.9l-2.9-5.6l-2.8,5.6l-6.1,0.9l4.5,4.4l-1,6.1l5.4-2.9l5.5,2.9l-1-6.1L95.4,7.2z M86.4,13.9
            l-4.2,2.2l0.8-4.8l-3.5-3.4l4.8-0.7l2.2-4.4l2.2,4.4l4.8,0.7l-3.5,3.4l0.8,4.8L86.4,13.9z'
          />
          <Path fill={starFill} d='M18.6,7.2l-6.1-0.9L9.5,0.7L6.8,6.3L0.6,7.2l4.5,4.4l-1,6.1l5.4-2.9l5.5,2.9l-1-6.1L18.6,7.2z M9.5,13.9
            l-4.2,2.2l0.8-4.8L2.5,7.9l4.8-0.7l2.2-4.4l2.2,4.4l4.8,0.7L13,11.3l0.8,4.8L9.5,13.9z'
          />
        </g>
      </Svg>
    </Container>
  )
}

ReviewStars.defaultProps = {
  rating: 0
}

const Container = styled.div`
  position: relative;
  width: ${props => props.size || '100%'};
  height: 2.2rem;
  ${props => props.styles};
`

const Svg = styled(SvgAttr)`
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 3rem; // ie 11 compat
  ${props => props.svgStyles}
`

const Path = styled.path`
  fill: ${props => props.fill};
`

const Line = styled.line`
  fill: ${props => props.fill};
`
