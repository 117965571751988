import React from 'react'
import styled, { css } from 'styled-components'
import { LazyImage } from '../LazyImage/LazyImage'
import {
  LeftProductCardA,
  ProductCardA,
  ProductCardBogoTextDiv,
  ProductCardDiscountDiv,
  ProductCardFlagContainerDiv,
  ProductCardNoDiscPriceDiv,
  ProductCardPercentDiscountDiv,
  ProductCardPriceDiv,
  ProductCardTextDiv,
  ProductCardTextP, TooLowToShow
} from '../ProductCard/ProductCard'
import { ReviewRating } from '../ReviewRating/ReviewRating'
import { VisuallyHidden } from '../_styled/VisuallyHidden'
import { AddToCartButton } from '../_inputs/AddToCartButton/AddToCartButton'

export const PDPCarouselProductCard = ({
  focus = false,
  product,
  additionalAfterAddToCartCode,
  isCarousel = false,
  updateCart,
  displayATCBanner,
  includeTracking
}) => {
  let flagColor = ''
  if (product.newWebItem) {
    flagColor = '#23350D'
  } else if (product.bogo) {
    flagColor = '#B93E09'
  } else if ((product?.flags?.indexOf('new_improved') !== -1) || (product?.flagMap?.new_improved)) {
    flagColor = '#23350D'
  } else if ((product?.rewards2x || product?.rewards3x || product?.rewards4x)) {
    flagColor = '#23350D'
  } else {
    flagColor = '#fff'
  }

  return (
    <PDPProductContainerDiv>
      <PDPProductBorderDiv
        data-cnstrc-item = "recommendation"
        data-cnstrc-item-id={product?.constructorOptions?.item_id}
        data-cnstrc-item-name={product?.constructorOptions?.item_name}
        data-cnstrc-item-variation-id={product?.constructorOptions?.variationId}
        data-cnstrc-strategy-id={product?.constructorOptions?.strategy_id}
        data-cnstrc-item-price={product.productDiscountPrice}
      >
        <PDPProductHalfDiv>
          <LeftProductCardA
            id={focus ? 'productFocus' : false}
            href={`/${product.longURL}`}
            title={`${product.productName} - ${product.productPartNumber}`}
          >
            <ProductCardTextDiv className='leftAnchorHover' aria-label={product.productVendor}>{product.productVendor.toUpperCase()}</ProductCardTextDiv>
            <div>
              <LazyImage
                width={105}
                src={`https://media.swansonvitamins.com/images/items/250/${product.productPartNumber}.jpg`}
                alt={`${product.productVendor} ${product.productName}`}
              />
            </div>
            <ProductCardTextDiv className='leftAnchorHover' aria-label={product.productDetails}>{product.productDetails}</ProductCardTextDiv>
          </LeftProductCardA>
        </PDPProductHalfDiv>
        <PDPProductHalfDiv>
          <ProductCardDiscountDiv>
            {product.percentDiscount > 0 &&
              <ProductCardTextP>{Math.floor(product.percentDiscount * 100)}%&nbsp;<span aria-label='off'>OFF</span></ProductCardTextP>
            }
          </ProductCardDiscountDiv>
          <ProductCardA
            href={`/${product.longURL}`}
            title={`${product.productName} - ${product.productPartNumber}`}
            styles={css`
              font-size: 1.4rem;
              text-align: center;
              justify-content: center;
            `}
          >
            {product.productName}
          </ProductCardA>
          <ReviewRating
            pdpCard
            productUrl={product.longURL}
            styles={css`
              color: #00833d;
              justify-content: center;
            `}
            beFirstStyles={css`
              text-align: center;
              color: #00833d;
            `}
            starStyles={css`
              width: 8.3rem;
              margin-right: 0.2rem;
            `}
            starTextStyles={css`
              font-size: 1.2rem;
              white-space: nowrap;
            `}
            rating={product.rating}
            ratingCount={product.numReviews}
            starsFill='#60A000'
          />
          <ProductCardPriceDiv styles={product.bogo ? css`justify-content: center` : ''}>
            {product.bogo &&
              <ProductCardBogoTextDiv>2 for&nbsp;{product.productDiscountPrice}</ProductCardBogoTextDiv>
            }
            {(product.percentDiscount > 0 && !product.bogo && !product.tooLowToShow) &&
              <>
                <ProductCardPercentDiscountDiv styles={css`text-decoration-line: line-through;`}><VisuallyHidden>retail price</VisuallyHidden>${product.everyDayLowPrice}</ProductCardPercentDiscountDiv>
                <ProductCardPercentDiscountDiv styles={css`color: #00833d;`}><VisuallyHidden>sale price</VisuallyHidden>${product.productDiscountPrice}</ProductCardPercentDiscountDiv>
              </>
            }
            {!(product.percentDiscount > 0 || product.bogo) &&
              <ProductCardNoDiscPriceDiv><VisuallyHidden>price</VisuallyHidden>${product.productDiscountPrice}</ProductCardNoDiscPriceDiv>
            }
            {product.tooLowToShow &&
              <TooLowToShow>
                Save {Math.floor(product.percentDiscount * 100)}% in cart
              </TooLowToShow>
            }
          </ProductCardPriceDiv>
          <AddToCartButton
            product={product}
            additionalAfterAddToCartCode={additionalAfterAddToCartCode}
            displayATCBanner={displayATCBanner}
            isCarousel={isCarousel}
            updateCart={updateCart}
            styles={css`margin: 0;`}
            ageVerifyRequired={(product?.flags?.indexOf('age_verification') > -1) || (product?.flagMap?.age_verification)}
            includeTracking={includeTracking}
            data-cnstrc-btn="add_to_cart"
          />
        </PDPProductHalfDiv>
      </PDPProductBorderDiv>
      {(product.newWebItem || product.bogo || product?.flags?.indexOf('new_improved') > 0 || (product?.flagMap?.new_improved) || (product?.rewards2x || product?.rewards3x || product?.rewards4x)) &&
        <ProductCardFlagContainerDiv flagColor={flagColor} styles={(product?.flags?.indexOf('new_improved') > 0) || (product?.flagMap?.new_improved)
          ? (css`
            display: flex;
            width: 15.5rem;
            top: -2.5rem;
            right: -6.9rem;
            height: 7rem;
            padding-bottom: 0.2rem;
            align-items: end;
          `) : (!((product?.flags?.indexOf('new_improved') > 0) || (product?.flagMap?.new_improved)) && !(product?.rewards2x || product?.rewards3x || product?.rewards4x)) ? (css`
            display: flex;
            top: -3.5rem;
            right: -7rem;
            width: 15.5rem;
            height: 8rem;
            padding-bottom: 0.7rem;
            align-items: end;
          `) : (product?.rewards2x || product?.rewards3x || product?.rewards4x) ? (css`
            display: block;
            align-items: end;
            top: -1.1rem;
            right: -5.8rem;
            width: 14.5rem;
            height: 5.3rem;
            text-align: center;
            padding-top: 2rem;
            z-index: 20;
          `) : ''}>
          {product.newWebItem &&
            <ProductCardTextP styles={css`text-align: center;font-size: 1.3rem;`}>New!</ProductCardTextP>
          }
          {product.bogo &&
            <ProductCardTextP styles={css`text-align: center;font-size: 1.3rem;`}>BOGO!</ProductCardTextP>
          }
          {(product?.flags?.indexOf('new_improved') > 0 || (product?.flagMap?.new_improved)) &&
            <ProductCardTextP styles={css`
              margin: -4px;
              line-height: 1.2;
              text-align: center;
              font-size: 1.2rem;
            `}
            >New &<br />Improved!</ProductCardTextP>
          }
          {((product?.rewards2x || product?.rewards3x || product?.rewards4x)) &&
            <>
              <ProductCardTextP
                styles={css`
                  font-size: 2rem;
                  line-height: 90%;
                  padding-left: 2rem;
                  padding-right: 2.2rem;
                  font-weight: 600;
                `}
              >
                {product?.rewards2x && '2X'}
                {product?.rewards3x && '3X'}
                {product?.rewards4x && '4X'}
              </ProductCardTextP>
              <ProductCardTextP
                styles={css`
                  font-size: 1rem;
                  line-height: 90%;
                  font-weight: 600;
                `}
              >Rewards Points</ProductCardTextP>
            </>
          }
        </ProductCardFlagContainerDiv>
      }
    </PDPProductContainerDiv>
  )
}

const PDPProductContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`

const PDPProductBorderDiv = styled.div`
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid ${props => props.theme.mediumGrey};
  padding: 1.5rem;
  position: relative;
  gap: 1rem;
  min-height: 230px;
  max-height: 230px;
  max-width: 300px;
  background-color: ${props => props.theme.white};
  z-index: 10;
  ${props => props.styles};
`

const PDPProductHalfDiv = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  gap: 0.5rem;
`
