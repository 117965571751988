import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const HorizontalLogo = ({
  classes,
  styles
}) => {
  return (
    <Svg x='0px' y='0px' styles={styles} viewBox='30 0 1340 450' className={classes}>
      <title>Go to Swanson Health Home Page</title>
      <g>
        <GreenPath
          d='M130.55,131.74c0,0-7.01,53.36,47.21,59.8C177.76,191.54,184.49,138.03,130.55,131.74'
        />
        <GreenPath
          d='M358.89,131.74c0,0,7.01,53.36-47.21,59.8C311.68,191.54,304.95,138.03,358.89,131.74'
        />
        <GreenPath
          d='M141.85,371.95c0,0,52.51,12.02,63.95-41.2C205.95,330.75,153.44,318.87,141.85,371.95'
        />
        <GreenPath
          d='M284.35,332.18c0,0,13.02,52.22,65.67,38.63C350.02,370.81,336.86,318.59,284.35,332.18'
        />
        <GreenPath
          d='M47,264.79c0,0,43.78,71.96,121.89,24.32C168.89,289.11,120.68,215.58,47,264.79'
        />
        <GreenPath
          d='M322.12,288.83c0,0,68.82,48.5,121.61-26.18C443.73,262.65,370.33,214.29,322.12,288.83'
        />
        <GreenPath d='M281.2,180.38c0,19.6-15.88,35.34-35.34,35.34c-19.6,0-35.34-15.88-35.34-35.34
          c0-19.6,15.88-35.34,35.34-35.34S281.2,160.78,281.2,180.38'
        />
        <GreenPath d='M132.84,207.71c46.93,3,75.97,19.6,110.73,54.08c0.86,0.86,2.43,0.86,3.29,0
          c34.91-34.48,65.67-51.08,112.88-54.08c2.58-0.14,3.58,3.43,1.14,4.58c-53.79,25.04-98.29,69.82-113.6,136.49
          c-0.43,2.15-4.15,2-4.72,0c-14.31-67.24-57.51-111.59-111.02-136.49C129.26,211.14,130.27,207.57,132.84,207.71'
        />
      </g>
      <g>

        <BlackPath
          d='M1287.26,306.71c-4.29,7.3-20.46,6.44-25.32-0.57c-11.3-16.45-23.03-32.76-34.62-49.07
            c-5.44-7.73-10.87-15.31-16.31-23.03c-0.29-0.43-0.72-0.86-1.43-1.72v78.12h-22.03V307c0-34.34,0-68.67,0-102.87
            c0-7.01,2.43-9.87,9.44-11.02c7.87-1.29,13.88,1.14,18.6,7.87c15.17,21.6,30.76,43.06,46.07,64.52c1.14,1.72,2.43,3.29,4.15,5.58
            v-77.83h21.17v113.45H1287.26z M669.78,284.39c-7.3-26.04-14.59-51.93-21.89-77.97H626c-7.15,25.75-14.31,51.5-21.46,77.26
            c-0.14,0-0.43-0.14-0.57-0.14c-6.44-30.04-12.88-60.09-19.31-90.13H559.9c0.14,1,0.29,1.86,0.43,2.58
            c8.58,35.34,17.03,70.53,25.61,105.87c1.14,4.72,3.86,8.15,8.73,9.16c4.15,0.86,8.44,1.29,12.59,0.86
            c6.29-0.57,11.02-3.58,12.73-10.3c4.72-18.17,9.59-36.2,14.45-54.22c0.57-2.29,1.29-4.58,2-6.87c2.72,8.15,4.86,16.31,7.01,24.46
            c3.29,12.3,6.72,24.61,9.87,36.91c1.29,5.15,4.44,8.58,9.59,9.3c4.86,0.72,9.87,0.86,14.59,0.14c5.15-0.72,8.58-4.15,9.87-9.59
            c7.58-31.48,15.45-62.95,23.18-94.57c1.14-4.58,2.15-9.16,3.29-14.02c-7.44,0-14.45,0-21.46,0c-1.86,0-2.58,0.57-3,2.43
            c-3.86,18.03-7.87,36.05-11.73,54.08c-2.58,11.59-5.01,23.03-7.58,34.62C670.06,284.39,669.92,284.39,669.78,284.39 M860.49,234.03
            c16.45,23.32,33.05,46.5,49.36,69.96c4.58,6.44,10.44,8.58,17.74,7.58c7.87-1.14,9.59-4.15,9.59-10.44c-0.14-34.77,0-69.53,0-104.3
            v-3.29h-21.6v77.26c-0.86-1-1.14-1.43-1.57-2c-16.17-22.6-32.48-45.21-48.64-68.1c-4.72-6.72-10.73-9.16-18.6-7.87
            c-7.01,1.14-9.44,4.01-9.44,11.02c0,34.34,0,68.67,0,102.87v3.43h21.89v-78.4C859.77,233.17,860.2,233.6,860.49,234.03
             M1054.2,270.09c4.15,16.74,13.45,29.47,29.47,36.77c11.59,5.29,23.61,6.58,36.05,4.86c18.03-2.58,32.33-11.02,40.77-27.33
            c10.01-19.17,10.3-39.2,2.72-59.09c-6.01-15.88-17.74-26.18-33.91-31.19c-9.73-3-19.6-3.43-29.62-1.86
            c-14.74,2.15-26.9,8.87-35.77,20.89c-8.58,11.59-11.87,25.04-12.3,39.34C1052.06,258.5,1052.77,264.36,1054.2,270.09
             M823.72,310.43c-0.29-0.86-0.43-1.43-0.72-2c-12.59-34.48-25.32-68.82-37.77-103.3c-2.58-7.15-6.72-11.73-14.45-12.16
            c-10.44-0.72-16.17,2.15-19.6,11.45c-11.3,31.62-23.03,63.09-34.62,94.57c-1.43,3.86-2.72,7.73-4.15,11.73c7.58,0,14.88,0,22.03,0
            c1.86,0,2.58-0.57,3.15-2.29c2.72-8.3,5.58-16.45,8.3-24.75c0.57-1.72,1.29-2.15,3-2.15c13.02,2.72,26.75,3.15,38.2,0
            c1.86,0,2.58,0.57,3.15,2.29c2.86,8.87,6.01,17.74,9.01,26.61L823.72,310.43L823.72,310.43z M475.78,303.14
            c14.31,9.01,29.76,11.3,46.07,7.87c10.3-2.15,19.17-7.01,25.47-15.74c9.16-12.73,8.87-33.33-7.58-43.35
            c-6.01-3.72-12.73-6.15-19.17-9.01c-6.01-2.58-12.45-4.58-18.46-7.3c-3.58-1.57-6.44-4.29-7.44-8.44c-1.57-6.58,2-12.59,8.87-15.17
            c7.01-2.58,14.02-2.43,20.89-0.14c5.29,1.72,10.3,4.01,15.74,6.3c3-5.15,6.15-10.59,9.3-16.02c-8.73-6.01-18.17-9.44-28.18-10.44
            c-11.87-1.14-23.46-0.14-34.19,5.72c-19.17,10.59-21.46,39.49-3.72,52.08c7.01,5.01,14.88,8.15,23.03,10.87
            c5.29,1.86,10.73,3.72,16.02,5.87c3.43,1.43,6.15,3.72,7.3,7.58c2,6.44-1.72,13.45-8.87,16.31c-9.87,4.01-19.6,2.86-28.9-1.43
            c-4.86-2.29-9.01-5.58-13.73-8.58c-3.58,4.72-7.44,9.73-11.73,15.31C469.62,298.27,472.49,300.99,475.78,303.14 M1008.71,191.83
            c-11.73-1.14-23.32-0.14-33.91,5.58c-19.6,10.73-21.6,39.63-3.86,52.22c7.01,5.01,14.88,8.15,23.03,10.87
            c5.29,1.86,10.59,3.58,15.74,5.72c3.43,1.43,6.3,3.86,7.44,7.73c2,6.44-1.72,13.45-9.01,16.31c-11.3,4.44-22.18,2.43-32.48-3.29
            c-3.43-2-6.58-4.58-10.01-7.01c-3.86,5.01-7.73,10.01-12.02,15.59c3.43,2.72,6.44,5.58,9.87,7.73
            c14.02,8.87,29.47,11.02,45.64,7.73c10.44-2.15,19.46-7.01,25.75-15.88c9.01-12.73,8.87-33.19-7.58-43.35
            c-6.01-3.72-12.73-6.15-19.17-8.87c-6.15-2.58-12.45-4.58-18.46-7.3c-3.58-1.57-6.44-4.29-7.44-8.44c-1.57-6.58,2-12.59,8.87-15.17
            c7.01-2.58,14.02-2.29,20.89-0.14c5.29,1.72,10.3,4.01,15.74,6.15c3-5.01,6.15-10.44,9.3-16.02
            C1028.31,196.26,1018.86,192.83,1008.71,191.83 M1076.23,251.92c0.43-6.58,0.86-12.88,3-18.89c4.44-13.02,14.16-19.74,27.61-20.89
            c11.73-0.86,22.18,2.15,29.62,12.02c4.44,5.87,6.44,12.59,7.3,19.74c1,9.16,0.72,18.31-2.58,27.18
            c-6.01,16.02-20.17,22.03-35.05,20.46c-19.46-2-28.33-16.31-29.76-33.62C1076.09,255.92,1076.23,253.92,1076.23,251.92
            C1075.95,251.92,1076.23,253.35,1076.23,251.92 M753.62,261.5c4.72-14.16,9.59-28.33,14.59-43.21c5.01,14.74,9.87,28.9,14.74,43.21
            C773.79,264.65,763.63,264.65,753.62,261.5'
        />
        <BlackPath d='M1311.01,219.01c-3.43,0-6.44-1.14-8.87-3.58c-2.43-2.43-3.58-5.44-3.58-8.87l0,0c0-3.43,1.14-6.44,3.58-8.87
          c2.43-2.43,5.44-3.72,8.73-3.72c3.43,0,6.44,1.14,8.87,3.58c2.43,2.43,3.58,5.44,3.58,8.87l0,0c0,3.43-1.14,6.44-3.58,8.87
          S1314.44,219.01,1311.01,219.01z M1303.14,214.43c2.15,2.15,4.72,3.29,7.87,3.29c3.15,0,5.72-1.14,7.87-3.29s3.15-4.86,3.15-7.87
          l0,0c0-3-1-5.72-3.15-7.87s-4.72-3.29-7.87-3.29c-3.15,0-5.72,1.14-7.87,3.29c-2.15,2.15-3.15,4.86-3.15,7.87l0,0
          C1299.99,209.57,1300.99,212.29,1303.14,214.43z M1306.15,212.57v-12.59h5.72c1.43,0,2.58,0.29,3.58,1c0.86,0.72,1.29,1.72,1.29,3
          c0,2-1,3.29-2.86,3.86l3.43,4.72h-3l-3-4.29h-2.58v4.29L1306.15,212.57L1306.15,212.57z M1308.58,206.13h3
          c1.72,0,2.58-0.72,2.58-2.15s-0.86-2-2.58-2h-3V206.13z'
        />
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  @media (min-width: 1280px) {
    height: 70px;
    width: 200px;
  }
  @media (max-width: 1279px) {
    height: 49px;
    width: 140px;
  }
  ${props => props.styles};
`

const GreenPath = styled.path`
  fill: ${props => props.theme.brandedLtGreen};
`

const BlackPath = styled.path`
  fill: ${props => props.theme.brandedBlack};
`
