import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const ShareIosIcon = () => {
  return (
    <Svg x='0px' y='0px' width='16px'
      viewBox='0 0 150 190'>
      <title>iOS Share Icon</title>
      <g>
        <Path d='M54.4,38.6c1-1.1,7.5-8.2,12.7-13.8v90.6h13.1v-91c5.8,5.8,13.3,13.3,13.3,13.3c2.4,2.3,5.9,4,9,2
          c3-1.9,4-5.9,2-9c-0.6-0.9-25.1-25-25.1-25S77,2.4,74.2,2.4c-2.9,0-5.4,2.6-5.4,2.6S45.7,28.2,44.6,29.5c-2.1,2.2-1.9,7.3-0.4,8.8
          C46.7,40.6,51.8,41.2,54.4,38.6z'
        />
        <Path as='polygon' points='89.4,48.7 89.4,59.7 130.7,59.7 130.7,172.3 18.2,172.3 18.2,59.7 59.2,59.7 59.2,48.7 4.6,48.7
        4.6,185.6 144,185.6 144,48.7     ' />
      </g>
    </Svg>
  )
}

const Svg = styled(SvgAttr)``

const Path = styled.path`
  fill: #007AFF;
`
