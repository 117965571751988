import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const CircleChevron = ({
  point,
  backgroundFill = '#fff',
  borderStroke = '#00843d',
  arrowFill = '#00833d',
  height = '4rem',
  width = '4rem',
  styles,
  disabled = false
}) => {
  const [isMobileDevice, setDevice] = useState(false)
  useEffect(() => {
    if (window) {
      let firefoxIsMobile = window.navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
      let universalMobileDetect = window.navigator.userAgentData?.mobile
      if (!!firefoxIsMobile || !!universalMobileDetect) {
        setDevice(true)
      }
    }
  }, [])

  return (
    <Svg styles={styles} width={width} height={height} direction='ltr' point={point} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.91 45.91">
      <title>Chevron</title>
      <G
        styles={disabled ? '' : css`
          &:hover {
            circle {
              fill: ${isMobileDevice ? '#00843d' : '#fff'}
            }
            polygon {
              fill: ${isMobileDevice ? '#fff' : '#00843d'}
            }
          }
        `}
      >
        <Circle
          fill={backgroundFill}
          stroke={disabled ? '#545454' : borderStroke}
          cx="22.96" cy="22.96" r="20.96"
        />
        <Polygon
          fill={disabled ? '#545454' : arrowFill}
          points="28.75 15.13 25.73 12.1 16.16 21.67 16.16 21.67 14.98 22.85 25.73 33.6 28.75 30.58 21.03 22.85 28.75 15.13"
        />
      </G>
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  width: ${props => props.width};
  height: ${props => props.height};
  ${props => props.point === 'down' && `
    transform: rotate(270deg);
  `}
  ${props => props.point === 'right' && `
    transform: rotate(180deg);
  `}
  ${props => props.point === 'top' && `
    transform: rotate(90deg);
  `}
  ${props => props.styles};
`

const Circle = styled.circle`
  fill: ${props => props.fill};
  stroke: ${props => props.borderStroke};
`

const Polygon = styled.polygon`
  fill: ${props => props.fill};
`

const G = styled.g`
  ${props => props.styles};
`