import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const HeartNoFill = ({
  height = '28',
  width = '26',
  fill = '#2F711C',
  styles
}) => {
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 28 26' styles={styles}>
      <title>See Your Favorites</title>
      <Path
        d='M19.8001 0.766663C17.4801 0.766663 15.2534 1.84666 13.8001 3.55333C12.3467 1.84666 10.1201 0.766663
        7.80007 0.766663C3.6934 0.766663 0.466736 3.99333 0.466736 8.1C0.466736 13.14 5.00007 17.2467 11.8667
        23.4867L13.8001 25.2333L15.7334 23.4733C22.6001 17.2467 27.1334 13.14 27.1334 8.1C27.1334 3.99333 23.9067
        0.766663 19.8001 0.766663ZM13.9334 21.5L13.8001 21.6333L13.6667 21.5C7.32007 15.7533 3.1334 11.9533 3.1334
        8.1C3.1334 5.43333 5.1334 3.43333 7.80007 3.43333C9.8534 3.43333 11.8534 4.75333 12.5601 6.58H15.0534C15.7467
        4.75333 17.7467 3.43333 19.8001 3.43333C22.4667 3.43333 24.4667 5.43333 24.4667 8.1C24.4667 11.9533
        20.2801 15.7533 13.9334 21.5Z'
        fill={fill}
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  fill: none;
  ${props => props.styles};
`

const Path = styled.path`
  fill: ${props => props.fill};
`