/**
 * Created by taylor.houston on 10/17/2018.
 */
export const LOADING = 'LOADING'
export const LOADING_COMPLETE = 'LOADING_COMPLETE'
export const FILTER_MOBILE_MENU_TOGGLE = 'FILTER_MOBILE_MENU_TOGGLE'
export const FILTER_MOBILE_MENU_CLOSE = 'FILTER_MOBILE_MENU_CLOSE'
export const APP_SET_DATA = 'APP_SET_DATA'
export const APP_SET_DATA_COMPLETE = 'APP_SET_DATA_COMPLETE'
export const APP_SORT = 'APP_SORT'
export const APP_ERROR = 'APP_ERROR'
export const APP_NO_RESULT = 'APP_NO_RESULT'
export const APP_SET_DATA_RERENDER = 'APP_SET_DATA_RERENDER'
