import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const HeaderMailIcon = ({
   width = '16',
   height = '17',
   fill = '#fff',
}) => {
  return (
    <SvgAttr xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 17">
      <Path
        d="M14.6666 4.50002C14.6666 3.76669 14.0666 3.16669 13.3333 3.16669H2.66665C1.93331 3.16669 1.33331 3.76669 1.33331 4.50002V12.5C1.33331
        13.2334 1.93331 13.8334 2.66665 13.8334H13.3333C14.0666 13.8334 14.6666 13.2334 14.6666 12.5V4.50002ZM13.3333 4.50002L7.99998 7.83335L2.66665
        4.50002H13.3333ZM13.3333 12.5H2.66665V5.83335L7.99998 9.16669L13.3333 5.83335V12.5Z"
        fill={fill}
      />
    </SvgAttr>
  )
}

const Path = styled.path`
  fill: ${props => props.fill};
`
