import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { tabbable } from 'tabbable'

export const FocusTrap = ({ show, doRemove = false, elementOverride, styles, children }) => {
  const tabElements = useRef(null)
  let tabbables = []
  const createTabbables = (remove = false) => {
    if (typeof tabElements === 'object' && tabElements.current !== null) {
      tabbables = tabbable(tabElements.current)

      if (tabbables.length > 0) {
        const lastFocusableElement = (tabbables[tabbables.length - 1])
        const firstFocusableElement = tabbables[0]
        if (remove) {
          tabbables.forEach((tab) => {
            tab.removeEventListener('keydown', previousElement)
            tab.removeEventListener('keydown', nextElement)
          })
        }

        if (elementOverride) {
          elementOverride.current.focus() //Needs to be a ref
        } else {
          firstFocusableElement.focus()
        }

        firstFocusableElement.addEventListener('keydown', previousElement)
        lastFocusableElement.addEventListener('keydown', nextElement)
      }
    }
  }
  const [clickElement, setClickElement] = useState('')
  const previousElement = useCallback((e) => {
    if (e.shiftKey && e.key === 'Tab') {
      e.preventDefault()
      tabbables[tabbables.length - 1].focus()
    }
  }, [])
  const nextElement = useCallback((e) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault()
      tabbables[0].focus()
    }
  }, [])
  if (typeof window.location !== 'undefined') {
    document.addEventListener('xhr-loaded', () => {
      if (show) {
        createTabbables(true)
      }
    })
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        if (typeof window.location !== 'undefined') {
          setClickElement(document.activeElement)
        }

        createTabbables(doRemove)
      }, 1)
    }
  }, [show, doRemove])

  const escHandler = (e) => {
    if (e.which === 27) {
      clickElement.focus()
    }
  }

  return (
    <FocusTrapDiv styles={styles} ref={tabElements} onKeyUp={escHandler}>
      {children}
    </FocusTrapDiv>
  )
}

const FocusTrapDiv = styled.div`
  ${props => props.styles ? props.styles : ''}
`
