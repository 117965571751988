import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { OptionButtonBox } from '../../_svgs/OptionButtonBox/OptionButtonBox'

export const OptionButton = ({
  name,
  formChange,
  returnState,
  inputType = 'checkbox',
  labelText,
  disabled,
  value,
  checked,
  defaultChecked,
  testId = '',
  styles,
  id,
  labelStyles,
  inputStyles = '',
  useBox = false,
  useAltChangeHandler = false
}) => {
  const changeHandler = (e) => {
    if (useAltChangeHandler) {
      if (formChange) {
        formChange(e, {name: name, value: e.currentTarget.value, target: e.currentTarget})
      }
    } else {
      if (e.currentTarget.checked) {
        returnState({name: name, value: e.currentTarget.value, target: e.currentTarget})
      } else {
        returnState({name: name, value: false})
      }
      if (formChange) {
        formChange(e)
      }
    }
  }

  useEffect(() => {
    if (defaultChecked === 'true') {
      returnState({ name: name, value: true })
    }
  }, [])

  const spaceHandler = (e) => {
    if (e.which === 32 || e.which === 13) {
      e.target.click()
    }
  }

  return (
    <CursorElement styles={styles}>

      <Label as='label'
        onKeyUp={spaceHandler}
        data-testid={testId}
        styles={labelStyles}
      >
        <Input onChange={changeHandler}
          id={id}
          value={value}
          checked={checked}
          defaultChecked={defaultChecked}
          type={inputType}
          name={name}
          data-testid='actualInput'
          disabled={disabled}
          aria-label={labelText.props ? labelText.props.children[0] : labelText}
        />

        <ArtificalInput
          id={id}
          data-testid='option-button'
          role={inputType}
          className='option-button__div'
          disabled={disabled}
          inputType={inputType}
          tabIndex={disabled ? -1 : 0}
          aria-label={labelText.props ? labelText.props.children[0] : labelText}
          aria-checked={checked}
          styles={useBox ? css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
            ${inputStyles};
          ` : ''}
        >
          {inputType === 'checkbox' &&
            <OptionButtonBox
              useBox={useBox}
              styles={css`
                position: absolute;
                top: -5px;
                right: -4px;
                width: 28px;
                height: 28px;
                display: none;
              `}
            />
          }
          {disabled &&
          <DashedArtificalInput />
          }
          {inputType === 'radio' &&
          <RoundArtificalInput />
          }
        </ArtificalInput>{labelText}
      </Label>
    </CursorElement>
  )
}

const RoundArtificalInput = styled.div`
  display: none;
  background-color: ${props => props.theme.black};
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
`

const Input = styled.input`
  display: none;
  &:checked ~ .option-button__div ${RoundArtificalInput},
  &:checked ~ .option-button__div .option-button__svg {
    display: block;
  }
`

const CursorElement = styled.div`
  cursor: pointer;
  ${props => props.styles};
`
const Label = styled(CursorElement)`
  display: flex;
  align-items: center;
  ${props => props.styles};
`

const DashedArtificalInput = styled.div`
  border-top: .2rem solid ${props => props.theme.mediumGrey};
  width: .8rem;
  height: 0;
`

const ArtificalInput = styled.div`
  ${props => props.inputType !== 'link' && css`
    flex-shrink: 0;
    width: 2.2rem;
    height: 2.2rem;
    border: .1rem solid ${props => props.theme.primary};
    position: relative;
    margin-right: .6rem;
  `}
  ${props => props.inputType === 'radio' && css`
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  ${props => props.disabled && css`
    border-color: ${props => props.theme.darkGrey};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  ${props => props.inputType === 'link' && css`
    margin-right: 0.6rem;
  `}
  ${props => props.styles};
`
