import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const MailIcon = ({ width, height, color = '#000' }) => {
  return (
    <SvgAttr x='0px' y='0px' width={`${width}px`} height={`${height}px`}
      viewBox='0 0 80 60'>
      <title>Mail Icon</title>
      <Polygon fill={color} points='77.8,12.5 40.3,34.4 2.2,12.4 2.2,54.3 77.8,54.3 ' />
      <Polygon fill={color} points='2.2,3.1 2.2,9.7 40.3,32 77.8,10 77.8,3.1 ' />
    </SvgAttr>
  )
}

const Polygon = styled.polygon`
 fill: ${props => props.fill};
`
