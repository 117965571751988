function tealiumPromise(callback) {
  if (window.utag === undefined) {
    new Promise(function (resolve, reject) {
      let tag = document.createElement('script')
      tag.src = window.currentEnvironment
      tag.async = true
      tag.onload = function () {
        resolve()
      }
      let firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }).then(function () {
      callback()
    })
  } else {
    callback()
  }
}

export function trackTealium (eventType, tealiumFields, definedCallback) {
  tealiumPromise( function () {
    window.utag_data = {...window.utag_data, ...tealiumFields}
    window.utag.view(window.utag_data)
    if (definedCallback) {
      definedCallback()
    }
  })
}

export function utagLink(obj) {
  tealiumPromise( function() {
    window.utag.link(obj)
  })
}

export const trackingStrip = (string) => {
  if (typeof string === 'string') {
    return string.toLowerCase().replace(/[.',]/g, '').replace('&', 'and').replace(/\s\s+/g, '-').replace(/\s/g, '-')
  }
}
