import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const Pin = ({
  fill = '#fff',
  height = '16',
  width = '17'
}) => {
  return (
    <SvgAttr xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 17">
      <g>
        <Path
          d="M7.99998 1.83334C5.41998 1.83334 3.33331 3.92001 3.33331 6.50001C3.33331 10 7.99998 15.1667 7.99998 15.1667C7.99998 15.1667 12.6666 10 12.6666 6.50001C12.6666
          3.92001 10.58 1.83334 7.99998 1.83334ZM4.66665 6.50001C4.66665 4.66001 6.15998 3.16668 7.99998 3.16668C9.83998 3.16668 11.3333 4.66001 11.3333 6.50001C11.3333
          8.42001 9.41331 11.2933 7.99998 13.0867C6.61331 11.3067 4.66665 8.40001 4.66665 6.50001Z"
          fill={fill}
        />
        <Path
          d="M7.99998 8.16668C8.92045 8.16668 9.66665 7.42048 9.66665 6.50001C9.66665 5.57954 8.92045 4.83334 7.99998 4.83334C7.07951 4.83334 6.33331 5.57954 6.33331
          6.50001C6.33331 7.42048 7.07951 8.16668 7.99998 8.16668Z"
          fill={fill}
        />
      </g>
    </SvgAttr>
  )}

const Path = styled.path`
  fill: ${props => props.fill};
`