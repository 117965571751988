import {
  ACCORDION_PAYMENT, ACCORDION_PAYMENT_BUTTON,
  ACCORDION_PAYMENT_SUMMARY,
  ACCORDION_PLACE_ORDER, ACCORDION_PLACE_ORDER_BUTTON,
  ACCORDION_SHIPPING, ACCORDION_SHIPPING_METHOD,
  ACCORDION_UPDATE_VIEW
} from '../../constants/accordionConstants'
import { CHECKOUT_REFRESH_CC_INFO } from '../../constants/checkoutConstants'
export const togglePaymentAction = (open) => (
  {
    type: ACCORDION_PAYMENT, payload: open
  }
)

export const togglePaymentButtonAction = (open) => (
  {
    type: ACCORDION_PAYMENT_BUTTON, payload: open
  }
)

export const toggleShippingAction = () => {
  return (dispatch, getReduxStore) => {
    dispatch({
      type: ACCORDION_SHIPPING, payload: !getReduxStore().accordion.isShippingOpen
    })
  }
}

export const toggleShippingMethodAction = () => {
  return (dispatch, getReduxStore) => {
    dispatch({
      type: ACCORDION_SHIPPING_METHOD, payload: !getReduxStore().accordion.isShippingMethodOpen
    })
  }
}

export const updateViewAction = (payload) => {
  return (dispatch) => {
    if (payload.stateId === 'getPaymentMethodEvent') {
      dispatch({ type: CHECKOUT_REFRESH_CC_INFO, payload: payload.orderInfo })
    }
    dispatch({ type: ACCORDION_UPDATE_VIEW, payload: payload.stateId })
  }
}

export const togglePlaceOrderAction = open => (
  {
    type: ACCORDION_PLACE_ORDER, payload: open
  }
)

export const togglePlaceOrderButtonAction = open => (
  {
    type: ACCORDION_PLACE_ORDER_BUTTON, payload: open
  }
)

export const togglePaymentSummaryAction = open => (
  {
    type: ACCORDION_PAYMENT_SUMMARY, payload: open
  }
)
