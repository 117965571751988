export const SHARED_LOCK_SCROLL = 'SHARED_LOCK_SCROLL'
export const SHARED_CHECK_EMAIL = 'SHARED_CHECK_EMAIL' // use name of reducer action combo in constants so it becomes obvious where it came from.
export const SHARED_CHECK_SMS = 'SHARED_CHECK_SMS'
export const SHARED_LOADING_START = 'SHARED_LOADING_START'
export const SHARED_LOADING_END = 'SHARED_LOADING_END'
export const SHARED_SET_INSTANCE = 'SHARED_SET_INSTANCE'
export const SHARED_CLEAR_INSTANCE = 'SHARED_CLEAR_INSTANCE'
export const UPDATE_OOS_MESSAGE = 'UPDATE_OOS_MESSAGE'
export const UPDATE_OOS_SUBMITTED = 'UPDATE_OOS_SUBMITTED'
export const CLEAR_OOS = 'CLEAR_OOS'
export const UPDATE_OOS_EMAIL_SHOW = 'UPDATE_OOS_EMAIL_SHOW'
export const SET_SECOND_LOADING = 'SET_SECOND_LOADING'
export const OG_UPDATE_CHECKED = 'OG_UPDATE_CHECKED'
export const SHARED_AGE_VERIFIED = 'SHARED_AGE_VERIFIED'
