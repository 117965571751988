import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import { TransparentButton } from '../../_inputs/Button/Button'
import { trackingStrip } from '../../../tealium'
import { checkIfMobile } from '../../../actions/headerActions'
import { HeaderCatFeaturedSpot } from '../HeaderCatFeaturedSpot/HeaderCatFeaturedSpot'
import { Pin } from '../../_svgs/Pin/Pin'
import { SignupTag } from '../../_svgs/SignupTag/SignupTag'

export const MobileCategoryMenu = ({
  setFocus = () => {},
  emailOpenHandler = () => {}
}) => {
  const ref = useRef(null)
  const [globalState, setGlobalState] = useState(window.globalState)
  const [currentMenu, setCurrentMenu] = useState(-1)
  const [isTabletMobile, setIsTabletMobile] = useState(false)
  const isIntl = window ? window?.isIntl : false

  useEffect(() => {
    if (!window.globalState) {
      axios.get('/headerlinks.json')
        .then((response) => {
          setGlobalState(response.data)
        })
    }
  }, [globalState])
  
  useEffect(() => {
    setIsTabletMobile(checkIfMobile())
  })
  
  const toggleMenu = (e, index, isLast) => {
    setCurrentMenu(currentMenu === index ? -1 : index)
    if (isLast && currentMenu !== index) {
      setFocus('open', ref)
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'fixed'
      }
    } else if (currentMenu === index) {
      setFocus('close', ref)
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'auto'
        document.body.style.position = 'initial'
      }
    }
    e.preventDefault()
  }

  return (
    <MobileCategoryMenuContainer data-testid='mobileCategoryMenu'>
      <MobileCategoryMenuUl>
        <MobileCategoryMenuLi
          key='storelocator'
          styles={isIntl ? css`margin: 1.6rem 0 1rem 0; border-bottom: ${props => props.theme.mediumGrey} 1px solid;` : css`margin-top: 1.6rem;`}
        >
          <MobileCategoryMenuA
            href='/store-locator'
            data-testid='storelocator'
            data-tracking='mobile-header|store-locator'
            aria-label='Find Swanson at a Store Near You'
            styles={css`color: ${props => props.theme.primary};`}
          >
            <Pin fill='#00833d'/>&nbsp;Find Swanson at a Store Near You
          </MobileCategoryMenuA>
        </MobileCategoryMenuLi>
        {!isIntl &&
          <MobileCategoryMenuLi
            key='signupForDeals'
            styles={css`border-bottom: ${props => props.theme.mediumGrey} 1px solid;`}
          >
            <MobileCategoryMenuBtn
              data-testid='signupForDeals'
              data-tracking='mobile-header|signup-deals'
              aria-label='Signup for Deals'
              onClick={emailOpenHandler}
              styles={css`color: ${props => props.theme.primary}; margin-bottom: 1rem;`}
            >
              <SignupTag fill='#00833d'/>&nbsp;Sign Up for Deals!
            </MobileCategoryMenuBtn>
          </MobileCategoryMenuLi>
        }
        {globalState && globalState?.categoryResultList.map((list, index) => (
          <MobileCategoryMenuLi
            key={`mobileCategory${index}`}
            className={index === currentMenu ? 'cat-active' : ''}
          >
            <MobileCategoryMenuBtnContainer className='button-container'>
              <MobileCategoryMenuBtn
                data-testid={`${trackingStrip(list.titleDisplay)}-button`}
                aria-expanded={index === currentMenu}
                ref={index + 1 === globalState.categoryResultList.length ? ref : null}
                onClick={(e) => { toggleMenu(e, index, index + 1 === globalState.categoryResultList.length)}}
                styles={index === currentMenu ? css`width: calc(100% - 9rem);` : css`width: 100%;`}
              >
                {list.titleDisplay}
              </MobileCategoryMenuBtn>
              {currentMenu === index &&
                <MobileCategoryA
                  data-testid={`${trackingStrip(list.titleDisplay)}-shop-all`}
                  href={list.dimensionUri.seoURL}
                  aria-label={`Shop All ${list.titleDisplay}`}
                  data-tracking={`header|mobile-category|${trackingStrip(list.titleDisplay)}`}
                >
                  Shop All &raquo;
                </MobileCategoryA>
              }
            </MobileCategoryMenuBtnContainer>
            {currentMenu === index &&
              <>
                <MobileCategoryMenuUl styles={css`margin: 0;`}>
                  {globalState.categoryResultList[index].categoryResultList.map((subList, subIndex) => (
                    <MobileCategoryMenuLi
                      key={`mobileSubCategory${subIndex}`}
                      styles={css`margin: 0; padding: 1.6rem;`}
                    >
                      <MobileSubCategoryADiv>
                        <MobileSubCategoryA
                          data-testid={`${trackingStrip(subList.titleDisplay)}-button`}
                          href={subList.dimensionUri.seoURL}
                          aria-label={subList.title}
                          data-tracking={`header|mobile-category|${trackingStrip(list.titleDisplay)}|${trackingStrip(subList.titleDisplay)}`}
                        >
                          {subList.titleDisplay}
                        </MobileSubCategoryA>
                      </MobileSubCategoryADiv>
                      {(!isTabletMobile && subList.categoryResultList !== null) &&
                        <MobileLvlThreeUl>
                          {subList.categoryResultList.map((lThreeList, lThreeIndex) => (
                            <li
                              key={`mobileLevelThreeCategory${lThreeIndex}`}
                            >
                              <MobileLvlThreeA
                                data-testid={`${trackingStrip(lThreeList.titleDisplay)}-button`}
                                href={subList.dimensionUri.seoURL}
                                aria-label={lThreeList.titleDisplay}
                                data-tracking={`header|mobile-category|${trackingStrip(subList.titleDisplay)}|${trackingStrip(lThreeList.titleDisplay)}`}
                              >
                                {lThreeList.titleDisplay}
                              </MobileLvlThreeA>
                            </li>
                          ))}
                        </MobileLvlThreeUl>
                      }
                    </MobileCategoryMenuLi>
                  ))}
                  {/*This is temporary until dropdown collection is moved from GroupBy to Constructor. This code should be removed then. START*/}
                  {list.titleDisplay === 'Vitamins & Supplements' &&
                    <>
                      <MobileCategoryMenuLi
                        key={`mobileSubCategory3`}
                        styles={css`margin: 0; padding: 1.6rem;`}
                      >
                        <MobileSubCategoryADiv>
                          <MobileSubCategoryA
                            data-testid='mens-health-button'
                            href='/mens-health'
                            aria-label="Men's Health"
                            data-tracking={`header|mobile-category|vitamins-and-supplements|mens-health`}
                          >
                            Men's Health
                          </MobileSubCategoryA>
                        </MobileSubCategoryADiv>
                      </MobileCategoryMenuLi>
                      <MobileCategoryMenuLi
                        key={`mobileSubCategory4`}
                        styles={css`margin: 0; padding: 1.6rem;`}
                      >
                        <MobileSubCategoryADiv>
                          <MobileSubCategoryA
                            data-testid='womens-health-button'
                            href='/womens-health'
                            aria-label="Women's Health"
                            data-tracking={`header|mobile-category|vitamins-and-supplements|womens-health`}
                          >
                            Women's Health
                          </MobileSubCategoryA>
                        </MobileSubCategoryADiv>
                      </MobileCategoryMenuLi>
                    </>
                  }
                {/*This is temporary until dropdown collection is moved from GroupBy to Constructor. This code should be removed then. END*/}
                </MobileCategoryMenuUl>
                <MobileCategoryMenuBanner
                  styles={isTabletMobile ?
                    css`
                      display: none;
                    `
                    :
                    css`
                      justify-content: center;
                      margin-bottom: 1.6rem;
                    `
                  }
                >
                  {globalState && globalState?.categoryResultList[index].categoryResultList.length < 7 &&
                    <HeaderCatFeaturedSpot
                      globalState={globalState}
                      currentIndex={index}
                    />
                  }
                </MobileCategoryMenuBanner>
              </>
            }
          </MobileCategoryMenuLi>
        ))}
      </MobileCategoryMenuUl>
    </MobileCategoryMenuContainer>
  )
}

const MobileCategoryMenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const MobileCategoryMenuUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  width: 100%;
  padding: 0;
  ${props => props.styles};
`

const MobileCategoryMenuLi = styled.li`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  transition: none !important;
  &.cat-active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
    .button-container {
      color: ${props => props.theme.white};
      background-color: ${props => props.theme.primary};
      transition: none !important;
      button {
        color: ${props => props.theme.white};
        transition: none !important;
      }
    }
  }
  ${props => props.styles};
`

const MobileCategoryMenuBtnContainer = styled.div`
  display: flex;
  width: 100%;
`

const MobileCategoryMenuBtn = styled(TransparentButton)`
  display: flex;
  font-size: 2rem;
  margin: 0;
  height: auto;
  padding: 1rem;
  color: ${props => props.theme.black};
  justify-content: flex-start;
  border: none;
  ${props => props.styles};
`

const MobileSubCategoryA = styled.a`
  color: ${props => props.theme.primary};
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
    color: ${props => props.theme.primary};
  }
`

const MobileSubCategoryADiv = styled.div`
  display: flex;
  width: 100%;
`

const MobileCategoryA = styled.a`
  font-size: 1.6rem;
  width: 9rem;
  display: flex;
  padding-left: 0.8rem;
  color: ${props => props.theme.white};
  align-items: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.white};
    
  }
`

const MobileCategoryMenuBanner = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${props => props.styles};
`

const MobileLvlThreeUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const MobileLvlThreeA = styled.a`
  color: ${props => props.theme.black};
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
    color: ${props => props.theme.primary};
  }
`

const MobileCategoryMenuA = styled.a`
  color: ${props => props.theme.primary};
  text-decoration: none;
  padding: 1rem;
  font-size: 2rem;
  &:hover {
    text-decoration: underline !important;
    color: ${props => props.theme.primary};
  }
`