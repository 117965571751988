import React from 'react'
import { SvgAttr } from '../SvgAttr/SvgAttr'
import styled from 'styled-components'

export const SMSIcon = ({ width, height, black = '#000', white = '#fff' }) => {
  return (
    <SvgAttr x='0px' y='0px' width={`${width}px`} height={`${height}px`}
      viewBox='0 0 80 80'>
      <title>SMS Icon</title>
      <g>
        <Polygon black={black} points='77.8,9.9 2.2,9.9 2.2,55.9 51.5,55.9 55.6,55.9 70.3,75.2 67,55.9 77.8,55.9 	' />
        <g>
          <Path white={white} d='M18.9,42.8c-3.2,0-6-1.1-8.3-3.2l2.1-2.5c2,1.7,4.1,2.6,6.3,2.6c1.1,0,1.9-0.2,2.6-0.7s1-1.1,1-1.8v-0.1
			c0-0.7-0.3-1.3-0.9-1.8C21,35,19.8,34.5,18,34.1c-1.1-0.3-2.1-0.6-2.9-0.9c-0.8-0.3-1.4-0.7-2.1-1.1c-0.6-0.5-1.1-1-1.3-1.7
			c-0.3-0.7-0.4-1.4-0.4-2.3v-0.1c0-1.7,0.6-3.1,1.9-4.2c1.3-1.1,2.9-1.6,4.9-1.6c2.7,0,5.2,0.8,7.2,2.5l-1.9,2.7
			c-1.8-1.4-3.6-2.1-5.4-2.1c-1,0-1.8,0.2-2.4,0.7c-0.6,0.4-0.9,1-0.9,1.7v0.1c0,0.8,0.3,1.4,1,1.8c0.6,0.4,1.9,0.9,3.8,1.3
			c2.2,0.5,3.8,1.2,4.8,2.1c1,0.9,1.5,2.1,1.5,3.7v0.1c0,1.9-0.7,3.3-2,4.4C22.8,42.3,21.1,42.8,18.9,42.8z' />
          <Path white={white} d='M30.2,42.6v-20h3.7L40,32l6.1-9.4h3.7v20h-3.5V28.2L40,37.6h-0.1l-6.2-9.3v14.3H30.2z' />
          <Path white={white} d='M61.9,42.8c-3.2,0-6-1.1-8.3-3.2l2.1-2.5c2,1.7,4.1,2.6,6.3,2.6c1.1,0,1.9-0.2,2.6-0.7c0.6-0.5,1-1.1,1-1.8v-0.1
			c0-0.7-0.3-1.3-0.9-1.8s-1.8-0.9-3.6-1.3c-1.1-0.3-2.1-0.6-2.9-0.9c-0.8-0.3-1.4-0.7-2.1-1.1c-0.6-0.5-1.1-1-1.3-1.7
			c-0.3-0.7-0.4-1.4-0.4-2.3v-0.1c0-1.7,0.6-3.1,1.9-4.2c1.3-1.1,2.9-1.6,4.9-1.6c2.7,0,5.2,0.8,7.2,2.5l-1.9,2.7
			c-1.8-1.4-3.6-2.1-5.4-2.1c-1,0-1.8,0.2-2.4,0.7c-0.6,0.4-0.9,1-0.9,1.7v0.1c0,0.8,0.3,1.4,1,1.8c0.6,0.4,1.9,0.9,3.8,1.3
			c2.2,0.5,3.8,1.2,4.8,2.1c1,0.9,1.5,2.1,1.5,3.7v0.1c0,1.9-0.7,3.3-2,4.4C65.8,42.3,64.1,42.8,61.9,42.8z' />
        </g>
      </g>
    </SvgAttr>
  )
}

const Polygon = styled.polygon`
 fill: ${props => props.black}
`
const Path = styled.path`
 fill: ${props => props.white}
`
