import styled, { css } from 'styled-components'

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: .5rem;
  width: 100%
`

export const NameElement = styled.div`
  width: 100%;
  margin: 0 .6rem;
  ${(props) => props.useHorizontalOrientation && css`
    overflow: hidden;
    text-overflow: ellipsis;
    height: 4rem;
    line-height: 1.8rem;
    text-align: center;
  `}
`

export const BrandElement = styled.div`
  font-size: 1.2rem;
  padding: 0 .5rem;
`