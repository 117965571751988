import React, { useState, useEffect, useRef } from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import './Field.sass'
import { ShowPassword } from '../ShowPassword/ShowPassword'
import { useUniqueId } from '../../../hooks/useUniqueId'

export const Field = ({
  showLabel = true,
  inputType = 'text',
  hidden,
  styles,
  error,
  id,
  labelText,
  returnState,
  keyPressHandler = () => {},
  name,
  value,
  placeholder,
  maxLength,
  rows,
  addRef,
  autoFocus,
  autocomplete,
  hideError = false,
  classes,
  labelClass,
  fieldStyles,
  labelStyles,
  dataTestid,
  includeTracking = false,
  focusStyle,
  useLabelPlaceholder = false
}) => {
  const [passwordField, setPasswordField] = useState(inputType)
  const [ focusedField, setFocus ] = useState(false)
  const idGenerator = useUniqueId('uniqueId')
  const ref = useRef()
  const label = showLabel ? (<FieldLabel useLabelPlaceholder={useLabelPlaceholder} focused={focusedField || value} styles={labelStyles} error={error && error[name]} htmlFor={id || idGenerator}>{labelText}</FieldLabel>) : ''
  const hiddenLabel = showLabel ? (<FieldLabel styles={labelStyles} error={error && error[name]}>{labelText}</FieldLabel>) : ''

  useEffect(() => {
    if (addRef) {
      addRef(name, ref.current)
    }
  }, [])

  const changeHandler = (e) => {
    returnState({ name: name, value: e.currentTarget.value })
  }

  const fieldKeyPressHandler = (e) => {
    keyPressHandler(e)
  }

  const passwordHandler = (e) => {
    if (passwordField === 'password') {
      setPasswordField('text')
    } else {
      setPasswordField('password')
    }
  }

  let fields = ''

  if (inputType === 'textarea') {
    fields = <TextArea as={'textarea'}
      data-testid={dataTestid}
      ref={ref}
      autoFocus={autoFocus}
      onChange={changeHandler}
      rows={rows}
      className='field__textarea field__field'
      id={id || idGenerator}
      name={name}
      value={value}
    />
  } else if (includeTracking) {
    fields = <InputField
      ref={ref}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      data-testid={dataTestid}
      styles={fieldStyles}
      autoFocus={autoFocus}
      autoComplete={autocomplete}
      hasError={error && error[name]}
      className={`field__input field__field checkout__field__margin ${error && error[name] ? 'checkout__error__margin' : ''}`}
      onChange={changeHandler}
      id={id || idGenerator}
      aria-label={labelText}
      name={name}
      onKeyDown={fieldKeyPressHandler}
      value={value}
      type={passwordField}
      placeholder={placeholder}
      maxLength={maxLength}
      data-cnstrc-search-input
    />
  } else {
    fields = <InputField
      ref={ref}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      data-testid={dataTestid}
      styles={fieldStyles}
      autoFocus={autoFocus}
      autoComplete={autocomplete}
      hasError={error && error[name]}
      className={`field__input field__field checkout__field__margin ${error && error[name] ? 'checkout__error__margin' : ''}`}
      onChange={changeHandler}
      id={id || idGenerator}
      aria-label={labelText}
      name={name}
      onKeyDown={fieldKeyPressHandler}
      value={value}
      type={passwordField}
      placeholder={placeholder}
      maxLength={maxLength}
      focusStyle={focusStyle}
    />
  }

  return (
    <FieldContainer
      useLabelPlaceholder={useLabelPlaceholder}
      valuePresent={!!value}
      focused={focusedField}
      error={error && error[name]}
      className={focusedField || value ? 'focused' : ''}
      aria-live='polite'
      hidden={inputType === 'hidden'}
      styles={styles}
    >
      {inputType === 'hidden' ? hiddenLabel : label}
      {showLabel &&
      <SignUpErrorMsg role='alert' aria-describedby={id || idGenerator} aria-live={!hideError && error && error[name] ? 'assertive' : 'polite'}>
        {!hideError && error && error[name]
          ? Array.isArray(error[name])
            ? error[name][0]
            : error[name]
          : ''
        }
      </SignUpErrorMsg>
      }

      {fields}{inputType === 'password' && <ShowPassword flipPassword={passwordHandler} passwordType={passwordField} />}
    </FieldContainer>
  )
}

Field.defaultProps = {
  inputType: 'text',
  hideError: false,
  showLabel: true,
  keyPressHandler: () => {}
}

Field.propTypes = {
  inputType: PropTypes.oneOf([
    'text', 'number', 'password', 'hidden', 'textarea'
  ]),
  orientation: PropTypes.oneOf([
    'horizontal', 'vertical'
  ]),
  autocomplete: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  error: PropTypes.object,
  hideError: PropTypes.bool
}

const FieldContainer = styled.div`
  position: relative;
  display: block;
  &.focused label {
    ${props => props.useLabelPlaceholder &&
      `transition: all .2s linear;
      top: 1.5rem;
      font-size: 1.4rem;
      background: white;
    `};
    ${props => (!props.focused && props.valuePresent) && css`
      color: ${props => props.theme.black};
    `}
    ${props => props.focused && css`
      color: ${props => props.theme.primary};
    `}
    ${props => props.error && css`
      color: ${props => props.theme.darkRed};
    `}
  }
  ${props => props.hidden && css`
    display: none;
  `}
  ${props => props.styles};
`
const FieldLabel = styled.label`
  ${props => props.useLabelPlaceholder && `
    position: absolute;
    top: 3.7rem;
    left: 1rem;
    transition: all .2s linear;
  `};
  color: ${props => props.theme.black}
  ${props => props.hidden && css`
    display: none;
  `}
  ${props => props.styles};
`

const TextArea = styled.input`
  border: .1rem solid ${props => props.theme.darkGrey};
  ${props => props.hasError ? `border: .1rem solid ${props.theme.darkRed} !important;` : ''};
  width: 100%;
  font-family: inherit;
  font-size: 1.6rem;
  -moz-appearance: textfield;
  margin: 0 !important; // for old framework
  &::-webkit-outer-spin-button,&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: red;
    opacity: 1;
  }
  ${props => props.styles};
`

const InputField = styled(TextArea)`
  height: 4rem;
  padding-left: .6rem;
  border-radius: .8rem;
  ${props => props.styles};
  :focus {
    background-color: #EEF9E7;
    border-color: #545454;
    ${props => props.focusStyle};
  }
`
const SignUpErrorMsg = styled.span`
  color: ${props => props.theme.darkRed} !important;
`
