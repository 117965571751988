import { FORM_UPDATE, FORM_INIT, FORM_COPY, FORM_CLEAR } from '../constants/formConstants'

const formReducer = (reduxState = {
  shipping: {},
  billing: {},
  payment: {
    _eventId: 'placeOrderEvent'
  },
  placeOrder: {
    _eventId: 'finishOrderEvent'
  },
  chooseShipping: {}
}, action) => {
  switch (action.type) {
    case FORM_UPDATE: {
      return {
        ...reduxState,
        [action.update.form]: {
          ...reduxState[action.update.form], [action.update.name]: action.update.value
        }
      }
    }
    case FORM_CLEAR: {
      return {
        ...reduxState,
        [action.payload.form]: {}
      }
    }
    case FORM_INIT: {
      return { ...reduxState,
        [action.payload.formName]: {
          ...reduxState[action.payload.formName], ...action.payload.defaults
        }
      }
    }
    case FORM_COPY: {
      const formToCopy = action.payload
      formToCopy._eventId = action.payload._eventId
      return { ...reduxState, [action.payload.form]: formToCopy }
    }
    default:
      return reduxState
  }
}

export default formReducer
