import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const KosherIcon = ({
  fillColor = '#23350d',
  strokeColor= '#21543b',
  height = '4.1rem',
  width = '100%'
}) => (
  <Svg 
    xmlns='http://www.w3.org/2000/svg' 
    id='specialty-flag_kosher' 
    data-name='specialty-flag kosher'
    viewBox='0 0 162.02 198.63'
    height={height}
    width={width}
  >
    <title>Kosher</title>

    <Line strokeColor={strokeColor} x1='4.46' y1='61.81' x2='157.56' y2='61.81'/>
    <g>
      <Path fillColor={fillColor} className='cls-2' d='M46.31,74.93c-3.72,0-6.3,2.94-6.3,6.54v.06c0,3.6,2.64,6.6,6.36,6.6s6.3-2.94,6.3-6.54v-.06c0-3.6-2.64-6.6-6.36-6.6Z'/>
      <Path fillColor={fillColor} d='M138.77,75.2h-4.59v6.03h4.68c2.25,0,3.54-1.2,3.54-2.97v-.06c0-1.98-1.38-3-3.63-3Z'/>
      <Path fillColor={fillColor} d='M157.1,58.68H4.81l-3,22.25,3,23.25h152.29l4-23.92-4-21.57ZM27.92,92.03l-6.51-8.94-2.43,2.52v6.42h-4.62v-21h4.62v9.18l8.52-9.18h5.58l-8.55,8.91,8.94,12.09h-5.55ZM57.5,81.53c0,5.97-4.71,10.86-11.19,10.86s-11.13-4.83-11.13-10.8v-.06c0-5.97,4.71-10.86,11.19-10.86s11.13,4.83,11.13,10.8v.06ZM78.14,85.82c0,4.17-3.18,6.51-7.71,6.51-3.18,0-6.39-1.11-8.91-3.36l2.73-3.27c1.89,1.56,3.87,2.55,6.27,2.55,1.89,0,3.03-.75,3.03-1.98v-.06c0-1.17-.72-1.77-4.23-2.67-4.23-1.08-6.96-2.25-6.96-6.42v-.06c0-3.81,3.06-6.33,7.35-6.33,3.06,0,5.67.96,7.8,2.67l-2.4,3.48c-1.86-1.29-3.69-2.07-5.46-2.07s-2.7.81-2.7,1.83v.06c0,1.38.9,1.83,4.53,2.76,4.26,1.11,6.66,2.64,6.66,6.3v.06ZM101.42,92.03h-4.62v-8.43h-8.52v8.43h-4.62v-21h4.62v8.31h8.52v-8.31h4.62v21ZM123.95,92.03h-15.99v-21h15.84v4.11h-11.25v4.26h9.9v4.11h-9.9v4.41h11.4v4.11ZM142.31,92.03l-4.5-6.72h-3.63v6.72h-4.62v-21h9.6c2.67,0,4.74.75,6.12,2.13,1.17,1.17,1.8,2.82,1.8,4.8v.06c0,3.39-1.83,5.52-4.5,6.51l5.13,7.5h-5.4Z'/>
    </g>
    <Path fillColor={fillColor} d='M81.01,3c43.08,0,78.01,34.93,78.01,78.01s-34.93,78.01-78.01,78.01S3,124.09,3,81.01,37.93,3,81.01,3M81.01,0C36.34,0,0,36.34,0,81.01s36.34,81.01,81.01,81.01,81.01-36.34,81.01-81.01S125.68,0,81.01,0h0Z'/>
    <g>
      <Path fillColor={fillColor} d='M33.02,179.63l7.11-8.18h4.76l-8.18,8.93,8.62,10.41h-4.99l-6.87-8.67-.44.43v8.24h-3.77v-19.35h3.77v8.18Z'/>
      <Path fillColor={fillColor} d='M66.2,181.14c0,5.89-4.24,10.09-10.33,10.09s-10.33-4.21-10.33-10.09,4.24-10.12,10.33-10.12,10.33,4.24,10.33,10.12ZM62.31,181.14c0-3.92-2.67-6.7-6.44-6.7s-6.44,2.79-6.44,6.7,2.67,6.67,6.44,6.67,6.44-2.76,6.44-6.67Z'/>
      <Path fillColor={fillColor} d='M79.19,175.86c-1.13-1.51-2.47-1.65-3.16-1.65-2.03,0-2.67,1.28-2.67,2.26,0,.46.15.9.61,1.3.46.44,1.1.67,2.32,1.1,1.51.52,2.93,1.04,3.92,1.94.87.78,1.65,2.09,1.65,4.06,0,3.77-2.73,6.35-6.73,6.35-3.57,0-5.66-2.18-6.67-3.66l2.46-2.35c.93,1.89,2.7,2.58,4.06,2.58,1.71,0,2.99-1.04,2.99-2.67,0-.7-.23-1.25-.75-1.74-.73-.67-1.89-1.04-2.99-1.42-1.02-.35-2.23-.81-3.22-1.68-.64-.55-1.54-1.62-1.54-3.6,0-2.84,1.92-5.69,6.21-5.69,1.22,0,3.51.23,5.48,2.26l-1.97,2.58Z'/>
      <Path fillColor={fillColor} d='M88.93,178.93h8.18v-7.48h3.77v19.35h-3.77v-8.62h-8.18v8.62h-3.77v-19.35h3.77v7.48Z'/>
      <Path fillColor={fillColor} d='M116.02,174.7h-6.96v4.32h6.67v3.25h-6.67v5.28h6.96v3.25h-10.73v-19.35h10.73v3.25Z'/>
      <Path fillColor={fillColor} d='M125.27,171.45c2.67,0,4.03.7,4.93,1.45,1.48,1.25,1.91,2.9,1.91,4.35,0,1.88-.75,3.54-2.26,4.55-.49.35-1.19.67-2.12.84l6.03,8.15h-4.7l-5.16-7.77h-.46v7.77h-3.77v-19.35h5.6ZM123.44,180.36h1.07c.72,0,3.71-.09,3.71-2.93s-2.96-2.9-3.66-2.9h-1.13v5.83Z'/>
    </g>
  </Svg>
)

const Svg = styled(SvgAttr)`
  height: ${props => props.height};
  width: ${props => props.width};
`

const Line = styled.line`
  fill: none;
  stroke: ${props => props.strokeColor};
  stroke-miterlimit: 10;
  stroke-width: 3px;
`

const Path = styled.path`
  fill: ${props => props.fillColor};
  stroke-width: 0;
`