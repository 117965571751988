import React from 'react'
import styled from 'styled-components'
import { SvgAttr } from '../SvgAttr/SvgAttr'

export const ControlPoint = ({
  width = '24',
  height = '24',
  svgFill = 'none',
  pathFill = '#00833D',
  styles
}) => {
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24' svgFill={svgFill} styles={styles}>
      <Path
        d='M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 
          6.49 17.51 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z'
        pathFill={pathFill}
      />
    </Svg>
  )
}

const Svg = styled(SvgAttr)`
  fill: ${props => props.svgFill};
  ${props => props.styles};
`

const Path = styled.path`
  fill: ${props => props.pathFill};
`